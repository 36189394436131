import React from "react";

type Props = React.PropsWithChildren<{
  className?: string;
}>;

export const Box: React.FC<Props> = (props: Props) => {
  return (
    <div className={`card ${props.className}`}>
      <div className="card-body">{props.children}</div>
    </div>
  );
};
