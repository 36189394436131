import React, { useMemo } from "react";

import { Input } from "../../../components/formComponents/input";
import { TextEditor } from "../../../components/textEditor/textEditor";
import {
  DropdownSelect,
  IDropdownList,
} from "@app/components/formComponents/dropdownSelect";
import { useAddEditModal } from "./hooks";
import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import { ModalComponent } from "../../../components/modals/modalComponent";
import {
  getDirectivenessOptions,
  getAffiliationOptions,
} from "../../../commonUtils/behaveHelper";

export interface IAddEditProfileDescriptionModalProps {
  editId: number;
  refetchProfileDescriptionList: () => void;
  closeProfileDecriptionModal: () => void;
}

export const AddEditProfileDescriptionModal = (
  props: IAddEditProfileDescriptionModalProps,
) => {
  const {
    languageText,
    isEditClicked,
    ProfileDescriptionInputs,
    profileDescriptionInfo,
    focusInput,
    languages,
    handleInputChange,
    handleBlurEvent,
    handleTextEditorChange,
    handleDropdownSelect,
    handleSaveClick,
  } = useAddEditModal(props);

  const directivenessList = getDirectivenessOptions(languageText);
  const affiliationList = getAffiliationOptions(languageText);
  const languageItems: Array<IDropdownList<number>> = languages.map((l) => {
    return {
      id: l.id,
      displayName: l.displayName,
      value: l.id,
    };
  });

  return (
    <>
      <ModalComponent
        width="lg"
        headerText={getLanguageValue(
          languageText,
          isEditClicked
            ? "Edit Profile Description"
            : "Add Profile Description",
        )}
        cancelButtonText={getLanguageValue(languageText, "Cancel")}
        submitButtonText={getLanguageValue(languageText, "Save")}
        handleCancelClick={props.closeProfileDecriptionModal}
        handleSubmitClick={handleSaveClick}
      >
        <DropdownSelect
          name={ProfileDescriptionInputs.directiveness}
          label={getLanguageValue(languageText, "Directiveness")}
          list={directivenessList}
          value={profileDescriptionInfo.directiveness}
          focusInput={focusInput.directiveness}
          handleDropdownSelect={handleDropdownSelect}
          required
        />
        <DropdownSelect
          name={ProfileDescriptionInputs.affiliation}
          label={getLanguageValue(languageText, "Affiliation")}
          list={affiliationList}
          value={profileDescriptionInfo.affiliation}
          focusInput={focusInput.affiliation}
          handleDropdownSelect={handleDropdownSelect}
          required
        />
        <Input
          name={ProfileDescriptionInputs.title}
          label={getLanguageValue(languageText, "Title")}
          placeholder={getLanguageValue(languageText, "Title")}
          value={profileDescriptionInfo.title}
          errorMessage={focusInput.title.errorMessage}
          handleInputChange={handleInputChange}
          handleBlurEvent={handleBlurEvent}
          required
        />
        <TextEditor
          name={ProfileDescriptionInputs.description}
          label={`${getLanguageValue(languageText, "Description")}:`}
          placeholder={getLanguageValue(languageText, "Description")}
          focusInput={focusInput.description}
          handleTextEditor={handleTextEditorChange}
          value={profileDescriptionInfo.description ?? ""}
          required
        />

        <TextEditor
          name={ProfileDescriptionInputs.affiliationText}
          label={`${getLanguageValue(languageText, "Affiliation text")}:`}
          placeholder={getLanguageValue(languageText, "Affiliation text")}
          focusInput={focusInput.affiliationText}
          handleTextEditor={handleTextEditorChange}
          value={profileDescriptionInfo.affiliationText ?? ""}
        />

        <TextEditor
          name={ProfileDescriptionInputs.directivenessText}
          label={`${getLanguageValue(languageText, "Directiveness text")}:`}
          placeholder={getLanguageValue(languageText, "Directiveness text")}
          focusInput={focusInput.directivenessText}
          handleTextEditor={handleTextEditorChange}
          value={profileDescriptionInfo.directivenessText ?? ""}
        />

        <TextEditor
          name={ProfileDescriptionInputs.function}
          label={`${getLanguageValue(languageText, "Function")}:`}
          placeholder={getLanguageValue(languageText, "Function")}
          focusInput={focusInput.function}
          handleTextEditor={handleTextEditorChange}
          value={profileDescriptionInfo.function ?? ""}
        />

        <TextEditor
          name={ProfileDescriptionInputs.success}
          label={`${getLanguageValue(languageText, "Success")}:`}
          placeholder={getLanguageValue(languageText, "Success")}
          focusInput={focusInput.success}
          handleTextEditor={handleTextEditorChange}
          value={profileDescriptionInfo.success ?? ""}
        />

        <TextEditor
          name={ProfileDescriptionInputs.pitfalls}
          label={`${getLanguageValue(languageText, "Pitfalls")}:`}
          placeholder={getLanguageValue(languageText, "Pitfalls")}
          focusInput={focusInput.pitfalls}
          handleTextEditor={handleTextEditorChange}
          value={profileDescriptionInfo.pitfalls ?? ""}
        />

        <TextEditor
          name={ProfileDescriptionInputs.combinationText}
          label={`${getLanguageValue(languageText, "Combination text")}:`}
          placeholder={getLanguageValue(languageText, "Combination text")}
          focusInput={focusInput.combinationText}
          handleTextEditor={handleTextEditorChange}
          value={profileDescriptionInfo.combinationText ?? ""}
        />

        <DropdownSelect
          name={ProfileDescriptionInputs.idiLanguageId}
          label={getLanguageValue(languageText, "CultureID")}
          defaultLabel={getLanguageValue(languageText, "Select Culture")}
          list={languageItems}
          value={profileDescriptionInfo.idiLanguageId}
          focusInput={focusInput.idiLanguageId}
          handleDropdownSelect={handleDropdownSelect}
          searchOption
          required
        />
      </ModalComponent>
    </>
  );
};
