import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Input } from "../../../components/formComponents/input";
import { routePath } from "../../routePaths";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../commonUtils/languageFunctionsHelper";
import {
  sendParticipantVerificationCodeEmail,
  verifyParticipantVerificationCode,
  verifyParticipantVerificationLink,
} from "../actions";
import { verifyUserEmailConfirmCode } from "../../twoFactorAuthenticationPage/actions";
import { IVerifyUserEmailBody } from "../../auth/signUp/hooks";
import { setSpinner } from "../../actions";
import { langKey } from "@app/consts";
import { RootState } from "@app/store/configureStore";
import { getParticipantProfileInfo } from "@app/commonUtils/participantProfileHelper";
import { ucQueryKey } from "../useParticipantProfileAuth";
import { UserId } from "@app/containers/reducer";
import { DigitCodeInput } from "@app/components/digitCodeInput";
import { VERIFICATION_CODE_LENGTH } from "@app/containers/auth/signUp";

interface IVerificationCodeProps {
  languageText: ITranslationObject;
  userId: UserId;
  password: string;
  uniqueCode: string;
  isVerificationCode: boolean;
  isEmailVerification: boolean;
  setIsVerificationCode: Dispatch<SetStateAction<boolean>>;
  handleNavigation: () => void;
}

export const VerificationCode = (
  props: IVerificationCodeProps,
): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const linkVerificationcode = queryParams.get("c") || "";

  const userLanguage = useSelector(
    (state: RootState) => state.loginReducer.userLanguage,
  );

  const [code, setCode] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");

  const verifyParticipantLink = async (): Promise<void> => {
    dispatch(setSpinner(true));
    await verifyParticipantVerificationLink(linkVerificationcode, dispatch)
      .then((response) => {
        if (response) {
          // why is this called? we're redirecting away immediately after.
          getParticipantProfileInfo(response.uniqueCode, dispatch, false);
          navigate(
            `${routePath.participantChangePassword}?${ucQueryKey}=${response.uniqueCode}&${langKey}=${userLanguage.userLanguageCode}`,
          );
        }
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  useEffect(() => {
    if (linkVerificationcode) {
      verifyParticipantLink();
    }
  }, []);

  const handleSendVerificationClick = (): void => {
    dispatch(setSpinner(true));
    sendParticipantVerificationCodeEmail(
      props.uniqueCode,
      userLanguage.userLanguageCode,
      dispatch,
    )
      .then((response) => {
        if (response?.success) props.setIsVerificationCode(true);
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setCode(e.target.value);
    if (errorMsg) setErrorMsg("");
  };

  const handleSubmit = async (): Promise<void> => {
    if (code) {
      dispatch(setSpinner(true));
      if (props.isEmailVerification) {
        const verifyUserEmailBody: IVerifyUserEmailBody = {
          userId: props.userId,
          password: props.password,
          emailConfirmCode: code,
        };
        await verifyUserEmailConfirmCode(verifyUserEmailBody, dispatch)
          .then((response) => {
            const accessToken = response?.accessToken?.token;
            if (accessToken) {
              props.handleNavigation();
            }
          })
          .finally(() => dispatch(setSpinner(false)));
      } else {
        await verifyParticipantVerificationCode(
          props.uniqueCode,
          code,
          dispatch,
        )
          .then((response) => {
            if (response?.success) {
              navigate(
                `${routePath.participantChangePassword}?${ucQueryKey}=${props.uniqueCode}&${langKey}=${userLanguage.userLanguageCode}`,
              );
            }
          })
          .finally(() => dispatch(setSpinner(false)));
      }
    } else
      setErrorMsg(
        getLanguageValue(props.languageText, "Enter verfication code"),
      );
  };

  return (
    <div>
      <h3>{getLanguageValue(props.languageText, "Verification")}</h3>

      {props.isVerificationCode ? (
        <>
          <div className="fs-5 my-4">
            {getLanguageValue(
              props.languageText,
              "Check your email inbox for the verification code",
            )}
            .
          </div>

          <DigitCodeInput
            handleSubmit={handleSubmit}
            handleChange={(value) => {
              setCode(value);
            }}
            length={VERIFICATION_CODE_LENGTH}
            value={code}
          />

          <button
            className="btn btn-success w-100 fs-5 mt-4"
            onClick={handleSubmit}
          >
            {getLanguageValue(props.languageText, "Verify")}
          </button>
        </>
      ) : (
        <>
          <div className="fs-5 my-4">
            {getLanguageValue(props.languageText, "Verify your email address")}.
          </div>
          <button
            className="btn btn-success w-100"
            onClick={handleSendVerificationClick}
          >
            {getLanguageValue(
              props.languageText,
              "Send verification code to my email",
            )}
          </button>
        </>
      )}
    </div>
  );
};
