import React from "react";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../commonUtils/languageFunctionsHelper";
import {
  DimensionCases,
  getDimensionsBarHeight,
} from "../../commonUtils/dimensionsHelper";

interface reportSubset {
  sFlex: number;
  oFlex: number;
  sAffPos: number;
  oAffPos: number;
  sDirPos: number;
  oDirPos: number;
}

interface IDimensionsChartProps {
  languageText: ITranslationObject;
  report: reportSubset;
}

export const DimensionsChart = (props: IDimensionsChartProps): JSX.Element => {
  return (
    <div className="card-body mt-4">
      <div className="row text-center">
        {/* Directiveness */}
        <div className="col-12 col-md-4">
          <div className="d-flex flex-column justify-content-end idi-profile-dimensions-height rounded border p-2 text-white">
            <div className="d-flex align-items-end position-relative w-100 h-100 mb-2 gap-2">
              <div
                className="bg-danger p-2 position-absolute bottom-0 start-0"
                style={{
                  height: `${getDimensionsBarHeight(
                    DimensionCases.Inquery,
                    props.report.sDirPos,
                  )}`,
                }}
              ></div>
              <div
                className="w-50 bg-success d-flex justify-content-center align-items-end pb-1"
                style={{
                  height: `${getDimensionsBarHeight(
                    DimensionCases.Inquery,
                    props.report.oDirPos,
                  )}`,
                }}
              >
                <span className="z-1">
                  {getLanguageValue(props.languageText, "Inquery")}
                </span>
              </div>

              <div
                className="bg-danger p-2 position-absolute bottom-0 end-0"
                style={{
                  height: `${getDimensionsBarHeight(
                    DimensionCases.Advocacy,
                    props.report.sDirPos,
                  )}`,
                }}
              ></div>
              <div
                className="w-50 bg-success d-flex justify-content-center align-items-end pb-1"
                style={{
                  height: `${getDimensionsBarHeight(
                    DimensionCases.Advocacy,
                    props.report.oDirPos,
                  )}`,
                }}
              >
                <span className="z-1">
                  {getLanguageValue(props.languageText, "Advocacy")}
                </span>
              </div>
            </div>
            <div className="w-100 text-dark fw-bold">
              {getLanguageValue(props.languageText, "Directiveness")}
            </div>
          </div>
        </div>

        {/* Affiliation */}
        <div className="col-12 col-md-4 align-items-end">
          <div className="d-flex flex-column justify-content-end idi-profile-dimensions-height rounded border p-2 text-white">
            <div className="d-flex align-items-end position-relative w-100 h-100 mb-2 gap-2">
              <div
                className="bg-danger p-2 position-absolute bottom-0 start-0"
                style={{
                  height: `${getDimensionsBarHeight(DimensionCases.Task, props.report.sAffPos)}`,
                }}
              ></div>
              <div
                className="w-50 bg-success d-flex justify-content-center align-items-end pb-1"
                style={{
                  height: `${getDimensionsBarHeight(DimensionCases.Task, props.report.oAffPos)}`,
                }}
              >
                <span className="z-1">
                  {getLanguageValue(props.languageText, "Task")}
                </span>
              </div>

              <div
                className="bg-danger p-2 position-absolute bottom-0 end-0"
                style={{
                  height: `${getDimensionsBarHeight(DimensionCases.People, props.report.sAffPos)}`,
                }}
              ></div>
              <div
                className="w-50 bg-success d-flex justify-content-center align-items-end pb-1"
                style={{
                  height: `${getDimensionsBarHeight(DimensionCases.People, props.report.oAffPos)}`,
                }}
              >
                <span className="z-1">
                  {getLanguageValue(props.languageText, "People")}
                </span>
              </div>
            </div>
            <div className="w-100 text-dark fw-bold">
              {getLanguageValue(props.languageText, "Affiliation")}
            </div>
          </div>
        </div>

        {/* Adaptability */}
        <div className="col-12 col-md-4">
          <div className="d-flex flex-column justify-content-end idi-profile-dimensions-height rounded border p-2 text-white">
            <div className="d-flex align-items-end position-relative w-100 h-100 mb-2 gap-2">
              <div
                className="bg-danger p-2 position-absolute bottom-0 start-0"
                style={{
                  height: `${getDimensionsBarHeight(
                    DimensionCases.OwnNeeds,
                    props.report.sFlex,
                  )}`,
                }}
              ></div>
              <div
                className="w-50 bg-success d-flex justify-content-center align-items-end pb-1"
                style={{
                  height: `${getDimensionsBarHeight(
                    DimensionCases.OwnNeeds,
                    props.report.oFlex,
                  )}`,
                }}
              >
                <span className="z-1">
                  {getLanguageValue(props.languageText, "Own needs")}
                </span>
              </div>

              <div
                className="bg-danger p-2 position-absolute bottom-0 end-0"
                style={{
                  height: `${getDimensionsBarHeight(
                    DimensionCases.OthersNeeds,
                    props.report.sFlex,
                  )}`,
                }}
              ></div>
              <div
                className="w-50 bg-success d-flex justify-content-center align-items-end pb-1"
                style={{
                  height: `${getDimensionsBarHeight(
                    DimensionCases.OthersNeeds,
                    props.report.oFlex,
                  )}`,
                }}
              >
                <span className="z-1">
                  {getLanguageValue(props.languageText, "Others needs")}
                </span>
              </div>
            </div>
            <div className="w-100 text-dark fw-bold">
              {getLanguageValue(props.languageText, "Adaptability")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
