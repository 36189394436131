import * as React from "react";
import { Step, StepComponent } from "./createProfilePage";
import { CurrentStepPanel, UpcomingStepPanel } from "./panels";
import { AffiliationDirectivenessGraph } from "../activityList/editActivity/modals/reports/components/affiliationDirectivenessGraph";
import { useSelector } from "react-redux";
import { RootState } from "@app/store/configureStore";
import { Box } from "@app/components/Box";
import { getFormattedDateOnly } from "@app/commonUtils/dateFunctionsHelper";
import { IProfileReport } from "@app/types";
import { Affiliation } from "../activityList/editActivity/modals/reports/components/affiliation";
import { Directiveness } from "../activityList/editActivity/modals/reports/components/directiveness";
import { Adaptability } from "../activityList/editActivity/modals/reports/components/adaptability";

import { getLanguageValue } from "@app/commonUtils/languageFunctionsHelper";
import {
  getDimensionsBarHeight,
  DimensionCases,
} from "@app/commonUtils/dimensionsHelper";
import { BarChart } from "@app/components/graphs/BarChart";
import { Pill } from "@app/components/Pill";

export const ProfileStep: StepComponent = (props) => {
  const __t = props.__t;
  const isSelfDelivery = !!props.profile.selfDeliveryTemplateId;
  const [report, setReport] = React.useState<IProfileReport | undefined>(
    undefined,
  );

  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  React.useEffect(() => {
    if (props.status !== "current") {
      return;
    }
    props.api.getProfileReport(props.profile.profileId).then(setReport);
  }, [props.status]);

  const title = (
    <span>
      {__t("IDI-Profile")}
      {report && report.title && <Pill>{report.title}</Pill>}
    </span>
  );

  switch (props.status) {
    case "upcoming":
      return <UpcomingStepPanel title={title} />;
    case "current": {
      return (
        <CurrentStepPanel
          __t={__t}
          title={title}
          onPreviousClick={
            isSelfDelivery
              ? (event) => {
                  event.preventDefault();
                  props.setStep(Step.Presentation);
                }
              : undefined
          }
        >
          <div className="p-4">
            {!!report && (
              <>
                <div className="row mb-5">
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-6">
                        <Box className="mb-4">
                          <div className="fw-bold">{__t("Norm")}</div>{" "}
                          <div>{report.culture}</div>
                        </Box>
                      </div>

                      <div className="col-6">
                        <Box className="mb-4">
                          <div className="fw-bold">{__t("Date")}</div>
                          <div>{getFormattedDateOnly(report.date)}</div>
                        </Box>
                      </div>
                    </div>

                    <div className="mb-4">
                      <AffiliationDirectivenessGraph
                        report={report}
                        languageText={languageText}
                      />
                    </div>
                    <div className="row">
                      <div className="col-12 col-lg-6 mb-4">
                        <BarChart
                          label={getLanguageValue(
                            languageText,
                            "Directiveness",
                          )}
                          bar1NarrowHeight={getDimensionsBarHeight(
                            DimensionCases.Inquery,
                            report.sDirPos,
                          )}
                          bar1Height={getDimensionsBarHeight(
                            DimensionCases.Inquery,
                            report.oDirPos,
                          )}
                          bar1Label={getLanguageValue(languageText, "Inquery")}
                          bar2NarrowHeight={getDimensionsBarHeight(
                            DimensionCases.Advocacy,
                            report.sDirPos,
                          )}
                          bar2Height={getDimensionsBarHeight(
                            DimensionCases.Advocacy,
                            report.oDirPos,
                          )}
                          bar2Label={getLanguageValue(languageText, "Advocacy")}
                        />
                      </div>
                      <div className="col-12 col-lg-6 mb-4">
                        <BarChart
                          label={getLanguageValue(languageText, "Affiliation")}
                          bar1NarrowHeight={getDimensionsBarHeight(
                            DimensionCases.Task,
                            report.sAffPos,
                          )}
                          bar1Height={getDimensionsBarHeight(
                            DimensionCases.Task,
                            report.oAffPos,
                          )}
                          bar1Label={getLanguageValue(languageText, "Task")}
                          bar2NarrowHeight={getDimensionsBarHeight(
                            DimensionCases.People,
                            report.sAffPos,
                          )}
                          bar2Height={getDimensionsBarHeight(
                            DimensionCases.People,
                            report.oAffPos,
                          )}
                          bar2Label={getLanguageValue(languageText, "People")}
                        />
                      </div>
                    </div>
                    <Box className="mb-4">
                      <Affiliation
                        languageText={languageText}
                        profileMeasure={report.affiliationMeasure ?? 0}
                        affiliationMessage={report.affiliationMessage ?? ""}
                        affiliationSpreadText={
                          report.affiliationSpreadText ?? ""
                        }
                      />
                    </Box>

                    <Box className="mb-4">
                      <Directiveness
                        languageText={languageText}
                        profileMeasure={report.directivenessMeasure ?? 0}
                        directivenessMessage={report.directivenessMessage ?? ""}
                        directivenessSpreadText={
                          report.directivenessSpreadText ?? ""
                        }
                      />
                    </Box>
                  </div>
                  <div className="col-lg-6">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: report.reportProfileDescription ?? "",
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <h5 className="fw-bold mb-3">{__t("Adaptability")}</h5>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-4">
                      <div className="mb-4">
                        <Adaptability
                          languageText={languageText}
                          report={report}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <Box className="bg-info-subtle mb-4">
                      <h6>{__t("About adaptability")}</h6>
                      <p className="mb-0">{report.adaptabilityText1}</p>
                    </Box>
                    <div>
                      <h6>{__t("Your adaptability")}</h6>
                      <p>{report.adaptabilityRespondentsText}</p>
                      <p>
                        {report.adaptabilityText2}
                        <br />
                        {report.adaptabilitySpreadText}
                      </p>
                      <p>{report.adaptabilityText3}</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 offset-lg-3">
                    <BarChart
                      label={getLanguageValue(languageText, "Adaptability")}
                      bar1Height={getDimensionsBarHeight(
                        DimensionCases.OwnNeeds,
                        report.oFlex,
                      )}
                      bar1NarrowHeight={getDimensionsBarHeight(
                        DimensionCases.OwnNeeds,
                        report.sFlex,
                      )}
                      bar1Label={getLanguageValue(languageText, "Own Needs")}
                      bar2Height={getDimensionsBarHeight(
                        DimensionCases.OthersNeeds,
                        report.oFlex,
                      )}
                      bar2NarrowHeight={getDimensionsBarHeight(
                        DimensionCases.OthersNeeds,
                        report.sFlex,
                      )}
                      bar2Label={getLanguageValue(languageText, "Others Needs")}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </CurrentStepPanel>
      );
    }
    case "completed": {
      return null;
    }
  }
};
