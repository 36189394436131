import React from "react";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../../../commonUtils/languageFunctionsHelper";
import { DeviationValue } from "./deviationValues/lowDeviationValue";

interface IAffiliationProps {
  languageText: ITranslationObject;
  profileMeasure: number;
  affiliationMessage: string;
  affiliationSpreadText: string;
}

export const Affiliation = (props: IAffiliationProps): JSX.Element => {
  return (
    <>
      <h5 className="fw-bold mb-2">
        {getLanguageValue(props.languageText, "Affiliation")}
      </h5>
      <DeviationValue
        profileMeasure={props.profileMeasure}
        deviationMessage={props.affiliationMessage}
        deviationSpreadText={props.affiliationSpreadText}
      />
    </>
  );
};
