import React from "react";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../commonUtils/languageFunctionsHelper";
import { ModalComponent } from "./modalComponent";

type IDeleteModalProps = React.PropsWithChildren<{
  languageText: ITranslationObject;
  headerText: string;
  bodyText: string;
  handleDeleteClick: React.MouseEventHandler<HTMLButtonElement>;
  closeDeleteModal: React.MouseEventHandler<HTMLButtonElement>;
}>;

export const DeleteModal: React.FC<IDeleteModalProps> = (props) => {
  return (
    <>
      <ModalComponent
        headerText={`${getLanguageValue(props.languageText, "Delete")} ${props.headerText}`}
        submitButtonText={getLanguageValue(props.languageText, "Delete")}
        cancelButtonText={getLanguageValue(props.languageText, "Discard")}
        handleSubmitClick={props.handleDeleteClick}
        handleCancelClick={props.closeDeleteModal}
        isSubmitDangerButton={true}
      >
        {getLanguageValue(props.languageText, "Do you really want to delete")} "
        {props.bodyText && `${props.bodyText}`}"?
        {props.children}
      </ModalComponent>
    </>
  );
};
