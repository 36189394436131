import React from "react";

import { AffiliationDirectivenessGraph } from "../../components/affiliationDirectivenessGraph";
import { Adaptability } from "../../components/adaptability";
import { Directiveness } from "../../components/directiveness";
import { Affiliation } from "../../components/affiliation";
import { CreatedDate } from "../../components/createdDate";
import { CultureNorm } from "../../components/cultureNorm";
import { Respondents } from "../../components/respondents";
import {
  getLanguageValue,
  ITranslationObject,
} from "@app/commonUtils/languageFunctionsHelper";
import { IProfileReport } from "@app/types";

interface IIndividualPreviewProps {
  languageText: ITranslationObject;
  participantReport: IProfileReport;
}

export const IndividualPreview = (
  props: IIndividualPreviewProps,
): JSX.Element => {
  return (
    <div className="d-flex justify-content-between flex-wrap gap-3 ms-4">
      <div className="individual-matris">
        <div className="individual-matris-affDir border p-2 ps-4 pb-4">
          <div className="fs-5 fw-bold p-2">
            {props.participantReport.title}
          </div>
          <AffiliationDirectivenessGraph
            languageText={props.languageText}
            report={props.participantReport}
          />
        </div>

        <div className="individual-matris-dir border border-top-0 p-2">
          <Directiveness
            languageText={props.languageText}
            profileMeasure={props.participantReport.directivenessMeasure!}
            directivenessMessage={props.participantReport.directivenessMessage!}
            directivenessSpreadText={
              props.participantReport.directivenessSpreadText ?? ""
            }
          />
        </div>

        <div className="individual-matris-aff border border-top-0 p-2 mb-4">
          <Affiliation
            languageText={props.languageText}
            profileMeasure={props.participantReport.affiliationMeasure!}
            affiliationMessage={props.participantReport.affiliationMessage!}
            affiliationSpreadText={
              props.participantReport.affiliationSpreadText ?? ""
            }
          />
        </div>

        <div>
          <h5 className="fw-bold mb-3">
            {getLanguageValue(props.languageText, "Adaptability")}
          </h5>
        </div>

        <div className="individual-matris-adap border p-2">
          <Adaptability
            languageText={props.languageText}
            report={props.participantReport}
          />
        </div>
      </div>

      <div>
        <CreatedDate
          languageText={props.languageText}
          createdDate={props.participantReport.date}
        />

        <div className="my-3">
          <CultureNorm
            languageText={props.languageText}
            language={props.participantReport.culture}
          />
        </div>

        <Respondents
          languageText={props.languageText}
          roleId={props.participantReport.roleId}
          roleText={props.participantReport.roleText}
          noOfRespondents={props.participantReport.noOfRespondents}
          respondents={props.participantReport.respondents}
        />
      </div>
    </div>
  );
};
