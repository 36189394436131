import * as React from "react";

import { PhoneNumberInput } from "../../../employeeList/addEmployeeModal/phoneNumberInput";
import { Input } from "../../../../components/formComponents/input";
import { getLanguageValue } from "../../../../commonUtils/languageFunctionsHelper";
import { E164Number } from "libphonenumber-js/types";
import { ILanguage } from "@app/containers/commonInterfaces";
import { useState } from "react";
import { IListRespondent } from "./selectFromList";
import { UserId } from "@app/containers/reducer";
import { useSelector } from "react-redux";
import { RootState } from "@app/store/configureStore";
import { ValidationHelper } from "@app/containers/validationHelper";
import { IProfileDetails } from "@app/types";
import { IProfileRespondent } from "@app/containers/activityList/editActivity/interface";

export const ManualRespondentInputs = {
  name: "name",
  emailAddress: "emailAddress",
  phoneNumber: "phoneNumber",
  nameError: "nameError",
  emailError: "emailError",
  phoneNumberError: "phoneNumberError",
} as const;

interface IAddManuallyProps {
  languages: Array<ILanguage>;
  respondents: IListRespondent[];
  invitedRespondents: IProfileRespondent[];
  profile: IProfileDetails;
  handleAddMultipleRespondents: (respondents: IListRespondent[]) => void;
}

interface ManualRow {
  name: string;
  emailAddress: string;
  phoneNumber: string;
}

const emptyRow: ManualRow = {
  name: "",
  emailAddress: "",
  phoneNumber: "",
};

type RowError = {
  name?: string;
  emailAddress?: string;
};

type FormError = {
  [key: number]: RowError;
};
export const AddManually: React.FC<IAddManuallyProps> = (props) => {
  const [errors, setErrors] = useState<FormError>({});

  const [candidateRespondentRows, setCandidateRespondentRows] = useState<
    ManualRow[]
  >([emptyRow]);

  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  /**
   * Create empty rows based on if the role can be changed by participant (3)
   * or as many as the facilitator dictates if not (profile.noOfRespondents)
   */
  React.useEffect(() => {
    //Create empty rows
    //If role can be changed by participant, we should default to 3 rows
    //If not, we should create as many rows as needed
    let emptyRowsToCreate = 0;

    if (props.profile.roleCanBeChangedByParticipant) {
      //Start with 3 and aim for 3, but at least show 1
      emptyRowsToCreate = Math.max(
        3 - (props.respondents.length + props.invitedRespondents.length),
        1,
      );
    } else {
      //facilitator has set this value
      emptyRowsToCreate = Math.max(
        props.profile.noOfRespondents -
          (props.respondents.length + props.invitedRespondents.length),
        0,
      );
    }

    const emptyRowArray: ManualRow[] = [];
    for (let i = 0; i < emptyRowsToCreate; i++) {
      emptyRowArray.push(emptyRow);
    }

    setCandidateRespondentRows(emptyRowArray);
  }, [props.respondents]);

  function trimEmptyRows(): ManualRow[] {
    return candidateRespondentRows!.filter((item) => {
      //We want to filter out those that both have empty name and email
      return !(item.name === "" && item.emailAddress === "");
    });
  }

  function validateRows(candidates: ManualRow[]): FormError {
    const errors: FormError = {};
    const duplicatedEmail = new Map<string, number>();

    candidates.forEach((candidate, index) => {
      const candidateError: RowError = {};
      if (duplicatedEmail.has(candidate.emailAddress)) {
        candidateError.emailAddress = getLanguageValue(
          languageText,
          "This email is a duplicate",
        );
      } else {
        duplicatedEmail.set(candidate.emailAddress, index);
      }
      if (!candidate.name) {
        candidateError.name = getLanguageValue(languageText, "Missing name");
      }
      if (!candidate.emailAddress) {
        candidateError.emailAddress = getLanguageValue(
          languageText,
          "Email is missing",
        );
      } else if (!ValidationHelper.isEmailValid(candidate.emailAddress)) {
        candidateError.emailAddress = getLanguageValue(
          languageText,
          "Email is invalid",
        );
      }

      //Check if email already exists in the invitee list
      if (
        candidate.emailAddress &&
        props.respondents.some((r) => r.emailAddress === candidate.emailAddress)
      ) {
        candidateError.emailAddress = getLanguageValue(
          languageText,
          "Email is already in the list of respondents to be invited",
        );
      }

      //Check if email already exists in the already invited list
      if (
        props.invitedRespondents.some((r) => r.email === candidate.emailAddress)
      ) {
        candidateError.emailAddress = getLanguageValue(
          languageText,
          "Email is already invited",
        );
      }

      if (Object.keys(candidateError).length > 0) {
        errors[index] = candidateError;
      }
    });

    return errors;
  }
  const handleChange = (
    index: number,
    field: keyof ManualRow,
    value: string,
  ) => {
    const updatedRows = candidateRespondentRows.map((item) => ({ ...item }));
    updatedRows[index][field] = value;

    //Remove any associated errors for this row
    //This will remove "all" errors for the row in question, but that's fine.
    const updatedErrors = errors;
    delete updatedErrors[index];
    setErrors(updatedErrors);

    setCandidateRespondentRows(updatedRows);
  };

  function removeRow(indexToDelete: number) {
    const updatedRows = candidateRespondentRows.filter(
      (_, index) => index !== indexToDelete,
    );
    setCandidateRespondentRows(updatedRows);
  }

  function addEmptyRow() {
    const updatedRows = candidateRespondentRows.map((item) => item);
    updatedRows.push(emptyRow);
    setCandidateRespondentRows(updatedRows);
  }

  function convertManualRowsToRespondents(
    candidates: ManualRow[],
  ): IListRespondent[] {
    return candidates.map(
      (candidate): IListRespondent => ({
        id: 0 as UserId,
        name: candidate.name,
        emailAddress: candidate.emailAddress,
        phoneNumber: candidate.phoneNumber,
        instructionLanguageId: null,
      }),
    );
  }

  function handleAddButtonClick() {
    //Remove empty rows
    const trimmedCandidates = trimEmptyRows();
    //Check for validation errors
    const validationErrors = validateRows(trimmedCandidates);
    setErrors(validationErrors);
    //Check if we have validation errors
    if (
      Object.keys(validationErrors).length === 0 &&
      trimmedCandidates.length > 0
    ) {
      const respondentsToAdd =
        convertManualRowsToRespondents(trimmedCandidates);
      props.handleAddMultipleRespondents(respondentsToAdd);
      setCandidateRespondentRows([emptyRow]);
    }
  }

  return (
    <div>
      {candidateRespondentRows.map((candidate, index) => (
        <div key={index} className="add-manually-row d-flex mb-3">
          <div className="row justify-content-center flex-grow-1">
            <div className="col-md-4 mb-3 mb-md-0">
              <Input
                name={ManualRespondentInputs.name}
                placeholder={getLanguageValue(languageText, "Name")}
                value={candidate.name}
                errorMessage={errors[index]?.name}
                handleInputChange={(event) => {
                  handleChange(index, "name", event.target.value);
                }}
                required
                narrow={true}
              />
            </div>
            <div className="col-md-3 mb-3 mb-md-0">
              <Input
                name={ManualRespondentInputs.emailAddress}
                placeholder={getLanguageValue(languageText, "Email")}
                value={candidate.emailAddress}
                errorMessage={errors[index]?.emailAddress}
                handleInputChange={(event) => {
                  handleChange(index, "emailAddress", event.target.value);
                }}
                required
                narrow={true}
              />
            </div>
            <div className="col-md-5 mb-3 mb-md-0">
              <PhoneNumberInput
                value={(candidate.phoneNumber as E164Number) || ""}
                onChange={(value) => {
                  handleChange(index, "phoneNumber", value);
                }}
                languageText={languageText}
                placeholder={getLanguageValue(languageText, "Mobile number")}
                narrow={true}
              />
            </div>
          </div>
          <div className="ms-2">
            <button
              className="btn btn-outline-danger"
              role="button"
              onClick={() => {
                removeRow(index);
              }}
            >
              <i className="bi bi-trash" />
            </button>
          </div>
        </div>
      ))}
      {/* The second condition here is if you have a fixed value for noOfRespondents
       * and you remove a manual row accidentally, you should be able to add it back.
       * - Joakim, 25-01-10 */}
      {props.profile.roleCanBeChangedByParticipant ||
        (candidateRespondentRows.length + props.respondents.length <
          props.profile.noOfRespondents && (
          <div className="add-manually-row mb-3">
            <div className="d-grid">
              <button
                className="btn btn-light"
                type="button"
                onClick={addEmptyRow}
              >
                <i className="bi bi-plus-lg"></i>
              </button>
            </div>
          </div>
        ))}
      {candidateRespondentRows.length > 0 ? (
        <div>
          <button className="btn btn-secondary" onClick={handleAddButtonClick}>
            {getLanguageValue(languageText, "Add")}
          </button>
        </div>
      ) : (
        <div>
          {`${getLanguageValue(languageText, "Maximum")} ${
            props.profile.noOfRespondents
          } ${getLanguageValue(languageText, "respondents can be invited")}.`}
        </div>
      )}
    </div>
  );
};
