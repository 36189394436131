import React from "react";

import { InvoiceBilling } from "../../../../components/invoice/invoiceBilling";
import { useBillingModal } from "./hooks";
import { IEditActivity } from "../interface";
import { ModalComponent } from "../../../../components/modals/modalComponent";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../commonUtils/languageFunctionsHelper";

export interface IProfileInvoiceBillingModalProps {
  languageText: ITranslationObject;
  profileData: IEditActivity;
  handleCloseClick: () => void;
}

export const ProfileInvoiceBillingModal = (
  props: IProfileInvoiceBillingModalProps,
) => {
  const {
    countries,
    paymentTypes,
    existingAddresses,
    selectAddressModal,
    existingInvoiceDetails,
    invoiceFormData,
    focusInput,
    fortnoxClientList,
    fortnoxInvoiceList,
    selectedFortnoxCustomer,
    showFortnoxInvoiceInputs,
    productFormData,
    isEditMode,
    productsList,
    handleSelectPaymentMethod,
    openSelectAddressModal,
    closeSelectAddressModal,
    onSelectAddressClick,
    handleInputChange,
    handlePhoneInputChange,
    handleTextAreaChange,
    handleCountrySelect,
    handleBlurEvent,
    handleTextAreaBlurEvent,
    handleFortnoxClientSelect,
    handleInvoiceSelect,
    handleCloseCustomerDefaultPopup,
    handleSaveCustomerAsDefault,
    handleProductSelect,
    handleProductInputChange,
    handleAddProduct,
    handleSaveProduct,
    handleCancelProduct,
    handleEditProduct,
    handleDeleteProduct,
    createOrUpdateInvoiceDraft,
    handleSendToFortnox,
  } = useBillingModal(props);

  return (
    <>
      <ModalComponent
        width="xl"
        headerText={getLanguageValue(props.languageText, "Billing details")}
        cancelButtonText={getLanguageValue(props.languageText, "Cancel")}
        submitButtonText={
          existingInvoiceDetails.idiInvoice.fortnoxInvoiceNumber
            ? getLanguageValue(props.languageText, "Update To Fortnox")
            : existingInvoiceDetails.idiInvoice.id
              ? getLanguageValue(props.languageText, "Send To Fortnox")
              : ""
        }
        handleCancelClick={props.handleCloseClick}
        handleSubmitClick={handleSendToFortnox}
      >
        <InvoiceBilling
          languageText={props.languageText}
          isActivity={true}
          countries={countries}
          paymentTypes={paymentTypes}
          existingAddresses={existingAddresses}
          selectAddressModal={selectAddressModal}
          invoiceFormData={invoiceFormData}
          focusInput={focusInput}
          fortnoxClientList={fortnoxClientList}
          fortnoxInvoiceList={fortnoxInvoiceList}
          selectedFortnoxCustomer={selectedFortnoxCustomer}
          showFortnoxInvoiceInputs={showFortnoxInvoiceInputs}
          productFormData={productFormData}
          isEditMode={isEditMode}
          productsList={productsList}
          handleSelectPaymentMethod={handleSelectPaymentMethod}
          openSelectAddressModal={openSelectAddressModal}
          closeSelectAddressModal={closeSelectAddressModal}
          onSelectAddressClick={onSelectAddressClick}
          handleInputChange={handleInputChange}
          handlePhoneInputChange={handlePhoneInputChange}
          handleTextAreaChange={handleTextAreaChange}
          handleCountrySelect={handleCountrySelect}
          handleBlurEvent={handleBlurEvent}
          handleTextAreaBlurEvent={handleTextAreaBlurEvent}
          handleFortnoxClientSelect={handleFortnoxClientSelect}
          handleInvoiceSelect={handleInvoiceSelect}
          handleCloseCustomerDefaultPopup={handleCloseCustomerDefaultPopup}
          handleSaveCustomerAsDefault={handleSaveCustomerAsDefault}
          handleProductSelect={handleProductSelect}
          handleProductInputChange={handleProductInputChange}
          handleAddProduct={handleAddProduct}
          handleSaveProduct={handleSaveProduct}
          handleCancelProduct={handleCancelProduct}
          handleEditProduct={handleEditProduct}
          handleDeleteProduct={handleDeleteProduct}
          createOrUpdateInvoiceDraft={createOrUpdateInvoiceDraft}
        />
      </ModalComponent>
    </>
  );
};
