import clsx from "clsx";
import React from "react";
import { MutableRefObject, useRef, useState } from "react";

interface ITogglePopOverProps {
  tooltipText: string;
  btnClassname?: string;
}

export const PopoverTooltip = (props: ITogglePopOverProps): JSX.Element => {
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const buttonRef: MutableRefObject<null> = useRef(null);
  const popoverRef: MutableRefObject<null> = useRef(null);

  const togglePopover = (): void => {
    setShowPopover(!showPopover);
  };

  const handlePopover = (): void => {
    setShowPopover(false);
  };

  return (
    <div className="d-inline-block px-2">
      <i
        ref={buttonRef}
        className={clsx(
          "bi bi-info-circle fs-6",
          props.btnClassname && props.btnClassname,
        )}
        aria-hidden="true"
        role="button"
        onMouseEnter={togglePopover}
        onMouseLeave={handlePopover}
      />
      {showPopover && (
        <div
          className="bg-secondary text-white rounded shadow-sm p-2 position-absolute z-1 col-3"
          ref={popoverRef}
        >
          {props.tooltipText}
        </div>
      )}
    </div>
  );
};
