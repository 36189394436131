import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import {
  ICombinedGroupReportParticipants,
  IEditActivity,
  IEditActivityDeliveredProfile,
  IParticipantProfileGroupReport,
  IProfileGroupReport,
} from "../../../../interface";
import { GroupAffDirGraph } from "@app/components/graphs/groupAffDirGraph";
import { GroupAdaptability } from "@app/components/graphs/groupAdaptability";
import { GroupProfileParticipants } from "./groupProfileParticipants";
import { getGroupReport } from "../../../../actions";
import { ITranslationObject } from "../../../../../../../commonUtils/languageFunctionsHelper";
import { DeliveredProfileParticipants } from "./deliveredProfileParticipants";
import { ReportType, ReportTypes } from "../../../../../../../types";
import { emptyParticipantProfileGroupReport } from "../../../../../../utils";
import { GuidePages } from "@app/containers/commonEnums";
import { ActivityId, ProfileId } from "@app/containers/reducer";
import { ILanguage } from "@app/containers/commonInterfaces";

interface IGroupPreviewProps {
  languageText: ITranslationObject;
  id: ActivityId;
  profileData: IEditActivity;
  isInstructionHelpClicked?: boolean;
  completedStatusEditProfileGuideDone?: boolean;
  reportType: ReportType;
  showUsers?: boolean;
  selectedProfileIds: Array<ProfileId>;
  deliveredProfileParticipants?: IEditActivityDeliveredProfile[];
  deliveredProfileParticipantsCount?: number;
  isActivityCancelled?: boolean;
  //TODO: Check if this should be optional - Joakim, 241129
  languages?: ReadonlyArray<ILanguage>;
  cultureValue?: string;
  messageTranslation: ITranslationObject;
  allProfilesDelivered?: boolean;
  handleExitGuide?: (guidePage: GuidePages, isSkipped: boolean) => void;
  refetchFullProfile?: () => void;
  refetchDeliveredParticipants?: () => void;
  handleUnPublishProfile?: () => void;
  handleTransferProfile?: () => void;
}

export const GroupPreview = (props: IGroupPreviewProps): JSX.Element => {
  const dispatch = useDispatch();

  const initialReportState: IParticipantProfileGroupReport =
    emptyParticipantProfileGroupReport();

  const initialGroupReportState: IProfileGroupReport = {
    selfGroupReport: initialReportState,
    otherGroupReport: initialReportState,
  };
  const [profileGroupReport, setProfileGroupReport] =
    useState<IProfileGroupReport>(initialGroupReportState);
  const [hoveredProfileId, setHoveredProfileId] = useState<ProfileId>(
    0 as ProfileId,
  );

  const [copyPastaSelectedProfileIds, setCopyPastaSelectedProfileIds] =
    useState<Array<ProfileId>>([]); // Checkbox selected participant

  useEffect(() => {
    if (!props.profileData.idiLanguageId) {
      return;
    }

    getGroupReport(props.selectedProfileIds, ReportTypes.BOTH, dispatch).then(
      (response) => {
        if (response) {
          setProfileGroupReport(response);
        }
      },
    );
  }, [props.profileData.idiLanguageId]);

  // Checkbox fn's
  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.checked) {
      // Select all participants
      const allParticipants = profileParticipants.map((p) => p.profileId);
      setCopyPastaSelectedProfileIds(allParticipants);
    } else {
      // Unselect all participants
      setCopyPastaSelectedProfileIds([]);
    }
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: ProfileId,
  ): void => {
    setCopyPastaSelectedProfileIds((prev) => {
      if (e.target.checked) {
        return [...prev, id];
      } else {
        return prev.filter((item) => item !== id);
      }
    });
  };

  const handleHover = (profileId: ProfileId): void => {
    setHoveredProfileId(profileId);
  };

  const handleMouseLeave = (): void => {
    setHoveredProfileId(0 as ProfileId);
  };

  const combineParticipants = (
    profileGroupReport: IProfileGroupReport,
  ): ICombinedGroupReportParticipants[] => {
    const combinedParticipants: ICombinedGroupReportParticipants[] = [];
    profileGroupReport.selfGroupReport.groupReportProfiles.forEach(
      (selfProfile) => {
        const otherParticipant =
          profileGroupReport.otherGroupReport.groupReportProfiles.find(
            (otherProfile) => otherProfile.profileId === selfProfile.profileId,
          );
        if (otherParticipant) {
          combinedParticipants.push({
            selfPointId: selfProfile.pointId,
            otherPointId: otherParticipant.pointId,
            selfAdaptabilityIndex: selfProfile.adaptabilityIndex,
            otherAdaptabilityIndex: otherParticipant.adaptabilityIndex,
            profileId: selfProfile.profileId,
            name: selfProfile.name,
          });
        }
      },
    );
    return combinedParticipants;
  };

  /**
   * This is a filler function since the props to this component is optional but the requirements
   * of the children are that there should be a function. - Joakim, 241129
   * @returns void
   */
  function noOp() {
    return;
  }

  //This is used to combine the results from self and other so that we can show values in the participant list
  const profileParticipants = combineParticipants(profileGroupReport);

  return (
    <div className="row">
      {/* Joakim - The class does not make sense now, it's basically the same if delivered or not */}
      {/* The col-xl-6 needs to be there to work if the menu is expanded */}
      <div
        className={clsx(
          props.allProfilesDelivered ? "col-12 col-xl-6" : "col-12 col-xl-6",
        )}
      >
        <div className="align-items-start">
          <GroupAffDirGraph
            languageText={props.languageText}
            reportType={props.reportType}
            profileGroupReport={profileGroupReport}
            hoveredProfileId={hoveredProfileId}
            selectedProfileIds={copyPastaSelectedProfileIds}
          />

          <GroupAdaptability
            languageText={props.languageText}
            reportType={props.reportType}
            profileGroupReport={profileGroupReport}
            hoveredProfileId={hoveredProfileId}
            selectedProfileIds={copyPastaSelectedProfileIds}
          />
        </div>
      </div>
      <div
        className={clsx(
          props.allProfilesDelivered ? "col-12 col-xl-6" : "col-12 col-xl-6",
        )}
      >
        {!props.allProfilesDelivered ? (
          <GroupProfileParticipants
            languageText={props.languageText}
            profileParticipants={profileParticipants}
            hoveredProfileId={hoveredProfileId}
            handleHover={handleHover}
            showUsers={props.showUsers}
            handleMouseLeave={handleMouseLeave}
            reportType={props.reportType}
          />
        ) : (
          <DeliveredProfileParticipants
            activityId={props.id}
            languageText={props.languageText}
            profileData={props.profileData}
            isInstructionHelpClicked={props.isInstructionHelpClicked!}
            completedStatusEditProfileGuideDone={
              props.completedStatusEditProfileGuideDone!
            }
            profiles={profileParticipants}
            languages={props.languages ?? []}
            cultureValue={props.cultureValue ?? ""}
            messageTranslation={props.messageTranslation}
            isActivityCancelled={
              props.isActivityCancelled ? props.isActivityCancelled : false
            }
            handleExitGuide={props.handleExitGuide!}
            refetchFullProfile={props.refetchFullProfile || noOp}
            refetchDeliveredParticipants={
              props.refetchDeliveredParticipants || noOp
            }
            handleUnPublishProfile={props.handleUnPublishProfile || noOp}
            handleHover={handleHover}
            handleMouseLeave={handleMouseLeave}
            handleSelectAll={handleSelectAll}
            selectedProfileIds={copyPastaSelectedProfileIds}
            setSelectedProfiles={setCopyPastaSelectedProfileIds}
            handleCheckboxChange={handleCheckboxChange}
            reportType={props.reportType}
            handleTransferProfile={props.handleTransferProfile}
          />
        )}
      </div>
    </div>
  );
};
