import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import {
  ICombinedGroupReportParticipants,
  IEditActivity,
} from "../../../../interface";
import { IDropdownList } from "@app/components/formComponents/dropdownSelect";
import { ITranslationObject } from "../../../../../../../commonUtils/languageFunctionsHelper";
import { ParticipantProfileDropdown } from "../../../participantProfileDropdown";
import { Checkbox } from "../../../../../../../components/formComponents/checkbox";
import { ReactTooltip } from "../../../../../../../components/tooltip/reactTooltip";
import { EditProfileParticipantModal } from "../../../editProfileParticipantModal/editProfileParticipantModal";
import { ProfileStatus } from "../../../../../activityEnums";
import { GroupPreviewModal } from "./groupPreviewModal";
import { IndividualPreviewModal } from "../individualPreview/individualPreviewModal";
import { IndividualDownloadModal } from "../../download/individualDownload/individualDownloadModal";
import { MultipleParticipantDownloadModal } from "../../download/multipleDownload/multipleParticipantDownloadModal";
import { GroupDownloadModal } from "../../download/groupDownload/groupDownloadModal";
import { RootState } from "@app/store/configureStore";
import { unDeliverProfilesManually } from "../../../../actions";
import { ReportType, ReportTypes } from "../../../../../../../types";
import { addToast } from "@app/containers/actions";
import { AnyAction } from "redux";
import { isLoggedInRoleAdmin } from "@app/commonUtils/roleHelper";
import { Instruction } from "@app/components/instruction/instruction";
import { GuidePages } from "@app/containers/commonEnums";
import { editProfileDeliveredProfileInstructionSteps } from "@app/components/instruction/instructionSteps";
import { ActivityId, ProfileId } from "@app/containers/reducer";
import { ILanguage } from "@app/containers/commonInterfaces";

interface IprofileParticipantsProps {
  selectedProfileIds: Array<ProfileId>;
  activityId: ActivityId;
  languageText: ITranslationObject;
  profileData: IEditActivity;
  isInstructionHelpClicked: boolean;
  completedStatusEditProfileGuideDone: boolean;
  profiles: ICombinedGroupReportParticipants[];
  languages: ReadonlyArray<ILanguage>;
  cultureValue: string;
  messageTranslation: ITranslationObject;
  isActivityCancelled: boolean;
  handleExitGuide: (guidePage: GuidePages, isSkipped: boolean) => void;
  refetchFullProfile: () => void;
  refetchDeliveredParticipants: () => void;
  handleUnPublishProfile: () => void;
  handleHover: (profileId: ProfileId) => void;
  handleMouseLeave: () => void;
  handleSelectAll: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setSelectedProfiles: (profileIds: Array<ProfileId>) => void;
  handleCheckboxChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: ProfileId,
  ) => void;
  reportType: ReportType;
  handleTransferProfile?: () => void;
}

export const DeliveredProfileParticipants = (
  props: IprofileParticipantsProps,
): JSX.Element => {
  const dispatch = useDispatch();
  const instructionSteps = useMemo(
    () => editProfileDeliveredProfileInstructionSteps(props.languageText),
    [props.languageText],
  );

  const loggedInUserRole = useSelector(
    (state: RootState) => state.loginReducer.loggedInUserRole,
  );

  const [editProfileId, setEditProfileId] = useState<ProfileId>(0 as ProfileId);
  const [previewProfileId, setPreviewProfileId] = useState<ProfileId>(
    0 as ProfileId,
  );
  const [showGroupPreviewModal, setShowGroupPreviewModal] =
    useState<boolean>(false);
  const [downloadProfileId, setDownloadProfileId] = useState<ProfileId>(
    0 as ProfileId,
  );
  const [isMultipleDownloadOpen, setIsMultipleDownloadOpen] =
    useState<boolean>(false);
  const [isGroupDownloadOpen, setIsGroupDownloadOpen] =
    useState<boolean>(false);
  // Edit Settings fn's
  const onSettingsClick = (id: ProfileId): void => {
    setEditProfileId(id);
  };

  const closeSettingsModal = (): void => {
    setEditProfileId(0 as ProfileId);
  };

  // Preview Group Participants
  //FIXME: 240205 Joakim - I added a default value to the isSelf parameter which could otherwise be undefined.
  //Chose false since that is the initial state of the isSelf state.
  const onGroupPreviewClick = (_: number, isSelf: boolean = false): void => {
    if (props.selectedProfileIds.length > 0) {
      setShowGroupPreviewModal(true);
    }
  };

  const closeGroupPreview = (): void => {
    setShowGroupPreviewModal(false);
  };

  // Preview Individual Participant
  const onIndividualPreviewClick = (profileId: ProfileId): void => {
    setPreviewProfileId(profileId);
  };

  const closeIndividualPreview = (): void => {
    setPreviewProfileId(0 as ProfileId);
  };

  // Download Individual Participant
  const onIndividualDownloadClick = (profileId: ProfileId): void => {
    setDownloadProfileId(profileId);
  };

  const closeIndividualDownload = (): void => {
    setDownloadProfileId(0 as ProfileId);
  };

  // Download Group Participants
  const onGroupDownloadClick = (): void => {
    if (props.selectedProfileIds.length === 0) {
      return;
    }
    setIsGroupDownloadOpen(true);
  };

  const closeGroupDownload = (): void => {
    setIsGroupDownloadOpen(false);
  };

  // Download Multiple Participants

  const onMultipleDownloadClick = (): void => {
    if (props.selectedProfileIds.length === 0) return;
    setIsMultipleDownloadOpen(true);
  };

  const closeMultipleDownload = (): void => {
    setIsMultipleDownloadOpen(false);
  };

  // UnDeliver participants
  const handleUnDeliverClick = (): void => {
    if (props.selectedProfileIds.length === 0) return;

    unDeliverProfilesManually(
      props.activityId,
      props.selectedProfileIds,
      dispatch,
    ).then((response) => {
      if (response) {
        dispatch(addToast("Undelivered successfully") as AnyAction);
        props.refetchFullProfile();
        props.setSelectedProfiles([]);
      }
    });
  };

  let allParticipantsSelected = false;
  if (props.profiles.length > 0) {
    allParticipantsSelected =
      props.selectedProfileIds.length === props.profiles.length;
  }

  //FIXME: 240216 Joakim - needs to be an Intl.collator based on the language to sort properly
  const sortCollator = new Intl.Collator();
  const isSelf = props.reportType === ReportTypes.SELF;
  const isOther = props.reportType === ReportTypes.OTHER;
  const isBoth = props.reportType === ReportTypes.BOTH;

  return (
    <div className="ep-delivered-profile-participants">
      <div
        id="editProfileDeliveredProfileHeader"
        className="d-flex justify-content-end align-items-center py-3 px-4 bg-secondary bg-opacity-50"
      >
        <div id="deliveredProfileMassAction" className="d-flex">
          <ParticipantProfileDropdown
            languageText={props.languageText}
            isGroup={true}
            isUnDeliver={isLoggedInRoleAdmin(loggedInUserRole)}
            handlePreviewClick={onGroupPreviewClick}
            handleDownloadClick={onMultipleDownloadClick}
            handleDownloadGroupClick={onGroupDownloadClick}
            handleDeliverOrUnDeliverClick={handleUnDeliverClick}
          />
          <Checkbox
            className="d-inline ms-4"
            inputClass={clsx(
              "fs-4",
              !allParticipantsSelected && "bg-white border grey-border",
            )}
            value={allParticipantsSelected}
            handleCheckboxChange={props.handleSelectAll}
          />
        </div>
      </div>

      {props.profiles
        .sort((a, b) => sortCollator.compare(a.name, b.name))
        .map((profile) => {
          const isSelected = props.selectedProfileIds.includes(
            profile.profileId,
          );
          const selfInfo =
            isSelf || isBoth
              ? `${profile.selfPointId}${profile.selfAdaptabilityIndex}`
              : "";
          const otherInfo =
            isOther || isBoth
              ? `${profile.otherPointId}${profile.otherAdaptabilityIndex}`
              : "";

          return (
            <React.Fragment key={profile.profileId}>
              <div className="d-flex border border-top-0 py-3 px-4">
                <div
                  id="deliveredProfileParticipantDetails"
                  className="d-flex flex-grow-1 p-0"
                >
                  {/* Participant name */}
                  <div
                    className={clsx(
                      "d-flex align-items-center fw-bold fs-5",
                      !props.isActivityCancelled && "cursor-pointer",
                    )}
                    onMouseEnter={() =>
                      !props.isActivityCancelled &&
                      props.handleHover(profile.profileId)
                    }
                    onMouseLeave={props.handleMouseLeave}
                    role="button"
                    onClick={() =>
                      !props.isActivityCancelled &&
                      onSettingsClick(profile.profileId)
                    }
                  >
                    <ReactTooltip
                      id={profile.profileId.toString()}
                      tooltipText={profile.name}
                    >
                      {`${profile.name} - ${selfInfo} ${otherInfo}`}
                    </ReactTooltip>
                  </div>
                </div>
                <div className="d-flex p-0">
                  {/* Profile dropdown */}
                  <div>
                    <ParticipantProfileDropdown
                      languageText={props.languageText}
                      id="deliveredProfileIndividualDropdown"
                      isDeliver={false}
                      profileId={profile.profileId}
                      handlePreviewClick={onIndividualPreviewClick}
                      handleDownloadClick={onIndividualDownloadClick}
                    />
                  </div>

                  {/* Checkbox icon */}
                  <div
                    id="deliveredProfileIndividualCheckbox"
                    className="w-50px d-flex align-items-center"
                  >
                    <Checkbox
                      // id='deliveredProfileIndividualCheckbox'
                      className="mb-1 ms-4"
                      inputClass={clsx(
                        "fs-4 ",
                        !isSelected && "bg-white border grey-border",
                      )}
                      value={isSelected}
                      handleCheckboxChange={(e) =>
                        props.handleCheckboxChange(e, profile.profileId)
                      }
                    />
                  </div>
                </div>

                {/* Instruction steps */}
                {props.completedStatusEditProfileGuideDone ? (
                  <Instruction
                    showHelpButton={false}
                    targetElement="deliveredProfileIndividualCheckbox"
                    guidePage={GuidePages.Edit_Profile_Delivered_Status}
                    instructionSteps={instructionSteps}
                    skipPostGuideRequest={true}
                    manualTrigger={props.isInstructionHelpClicked}
                    handleExitGuide={props.handleExitGuide}
                  />
                ) : null}
              </div>
            </React.Fragment>
          );
        })}

      {/* Edit Settings Modal */}
      {editProfileId > 0 ? (
        <EditProfileParticipantModal
          languageText={props.languageText}
          profileId={editProfileId}
          languageCode={props.cultureValue}
          languages={props.languages}
          messageTranslation={props.messageTranslation}
          status={ProfileStatus.Delivery}
          fetchActivity={props.refetchFullProfile}
          handleCloseClick={closeSettingsModal}
          refetchParticipants={props.refetchDeliveredParticipants}
          handleUnPublishProfile={props.handleUnPublishProfile}
          handleTransferProfile={props.handleTransferProfile}
        />
      ) : (
        <></>
      )}

      {/* Preview Group Participants */}
      {showGroupPreviewModal ? (
        <GroupPreviewModal
          id={props.activityId}
          languageText={props.languageText}
          profileData={props.profileData}
          isSelf={isSelf}
          selectedProfileIds={props.selectedProfileIds}
          handleCloseClick={closeGroupPreview}
        />
      ) : (
        <></>
      )}

      {/* Preview Individual Participant  */}
      {previewProfileId > 0 ? (
        <IndividualPreviewModal
          languageText={props.languageText}
          profileId={previewProfileId}
          handleCloseClick={closeIndividualPreview}
        />
      ) : (
        <></>
      )}

      {/* Download Participant Profile */}
      {downloadProfileId > 0 ? (
        <IndividualDownloadModal
          languageText={props.languageText}
          profileId={downloadProfileId}
          handleCloseClick={closeIndividualDownload}
          profileParticipantName={
            props.profiles.find((par) => par.profileId === downloadProfileId)
              ?.name ?? ""
          }
        />
      ) : (
        <></>
      )}

      {/* Download Group Participant */}
      {isGroupDownloadOpen ? (
        <GroupDownloadModal
          id={props.activityId}
          languageText={props.languageText}
          name={props.profileData.name}
          profileIds={props.selectedProfileIds}
          handleCloseClick={closeGroupDownload}
          profileData={props.profileData}
        />
      ) : (
        <></>
      )}

      {isMultipleDownloadOpen ? (
        <MultipleParticipantDownloadModal
          languageText={props.languageText}
          profileIds={props.selectedProfileIds}
          status={ProfileStatus.Delivery}
          //TODO: 240130 Arpita - Need to figure out if this is needed
          //For now, commenting it out so that we can ask Umair when he's back.
          //profileParticipantsToUpdateDownloadStatus={selectedParticipants}
          handleCloseClick={closeMultipleDownload}
          refetchCompletedParticipants={props.refetchDeliveredParticipants}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
