import React from "react";

import { DeviationValue } from "./deviationValues/lowDeviationValue";
import { getAdapDeviation } from "@app/commonUtils/dimensionsHelper";
import {
  ITranslationObject,
  getLanguageValue,
} from "@app/commonUtils/languageFunctionsHelper";
import { IProfileReport } from "@app/types";

interface IAdaptabilityProps {
  languageText: ITranslationObject;
  report: IProfileReport;
}

export const Adaptability = (props: IAdaptabilityProps): JSX.Element => {
  // Adaptability deviation
  const { leftOrBottomDeviation: leftDeviA, rightOrTopDeviation: rightDeviA } =
    getAdapDeviation(props.report.oFlex, props.report.outsideStdDevF);

  return (
    <>
      <div className="d-flex gap-5">
        <div className="d-flex align-items-center gap-2">
          <div className="self-circle bg-danger" />
          {getLanguageValue(props.languageText, "Self")}: {props.report.sFlex}
        </div>
        <div className="d-flex align-items-center gap-2">
          <div className="other-square bg-success" />
          {getLanguageValue(props.languageText, "Other")}: {props.report.oFlex}
        </div>
      </div>
      <div className="w-100 p-2">
        <div className="d-flex justify-content-center flex-wrap position-relative pt-4 mt-2 text-end w-100">
          <div className="position-relative w-100">
            <div
              className="idi-adap ratio ratio-1x1 bg-success"
              style={{
                // The below conditions are to restrict marker going beyond 0 & 100
                left: `${props.report.oFlex < 4 ? 0 : props.report.oFlex > 96 ? 92 : props.report.oFlex - 4}%`,
                top: `10px`,
              }}
            >
              <div className="idi-adap-child">
                <div className={`deviDir deviDirL${leftDeviA}`}></div>
                <div className={`deviDir deviDirR${rightDeviA}`}></div>
              </div>
            </div>
            <div
              className="idi-adap ratio ratio-1x1 rounded-circle bg-danger"
              style={{
                // The below conditions are to restrict marker going beyond 0 & 100
                left: `${props.report.sFlex < 4 ? 0 : props.report.sFlex > 96 ? 92 : props.report.sFlex - 4}%`,
                top: `10px`,
              }}
            >
              <div className="idi-adap-child"></div>
            </div>
          </div>
          <div className="w-10 border border-top-0 py-5 position-relative small">
            <div className="float-start position-absolute top-0 start-0 translate-middle bg-white">
              0
            </div>
            <div className="float-start position-absolute top-0 start-100 translate-middle bg-white">
              10
            </div>
          </div>
          <div className="w-10 border-bottom border-end position-relative small">
            <div className="float-start position-absolute top-0 start-100 translate-middle bg-white">
              20
            </div>
          </div>
          <div className="w-10 border-bottom border-end position-relative small">
            <div className="float-start position-absolute top-0 start-100 translate-middle bg-white">
              30
            </div>
          </div>
          <div className="w-10 border-bottom border-end position-relative small">
            <div className="float-start position-absolute top-0 start-100 translate-middle bg-white">
              40
            </div>
          </div>
          <div className="w-10 border-bottom border-end position-relative small">
            <div className="float-start position-absolute top-0 start-100 translate-middle bg-white">
              50
            </div>
          </div>
          <div className="w-10 border-bottom border-end position-relative small">
            <div className="float-start position-absolute top-0 start-100 translate-middle bg-white">
              60
            </div>
          </div>
          <div className="w-10 border-bottom border-end position-relative small">
            <div className="float-start position-absolute top-0 start-100 translate-middle bg-white">
              70
            </div>
          </div>
          <div className="w-10 border-bottom border-end position-relative small">
            <div className="float-start position-absolute top-0 start-100 translate-middle bg-white">
              80
            </div>
          </div>
          <div className="w-10 border-bottom border-end position-relative small">
            <div className="float-start position-absolute top-0 start-100 translate-middle bg-white">
              90
            </div>
          </div>
          <div className="w-10 border-bottom border-end position-relative small">
            <div className="float-start position-absolute top-0 start-100 translate-middle bg-white">
              100
            </div>
          </div>
          <div className="w-100 d-flex justify-content-between pt-2 px-4">
            <div>{getLanguageValue(props.languageText, "Attend-to-self")}</div>
            <div className="text-center fw-bold">
              {getLanguageValue(props.languageText, "Adaptability")}{" "}
            </div>
            <div>{getLanguageValue(props.languageText, "Attend-to-other")}</div>
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center gap-2 mt-3 pt-2 border-top">
        <DeviationValue
          profileMeasure={props.report.flexMeasure ?? 0}
          deviationMessage={props.report.flexMessage ?? ""}
          deviationSpreadText={props.report.adaptabilitySpreadText ?? ""}
        />
      </div>
    </>
  );
};
