import React, { useState } from "react";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../commonUtils/languageFunctionsHelper";
import {
  DropdownSelect,
  IDropdownList,
  IDropdownSelectedItem,
} from "@app/components/formComponents/dropdownSelect";
import { ModalComponent } from "../modals/modalComponent";
import { ILanguage } from "@app/containers/commonInterfaces";

interface ISelectLanguageDropdownModalProps {
  languageText: ITranslationObject;
  label?: string;
  name?: string;
  languages: ReadonlyArray<ILanguage>;
  languageValue: string;
  closeModal: () => void;
  handleSaveLanguage: (selectedValue: string) => void;
}

export const SelectLanguageDropdownModal = (
  props: ISelectLanguageDropdownModalProps,
) => {
  const [selectedLanguageValue, setSelectedLanguageValue] = useState<string>(
    props.languageValue,
  );

  const handleLanguageSelect = (selectedItem: IDropdownSelectedItem): void => {
    setSelectedLanguageValue(selectedItem.value);
  };

  const handleSaveClick = (): void => {
    props.handleSaveLanguage(selectedLanguageValue);
    props.closeModal();
  };

  const languageDropdownItems: Array<IDropdownList> = props.languages.map(
    (l) => {
      return {
        id: l.id,
        displayName: l.displayName,
        value: l.name,
      };
    },
  );

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(props.languageText, "Select language")}
        submitButtonText={getLanguageValue(props.languageText, "Save")}
        cancelButtonText={getLanguageValue(props.languageText, "Discard")}
        handleSubmitClick={handleSaveClick}
        handleCancelClick={props.closeModal}
      >
        <DropdownSelect
          name={props.name || "language"}
          label={
            props.label
              ? props.label
              : getLanguageValue(props.languageText, "Language")
          }
          list={languageDropdownItems}
          value={selectedLanguageValue}
          handleDropdownSelect={handleLanguageSelect}
        />
      </ModalComponent>
    </>
  );
};
