import { Dispatch } from "redux";
import qs from "qs";
import { rawAxiosApi, apiEndPoints } from "../../../api";
import { setNotification } from "../../actions";
import { ISaveProfileEditFieldValueBody } from "./editProfileFirstStep/descriptionStep/editProfileDescriptionModal";
import { IEditActivityUpdateRoleBody } from "./editProfileFirstStep/roleStep/editActivityRoleModal";
import {
  IEditActivity,
  IEditProfileNewParticipantStepBody,
  IEditProfileByIdAndStatus,
  IEditProfileByIdAndStatusBody,
  IProfileGroupReport,
  IProfileRespondentsList,
} from "./interface";
import { IUpdateProfileParticipantNameBody } from "./modals/editProfileParticipantModal/profileParticipantName";
import {
  ICreatePresentationBody,
  IUpdatePresentationBody,
} from "./modals/presentationsModal/hooks";
import { ApiResponse, ReportType, ReportTypes } from "../../../types";
import { ProfileOutputPage } from "../activityEnums";
import { IClientFacilitator } from "./editProfileFirstStep/descriptionStep/editProfileDescriptionStep";
import { ActivityId, ProfileId } from "@app/containers/reducer";

export const getActivityById = async (
  id: ActivityId,
  dispatch: Dispatch,
): Promise<ApiResponse<IEditActivity>> => {
  try {
    const response = await rawAxiosApi.get<ApiResponse<IEditActivity>>(
      apiEndPoints.getActivityById,
      {
        params: { id },
      },
    );
    return response.data;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const saveActivityEditFieldValue = async (
  body: ISaveProfileEditFieldValueBody,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.post(
      apiEndPoints.saveActivityEditFieldValue,
      JSON.stringify(body),
    );
    return response.data.success;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const updateCompletionDate = async (
  activityId: ActivityId,
  completionDate: string,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.put(
      apiEndPoints.updateCompletionDate,
      {},
      {
        params: { activityId, completionDate },
      },
    );
    return response.data.success;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const cancelActivity = async (
  activityId: ActivityId,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.post(
      apiEndPoints.cancelActivity,
      {},
      {
        params: { activityId },
      },
    );
    return response.data.success;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getClientFacilitators = async (
  clientId: number,
  dispatch: Dispatch,
): Promise<IClientFacilitator[]> => {
  try {
    const response = await rawAxiosApi.get<
      ApiResponse<Array<IClientFacilitator>>
    >(apiEndPoints.getClientFacilitators, {
      params: { clientId },
    });
    if (!response.data.success) {
      return Promise.reject(response.data);
    }
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const editActivityUpdateRole = async (
  body: IEditActivityUpdateRoleBody,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.post(
      apiEndPoints.editActivityUpdateRole,
      JSON.stringify(body),
    );
    return response;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getActivityByStatus = async (
  body: IEditProfileNewParticipantStepBody,
  dispatch: Dispatch,
): Promise<ApiResponse<IEditActivity>> => {
  try {
    const response = await rawAxiosApi.get<ApiResponse<IEditActivity>>(
      apiEndPoints.getActivityByStatus,
      {
        params: body,
      },
    );
    return response.data;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getEditProfileByIdAndStatus = async (
  body: IEditProfileByIdAndStatusBody,
  dispatch: Dispatch,
): Promise<IEditProfileByIdAndStatus> => {
  try {
    const response = await rawAxiosApi.get(
      apiEndPoints.getEditProfileByIdAndStatus,
      {
        params: body,
      },
    );
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const updateProfileParticipantName = async (
  body: IUpdateProfileParticipantNameBody,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.put(
      apiEndPoints.updateProfileParticipantName,
      JSON.stringify(body),
      {
        params: body,
      },
    );
    return response.data;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const updateProfileParticipantPdfDownload = async (
  activityId: ActivityId,
  profileIds: Array<ProfileId>,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.put(
      apiEndPoints.updateProfileParticipantPdfDownload,
      JSON.stringify(profileIds),
      {
        params: { activityId },
      },
    );
    return response.data;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const deleteParticipant = async (
  profileId: ProfileId,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.delete(apiEndPoints.deleteParticipant, {
      params: { id: profileId },
    });
    return response.data;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const deliverProfilesManually = async (
  activityId: ActivityId,
  profileIds: Array<ProfileId>,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.post(
      apiEndPoints.deliverProfilesManually,
      JSON.stringify(profileIds),
      {
        params: { activityId },
      },
    );
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const unDeliverProfilesManually = async (
  activityId: ActivityId,
  profileIds: Array<ProfileId>,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.post(
      apiEndPoints.unDeliverProfilesManually,
      JSON.stringify(profileIds),
      {
        params: { activityId },
      },
    );
    return response.data.success;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const sendPublishOrManualProfileDeliverEmail = async (
  activityId: ActivityId,
  languageCode: string,
  profileIds: Array<ProfileId>,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.post(
      apiEndPoints.sendPublishOrManualProfileDeliverEmail,
      JSON.stringify(profileIds),
      {
        params: { activityId, languageCode },
      },
    );
    return response.data.success;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getAllPlannedAndOngoingPresentationsByCourseId = async (
  courseId: number,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.get(
      apiEndPoints.getAllPlannedAndOngoingPresentationsByCourseId,
      {
        params: { courseId },
      },
    );
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getParticipantRespondentsByParticipantId = async (
  profileId: ProfileId,
  dispatch: Dispatch,
): Promise<IProfileRespondentsList> => {
  try {
    const response = await rawAxiosApi.get<
      ApiResponse<IProfileRespondentsList>
    >(apiEndPoints.getParticipantRespondentsByParticipantId, {
      params: { profileId },
    });
    if (!response.data.success) {
      return Promise.reject(response.data);
    }
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getGroupReport = async (
  profileIds: Array<ProfileId>,
  type: ReportType,
  dispatch: Dispatch,
): Promise<IProfileGroupReport> => {
  const params: {
    isSelf?: boolean;
    isOther?: boolean;
    isBoth?: boolean;
    profileIds: number[];
  } = {
    profileIds: profileIds,
  };

  /** Since we can't set a parameter like 'type=someValue', we need to invert this so that we add the
   * parameter that we want to use as a key in the object and set it to true.
   */
  switch (type) {
    case ReportTypes.SELF:
      params.isSelf = true;
      break;
    case ReportTypes.OTHER:
      params.isOther = true;
      break;
    case ReportTypes.BOTH:
      params.isBoth = true;
      break;
  }

  try {
    const response = await rawAxiosApi.get<ApiResponse<IProfileGroupReport>>(
      apiEndPoints.getGroupReport,
      {
        params: params,
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: "comma" });
        },
      },
    );
    if (!response.data.success) {
      throw new Error(response.data.error?.message || "Very bad error");
    }
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const createPresentation = async (
  isEmailSend: boolean,
  body: ICreatePresentationBody,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.post(
      apiEndPoints.createPresentation,
      JSON.stringify(body),
      {
        params: { isEmailSend },
      },
    );
    return response;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

type UserFullName = string;
type ProfileUserFullNameMap = {
  [profileId: ProfileId]: UserFullName;
};

export const getCompletedParticipantsByProfileIdToSendPresentation = async (
  activityId: ActivityId,
  dispatch: Dispatch,
): Promise<ProfileUserFullNameMap> => {
  try {
    const response = await rawAxiosApi.get<ApiResponse<ProfileUserFullNameMap>>(
      apiEndPoints.getCompletedParticipantsByProfileIdToSendPresentation,
      {
        params: { activityId },
      },
    );
    if (!response.data.success) {
      return Promise.reject(response.data);
    }
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getParticipantNamesByProfileAndPresentationId = async (
  activityId: ActivityId,
  presentationId: number,
  dispatch: Dispatch,
): Promise<ProfileUserFullNameMap> => {
  try {
    const response = await rawAxiosApi.get<ApiResponse<ProfileUserFullNameMap>>(
      apiEndPoints.getParticipantNamesByProfileAndPresentationId,
      {
        params: { activityId, presentationId },
      },
    );
    if (!response.data.success) {
      return Promise.reject(response.data);
    }
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const sendParticipantPresentationEmail = async (
  dispatch: Dispatch,
  profileIds: Array<ProfileId>,
  activityId?: ActivityId,
  presentationId?: number,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.post(
      apiEndPoints.sendParticipantPresentationEmail,
      JSON.stringify(profileIds),
      {
        params: { activityId, presentationId },
      },
    );
    return response;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const updatePresentation = async (
  body: IUpdatePresentationBody,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.put(
      apiEndPoints.updatePresentation,
      JSON.stringify(body),
      {},
    );
    return response;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const disconnectParticipantFromPresentation = async (
  profileId: ProfileId,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.post(
      apiEndPoints.disconnectParticipantFromPresentation,
      {},
      {
        params: { profileId },
      },
    );
    return response.data;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const deletePresentation = async (
  id: number,
  dispatch: Dispatch,
): Promise<boolean> => {
  try {
    const response = await rawAxiosApi.delete(
      apiEndPoints.deletePresentation(id),
    );
    if (!response.data.success) {
      return await Promise.reject();
    }
    return response.data.success;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const recalculateProfiles = async (
  activityId: ActivityId,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.post(
      apiEndPoints.recalculateProfiles,
      {},
      {
        params: { activityId },
      },
    );
    return response.data;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const updateParticipantProfileLock = async (
  profileId: ProfileId,
  profileLocked: boolean,
  dispatch: Dispatch,
): Promise<boolean> => {
  try {
    const response = await rawAxiosApi.put(
      apiEndPoints.updateParticipantProfileLock,
      {},
      {
        params: { profileId, profileLocked },
      },
    );
    return response.data.success;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

//This method downloads PDF generated from API. The other method getParticipantProfilePdfReport will get only the data.
export const downloadParticipantProfilePdfReport = async (
  profileIds: Array<ProfileId>,
  languageCode: string,
  page: ProfileOutputPage,
  dispatch: Dispatch,
): Promise<Blob> => {
  try {
    const response = await rawAxiosApi.get(
      apiEndPoints.getParticipantProfilePdfFile,
      {
        params: {
          profileIds,
          languageCode,
          page,
          format: "Pdf",
        },
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: "comma" });
        },
        responseType: "blob",
      },
    );

    const blob = new Blob([response.data]);
    return blob;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
