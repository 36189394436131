import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { SocialProviderTypes } from "../../commonEnums";
import { clearNotifications, setSpinner } from "../../actions";
import { getUserExternalLogins } from "../../usersProfile/profileDetails/socialLogins/actions";
import { externalLogin, setSocialLoginStatus, getUserDetails } from "./actions";
import { RootState } from "@app/store/configureStore";
import { MultiRoleBehavior, useAuthFlow } from "@app/auth";

interface IExternalLoginProps {
  profile: any; // what?
  provider: string;
}

// why is this some totally separate login flow? shouldn't it be tightly
// integrated with the main login page? any reason for it not to be?
export const ExternalUserLogin: React.FC<IExternalLoginProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formattedProvider =
    props.provider.charAt(0).toUpperCase() + props.provider.slice(1);
  const userLanguage = useSelector(
    (state: RootState) => state.loginReducer.userLanguage,
  );
  const authFlow = useAuthFlow(dispatch, navigate);

  let sub: any = "";
  let accessCode: string = "";
  if (props.provider === SocialProviderTypes.MICROSOFT) {
    sub = props.profile.id_token
      ? JSON.parse(atob(props.profile.id_token.split(".")[1])).oid
      : null;
  } else {
    sub = props.profile.sub;
    accessCode = props.profile.code;
  }

  const payload = {
    authProvider: formattedProvider,
    providerKey: sub,
    providerAccessToken: props.profile.access_token,
    providerCode: accessCode,
    provider: props.provider,
    returnUrl: "/App",
    singleSignIn: true,
  };

  useEffect(() => {
    dispatch(clearNotifications(""));

    externalLogin(dispatch, payload)
      .then((response) => {
        dispatch(setSpinner(true));
        getUserDetails(dispatch, userLanguage.userLanguageCode).then(
          (details) => {
            getUserExternalLogins(dispatch).then((socialLogins: string[]) => {
              dispatch(setSocialLoginStatus(socialLogins));
            });
            authFlow.redirectToStartPageByRole(
              response.accessToken.roleName,
              details,
              MultiRoleBehavior.AskUser,
            );
          },
        );
      })
      .finally(() => {
        dispatch(setSpinner(false));
      });
  }, [props.profile.email]);

  return <React.Fragment />;
};
