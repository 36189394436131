import { Dispatch } from "redux";

import { rawAxiosApi, apiEndPoints } from "../../../api";
import { setNotification } from "../../actions";

export const updateEmployee = async (body: object, dispatch: Dispatch) => {
  try {
    const response = await rawAxiosApi.put(apiEndPoints.updateEmployee, body);
    return response.data.success;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
