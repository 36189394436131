import React from "react";
import { useNavigate } from "react-router-dom";

import { getLanguageValue } from "../../commonUtils/languageFunctionsHelper";
import { useDispatch, useSelector } from "react-redux";
import { clearNotifications } from "../../containers/actions";
import { RootState } from "@app/store/configureStore";

type AlertWindowProps = unknown;

export const AlertWindow: React.FC<AlertWindowProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const notificationMessage: string = useSelector(
    (state: RootState) => state.mainReducer.error,
  );

  const handleButtonClick = (): void => {
    navigate("/");
    dispatch(clearNotifications(""));
  };

  return (
    <div className="alert alert-danger text-center mt-3">
      <h4 className="alert-heading">
        {getLanguageValue(languageText, "Permissions not granted")}
      </h4>

      <div className="mb-3">
        {getLanguageValue(
          languageText,
          "The page you are trying to access is unavailable",
        )}
        <br />
        {getLanguageValue(languageText, "Please refer to our")}&nbsp;
        <a href="https://idi.se/terms">
          {getLanguageValue(languageText, "Terms and Conditions")}
        </a>
        &nbsp;
        {getLanguageValue(languageText, "for further information")}.
      </div>

      {notificationMessage.length > 0 && (
        <div className="mb-3">
          {getLanguageValue(languageText, "Reason")}:&nbsp;
          {notificationMessage}
        </div>
      )}
      <button onClick={handleButtonClick} className="btn btn-danger">
        {getLanguageValue(languageText, "Ok I got it")}
      </button>
    </div>
  );
};
