import { Dispatch } from "redux";

import { rawAxiosApi, apiEndPoints } from "../../api";
import { setNotification } from "../actions";
import { IRequestWordBody, IWords } from "./hooks";
import { ApiResult } from "@app/types";

export const getWordPairsByCultureId = async (
  body: IRequestWordBody,
  dispatch: Dispatch,
): Promise<ApiResult<IWords>> => {
  try {
    const response = await rawAxiosApi.get(
      apiEndPoints.getWordPairsByCultureId,
      {
        params: body,
      },
    );
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
