import {
  ActivityId,
  ProfileId,
  RespondentId,
  UserId,
} from "@app/containers/reducer";
import {
  ActivityStatus,
  CoursePresentationStatus,
  ProfileRoleStatus,
  ProfileStatus,
} from "../activityEnums";

export interface IEditActivity {
  id: ActivityId;
  clientId: number;
  facilitatorId: UserId;
  clientName: string;
  status: ActivityStatus;
  completionDate: string;

  /** true = group activity, false = individual activity */
  activityType: boolean;
  notes: string;
  facilitator: string;
  name: string;

  /** means 'culture norm', not language. */
  idiLanguageId: number;
  countryId: number;
  roleSetBy: number;
  roleSameForAll: boolean;

  /** NOTE: the lowercase 'I' is an intentional typo. the database column is misnamed. */
  roleid: number;
  roleText: string;
  showBilling?: boolean;
  roleNoOfRespondents: number;
  roleCanBeChangedByParticipant: boolean;
  participantInviteExternal: boolean;
  participantInviteOtherParticipant: boolean;
  participantInviteColleagues: boolean;
  profileCount: number;
  newProfileCount: number;
  newProfiles: IEditActivityNewProfile[];
  onGoingProfileCount: number;
  onGoingProfiles: IEditActivityOngoingProfile[];
  completedProfileCount: number;
  completedProfiles: IEditActivityCompletedProfile[];
  plannedPresentations: IEditActivityPlannedPresentation[];
  deliveredProfileCount: number;
  deliveredProfiles: IEditActivityDeliveredProfile[];
  sourceType: string;
  sourceAddress: string;
  isProBono: boolean;
}

export interface IEditActivityNewProfile {
  userId: UserId;
  name: string;
  emailAddress: string;
  phoneNumber: string;
  participantLink: string;
  emailStatus: number;
  emailOwnMessage: string;
  smsStatus: number;
  smsOwnMessage: string;
  roleId: number;
  roleText: string;
  noOfRespondents: number;
  isRoleSet: boolean;
  id: ProfileId;
}

export interface IEditActivityOngoingProfile {
  userId: UserId;
  name: string;
  emailAddress: string;
  phoneNumber: string;
  respondentsInvited: number;
  respondentsAnswered: number;
  noOfRespondents: number;
  emailStatus: number;
  smsStatus: number;
  roleId: number;
  emailOwnMessage: string;
  smsOwnMessage: string;
  roleText: string;
  isRoleSet: boolean;
  id: ProfileId;
}

export interface IEditActivityCompletedProfile {
  presentationId: number;
  userId: UserId;
  name: string;
  emailAddress: string;
  respondentsInvited: number;
  respondentsAnswered: number;
  noOfRespondents: number;
  roleId: number;
  roleText: string;
  isRoleSet: boolean;
  presentationDate: string;
  id: ProfileId;
  pdfProfileDownloaded: boolean;
}

export interface IEditActivityPlannedPresentation {
  presentationId: number;
  presentationDateTime: string;
  presentationStatus: CoursePresentationStatus;
  presentationTemplateName: string;
  presentationTemplateId: number;
  currentSlideId: number;
  presentationLanguageFlag: string;
  presentationPublicId: string;
}

export interface IEditActivityDeliveredProfile {
  userId: UserId;
  name: string;
  emailAddress: string;
  respondentsInvited: number;
  respondentsAnswered: number;
  noOfRespondents: number;
  roleId: number;
  roleText: string;
  presentation: number;
  presentationId: number;
  isRoleSet: boolean;
  presentationDate: string;
  id: ProfileId;
}

// Edit profile participant interface
export interface IEditProfileByIdAndStatus {
  name: string;
  surname: string;
  roleId: number;
  roleText: string;
  noOfRespondents: number;
  emailAddress: string;
  emailStatus: number;
  emailSentLog: Array<string>;
  latestEmailSentLog: string;
  phoneNumber: string;
  smsStatus: number;
  emailOwnMessage: string;
  smsOwnMessage: string;
  smsSentLog: Array<string>;
  latestSmsSentLog: string;
  respondentMsg1: string;
  respondentMsg2: string;
  respondentsAnswered: number;
  respondentsInvited: number;
  pdfProfileDownloaded: boolean;
  isLocked: boolean;
  enableSelfDelivery: boolean;
  selfDeliveryTemplateId: number | null;
}

// export Interface body for api's
export interface IEditProfileNewParticipantStepBody {
  activityId: ActivityId;
  status: ProfileStatus;
}

export interface IEditProfileByIdAndStatusBody {
  profileId: ProfileId;
  status: number;
  languageCode: string;
}

// email / sms status
export enum EmailStatus {
  NotSent = 1,
  InvitationSentWaitingForResult = 2,
  InvitationSent = 3,
  InvitationSentFailed = 4,
  ReminderSentWaitingForResult = 5,
  ReminderSent = 6,
  ReminderSentFailed = 7,
}

const emailStatusClasses: Record<EmailStatus, string> = {
  [EmailStatus.NotSent]: "bg-dark bg-opacity-10",
  [EmailStatus.InvitationSentWaitingForResult]: "bg-warning-subtle",
  [EmailStatus.InvitationSent]: "bg-success-subtle",
  [EmailStatus.InvitationSentFailed]: "bg-danger-subtle",
  [EmailStatus.ReminderSentWaitingForResult]: "bg-warning-subtle",
  [EmailStatus.ReminderSent]: "bg-primary-subtle",
  [EmailStatus.ReminderSentFailed]: "bg-danger-subtle",
};

export const getEmailStatus = (emailStatus: EmailStatus): string => {
  return emailStatusClasses[emailStatus] || "bg-dark bg-opacity-10";
};

export const getEmailStatusMessage = (emailStatus: EmailStatus): string => {
  let statusMessage = "";

  if (emailStatus) {
    const message: { [key: number]: string } = {
      [EmailStatus.NotSent]: "Email is not sent",
      [EmailStatus.InvitationSentWaitingForResult]:
        "Email is queued and will be delivered shortly",
      [EmailStatus.InvitationSent]:
        "Email sent successfully. It may take upto 30 mins to update the status",
      [EmailStatus.InvitationSentFailed]: "Email send failed",
      [EmailStatus.ReminderSentWaitingForResult]:
        "Email is queued and will be delivered shortly",
      [EmailStatus.ReminderSent]:
        "Reminder email sent successfully. It may take upto 30 mins to update the status",
      [EmailStatus.ReminderSentFailed]: "Reminder email send failed",
    };
    statusMessage = message[emailStatus];
  }
  return statusMessage;
};

export enum SmsStatus {
  NotSent = 1,
  SmsSent = 2,
  SmsFailed = 3,
  ReminderSent = 4,
  ReminderFailed = 5,
}

export const getSMSStatusMessage = (smsStatus: SmsStatus): string => {
  let statusMessage = "";

  if (smsStatus) {
    const message: { [key: number]: string } = {
      [SmsStatus.NotSent]: "SMS is not sent",
      [SmsStatus.SmsSent]: "SMS sent successfully",
      [SmsStatus.SmsFailed]: "SMS send failed",
      [SmsStatus.ReminderSent]: "Reminder SMS sent successfully",
      [SmsStatus.ReminderFailed]: "Reminder SMS send failed",
    };
    statusMessage = message[smsStatus];
  }

  return statusMessage;
};

// ParticipantRespondents Account Details
export interface IProfileRespondent {
  id: RespondentId;
  name: string;
  email: string;
  telephone: string;
  status: number;
  emailStatus: number;
  smsStatus: number;
  emailOwnMessage: string;
  smsOwnMessage: string;
  idiLanguageId: number;
  emailSentDate: string;
  instructionLanguageId: number | null;
}
export interface IProfileRespondentsList {
  participantLink: string;
  noOfRespondents: number;
  respondentsInvited: number;
  respondentsAnswered: number;
  respondents: IProfileRespondent[];
}

export enum RespondentProfileStatus {
  Invite = 1,
  Reminder = 2,
  Completed = 3,
}

export const getRespondentProfileStatus = (status: number): string => {
  switch (status) {
    case RespondentProfileStatus.Invite:
    case RespondentProfileStatus.Reminder:
      return "Invitation sent";
    case RespondentProfileStatus.Completed:
      return "Form filled";
    default:
      return "";
  }
};

// Group Reports
export interface IGroupReportDirectivenesses {
  pointId: number;
  affPos: number;
  dirPos: number;
  count: number;
  profileIds: Array<ProfileId>;
}
export interface IGroupReportAdaptabilities {
  adaptabilityIndex: string;
  flex: number;
  count: number;
  sMarginTop: string; // alternative margin top for self if overlapping
  oMarginTop: string; // alternative margin top for others if overlapping
  profileIds: Array<ProfileId>;
}
export interface IGroupReportProfile {
  pointId: number;
  adaptabilityIndex: string;
  name: string;
  profileId: ProfileId;
}

/** This is used to combine the results from self and other so that we can show values in the participant list */
export interface ICombinedGroupReportParticipants {
  selfPointId: number;
  otherPointId: number;
  selfAdaptabilityIndex: string;
  otherAdaptabilityIndex: string;
  profileId: ProfileId;
  name: string;
}

export interface IParticipantProfileGroupReport {
  averageAdaptability: number;
  averageAffiliation: number;
  averageDirectiveness: number;
  groupReportAdaptabilities: IGroupReportAdaptabilities[];
  groupReportDirectivenesses: IGroupReportDirectivenesses[];
  groupReportProfiles: IGroupReportProfile[];
}

export interface IParticipantProfileReportHtml {
  participantName: string;
  title: string;
  html: string;
  enableSelfDelivery: boolean;
  selfDeliveryTemplateId: number;
  selfDeliverySlideIndex: number;
}

export interface IProfileGroupReport {
  selfGroupReport: IParticipantProfileGroupReport;
  otherGroupReport: IParticipantProfileGroupReport;
}
