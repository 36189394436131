import React from "react";

interface INavigationButtonsProps {
  onForwardClick: () => void;
  onBackClick: () => void;
  isDisabled: boolean;
  forwardLabel: string | boolean;
}

export const NavigationButtons = (
  props: INavigationButtonsProps,
): JSX.Element => {
  return (
    <div className="d-flex justify-content-between mt-5">
      <button
        type="button"
        className="btn btn-lg btn-outline-secondary py-1 px-4"
        onClick={props.onBackClick}
      >
        <i className="bi bi-arrow-left fs-3"></i>
      </button>
      <button
        type="button"
        disabled={props.isDisabled}
        className="btn btn-lg btn-success py-1 px-4"
        onClick={props.onForwardClick}
      >
        {props.forwardLabel || <i className="bi bi-arrow-right fs-3"></i>}
      </button>
    </div>
  );
};
