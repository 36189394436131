import React from "react";
import { ICourseParts } from "../types";

interface ICourseTopProgress {
  courseParts: ICourseParts[];
  activeSection: number;
}

export const CourseTopProgress = (props: ICourseTopProgress): JSX.Element => {
  return (
    <div className="d-flex justify-content-between gap-2">
      {props.courseParts.map((coursePart: ICourseParts) => {
        let pgBarbg = "bg-secondary bg-opacity-25";
        if (props.activeSection == coursePart.partNumber)
          pgBarbg = "bg-success";
        else if (props.activeSection > coursePart.partNumber)
          pgBarbg = "bg-success bg-opacity-25";
        return (
          <div
            key={coursePart.id}
            className={`p-2 rounded ${pgBarbg}`}
            role="progressbar"
            style={{ width: "100%" }}
          ></div>
        );
      })}
    </div>
  );
};
