import React from "react";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../commonUtils/languageFunctionsHelper";
import { ModalComponent } from "@app/components/modals/modalComponent";

interface IPreviewPopupProps {
  languageText: ITranslationObject;
  previewHTML: string;
  onCloseClick: () => void;
}

export const PreviewPopup: React.FC<IPreviewPopupProps> = (props) => {
  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(props.languageText, "Invitation Preview")}
        cancelButtonText={getLanguageValue(props.languageText, "Close")}
        handleCancelClick={props.onCloseClick}
        width="lg"
      >
        <div dangerouslySetInnerHTML={{ __html: props.previewHTML }} />
      </ModalComponent>
    </>
  );
};
