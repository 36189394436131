import { Dispatch } from "redux";
import { rawAxiosApi, apiEndPoints } from "../../api";
import { setNotification, setSpinner } from "../actions";
import { ICreateOrUpdatePresentation } from "./addEditPresentationModal/hooks";
import { ICreateOrUpdateSlide } from "./presentationSlideList/addEditSlideModal/hooks";
import { ImportExportPresentationTemplateSlides } from "./hooks";
import { ApiResponse, ApiResult } from "@app/types";
import { IUpcomingPresentationsParams, UpcomingPresentation } from "./upcoming";

// Presentation templates

export const deletePresentationTemplate = async (
  deleteId: number,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.delete(
      apiEndPoints.deletePresentationTemplate,
      {
        params: { id: deleteId },
      },
    );
    return response.status;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const createPresentationTemplate = async (
  body: ICreateOrUpdatePresentation,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.post(
      apiEndPoints.createPresentationTemplate,
      JSON.stringify(body),
      {},
    );
    return response.status;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const updatePresentationTemplate = async (
  body: ICreateOrUpdatePresentation,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.put(
      apiEndPoints.updatePresentationTemplate,
      JSON.stringify(body),
      {},
    );
    return response.status;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getPresentationTemplateById = async (
  id: number,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.get(
      apiEndPoints.getPresentationTemplateById,
      {
        params: { id },
      },
    );
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

// Presentation template slides
export const getPresentationSlides = async (
  filter: string,
  sorting: string,
  maxResultCount: number,
  skipCount: number,
  templateId: number,
  dispatch: Dispatch,
): Promise<any> => {
  const params = {
    filter,
    sorting,
    maxResultCount,
    skipCount,
    templateId,
  };
  try {
    const response = await rawAxiosApi.get(apiEndPoints.getPresentationSlides, {
      params: params,
    });
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getPresentationTemplateSlideById = async (
  slideId: number,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.get(
      apiEndPoints.getPresentationTemplateSlideById,
      {
        params: { id: slideId },
      },
    );
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const createPresentationTemplateSlide = async (
  body: ICreateOrUpdateSlide,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.post(
      apiEndPoints.createPresentationTemplateSlide,
      JSON.stringify(body),
      {},
    );
    return response.data.success;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const updatePresentationTemplateSlide = async (
  body: ICreateOrUpdateSlide,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.put(
      apiEndPoints.updatePresentationTemplateSlide,
      JSON.stringify(body),
      {},
    );
    return response.data.success;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const deletePresentationTemplateSlide = async (
  id: number,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.delete(
      apiEndPoints.deletePresentationTemplateSlide,
      {
        params: { id },
      },
    );
    return response.data.success;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export function exportPresentationTemplateSlides(
  id: number,
  dispatch: Dispatch,
): Promise<ImportExportPresentationTemplateSlides> {
  dispatch(setSpinner(true));
  return rawAxiosApi
    .get<ApiResponse<ImportExportPresentationTemplateSlides>>(
      apiEndPoints.exportPresentationTemplateSlides(id),
    )
    .then((res) => {
      if (!res.data.success) {
        return Promise.reject(res.data.error);
      }

      return res.data.result;
    })
    .catch((e) => {
      dispatch(setNotification(e));
      return Promise.reject(e);
    })
    .finally(() => {
      dispatch(setSpinner(false));
    });
}

export function importPresentationTemplateSlides(
  id: number,
  data: ImportExportPresentationTemplateSlides,
  dispatch: Dispatch,
): Promise<unknown> {
  dispatch(setSpinner(true));
  return rawAxiosApi
    .post(apiEndPoints.importPresentationTemplateSlides(id), data)
    .catch((err) => {
      dispatch(setNotification(err));
      return Promise.reject(err);
    })
    .finally(() => {
      dispatch(setSpinner(false));
    });
}

export const getUpcomingPresentations = async (
  params: IUpcomingPresentationsParams,
  dispatch: Dispatch,
): Promise<ApiResult<UpcomingPresentation>> => {
  try {
    const response = await rawAxiosApi.get(
      apiEndPoints.getUpcomingPresentations,
      {
        params: params,
      },
    );
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
