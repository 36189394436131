import { AnyAction, Dispatch } from "redux";
import { rawAxiosApi as deprecatedApi, apiEndPoints } from "../../../api";
import {
  setNotification,
  setSpinner,
  reloadLanguageStrings,
} from "../../actions";
import {
  LOGGED_IN_USER_DATA,
  LOGGED_IN_USER_ID,
  LOGGED_IN_USER_DETAILS,
  SET_CURRENT_CLIENT,
  PASSWORD_RESET_CODE,
  GET_SOCIAL_LOGIN_STATUS,
  CLEAR_CURRENT_CLIENT,
  SET_LOGGED_IN_USER_ROLE,
  SET_USER_LANGUAGE,
} from "./constants";
import {
  LanguageCode,
  LanguageId,
  RoleEnum,
  RoleName,
} from "../../commonEnums";
import { setParticipantProfileExists } from "../../participantPages/actions";
import { IUserDetails, IUserProfile } from "@app/containers/commonInterfaces";
import { ApiResponse } from "@app/types";
import { ICurrentClient, IUserLanguage } from "./reducer";
import { UserId } from "@app/containers/reducer";
import { useApiEndpoints } from "@app/api/end-points";

export interface IDispatch {
  (action: any): void;
}

export const loggedInUserData = (payload: IUserProfile) => ({
  type: LOGGED_IN_USER_DATA,
  payload,
});

export const loggedInUserId = (payload: number) => ({
  type: LOGGED_IN_USER_ID,
  payload,
});

export const setPasswordResetCode = (payload: string) => ({
  type: PASSWORD_RESET_CODE,
  payload,
});

export const loggedInUserDetails = (payload: IUserDetails) => ({
  type: LOGGED_IN_USER_DETAILS,
  payload,
});

export const setCurrentClient = (payload: ICurrentClient) => ({
  type: SET_CURRENT_CLIENT,
  payload,
});

/** @deprecated Using the system without a current client is not really possible unless you're an admin. */
export const clearCurrentClient = () => ({ type: CLEAR_CURRENT_CLIENT });

export const setSocialLoginStatus = (payload: string[]) => ({
  type: GET_SOCIAL_LOGIN_STATUS,
  payload,
});

export const setLoggedInUserRole = (payload: RoleName) => ({
  type: SET_LOGGED_IN_USER_ROLE,
  payload,
});

interface ISetUserLanguageAction {
  type: typeof SET_USER_LANGUAGE;
  payload: IUserLanguage;
}
export const setUserLanguage = (
  languageCode: string,
  languageId: number,
): ISetUserLanguageAction => ({
  type: SET_USER_LANGUAGE,
  payload: {
    userLanguageCode: languageCode,
    userLanguageId: languageId,
  },
});

export type AccessTokenScope = {
  userId: UserId;
  roleId: RoleEnum;
  roleName: RoleName;
  clientId: number | null;
  clientName: string | null;
  /** This may or may not need to be changed into "isCurrentClient", but I leave it as is.
   * It makes no sense in signal current since the clientId and name above **is** the current.
   * - Joakim, 25-01-15 */
  isDefaultClient: boolean;
  token: string;
  tokenIssuedAt: string;
  tokenExpiresAt: string;
};

export type AuthenticateResult = {
  accessToken: AccessTokenScope;
  emailConfirmed: boolean;
  expireInSeconds: number;
  passwordResetCode: string;
  requiresTwoFactorVerification: boolean;
  shouldResetPassword: string;
  userId: UserId;
};

export const loginUserAction = async (
  dispatch: Dispatch,
  user: { email: string; password: string },
  isParticipant: boolean,
): Promise<AuthenticateResult> => {
  const person: object = {
    userNameOrEmailAddress: user.email,
    password: user.password,
    isParticipant: isParticipant, // does the backend need to know this? aren't we like, logging in first?
  };
  try {
    const response = await deprecatedApi
      .post<
        ApiResponse<AuthenticateResult>
      >(apiEndPoints.logIn, JSON.stringify(person))
      .then((res) => res);

    if (!response.data.success) {
      return await Promise.reject(response.data);
    }

    const accessToken = response.data.result.accessToken;
    const userId = response.data.result.userId;
    const passwordResetCode = response.data.result.passwordResetCode;
    const expireInSeconds = response.data.result.expireInSeconds;

    dispatch(loggedInUserId(userId));

    if (passwordResetCode) {
      dispatch(setPasswordResetCode(passwordResetCode));
    }

    if (accessToken) {
      // Setting logged in role
      // Yes we are!
      const roleName = response.data.result.accessToken.roleName;
      dispatch(setLoggedInUserRole(roleName));

      // FIXME: hack for localhost frontend on non-localhost backend
      window.localStorage.setItem("logged_in", "1");
    }

    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
  }
  return Promise.reject();
};

export type ExternalAuthenticateResult = {
  accessToken: AccessTokenScope;
  expireInSeconds: number;
  waitingForActivation: boolean;
  returnUrl: string;
};

export const externalLogin = async (
  dispatch: Dispatch<AnyAction>,
  socialLoginData: {
    authProvider: string;
    providerKey: string;
    providerAccessToken: string;
    providerCode: string;
    returnUrl: string;
    singleSignIn: boolean;
  },
): Promise<ExternalAuthenticateResult> => {
  const body = {
    authProvider: socialLoginData.authProvider,
    providerKey: socialLoginData.providerKey,
    providerAccessToken: socialLoginData.providerAccessToken,
    providerCode: socialLoginData.providerCode,
    returnUrl: socialLoginData.returnUrl,
    singleSignIn: socialLoginData.singleSignIn,
  };

  try {
    const response = await deprecatedApi.post<
      ApiResponse<ExternalAuthenticateResult>
    >(apiEndPoints.externalAuthenticate, JSON.stringify(body));

    if (!response.data.success) {
      return Promise.reject(response.data);
    }

    // the access token might be falsy when email verification
    // or 2FA is necessary.
    if (response.data.result.accessToken) {
      // Setting logged in role
      const roleName = response.data.result.accessToken.roleName;
      dispatch(setLoggedInUserRole(roleName));

      // FIXME: hack for localhost frontend on non-localhost backend
      window.localStorage.setItem("logged_in", "1");
    }

    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getLoggedInUserData = async (
  isParticipant: boolean,
  dispatch: IDispatch,
): Promise<IUserProfile> => {
  const params = {
    isParticipant,
  };
  try {
    const response = await deprecatedApi.get<ApiResponse<IUserProfile>>(
      apiEndPoints.getCurrentUserProfile,
      {
        params,
      },
    );
    if (!response.data.success) {
      return await Promise.reject(response.data);
    }
    dispatch(loggedInUserData(response.data.result));
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getUserDetails = async (
  dispatch: Dispatch,
  existingLangCode: string,
): Promise<IUserDetails> => {
  const api = useApiEndpoints(dispatch);
  dispatch(setSpinner(true));

  try {
    const response = await api.getUserDetails().then((details) => {
      dispatch(setSpinner(false));

      //The userId must be set here for the times when the page is reloaded
      //without the user logging in. When logging in, the userId is set in the
      //login action with the response from the backend.
      dispatch(loggedInUserId(details.userId));
      dispatch(setLoggedInUserRole(details.userRole!));
      dispatch(loggedInUserDetails(details));

      const userLanguageCode: string =
        details.userLanguageCode || LanguageCode.English;
      const userLanguageId: number =
        details.userLanguageId || LanguageId.English;

      if (existingLangCode !== userLanguageCode) {
        reloadLanguageStrings(
          userLanguageCode,
          false,
          dispatch,
          userLanguageId,
        );
      } else {
        // reloadLanguageStrings also sets the user language - hence the else
        dispatch(setUserLanguage(userLanguageCode, userLanguageId));
      }

      // Setting the current client details
      if (details.currentClientId) {
        const currentClientId: number = details.currentClientId ?? 0;
        const currentClientName: string = details.currentClientName;
        dispatch(
          setCurrentClient({
            currentClientId: currentClientId,
            currentClientName: currentClientName,
          }),
        );
      }

      dispatch(
        setParticipantProfileExists(details.isProfileExists ? true : false),
      );

      return details;
    });
    return response;
  } catch (error: any) {
    dispatch(setSpinner(false));
    dispatch(setNotification(error));
    throw error;
  }
};

export const sendEmailForResetPassword = async (
  email: string,
  languageCode: string,
  dispatch: IDispatch,
): Promise<any> => {
  const body = {
    emailAddress: email,
    languageCode,
  };
  try {
    const res = await deprecatedApi.post(
      apiEndPoints.sendResetPasswordCode,
      JSON.stringify(body),
    );
    return res;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  } finally {
    dispatch(setSpinner(false));
  }
};

// this returns a 'Set-Cookie' header that tells the browser
// to delete any session cookies.
export function logout(dispatch: Dispatch): Promise<unknown> {
  dispatch(setSpinner(true));
  return deprecatedApi.post(apiEndPoints.logout).finally(() => {
    dispatch(setSpinner(false));
    return;
  });
}
