import { AnyAction, Dispatch } from "redux";
import { AxiosRequestConfig } from "axios";

import { rawAxiosApi, apiEndPoints } from "../api";
import {
  REMOVE_NOTIFICATION,
  SET_NOTIFICATION,
  SET_SPINNER,
  CLEAR_NOTIFICATIONS,
  SET_CURRENT_USER_LANGUAGES,
  PAGE_CONFIGURATION_INFO,
  SET_TRANSLATIONS,
  SET_ALERTS,
  TRIGGER_ALERT,
  UPDATE_ALERT_WITH_ACTION_TAKEN,
  ADD_TOAST,
  REMOVE_TOAST,
  SET_GUIDE_DATA,
  MARK_GUIDE_AS_COMPLETED,
  SHOW_ACCOUNT_SWITCH_MENU,
  SHOW_RIGHT_MENU,
} from "./constants";
import {
  GuidePages,
  LanguageCode,
  LanguageId,
  UserGeneratedMediaType,
} from "./commonEnums";
import { ILanguageText, IPageConfiguredInfo } from "./reducer";
import { ApiResponse, ApiResult } from "@app/types";
import { AlertAction, AlertTrigger, IAlert } from "./alertList/types";
import { IGuide, ToastActionTypes } from "./commonInterfaces";
import { setUserLanguage } from "./auth/signUp/actions";
import { joinPaths } from "./utils";
import { API_BASE_URL } from "@app/constants";

export const setNotification = (payload: any) => ({
  type: SET_NOTIFICATION,
  payload,
});

export const removeNotification = (payload: any) => ({
  type: REMOVE_NOTIFICATION,
  payload,
});

export const setSpinner = (visible: boolean) => ({
  type: SET_SPINNER,
  payload: visible,
});

export const clearNotifications = (payload: any) => ({
  type: CLEAR_NOTIFICATIONS,
  payload,
});

/** This does not make sense to me, can a user have several
 * userlanguages current?
 * Ok - seems to be used for the available languages in the navigation menu
 */
export const setCurrentUserLanguages = (payload: any) => ({
  type: SET_CURRENT_USER_LANGUAGES,
  payload,
});

export const setGuideData = (payload: IGuide[]) => ({
  type: SET_GUIDE_DATA,
  payload,
});

export function setTranslations(payload: ILanguageText) {
  return {
    type: SET_TRANSLATIONS,
    payload: payload,
  };
}

export function setAlerts(alerts: ReadonlyArray<IAlert>) {
  return {
    type: SET_ALERTS,
    payload: alerts,
  };
}

export function triggerAlert(trigger: AlertTrigger) {
  return {
    type: TRIGGER_ALERT,
    payload: trigger,
  };
}

export function setOpenAccountList(visible: boolean) {
  return {
    type: SHOW_ACCOUNT_SWITCH_MENU,
    payload: visible,
  };
}

export function setOpenRightMenu(visible: boolean) {
  return {
    type: SHOW_RIGHT_MENU,
    payload: visible,
  };
}
export function updateAlertWithActionTaken(
  trigger: AlertTrigger,
  actionTaken: AlertAction,
) {
  return {
    type: UPDATE_ALERT_WITH_ACTION_TAKEN,
    payload: {
      trigger: trigger,
      actionTaken: actionTaken,
    },
  };
}

export function reloadLanguageStrings(
  languageCode: string,
  spinnerRequired: boolean,
  dispatch: Dispatch,
  languageId: number,
): void {
  if (spinnerRequired) {
    dispatch(setSpinner(true));
  }

  const langCode = languageCode || LanguageCode.English;
  // In sign-in pages, if the user reloads the page, we will be having languageCode in the URL, but not Id.
  const langId = languageId || 0;

  dispatch(setUserLanguage(langCode, langId));

  getLanguageTextByName(langCode, dispatch)
    .then((text) => {
      dispatch(setTranslations(text));
    })
    .finally(() => {
      if (spinnerRequired) {
        //No dispatch here? - Joakim 241031
        setSpinner(false);
      }
    });
}

export function getLanguageTextByName(
  lang: string,
  dispatch: Dispatch,
): Promise<ILanguageText> {
  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };

  return rawAxiosApi
    .get<ApiResponse<ILanguageText>>(
      apiEndPoints.getLanguageTextByName(lang),
      config,
    )
    .then((res) => {
      if (!res.data.success) {
        return Promise.reject(res.data.error?.message || "Very bad error");
      }
      return res.data.result;
    })
    .catch((err) => {
      dispatch(setNotification(err));
      return Promise.reject(err);
    });
}

export const updatePageConfiguredInfo = (info: IPageConfiguredInfo) => ({
  type: PAGE_CONFIGURATION_INFO,
  payload: info,
});

export function getMediaURL(id: number, type: UserGeneratedMediaType): string {
  const url = new URL(joinPaths(API_BASE_URL!, apiEndPoints.getMediaImageFile));
  url.searchParams.append("id", String(id));
  url.searchParams.append("userGeneratedMediaType", String(type));
  return url.toString();
}

/**
 * @deprecated What's the reasoning behind this? Why would we ever
 *   want to manually download an image from the backend? It's just
 *   a freakin file... no need to re-implement standard browser behavior.
 *   Just use an old-fashioned URL.
 * @see getMediaURL
 *
 * @returns {string} A data URL to the file with the specified ID.
 */

export const getMediaImageFile = async (
  id: number,
  userGeneratedMediaType: UserGeneratedMediaType,
  dispatch: Dispatch,
): Promise<string> => {
  try {
    const response = await rawAxiosApi.get(apiEndPoints.getMediaImageFile, {
      params: {
        id,
        userGeneratedMediaType,
      },
      responseType: "arraybuffer",
    });

    if (!response?.data || response.data.length === 0) {
      return Promise.reject(new Error("No image data received"));
    }

    const checkIfSvgData = new TextDecoder("utf-8").decode(response.data);

    // Check if the response is an SVG image
    const contentType = response.headers["content-type"];
    if (
      contentType === "application/octet-stream" &&
      checkIfSvgData.includes("<svg")
    ) {
      return `data:image/svg+xml,${encodeURIComponent(checkIfSvgData)}`;
    }

    // Convert image data to blob and URL
    const arrayBufferView = new Uint8Array(response.data);
    const blob = new Blob([arrayBufferView], {
      type: response.headers["content-type"],
    });
    const dataUrl = URL.createObjectURL(blob);

    return dataUrl;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getParticipantMedia = async (
  id: number,
  userGeneratedMediaType: UserGeneratedMediaType,
  dispatch: Dispatch,
): Promise<string> => {
  try {
    const response = await rawAxiosApi.get(apiEndPoints.getParticipantMedia, {
      params: {
        id,
        userGeneratedMediaType,
      },
      responseType: "arraybuffer",
    });

    const checkIfSvgData = new TextDecoder("utf-8").decode(response.data);

    // Check if the response is an SVG image
    const contentType = response.headers["content-type"];
    if (
      contentType === "application/octet-stream" &&
      checkIfSvgData.includes("<svg")
    ) {
      return `data:image/svg+xml,${encodeURIComponent(checkIfSvgData)}`;
    }

    //whatever we are getting the images are been converted to blob and local url
    const arrayBufferView = new Uint8Array(response.data);
    const blob = new Blob([arrayBufferView], {
      type: response.headers["content-type"],
    });
    const dataUrl = URL.createObjectURL(blob);

    return dataUrl;

    // Try to extract extension from the content-disposition header
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

// Toaster
// Once toast is added, remove toast automatically within 2 seconds
export const addToast = (
  message: string,
): AnyAction | ((dispatch: Dispatch) => void) => {
  return (dispatch: Dispatch) => {
    const id = Math.random().toString(36).substring(2, 9);
    dispatch({
      type: ADD_TOAST,
      payload: { id, message },
    });
    setTimeout(() => {
      dispatch(removeToast(id));
    }, 2000);
  };
};
export const removeToast = (id: string): ToastActionTypes => ({
  type: REMOVE_TOAST,
  payload: { id },
});

const DID_CREATE_DEFAULT_CACHE: { [key: string]: boolean } = {};

export function createDefaultText(key: string): void {
  if (DID_CREATE_DEFAULT_CACHE[key]) {
    return;
  }

  DID_CREATE_DEFAULT_CACHE[key] = true;

  rawAxiosApi
    .post(apiEndPoints.createDefaultText, { key })
    .then((res) => {
      //The dispatch method should be passed from the component but have to change in numerous places. So commented the code.
      //dispatch(setTranslations({ ...languageText, [key]: key }))
    })
    .catch((err) => {
      //We can suppress the error as this happens  silently. Also, even failed, it will execute next time. So no need to show the error.
      //dispatch(setNotification(err))
    });
}

// Guide Actions
export const getGuideData = async (
  dispatch: Dispatch,
): Promise<ApiResult<IGuide>> => {
  try {
    const response = await rawAxiosApi.get<ApiResponse<ApiResult<IGuide>>>(
      apiEndPoints.getOrPostGuide,
    );
    if (!response.data.success) {
      return await Promise.reject(response.data);
    }
    dispatch(setGuideData([...response.data.result.items]));
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const markGuideAsCompleted = async (
  guideName: GuidePages,
  dispatch: Dispatch,
): Promise<boolean> => {
  const body: IGuide = {
    guideName: guideName,
    isCompleted: true,
  };
  try {
    const response = await rawAxiosApi.post<ApiResponse<ApiResult<IGuide>>>(
      apiEndPoints.getOrPostGuide,
      JSON.stringify(body),
    );
    if (!response.data.success) {
      return await Promise.reject(response.data);
    }
    dispatch({
      type: MARK_GUIDE_AS_COMPLETED,
      payload: guideName,
    });
    return response.data.success;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
