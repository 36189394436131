import React, { useEffect, useMemo, useState } from "react";
import clsx from "clsx";

import { getLanguageValue } from "../../../../commonUtils/languageFunctionsHelper";
import { AddManually } from "./addManually";
import { IListRespondent, SelectFromList } from "./selectFromList";
import { PreviewPopup } from "../../../activityList/addActivity/emailSMSInvitation/previewPopup";
import {
  GetTypeOfRoleName,
  ProfileStatus,
} from "../../../activityList/activityEnums";
import {
  CustomModal,
  CustomModal as RespondentsLimitReachedModal,
} from "../../../../components/modals/customModal";
import { Instruction } from "@app/components/instruction/instruction";
import { GuidePages } from "@app/containers/commonEnums";
import { Step, StepComponentProps } from "../../createProfilePage";
import {
  CompletedStepPanel,
  CurrentStepPanel,
  UpcomingStepPanel,
} from "../../panels";
import { RoleSettingsWithEditOption } from "@app/containers/activityList/addActivity/roleSettings/roleSettingsWithEditOption";
import { useRolePage } from "../rolePage/hooks";
import { ModalComponent } from "@app/components/modals/modalComponent";
import { useDispatch, useSelector } from "react-redux";
import { useParticipantProfileAuth } from "../../useParticipantProfileAuth";
import { RootState } from "@app/store/configureStore";
import { inviteRespondentsInstructionSteps } from "@app/components/instruction/instructionSteps";
import { IFocusError } from "@app/components/formComponents/input";
import { ProfileId } from "@app/containers/reducer";
import { getParticipantRespondentsByParticipantId } from "@app/containers/activityList/editActivity/actions";
import {
  SendRespondentsInvitation,
  ICreateManualRespondentsBody,
  ICreateRespondentsParams,
  IRespondentEmailPreviewBody,
} from "./sendRespondentsInvitation";
import {
  createManualProfileParticipantRespondents,
  getRespondentEmailPreview,
  getUserForRespondents,
} from "../../actions";
import { setSpinner } from "@app/containers/actions";
import { IProfileRespondentsList } from "@app/containers/activityList/editActivity/interface";
import { useApiEndpoints } from "@app/api/end-points";
import { InstructionLanguageSelector } from "./instructionLanguageSelector";
import { Box } from "@app/components/Box";
import {
  MAXIMUM_ALLOWED_RESPONDENTS,
  MAXIMUM_RECOMMENDED_RESPONDENTS,
} from "@app/consts";
import { Pill } from "@app/components/Pill";

/**
 * TODO: we probably have this kind of logic duplicated elsewhere - i just don't know where.
 */
function getShortName(name: string): string {
  const parts = name.trim().split(/\s+/);
  if (parts.length === 1) {
    return name;
  }

  // first name and the initial of the last name. should work
  // for people with more than two names.
  return [parts[0], parts[parts.length - 1].charAt(0)]
    .filter((x) => !!x)
    .join(" ");
}

type InviteRespondentsStepProps = StepComponentProps & {
  /**
   * This is a trashy workaround because this component is _extremely_ tightly
   * coupled to the hook 'useInviteRespondent'. It's so coupled that the hook
   * itself is useless - it could just be inline code. To pull the state
   * upwards would require massive changes, and I'm not in a mood to do that.
   *   -johan, 2024-10-17
   */
  variant:
    | { kind: "step" }
    | { kind: "modal"; onSubmit: () => unknown; onClose: () => unknown };
};

enum NavTabEnums {
  None = 0,
  Select_From_List = 1,
  Add_Manually = 2,
}

export interface IUserRespondentsBody {
  profileId: ProfileId;
  searchText: string;
}

const initialErrorState: IFocusError = { errorMessage: "", touched: false };

export const InviteRespondents: React.FC<InviteRespondentsStepProps> = (
  props,
) => {
  function onInvited() {
    if (props.variant.kind === "step") {
      props.setStep(Step.SelfAssessment);
    }
  }

  const dispatch = useDispatch();
  const api = useApiEndpoints(dispatch);

  // Required to get data from the URL unique code
  // Also has a nasty side effect that sets 'participantProfileDetails' in Redux state.
  useParticipantProfileAuth();

  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );
  const userLanguage = useSelector(
    (state: RootState) => state.loginReducer.userLanguage,
  );
  const currentUserLanguages = useSelector(
    (state: RootState) => state.mainReducer.currentUserLanguages,
  );
  const instructionSteps = useMemo(
    () => inviteRespondentsInstructionSteps(languageText),
    [languageText],
  );
  const [respondentsListFromSearch, setRespondentsListFromSearch] = useState<
    IListRespondent[]
  >([]);
  const [selectedTab, setSelectedTab] = useState<NavTabEnums>(
    NavTabEnums.Select_From_List,
  );
  const [respondentsLimitReachedModal, setRespondentsLimitReachedModal] =
    useState<boolean>(false);

  // Select from list states
  const [searchText, setSearchText] = useState<string>("");
  //The list of respondents to be invited when submitting
  const [respondentsToBeInvited, setRespondentsToBeInvited] = useState<
    IListRespondent[]
  >([]);
  const [refetchList, setRefetchList] = useState<boolean>(false);
  const [instructionMessage, setInstructionMessage] = useState<string>("");
  const [tooManyRespondentsWarningShown, setTooManyRespondentsWarningShown] =
    useState<boolean>(false);

  const [selectedRespondentIndex, setSelectedRespondentIndex] = useState<
    number | undefined
  >(undefined);

  // there are basically two scenarios here, related to 'roleCanBeChangedByParticipant':
  //
  //   - when the value is true the participant is allowed to modify the number of respondents,
  //     which means that we can safely ignore 'noOfRespondents' and just output a single
  //     row (so they see something).
  //
  //   - when the value is false the sum of 'list respondents' and 'manual respondents'
  //     must necessarily be equal to 'noOfRespondents'. when the component loads there are
  //     never any list respondents.

  const [previewHTML, setPreviewHTML] = useState<string>("");

  // FIXME: this state is super confusing. when we're at the 'invite respondents' step
  //   this will be empty, because we haven't invited anyone yet (of course). when this
  //   component is reused in the 'waiting for respondents' step this thing will have data.
  //   -johan, 2024-11-27
  // Respondents status states
  const [invitedRespondentsList, setInvitedRespondentsList] =
    useState<IProfileRespondentsList>({
      participantLink: "",
      respondents: [],
      noOfRespondents: 0,
      respondentsAnswered: 0,
      respondentsInvited: 0,
    });
  const [refetchInvitedRespondentsList, setRefetchInvitedRespondentsList] =
    useState<boolean>(false);

  function setInstructionLanguageForAllRespondents(languageCode: string): void {
    // FIXME: this method curiously only operates on manual respondents. is that correct?
    //   maybe we're assuming that the 'list respondents' already have a preferred language
    //   on the user level?

    const lang = currentUserLanguages.find((it) => it.name === languageCode);
    const next = respondentsToBeInvited.map((r) => {
      return {
        ...r,
        instructionLanguageId: lang?.id || null,
      };
    });
    setRespondentsToBeInvited(next);
  }

  useEffect(() => {
    if (userLanguage.userLanguageCode) {
      setInstructionLanguageForAllRespondents(userLanguage.userLanguageCode);
    }
  }, [props.profile, userLanguage.userLanguageCode]);

  useEffect(() => {
    if (props.status !== "current") {
      return;
    }

    if (props.profile.profileId && !props.profile.isLocked) {
      const body: IUserRespondentsBody = {
        profileId: props.profile.profileId,
        searchText,
      };
      //This fetches potential respondents for a user...
      getUserForRespondents(body, dispatch).then((response) => {
        if (response) {
          setRespondentsListFromSearch(response);
        }
      });
    }
  }, [searchText, refetchList, props.profile, props.status]);

  // Respondents status useEffect
  useEffect(() => {
    if (props.status !== "current") {
      return;
    }

    if (props.profile.profileId && props.profile.noOfRespondents) {
      getParticipantRespondentsByParticipantId(
        props.profile.profileId,
        dispatch,
      ).then((response) => {
        if (response) {
          setInvitedRespondentsList(() => ({
            ...response,
            respondentsInvited: response.respondentsInvited ?? 0,
            respondentsAnswered: response.respondentsAnswered ?? 0,
            participantRespondents: response.respondents ?? [],
          }));
        }
      });
    }
  }, [refetchInvitedRespondentsList, props.profile, props.status]);

  useEffect(() => {
    if (
      invitedRespondentsList.respondents.length >= props.profile.noOfRespondents
    ) {
      //We have enough invited respondents already
      setSelectedTab(NavTabEnums.None);
    }
    if (
      props.profile.participantInviteColleagues ||
      props.profile.participantInviteOtherParticipant
    ) {
      if (userLanguage.userLanguageCode) {
        setInstructionLanguageForAllRespondents(userLanguage.userLanguageCode);
      }
      if (
        invitedRespondentsList.respondents.length <
        props.profile.noOfRespondents
      ) {
        setSelectedTab(NavTabEnums.Select_From_List);
      }
    } else if (
      invitedRespondentsList.respondents.length < props.profile.noOfRespondents
    ) {
      setSelectedTab(NavTabEnums.Add_Manually);
    }
  }, [invitedRespondentsList, props.profile, userLanguage.userLanguageCode]);

  const handleTabSelection = (tabStatus: number): void => {
    setSelectedTab(tabStatus);
  };

  // Select from list fn's
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchText(e.target.value);
  };

  /**
   * Removes any candidates that already exists either in the listRespondents or already invited respondents. This just bluntly removes them,
   * which is maybe not the best UX, but will do for now.
   * @param newInvitees
   * @returns
   */
  function removeSeenRespondents(
    newInvitees: IListRespondent[],
  ): IListRespondent[] {
    //Check against invitation list
    const filteredInvitees = newInvitees.filter(
      (invitee) =>
        !respondentsToBeInvited.some(
          (invited) => invited.emailAddress === invitee.emailAddress,
        ),
    );

    //then check against already invited respondents
    const filteredRespondents = filteredInvitees.filter(
      (invitee) =>
        !invitedRespondentsList.respondents.some(
          (invited) => invited.email === invitee.emailAddress,
        ),
    );
    return filteredRespondents;
  }

  const handleSelectRespondent = async (
    respondent: IListRespondent,
  ): Promise<void> => {
    //Filter through already invited or pending invitation respondents
    const filteredInvitees = removeSeenRespondents([respondent]);

    //we don't have any new invitees to add
    if (filteredInvitees.length === 0) {
      return;
    }

    if (
      isRespondentsLimitReached(
        respondentsToBeInvited.length + filteredInvitees.length,
      )
    ) {
      setRespondentsLimitReachedModal(true);
      return;
    }

    if (
      !props.profile.roleCanBeChangedByParticipant &&
      invitedRespondentsList.respondents.length +
        respondentsToBeInvited.length >=
        props.profile.noOfRespondents
    ) {
      setRespondentsLimitReachedModal(true);
      return;
    }

    const nextList = respondentsToBeInvited.concat(filteredInvitees);

    setRespondentsToBeInvited(nextList);
  };

  const handleAddMultipleRespondents = (
    candidates: IListRespondent[],
  ): void => {
    const filteredInvitees = removeSeenRespondents(candidates);

    if (filteredInvitees.length === 0) {
      return;
    }

    const nextList = respondentsToBeInvited.concat(filteredInvitees);
    setRespondentsToBeInvited(nextList);
  };

  const handleRemoveRespondent = (respondent: IListRespondent): void => {
    const nextList = respondentsToBeInvited.filter(
      (r) => r.emailAddress !== respondent.emailAddress,
    );

    setRespondentsToBeInvited(nextList);
  };

  const handlePreviewModal = (): void => {
    const body: IRespondentEmailPreviewBody = {
      profileId: props.profile.profileId,
      languageCode: userLanguage.userLanguageCode,
      ownMsg: instructionMessage,
    };
    getRespondentEmailPreview(body, dispatch).then((response) => {
      if (response) {
        setPreviewHTML(response);
      }
    });
  };

  const isRespondentsLimitReached = (selectedLength: number): boolean => {
    //Check both the currently selected candidates and the already invited respondents
    const totalRespondents =
      selectedLength + invitedRespondentsList.respondents.length;

    if (totalRespondents > MAXIMUM_ALLOWED_RESPONDENTS) {
      return true;
    }

    if (
      props.profile.status === ProfileStatus.New &&
      props.profile.roleCanBeChangedByParticipant
    ) {
      // if the profile owner is allowed to change the 'role'
      // they're also allowed to invite however many respondents
      // they want.
      // Nope, there should be a hard stop at 20 and a warning when
      // trying to add the ninth, Joakim - 241217
      // Only when the profile is new should the participant be able
      // to freely choose amount of respondents, after that we freeze
      // the value into noOfRespondents

      return false;
    }

    // If selected more than limit, show limit message
    if (
      selectedLength + invitedRespondentsList.respondents.length >
      props.profile.noOfRespondents
    ) {
      return true;
    } else return false;
  };

  const closePreviewModal = (): void => {
    setPreviewHTML("");
  };

  const closeRespondentsLimitReachedModal = (): void => {
    setRespondentsLimitReachedModal(false);
  };

  const showAddManualOption = props.profile.participantInviteExternal;
  const showSelectFormListOption =
    props.profile.participantInviteColleagues ||
    props.profile.participantInviteOtherParticipant;
  const respondentsLimitReached =
    invitedRespondentsList.respondents.length >= props.profile.noOfRespondents;

  const rolePage = useRolePage(props);

  const __t = props.__t;
  const title = __t("Invite respondents");

  const canMoveToNextStep =
    respondentsToBeInvited.length >= props.profile.noOfRespondents &&
    respondentsToBeInvited.length > 0;

  const roleNameOrText = props.profile.roleId
    ? getLanguageValue(languageText, GetTypeOfRoleName[props.profile.roleId])
    : props.profile.roleText
      ? props.profile.roleText
      : "";

  const theActualFormAndStuff = (
    <React.Fragment>
      <div className="p-4">
        <RoleSettingsWithEditOption
          languageText={languageText}
          profileId={props.profile.profileId}
          status={props.profile.status}
          roleId={props.profile.roleId}
          roleText={props.profile.roleText}
          noOfRespondents={props.profile.noOfRespondents}
          isEditable={rolePage.roleCanBeChangedByParticipant}
          isParticipant={true}
          errorMessage={rolePage.errorMessage}
          handleTypeOfRoleSave={rolePage.handleTypeOfRoleSave}
          handleNoOfRespondentsSave={rolePage.handleNoOfRespondentsSave}
          canEditNoOfRespondents={false}
        />

        <p className="mt-4">
          {getLanguageValue(
            languageText,
            "Invite the respondents you want to engage to create your IDI profile",
          )}
          .
        </p>
        {!props.profile.roleCanBeChangedByParticipant && (
          <p>
            {__t("Number of respondents required")}:{" "}
            {props.profile.noOfRespondents}
          </p>
        )}
        {!respondentsLimitReached ? (
          <>
            <div className="invite-respondents-tab fs-5 fw-bold mb-3">
              {showSelectFormListOption && showAddManualOption && (
                <div
                  id="inviteRespondentInstruction2"
                  className={clsx(
                    `me-2 btn`,
                    selectedTab === NavTabEnums.Select_From_List
                      ? "btn-dark"
                      : "btn-light",
                  )}
                  onClick={() =>
                    handleTabSelection(NavTabEnums.Select_From_List)
                  }
                >
                  <i className="bi bi-list-task me-2"></i>
                  {getLanguageValue(languageText, "Select from list")}
                </div>
              )}
              {showAddManualOption && showSelectFormListOption && (
                <div
                  id="inviteRespondentInstruction3"
                  className={clsx(
                    `btn`,
                    selectedTab === NavTabEnums.Add_Manually
                      ? "btn-dark"
                      : "btn-light",
                  )}
                  onClick={() => handleTabSelection(NavTabEnums.Add_Manually)}
                >
                  <i className="bi bi-pencil-fill me-2"></i>
                  {getLanguageValue(languageText, "Add manually")}
                </div>
              )}
            </div>

            <Box className="mb-3">
              {/* Select from list */}
              {showSelectFormListOption &&
                selectedTab === NavTabEnums.Select_From_List && (
                  <SelectFromList
                    languageText={languageText}
                    languages={currentUserLanguages}
                    respondentsList={respondentsListFromSearch}
                    searchText={searchText}
                    selectedRespondents={respondentsToBeInvited}
                    handleSearch={handleSearch}
                    handleSelectRespondent={handleSelectRespondent}
                    handleRemoveRespondent={handleRemoveRespondent}
                  />
                )}

              {/* Add Manually */}
              {showAddManualOption &&
                selectedTab === NavTabEnums.Add_Manually && (
                  <>
                    <AddManually
                      languages={currentUserLanguages}
                      respondents={respondentsToBeInvited}
                      invitedRespondents={invitedRespondentsList.respondents}
                      profile={props.profile}
                      handleAddMultipleRespondents={
                        handleAddMultipleRespondents
                      }
                    />
                  </>
                )}
            </Box>
          </>
        ) : (
          <div className="text-danger d-none">
            {
              getLanguageValue(
                languageText,
                "Respondents limit reached. Cannot add further respondents",
              )
              //AM: not sure if we will keep this
            }
            .
          </div>
        )}

        {/* List of respondents selected  */}
        <Box className="mb-3">
          <div className="fs-5 fw-bold mb-3">
            {getLanguageValue(languageText, "Selected")}
          </div>
          {respondentsToBeInvited.length > 0 && (
            <>
              <div className="mb-4">
                {respondentsToBeInvited.map((respondent, index: number) => (
                  <div
                    key={index}
                    className="d-md-flex align-items-center bg-primary rounded bg-opacity-10 mb-2 p-3"
                  >
                    <div className="flex-grow-1">
                      <span className="fs-5 fw-bold">{respondent.name}</span>
                      <span className="">
                        &nbsp;({respondent.emailAddress})
                      </span>
                    </div>
                    <div
                      className="me-3 d-flex align-items-center"
                      title={getLanguageValue(
                        languageText,
                        "Select the instruction language",
                      )}
                      role="button"
                      onClick={(event) => {
                        event.preventDefault();
                        setSelectedRespondentIndex(index);
                      }}
                    >
                      <i className="bi bi-globe fs-4 me-2" />{" "}
                      <span className="text-decoration-underline">
                        {respondent.instructionLanguageId ? (
                          <>
                            {currentUserLanguages.find(
                              (r) => r.id === respondent.instructionLanguageId,
                            )?.displayName ?? ""}
                          </>
                        ) : (
                          __t("Click to set instruction language")
                        )}
                      </span>
                    </div>
                    <div className="me-3"></div>
                    <div>
                      <div onClick={() => handleRemoveRespondent(respondent)}>
                        <i className="bi bi-trash3 fs-4" />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
          {respondentsToBeInvited.length < 1 && (
            <>
              <div className="d-inline-block bg-primary rounded bg-opacity-10 mb-4 p-3">
                <i className="bi bi-info-circle me-2 fs-4" />{" "}
                {getLanguageValue(
                  languageText,
                  "No respondent selected. Select by clicking the + button above.",
                )}
              </div>
            </>
          )}
        </Box>
        {/** /Selected respondents */}

        <SendRespondentsInvitation
          languageText={languageText}
          messageValue={instructionMessage}
          handleSaveMessage={(message) => {
            setInstructionMessage(message);
            api.saveParticipantFieldValue({
              id: props.profile.profileId,
              fieldName: "InstructionMessage",
              fieldValue: message,
            });
          }}
          onPreviewClick={handlePreviewModal}
        />
      </div>
      {previewHTML && (
        <PreviewPopup
          languageText={languageText}
          previewHTML={previewHTML}
          onCloseClick={closePreviewModal}
        />
      )}

      {respondentsLimitReachedModal && (
        <RespondentsLimitReachedModal
          headerText={getLanguageValue(languageText, "Limit Reached")}
          bodyText={`${getLanguageValue(languageText, "Maximum")} ${
            //max allowed respondents only 20 when new status after that
            //the participant has chosen his or hers number
            props.profile.roleCanBeChangedByParticipant &&
            props.profile.status === ProfileStatus.New
              ? MAXIMUM_ALLOWED_RESPONDENTS
              : props.profile.noOfRespondents
          } ${getLanguageValue(languageText, "respondents can be invited")}.`}
          cancelButtonText={getLanguageValue(languageText, "Ok")}
          handleCancelClick={closeRespondentsLimitReachedModal}
        />
      )}

      {/** TODO: There is an issue here if you open the language modal without changing
       * anything and clicking save, it looks like it's going to be English but that's only shown in the UI.
       */}
      {typeof selectedRespondentIndex === "number" && (
        <InstructionLanguageSelector
          languageText={languageText}
          languages={currentUserLanguages}
          onSave={(respondent) => {
            setRespondentsToBeInvited((prevItems) =>
              prevItems.map((item) =>
                item.emailAddress === respondent.emailAddress
                  ? {
                      ...item,
                      instructionLanguageId: respondent.instructionLanguageId,
                    }
                  : item,
              ),
            );
          }}
          onClose={() => setSelectedRespondentIndex(undefined)}
          value={respondentsToBeInvited[selectedRespondentIndex]}
        />
      )}

      {!tooManyRespondentsWarningShown &&
        respondentsToBeInvited.length +
          invitedRespondentsList.respondents.length >
          MAXIMUM_RECOMMENDED_RESPONDENTS && (
          <CustomModal
            headerText={__t("Too many respondents")}
            bodyText={__t("We don't recommend adding more than 8 respondents")}
            cancelButtonText="Ok"
            handleCancelClick={() => {
              setTooManyRespondentsWarningShown(true);
            }}
          ></CustomModal>
        )}
    </React.Fragment>
  );

  /**
   * This first checks if the total sum of invitees (list and manual) is higher
   * than the allowed and then sends the request to add them.
   * @returns
   */
  async function saveRespondentsAndMaybeSendInvites(): Promise<void> {
    if (respondentsToBeInvited.length === 0) {
      Promise.reject();
    }
    if (isRespondentsLimitReached(respondentsToBeInvited.length)) {
      setRespondentsLimitReachedModal(true);
      return Promise.reject();
    }

    dispatch(setSpinner(true));
    const params: ICreateRespondentsParams = {
      profileId: props.profile.profileId,
    };

    // Create body from the selected respondents
    const respondents: Array<ICreateManualRespondentsBody> =
      respondentsToBeInvited.map((r) => {
        return {
          name: r.name,
          email: r.emailAddress,
          telephone: r.phoneNumber,
          instructionLanguageId: r.instructionLanguageId,
        };
      });

    return createManualProfileParticipantRespondents(
      respondents,
      params,
      dispatch,
    )
      .then((response) => {
        if (response?.success) {
          setRefetchList(!refetchList);
          setRefetchInvitedRespondentsList(!refetchInvitedRespondentsList);
          onInvited();
        }
      })
      .finally(() => dispatch(setSpinner(false)));
  }

  if (props.variant.kind === "modal") {
    // for an explanation for why this garbage is here, see the rant above in the props declaration.
    // this component should obviously not "be" two things at once, but the form is inherently
    // tighly coupled to the hook 'useInviteRespondent'.

    const variant = props.variant;
    return (
      <ModalComponent
        headerText={__t("Add respondent")}
        submitButtonText={__t("Submit")}
        handleSubmitClick={(event) => {
          event.preventDefault();
          saveRespondentsAndMaybeSendInvites().then(() => {
            variant.onSubmit();
          });
        }}
        cancelButtonText={__t("Cancel")}
        handleCancelClick={(event) => {
          event.preventDefault();
          variant.onClose();
        }}
        submitButtonDisabled={respondentsToBeInvited.length === 0}
        width="xl"
      >
        {theActualFormAndStuff}
      </ModalComponent>
    );
  }

  switch (props.status) {
    case "upcoming":
      return <UpcomingStepPanel title={title} />;
    case "current":
      return (
        <CurrentStepPanel
          __t={__t}
          title={
            <div className="d-flex">
              <div className="flex-grow-1">{title}</div>
              <div className="flex-grow-0">
                <Instruction
                  showHelpButton={true}
                  targetElement="instructionBtn"
                  //Shouldn't this be GuidePages.Participant_Invite_Respondents? - Joakim, 241112
                  guidePage={GuidePages.Participant_Role}
                  instructionSteps={instructionSteps}
                  /* is the '4' here accurate? is it an index? is it a step 'number'? */
                  stepsNotToSkip={
                    !rolePage.roleCanBeChangedByParticipant ? [4] : []
                  }
                  manualTrigger={true}
                />
              </div>
            </div>
          }
          onPreviousClick={(event) => {
            event.preventDefault();
            props.setStep(Step.DemographicSurvey);
          }}
          onNextClick={(event) => {
            event.preventDefault();

            saveRespondentsAndMaybeSendInvites();
          }}
          nextDisabled={!canMoveToNextStep}
        >
          {theActualFormAndStuff}
        </CurrentStepPanel>
      );
    case "completed": {
      const title = (
        <span>
          {__t("My respondents are")}{" "}
          <span className="fw-bold">
            {props.profile.respondents.length} {roleNameOrText}
          </span>
          {props.profile.respondents.map((r, index: number) => {
            return <Pill key={index}>{getShortName(r.name)}</Pill>;
          })}
        </span>
      );

      return <CompletedStepPanel title={title} />;
    }
  }
};
