import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../../commonUtils/languageFunctionsHelper";
import { RootState } from "@app/store/configureStore";
import {
  ActivityRoleStep,
  IRoleStepData,
} from "../../../addActivity/activityRoleStep";
import {
  IndividualGroupBtnsEnum,
  ParticipantChangeRole,
  ProfileRoleStatus,
  RoleBtnsEnum,
} from "../../../activityEnums";
import { editActivityUpdateRole } from "../../actions";
import { getTypeOfRoleButtonList } from "../../../../../commonUtils/roleSettingsFunctionsHelper";
import { addToast, setSpinner } from "../../../../actions";
import { ModalComponent } from "@app/components/modals/modalComponent";
import { AnyAction } from "redux";
import { isLoggedInRoleAdmin } from "@app/commonUtils/roleHelper";

export interface IEditActivityUpdateRoleBody extends IRoleStepData {
  id: number;
}

interface IEditActivityRoleModalProps {
  id: number;
  languageText: ITranslationObject;
  isGroup: boolean;
  roleSetBy: number;
  roleSameForAll: boolean;
  roleId: number;
  roleText: string;
  noOfRespondents: number;
  roleCanBeChangedByParticipant: boolean;
  cannotEditRoleCanBeChangedByParticipant: boolean;
  closeModal: () => void;
  handleSaveClick: (roleSettings: IRoleStepData) => void;
}

export const EditActivityRoleModal: React.FC<IEditActivityRoleModalProps> = (
  props,
) => {
  const dispatch = useDispatch();

  const loggedInUserRole = useSelector(
    (state: RootState) => state.loginReducer.loggedInUserRole,
  );
  const typeOfRoleButtonList = useMemo(
    () => getTypeOfRoleButtonList(props.languageText),
    [props.languageText],
  );

  const [selectedSetRoleBtn, setSelectedSetRoleBtn] = useState<string>(""); // SET ROLE STATE
  const [selectedSameRoleBtn, setSelectedSameRoleBtn] = useState<string>(""); // ROLE SETTINGS STATE
  const [selectedTypeOfRoleBtn, setSelectedTypeOfRoleBtn] =
    useState<string>(""); // TYPE OF ROLE STATES BEGIN
  const [typeOfRoleInput, setTypeOfRoleInput] = useState<string>("");
  const [customTypeOfRole, setCustomTypeOfRole] = useState<string>("");
  const [roleInputSelected, setRoleInputSelected] = useState<boolean>(false);
  const [selectedNoOfRespondentsBtn, setSelectedNoOfRespondentsBtn] =
    useState<string>(""); // NUMBER OF RESPONDENTS STATES BEGIN
  const [noOfRespondentsInput, setNoOfRespondentsInput] = useState<string>("");
  const [customNoOfRespondents, setCustomNoOfRespondents] =
    useState<string>("");
  const [respondentsInputSelected, setRespondentsInputSelected] =
    useState<boolean>(false);
  const [respondentsConfirmModal, setRespondentsConfirmModal] =
    useState<boolean>(false);
  const [selectedParticipantChangeBtn, setSelectedParticipantChangeBtn] =
    useState<string>(""); // PARTICIPANT CHANGE ROLE STATE
  const [roleStepError, setRoleStepError] = useState<string>("");
  const [showEditModeRoleType, setShowEditModeRoleType] =
    useState<boolean>(true);
  const [showEditModeNoOfRespondent, setShowEditModeNoOfRespondent] =
    useState<boolean>(true);
  const [showEditModeRoleButtonList, setShowEditModeRoleButtonList] =
    useState<boolean>(true);
  const [showEditModeSelectedSameRoleBtn, setShowEditModeSelectedSameRoleBtn] =
    useState<boolean>(true);
  const [
    showEditModeSelectedParticipantBtnClick,
    setshowEditModeSelectedParticipantBtnClick,
  ] = useState<boolean>(true);

  useEffect(() => {
    // Set role settings
    setSelectedSetRoleBtn(String(props.roleSetBy));
    if (props.roleSetBy) setShowEditModeRoleButtonList(false);
    setSelectedSameRoleBtn(
      props.roleSameForAll
        ? RoleBtnsEnum.sameRole
        : RoleBtnsEnum.individualRole,
    );
    setShowEditModeSelectedSameRoleBtn(false);
    if (props.roleId) {
      setSelectedTypeOfRoleBtn(String(props.roleId));
      setShowEditModeRoleType(false);
    }
    if (props.roleText) {
      setTypeOfRoleInput(props.roleText);
      setCustomTypeOfRole(props.roleText);
      setRoleInputSelected(true);
      setShowEditModeRoleType(false);
    }
    if (
      props.noOfRespondents === 3 ||
      props.noOfRespondents === 4 ||
      props.noOfRespondents === 5 ||
      props.noOfRespondents === 6
    ) {
      setSelectedNoOfRespondentsBtn(String(props.noOfRespondents));
      setShowEditModeNoOfRespondent(false);
    } else if (props.noOfRespondents) {
      setNoOfRespondentsInput(String(props.noOfRespondents));
      setCustomNoOfRespondents(String(props.noOfRespondents));
      setRespondentsInputSelected(true);
      setShowEditModeNoOfRespondent(false);
    }
    setSelectedParticipantChangeBtn(
      props.roleCanBeChangedByParticipant
        ? ParticipantChangeRole.canChange
        : ParticipantChangeRole.cannotChange,
    );
    setshowEditModeSelectedParticipantBtnClick(false);
  }, []);

  const clearRoleStepError = (): void => {
    if (roleStepError) setRoleStepError("");
  };

  // SET ROLE FUNCTION
  const onSetRoleBtnClick = (name: string): void => {
    clearRoleStepError();
    setSelectedSetRoleBtn(name);
    setShowEditModeRoleButtonList(false);
    if (name !== ProfileRoleStatus.RoleSetByFacilitator) {
      if (selectedSameRoleBtn) setSelectedSameRoleBtn("");
      if (selectedTypeOfRoleBtn) setSelectedTypeOfRoleBtn("");
      if (customTypeOfRole) {
        setTypeOfRoleInput("");
        setCustomTypeOfRole("");
        setRoleInputSelected(false);
      }
      if (selectedNoOfRespondentsBtn) setSelectedNoOfRespondentsBtn("");
      if (customNoOfRespondents) {
        setNoOfRespondentsInput("");
        setCustomNoOfRespondents("");
        setRespondentsInputSelected(false);
      }
      if (selectedParticipantChangeBtn) setSelectedParticipantChangeBtn("");
    }
  };

  // ROLE SETTINGS FUNCTION
  const onSameRoleBtnClick = (name: string): void => {
    clearRoleStepError();
    setSelectedSameRoleBtn(name);
    setShowEditModeSelectedSameRoleBtn(false);
    if (selectedTypeOfRoleBtn) setSelectedTypeOfRoleBtn("");
    if (customTypeOfRole) {
      setTypeOfRoleInput("");
      setCustomTypeOfRole("");
      setRoleInputSelected(false);
    }
    if (selectedNoOfRespondentsBtn) setSelectedNoOfRespondentsBtn("");
    if (customNoOfRespondents) {
      setNoOfRespondentsInput("");
      setCustomNoOfRespondents("");
      setRespondentsInputSelected(false);
    }
    if (selectedParticipantChangeBtn) setSelectedParticipantChangeBtn("");
  };

  const onNoOfRespondentsClick = (name: string): void => {
    clearRoleStepError();
    setSelectedNoOfRespondentsBtn(name);
    setShowEditModeNoOfRespondent(false);
    if (respondentsInputSelected) {
      setRespondentsInputSelected(false);
      setNoOfRespondentsInput("");
      setCustomNoOfRespondents("");
    }
  };

  const onTypeOfRoleBtnClick = (name: string): void => {
    clearRoleStepError();
    setSelectedTypeOfRoleBtn(name);
    setShowEditModeRoleType(false);
    if (roleInputSelected) {
      setRoleInputSelected(false);
      setTypeOfRoleInput("");
      setCustomTypeOfRole("");
    }
  };

  const onNoOfRespondentsChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setNoOfRespondentsInput(e.target.value);
  };

  const onTypeOfRoleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTypeOfRoleInput(e.target.value);
  };

  const handleTypeOfRoleFocus = (
    e: React.FocusEvent<HTMLInputElement>,
  ): void => {
    clearRoleStepError();
    const { value } = e.target;
    if (value) {
      setRoleInputSelected(true);
      setShowEditModeRoleType(false);
    } else {
      setShowEditModeRoleType(true);
      if (roleInputSelected) setRoleInputSelected(false);
    }
    setCustomTypeOfRole(value);
    if (value && selectedTypeOfRoleBtn) setSelectedTypeOfRoleBtn("");
  };

  const handleCustomNoOfRespondents = (value: string): void => {
    if (value && selectedNoOfRespondentsBtn) setSelectedNoOfRespondentsBtn("");
    setCustomNoOfRespondents(value);
  };

  const handleNoOfRespondentsFocus = (
    e: React.FocusEvent<HTMLInputElement>,
  ): void => {
    clearRoleStepError();
    const { value } = e.target;
    if (value) {
      if (
        Number(value) > 8 &&
        value !== customNoOfRespondents &&
        (isLoggedInRoleAdmin(loggedInUserRole) ||
          (!isLoggedInRoleAdmin(loggedInUserRole) && Number(value) <= 20))
      ) {
        setRespondentsConfirmModal(true);
        setShowEditModeNoOfRespondent(false);
        return;
      } else if (value === "") {
        setShowEditModeNoOfRespondent(true);
      } else if (
        value === "3" ||
        value === "4" ||
        value === "5" ||
        value === "6"
      ) {
        onNoOfRespondentsClick(value);
        setShowEditModeNoOfRespondent(false);
        setNoOfRespondentsInput("");
        return;
      } else {
        setRespondentsInputSelected(true);
        setShowEditModeNoOfRespondent(false);
      }
    } else {
      if (respondentsInputSelected) setRespondentsInputSelected(false);
      setShowEditModeNoOfRespondent(true);
    }
    handleCustomNoOfRespondents(value);
  };

  const cancelRespondentsConfirmModal = (): void => {
    setRespondentsConfirmModal(false);
    setNoOfRespondentsInput("");
    setRespondentsInputSelected(false);
    setShowEditModeNoOfRespondent(true);
  };

  const SubmitRespondentsConfirmModal = (): void => {
    setRespondentsConfirmModal(false);
    setRespondentsInputSelected(true);
    handleCustomNoOfRespondents(noOfRespondentsInput);
  };

  const handleParticipantChangeRoleClick = (name: string): void => {
    if (
      name === ParticipantChangeRole.cannotChange &&
      props.cannotEditRoleCanBeChangedByParticipant
    ) {
      setRoleStepError(
        getLanguageValue(
          props.languageText,
          "Individual participants role settings must be selected first",
        ),
      );
    } else {
      clearRoleStepError();
      setSelectedParticipantChangeBtn(name);
      setshowEditModeSelectedParticipantBtnClick(false);
    }
  };

  const handleThirdStepValidation = (): boolean => {
    let errorMsg: string = "";

    if (!selectedSetRoleBtn) {
      errorMsg = getLanguageValue(props.languageText, "Select role status");
    } else if (selectedSetRoleBtn === ProfileRoleStatus.RoleSetByFacilitator) {
      if (!selectedSameRoleBtn && props.isGroup) {
        errorMsg = getLanguageValue(props.languageText, "Select role setting");
      } else if (
        selectedSameRoleBtn === RoleBtnsEnum.sameRole ||
        !props.isGroup
      ) {
        if (!selectedTypeOfRoleBtn && !customTypeOfRole) {
          errorMsg = getLanguageValue(props.languageText, "Select role type");
        } else if (!selectedNoOfRespondentsBtn && !customNoOfRespondents) {
          errorMsg = getLanguageValue(
            props.languageText,
            "Select no of respondents",
          );
        } else if (
          !isLoggedInRoleAdmin(loggedInUserRole) &&
          customNoOfRespondents &&
          Number(customNoOfRespondents) < 3
        ) {
          errorMsg = getLanguageValue(
            props.languageText,
            "Minimum 3 respondents should be selected",
          );
        } else if (
          !isLoggedInRoleAdmin(loggedInUserRole) &&
          customNoOfRespondents &&
          Number(customNoOfRespondents) > 20
        ) {
          errorMsg = getLanguageValue(
            props.languageText,
            "Maximum 20 respondents can be selected",
          );
        } else if (!selectedParticipantChangeBtn) {
          errorMsg = getLanguageValue(
            props.languageText,
            "Select participant role setting",
          );
        }
      } else if (!selectedParticipantChangeBtn) {
        errorMsg = getLanguageValue(
          props.languageText,
          "Select participant role setting",
        );
      }
    }
    setRoleStepError(errorMsg);
    return errorMsg ? false : true;
  };

  const onSaveClick = (): void => {
    if (!handleThirdStepValidation()) return;

    dispatch(setSpinner(true));
    const roleSettings: IRoleStepData = {
      roleSetBy: Number(selectedSetRoleBtn),
      roleSameForAll:
        selectedSameRoleBtn === RoleBtnsEnum.sameRole || !props.isGroup,
      roleId: Number(selectedTypeOfRoleBtn),
      roleText: customTypeOfRole,
      noOfRespondents: customNoOfRespondents
        ? Number(customNoOfRespondents)
        : Number(selectedNoOfRespondentsBtn),
      roleCanBeChangedByParticipant:
        selectedParticipantChangeBtn === ParticipantChangeRole.canChange,
    };

    const body: IEditActivityUpdateRoleBody = {
      ...roleSettings,
      id: props.id,
    };

    editActivityUpdateRole(body, dispatch)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(addToast("Updated role settings") as AnyAction);
          props.handleSaveClick(roleSettings);
        }
      })
      .finally(() => {
        dispatch(setSpinner(false));
        props.closeModal();
      });
  };

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(props.languageText, "Edit Role")}
        submitButtonText={getLanguageValue(props.languageText, "Save")}
        cancelButtonText={getLanguageValue(props.languageText, "Cancel")}
        handleSubmitClick={onSaveClick}
        handleCancelClick={props.closeModal}
        width="lg"
      >
        <ActivityRoleStep
          languageText={props.languageText}
          roleFromEditProfile={true}
          selectedIndividualGroupBtn={
            props.isGroup
              ? IndividualGroupBtnsEnum.group
              : IndividualGroupBtnsEnum.individual
          }
          typeOfRoleButtonList={typeOfRoleButtonList}
          selectedSetRoleBtn={selectedSetRoleBtn}
          selectedSameRoleBtn={selectedSameRoleBtn}
          selectedTypeOfRoleBtn={selectedTypeOfRoleBtn}
          typeOfRoleInput={typeOfRoleInput}
          customTypeOfRole={customTypeOfRole}
          roleInputSelected={roleInputSelected}
          selectedNoOfRespondentsBtn={selectedNoOfRespondentsBtn}
          noOfRespondentsInput={noOfRespondentsInput}
          customNoOfRespondents={customNoOfRespondents}
          respondentsInputSelected={respondentsInputSelected}
          respondentsConfirmModal={respondentsConfirmModal}
          selectedParticipantChangeBtn={selectedParticipantChangeBtn}
          roleStepError={roleStepError}
          showEditModeNoOfRespondent={showEditModeNoOfRespondent}
          setShowEditModeNoOfRespondent={setShowEditModeNoOfRespondent}
          onSetRoleBtnClick={onSetRoleBtnClick}
          onSameRoleBtnClick={onSameRoleBtnClick}
          onTypeOfRoleChange={onTypeOfRoleChange}
          onTypeOfRoleBtnClick={onTypeOfRoleBtnClick}
          handleTypeOfRoleFocus={handleTypeOfRoleFocus}
          onNoOfRespondentsChange={onNoOfRespondentsChange}
          onNoOfRespondentsClick={onNoOfRespondentsClick}
          handleNoOfRespondentsFocus={handleNoOfRespondentsFocus}
          cancelRespondentsConfirmModal={cancelRespondentsConfirmModal}
          SubmitRespondentsConfirmModal={SubmitRespondentsConfirmModal}
          handleParticipantChangeRoleClick={handleParticipantChangeRoleClick}
          commonText={true}
          setShowEditModeRoleType={setShowEditModeRoleType}
          showEditModeRoleType={showEditModeRoleType}
          showEditModeRoleButtonList={showEditModeRoleButtonList}
          setShowEditModeRoleButtonList={setShowEditModeRoleButtonList}
          showEditModeSelectedSameRoleBtn={showEditModeSelectedSameRoleBtn}
          setShowEditModeSelectedSameRoleBtn={
            setShowEditModeSelectedSameRoleBtn
          }
          showEditModeSelectedParticipantBtnClick={
            showEditModeSelectedParticipantBtnClick
          }
          setshowEditModeSelectedParticipantBtnClick={
            setshowEditModeSelectedParticipantBtnClick
          }
        />
      </ModalComponent>
    </>
  );
};
