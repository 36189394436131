import {
  ITranslationObject,
  getLanguageValue,
} from "../../commonUtils/languageFunctionsHelper";
import { IDropdownList } from "@app/components/formComponents/dropdownSelect";
import { Behave } from "./hooks";

export const getBehaveOptions = (
  languageText: ITranslationObject,
): IDropdownList<Behave>[] => [
  {
    id: Behave.Affiliation,
    value: Behave.Affiliation,
    displayName: getLanguageValue(languageText, "Affiliation"),
  },
  {
    id: Behave.Directiveness,
    value: Behave.Directiveness,
    displayName: getLanguageValue(languageText, "Directiveness"),
  },
  {
    id: Behave.Adaptability,
    value: Behave.Adaptability,
    displayName: getLanguageValue(languageText, "Adaptability"),
  },
];
