import React from "react";

import { ClientBilling } from "./utils/clientBilling";
import { DeprecatedClientDetails } from "./utils/deprecatedClientDetails";
import { ReactTable } from "../../../components/reactTable/reactTable";
import { TabNavItem } from "../../../components/multiTabComponent/tabNavItem";
import { TabContent } from "../../../components/multiTabComponent/tabContent";
import { NavTabs } from "./clientEnums";
import { useAddAccount } from "./addEditAccountModalHooks";
import { ModalComponent } from "../../../components/modals/modalComponent";
import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import { ActivityList } from "@app/containers/activityList";
import { ClientUsersList } from "./utils/clientUsers/clientUsersList";

export interface IAddEditAccountModalProps {
  rowData: any;
  isActivity?: boolean;
  refreshClientList: () => void;
  closeClientModal: (clientId?: number) => void;
}

export const AddEditAccountModal = (props: IAddEditAccountModalProps) => {
  const {
    languageText,
    clientId,
    isEditClicked,
    navTabs,
    activeTab,
    accountInfo,
    focusInput,
    countries,
    subscriptionTableHeader,
    data,
    openClientBillingForm,
    selectedClientSubscription,
    plans,
    selectedPlan,
    paymentTermsList,
    billingInfo,
    billingFocusInput,
    paymentTypes,
    paymentStatusList,
    paymentList,
    paymentAfterEndList,
    currencyList,
    invoiceList,
    setActiveTab,
    handleClientInputChange,
    handleClientCheckboxChange,
    handleClientBlurEvent,
    handleCountrySelect,
    handleTextAreaChange,
    handleTextAreaBlurEvent,
    handlePhoneInputChange,
    handleDifferentInvoiceAddress,
    handleSubscription,
    getClientSubscription,
    closeAddEditAccountModal,
    handleClientSubmit,
    addSubscriptionClick,
    handleBackToSubscriptionList,
    handleSelectPlan,
    handlePlanChange,
    handleBillingInputChange,
    handleBillingBlurEvent,
    handleBillingDropdownSelect,
    handleDateSelect,
    handleSelectPaymentMethod,
    handleBillingSubmit,
  } = useAddAccount(props);

  return (
    <>
      <ModalComponent
        width="xl"
        headerText={
          isEditClicked
            ? getLanguageValue(languageText, "Edit Account")
            : getLanguageValue(languageText, "Add Account")
        }
        cancelButtonText={getLanguageValue(languageText, "Cancel")}
        submitButtonText={
          activeTab === NavTabs.clientDetails ||
          (activeTab === NavTabs.billing && openClientBillingForm)
            ? getLanguageValue(
                languageText,
                activeTab === NavTabs.clientDetails
                  ? "Save and continue"
                  : "Save",
              )
            : ""
        }
        handleCancelClick={closeAddEditAccountModal}
        handleSubmitClick={
          activeTab === NavTabs.clientDetails
            ? handleClientSubmit
            : handleBillingSubmit
        }
      >
        <div className="d-flex flex-column">
          <TabNavItem
            navTabs={navTabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />

          {/* Client Details */}
          <TabContent id={NavTabs.clientDetails} activeTab={activeTab}>
            <DeprecatedClientDetails
              languageText={languageText}
              clientId={clientId}
              accountInfo={accountInfo}
              focusInput={focusInput}
              countries={countries}
              handleInputChange={handleClientInputChange}
              handleCheckboxChange={handleClientCheckboxChange}
              handleBlurEvent={handleClientBlurEvent}
              handleCountrySelect={handleCountrySelect}
              handleTextAreaChange={handleTextAreaChange}
              handleTextAreaBlurEvent={handleTextAreaBlurEvent}
              handlePhoneInputChange={handlePhoneInputChange}
              handleDifferentInvoiceAddress={handleDifferentInvoiceAddress}
              closeClientModal={props.closeClientModal}
              refreshClientList={props.refreshClientList}
            />
          </TabContent>

          {/* Billing Details */}
          <TabContent id={NavTabs.billing} activeTab={activeTab}>
            <>
              {/* Client Subscription List */}
              {!openClientBillingForm && (
                <ReactTable
                  headerText={getLanguageValue(
                    languageText,
                    "Subscription list",
                  )}
                  buttonText={getLanguageValue(
                    languageText,
                    "Add Subscription",
                  )}
                  minHeight="min-h-250px"
                  tableHeader={subscriptionTableHeader}
                  data={data}
                  paginationRequired={false}
                  handleButtonClick={addSubscriptionClick}
                />
              )}

              {/* Client Subscription Billing Form */}
              {openClientBillingForm && (
                <>
                  <ClientBilling
                    languageText={languageText}
                    accountInfo={accountInfo}
                    plans={plans}
                    selectedPlan={selectedPlan}
                    paymentTermsList={paymentTermsList}
                    billingInfo={billingInfo}
                    billingFocusInput={billingFocusInput}
                    paymentTypes={paymentTypes}
                    paymentStatusList={paymentStatusList}
                    paymentList={paymentList}
                    paymentAfterEndList={paymentAfterEndList}
                    currencyList={currencyList}
                    invoiceList={invoiceList}
                    selectedClientSubscription={selectedClientSubscription}
                    handlePlanChange={handlePlanChange}
                    handleSelectPlan={handleSelectPlan}
                    handleInputChange={handleBillingInputChange}
                    handleBlurEvent={handleBillingBlurEvent}
                    handleDropdownSelect={handleBillingDropdownSelect}
                    handleDateSelect={handleDateSelect}
                    handleSelectPaymentMethod={handleSelectPaymentMethod}
                    handleSubscription={handleSubscription}
                    handleBackToSubscriptionList={handleBackToSubscriptionList}
                    getClientSubscription={getClientSubscription}
                  />
                </>
              )}
            </>
          </TabContent>

          {/* Users for client */}
          <TabContent id={NavTabs.users} activeTab={activeTab}>
            <ClientUsersList
              clientId={clientId}
              refreshClientList={props.refreshClientList}
            />
          </TabContent>

          <TabContent id={NavTabs.profiles} activeTab={activeTab}>
            <ActivityList clientIdFromAccount={clientId} />
          </TabContent>
        </div>
      </ModalComponent>
    </>
  );
};
