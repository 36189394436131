import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { AnyAction } from "redux";

import {
  DropdownSelect,
  IDropdownList,
  IDropdownSelectedItem,
} from "@app/components/formComponents/dropdownSelect";
import { RootState } from "@app/store/configureStore";
import { createProfileByParticipant } from "../actions";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../commonUtils/languageFunctionsHelper";
import { ModalComponent } from "../../../../components/modals/modalComponent";
import { IFocusError } from "../../../../components/formComponents/input";
import { getFacilitatorCultures } from "../../../languageList/actions";
import { getAllCountries } from "../../../clientList/addEditModal/action";
import { setParticipantProfileExists } from "../../actions";
import { routePath } from "../../../routePaths";
import { addToast, setSpinner } from "../../../actions";
import { ICountry, ICulture } from "@app/containers/commonInterfaces";
import { ucQueryKey } from "../../useParticipantProfileAuth";
import { pushToDataLayer } from "@app/commonUtils/google";
import { UserId } from "@app/containers/reducer";

const ProfileInputs = {
  languageId: "languageId",
  countryId: "countryId",
} as const;

export interface ICreateProfileByUserBody {
  userId: UserId;
  idiLanguageId: number;
  countryId: number;
  idiClientId: number;
  isProfileExists: boolean;
}

interface IProfileDetails {
  languageId: string;
  countryId: string;
}

interface IFocusInput {
  languageId: IFocusError;
  countryId: IFocusError;
}

interface ICreateNewProfile {
  languageText: ITranslationObject;
  readOnly: boolean;
}

export const CreateNewProfile = (props: ICreateNewProfile): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentClient = useSelector(
    (state: RootState) => state.loginReducer.currentClient,
  );
  const userId = useSelector((state: RootState) => state.loginReducer.userId);
  const participantProfileExists: boolean = useSelector(
    (state: RootState) => state.participantReducer.participantProfileExists,
  );

  const initialProfileDetailsState: IProfileDetails = {
    languageId: "",
    countryId: "",
  };
  const initialFocusInputState: IFocusError = {
    touched: false,
    errorMessage: "",
  };
  const [createProfileModal, setCreateProfileModal] = useState<boolean>(false);
  const [cultures, setCultures] = useState<IDropdownList[]>([]);
  const [countries, setCountries] = useState<IDropdownList[]>([]);
  const [profileDetails, setProfileDetails] = useState<IProfileDetails>(
    initialProfileDetailsState,
  );
  const [startTime, setStartTime] = useState(Date.now());
  const [focusInput, setFocusInput] = useState<IFocusInput>({
    languageId: initialFocusInputState,
    countryId: initialFocusInputState,
  });

  useEffect(() => {
    // Set start time when the component triggers
    setStartTime(Date.now());
  }, []);
  const userDetails = useSelector(
    (state: RootState) => state.loginReducer.userDetails,
  );

  useEffect(() => {
    if (!participantProfileExists) {
      getFacilitatorCultures(dispatch).then((response) => {
        if (response?.length > 0) {
          const cultures: IDropdownList[] = response.map((item) => ({
            id: item.id,
            displayName: item.displayName,
            value: item.id.toString(),
          }));
          setCultures(cultures);
        }
      });
      getAllCountries(dispatch).then((response: ICountry[]) => {
        if (response?.length > 0) {
          const countries: IDropdownList[] = response.map((item: ICountry) => ({
            id: item.id,
            displayName: item.name,
            value: item.id.toString(),
          }));
          setCountries(countries);
        }
      });
    }
  }, [participantProfileExists]);

  // Create new profile
  const handleCreateNewProfileClick = (): void => {
    setCreateProfileModal(true);
  };

  const closeCreateProfileModal = (): void => {
    setCreateProfileModal(false);
  };

  const handleFormErrors = (name: string, value: string): void => {
    let errorMessage: string = "";

    if (!value) {
      const errorMessages: { [key: string]: string } = {
        [ProfileInputs.languageId]: "Culture is required",
        [ProfileInputs.countryId]: "Country is required",
      };
      errorMessage =
        errorMessages[name] &&
        getLanguageValue(props.languageText, errorMessages[name]);
    }

    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }));
  };

  const handleDropdownSelect = (selectedItem: IDropdownSelectedItem): void => {
    const name = selectedItem.name as keyof IFocusInput;
    const value = selectedItem.value;

    setProfileDetails({
      ...profileDetails,
      [name]: value,
    });

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  };

  const handleValidationOnSubmit = (): boolean => {
    if (
      !profileDetails.languageId ||
      focusInput.languageId.errorMessage ||
      !profileDetails.countryId ||
      focusInput.countryId.errorMessage
    ) {
      for (const item of Object.values(ProfileInputs)) {
        handleFormErrors(item, profileDetails[item]);
      }
      return false;
    }
    return true;
  };

  const handleSubmitClick = (): void => {
    if (!handleValidationOnSubmit() && !participantProfileExists) return;
    dispatch(setSpinner(true));

    const endTime = Date.now();
    const timeTaken = endTime - startTime;

    const body: ICreateProfileByUserBody = {
      userId: userId,
      idiLanguageId: Number(profileDetails.languageId),
      countryId: Number(profileDetails.countryId),
      idiClientId: currentClient.currentClientId,
      isProfileExists: participantProfileExists ? true : false,
    };

    // Perform the asynchronous submission
    createProfileByParticipant(body, dispatch)
      .then((uniqueCode) => {
        // Push data to Google Tag Manager's dataLayer
        pushToDataLayer({
          event: "formSubmit",
          formType: "profileCreation",
          fields: {
            activityName: `Individual ${userDetails.name}`,
            userId: userDetails.userId,
            client: userDetails.currentClientName,
            timeTaken: timeTaken,
          },
        });

        if (uniqueCode) {
          dispatch(addToast("Profile created successfully") as AnyAction);
          if (!participantProfileExists) {
            dispatch(setParticipantProfileExists(true));
          }
          navigate(
            `${routePath.participantWelcomePage}?${ucQueryKey}=${uniqueCode}`,
          );
        }
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  return (
    <>
      <button
        className="btn btn-success fs-12px mt-15px"
        onClick={handleCreateNewProfileClick}
        disabled={props.readOnly}
      >
        {getLanguageValue(props.languageText, "Create new profile")}
      </button>
      {createProfileModal && (
        <ModalComponent
          headerText={getLanguageValue(
            props.languageText,
            "Create New Profile",
          )}
          cancelButtonText={getLanguageValue(props.languageText, "Cancel")}
          submitButtonText={getLanguageValue(props.languageText, "Create")}
          handleCancelClick={closeCreateProfileModal}
          handleSubmitClick={handleSubmitClick}
        >
          {participantProfileExists ? (
            `${getLanguageValue(props.languageText, "Do you want to create new profile")}?`
          ) : (
            <>
              <DropdownSelect
                name={ProfileInputs.languageId}
                label={getLanguageValue(props.languageText, "Culture")}
                defaultLabel={getLanguageValue(
                  props.languageText,
                  "Select Culture",
                )}
                list={cultures}
                value={profileDetails.languageId}
                focusInput={focusInput.languageId}
                handleDropdownSelect={handleDropdownSelect}
                searchOption
                required
              />
              <DropdownSelect
                name={ProfileInputs.countryId}
                label={getLanguageValue(props.languageText, "Country")}
                defaultLabel={getLanguageValue(
                  props.languageText,
                  "Select Country",
                )}
                list={countries}
                value={profileDetails.countryId}
                focusInput={focusInput.countryId}
                handleDropdownSelect={handleDropdownSelect}
                searchOption
                required
              />
            </>
          )}
        </ModalComponent>
      )}
    </>
  );
};
