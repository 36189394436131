import { AlertTrigger, IAlert } from "./alertList/types";
import { IGuide, ILanguage, Toast } from "./commonInterfaces";
import {
  REMOVE_NOTIFICATION,
  SET_NOTIFICATION,
  SET_SPINNER,
  CLEAR_NOTIFICATIONS,
  SET_CURRENT_USER_LANGUAGES,
  ROLES_SWITCHED,
  PAGE_CONFIGURATION_INFO,
  SET_TRANSLATIONS,
  SET_ALERTS,
  TRIGGER_ALERT,
  UPDATE_ALERT_WITH_ACTION_TAKEN,
  ADD_TOAST,
  REMOVE_TOAST,
  SET_GUIDE_DATA,
  MARK_GUIDE_AS_COMPLETED,
  SHOW_CONFIRM_DIALOG,
  HIDE_CONFIRM_DIALOG,
  SHOW_ACCOUNT_SWITCH_MENU,
  SHOW_RIGHT_MENU,
} from "./constants";
import * as Redux from "redux";

const LANGUAGE_TEXT_SYMBOL = Symbol();
const USER_ID_SYMBOL = Symbol();
const PROFILE_ID_SYMBOL = Symbol();
const ACTIVITY_ID_SYMBOL = Symbol();
const RESPONDENT_ID_SYMBOL = Symbol();

export type ILanguageText = {
  [key: string]: string;
} & { __brand: typeof LANGUAGE_TEXT_SYMBOL };

export type UserId = number & { __brand: typeof USER_ID_SYMBOL };
export type ProfileId = number & { __brand: typeof PROFILE_ID_SYMBOL };
export type ActivityId = number & { __brand: typeof ACTIVITY_ID_SYMBOL };
export type RespondentId = number & { __brand: typeof RESPONDENT_ID_SYMBOL };

export interface IPageConfiguredInfo {
  pathname: string;
  searchText: string;
  sorting: string;
  pageLimit: number; // items_per_page
  pageSelected: number; // page_number_selected
}

export type IConfirmDialog = {
  title: string;
  message: string;
  resolve: () => unknown;
  reject: () => unknown;
};

export interface IInitialState {
  error: string;
  spinner: boolean;
  toasts: Toast[];
  currentUserLanguages: ILanguage[];
  isRoleSwitched: boolean;
  isLanguageChanged: boolean;
  pageConfiguredInfo: IPageConfiguredInfo[]; // To store searchText, sorting, pagination & noOfRows of pages
  languageText: ILanguageText;
  alerts: ReadonlyArray<IAlert>;

  // this could technically be an array, but whatever.
  triggeredAlerts: ReadonlyArray<AlertTrigger>;
  guideData: IGuide[] | undefined; // Initialized as undefined to allow checking the data before triggering the guide
  confirm: IConfirmDialog | undefined;
  openAccountList: boolean;
  openRightMenu: boolean;
}

const initialState: IInitialState = {
  error: "",
  spinner: false,
  toasts: [],
  currentUserLanguages: [],
  isRoleSwitched: false,
  isLanguageChanged: false,
  pageConfiguredInfo: [],
  languageText: {} as ILanguageText,
  alerts: [],
  triggeredAlerts: [],
  guideData: undefined,
  confirm: undefined,
  openAccountList: false,
  openRightMenu: false,
};

export const mainReducer: Redux.Reducer<IInitialState> = (
  state = initialState,
  action: any,
) => {
  switch (action.type) {
    case SET_NOTIFICATION:
      return { ...state, error: action.payload || "" };
    case REMOVE_NOTIFICATION:
      return { ...state, error: action.payload || "" };
    case SET_SPINNER:
      return { ...state, spinner: action.payload || false };
    case ADD_TOAST:
      return { ...state, toasts: [...state.toasts, action.payload] };
    case REMOVE_TOAST:
      return {
        ...state,
        toasts: state.toasts.filter((toast) => toast.id !== action.payload.id),
      };
    case CLEAR_NOTIFICATIONS:
      return { ...state, error: "" };
    case SET_CURRENT_USER_LANGUAGES:
      return { ...state, currentUserLanguages: action.payload || [] };
    case ROLES_SWITCHED:
      return {
        ...state,
        isRoleSwitched: !state.isRoleSwitched,
      };
    case PAGE_CONFIGURATION_INFO: {
      let updatedPageConfiguredInfo = state.pageConfiguredInfo;
      const index = state.pageConfiguredInfo.findIndex(
        (item) => item.pathname === action.payload.pathname,
      );
      if (index !== -1) {
        // Item exists, update it
        updatedPageConfiguredInfo = state.pageConfiguredInfo.map((item) =>
          item.pathname === action.payload.pathname
            ? { ...item, ...action.payload }
            : item,
        );
      } else {
        // Item doesn't exist, add it
        updatedPageConfiguredInfo = [
          ...state.pageConfiguredInfo,
          { ...action.payload },
        ];
      }
      return {
        ...state,
        pageConfiguredInfo: updatedPageConfiguredInfo,
      };
    }
    case SET_TRANSLATIONS: {
      const lowered: { [key: string]: string } = {};

      for (const key in action.payload) {
        lowered[key.toLowerCase()] = action.payload[key];
      }

      return {
        ...state,
        isLanguageChanged: !state.isLanguageChanged,
        languageText: lowered as ILanguageText,
      };
    }
    case SET_ALERTS:
      return {
        ...state,
        alerts: action.payload,
      };
    case TRIGGER_ALERT:
      return {
        ...state,
        triggeredAlerts: [...state.triggeredAlerts, action.payload],
      };
    case UPDATE_ALERT_WITH_ACTION_TAKEN: {
      const trigger: AlertTrigger = action.payload.trigger;

      return {
        ...state,
        triggeredAlerts: state.triggeredAlerts.filter((it) => it !== trigger),
        alerts: state.alerts.map((x) => {
          if (x.trigger === trigger) {
            return {
              ...x,
              actionTaken: action.payload.actionTaken,
            };
          }
          return x;
        }),
      };
    }
    case SET_GUIDE_DATA:
      return {
        ...state,
        guideData: action.payload,
      };
    case MARK_GUIDE_AS_COMPLETED:
      return {
        ...state,
        guideData: (state.guideData ?? []).concat({
          guideName: action.payload,
          isCompleted: true,
        }),
      };
    case SHOW_CONFIRM_DIALOG:
      return {
        ...state,
        confirm: action.payload,
      };
    case HIDE_CONFIRM_DIALOG:
      return {
        ...state,
        confirm: undefined,
      };
    case SHOW_ACCOUNT_SWITCH_MENU:
      //When opening the account list menu, also open the right menu
      //There is a potential risk here since just closing the account list
      //menu will also close the right menu but it seems to work fine - Joakim, 25-01-22
      return {
        ...state,
        openAccountList: action.payload,
        openRightMenu: action.payload === true ? true : state.openRightMenu,
      };
    case SHOW_RIGHT_MENU:
      return {
        ...state,
        openRightMenu: action.payload,
      };

    default:
      return state;
  }
};
