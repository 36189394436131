import React from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { getFormattedDateOnly } from "../../../commonUtils/dateFunctionsHelper";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../commonUtils/languageFunctionsHelper";
import {
  IDropdownList,
  IDropdownSelectedItem,
} from "@app/components/formComponents/dropdownSelect";
import { IFocusError } from "../../../components/formComponents/input";
import { getClientList } from "../../clientList/actions";
import { RenderFlagComponent } from "../../languageList/renderFlagComponent";
import { ValidationHelper } from "../../validationHelper";
import { getUserById } from "../actions";
import { createOrUpdateUser, verifyUser } from "./actions";
import { E164Number } from "libphonenumber-js/types";
import {
  getFacilitatorLanguages,
  getParticipantLanguages,
} from "../../languageList/languageTexts/actions";
import { RoleEnum, getRoleName } from "../../commonEnums";
import { IClient, IUserClientRoles } from "@app/containers/commonInterfaces";
import { addToast, setSpinner } from "../../actions";
import { AnyAction } from "redux";
import { UserId } from "@app/containers/reducer";

export const UserInputs = {
  name: "name",
  surname: "surname",
  emailAddress: "emailAddress",
  password: "password",
  confirmPassword: "confirmPassword",
  languageId: "languageId",
  participantLanguageId: "participantLanguageId",
  startDate: "startDate",
  nonParticipantClients: "nonParticipantClients",
  participantClients: "participantClients",
  phoneNumber: "phoneNumber",
  clientId: "clientId",
  roleIds: "roleIds",
  isActive: "isActive",
  shouldChangePasswordOnNextLogin: "shouldChangePasswordOnNextLogin",
  isTwoFactorEnabled: "isTwoFactorEnabled",
  isLockoutEnabled: "isLockoutEnabled",
  isConsultant: "isConsultant",
  sourceType: "sourceType",
  sourceAddress: "sourceAddress",
  isManager: "isManager",
} as const;

export interface ICreateOrUpdateUserBody {
  user: {
    id: number;
    name: string;
    surname: string;
    emailAddress: string;
    phoneNumber: string;
    password: string;
    isActive: boolean;
    shouldChangePasswordOnNextLogin: boolean;
    isTwoFactorEnabled: boolean;
    isLockoutEnabled: boolean;
    fromDate: string | null;
    languageId: number | null;
    participantLanguageId: number | null;
    isConsultant: boolean;
    sourceType: string;
    sourceAddress: string;
    isManager: boolean;
  };
  sendActivationEmail: boolean;
  userClientRoles: IUserClientRoles[];
}

interface IFocusInput {
  name: IFocusError;
  surname: IFocusError;
  emailAddress: IFocusError;
  password: IFocusError;
  confirmPassword: IFocusError;
  startDate: IFocusError;
  languageId: IFocusError;
  participantLanguageId: IFocusError;
  nonParticipantClients: IFocusError;
  participantClients: IFocusError;
}

export type UserInfoRole = {
  roleId: RoleEnum;
  roleName: string | null;
  clientId: number;
  isDefaultClient: boolean;
};

/** TODO: We have another thing called 'GetUserByIdResult'. Maybe we can reuse that? */
interface IUserInfo {
  name: string;
  surname: string;
  emailAddress: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
  userClients: ReadonlyArray<IClient>;
  languageId: string;
  participantLanguageId: string;
  isActive: boolean;
  startDate: Date | null;
  roles: ReadonlyArray<UserInfoRole>;
  shouldChangePasswordOnNextLogin: boolean;
  isTwoFactorEnabled: boolean;
  isLockoutEnabled: boolean;
  isConsultant: boolean;
  sourceType: string;
  sourceAddress: string;
  isManager: boolean;
}

export interface IAddEditUserModalProps {
  languageText: ITranslationObject;
  editUserId: UserId;

  /** FIXME: what does this 'roleId' mean? who's role is it? */
  roleId: number;
  clientIdFromAccount: number | undefined;
  closeUserModal: (refreshUserList: boolean) => void;
}

/**
 * Removes roles that will be affected by editing operations, depending on the edit mode:
 *   - participant mode:     removes all 'Participant' roles belonging to the client.
 *   - non-participant mode: removes all non-'Participant' roles belonging to the client.
 */
export function removeAffectedRolesForEditMode(
  clientId: number,
  roles: ReadonlyArray<UserInfoRole>,
  editMode: EditRoleMode,
): ReadonlyArray<UserInfoRole> {
  return roles.filter((r) => {
    switch (editMode) {
      case "participant":
        return r.clientId !== clientId || r.roleId !== RoleEnum.Participant;
      case "non_participant":
        return r.clientId !== clientId || r.roleId === RoleEnum.Participant;
    }
  });
}

/**
 * Determines if the specified 'clientId' is the default client for the edit mode.
 */
export function isDefaultClientForEditMode(
  clientId: number,
  roles: ReadonlyArray<UserInfoRole>,
  editMode: EditRoleMode,
): boolean {
  return roles.some((r) => {
    if (r.clientId !== clientId || !r.isDefaultClient) {
      return false;
    }
    switch (editMode) {
      case "participant":
        return r.roleId === RoleEnum.Participant;
      case "non_participant":
        return r.roleId !== RoleEnum.Participant;
    }
  });
}

/** Controls if we're editing participant roles or not. */
export type EditRoleMode = "non_participant" | "participant";

export const useAddEditUserModal = (props: IAddEditUserModalProps) => {
  const dispatch = useDispatch();
  const isParticipant = props.roleId === RoleEnum.Participant;

  const initialFocusInputState: IFocusError = {
    touched: false,
    errorMessage: "",
  };
  const [userInfo, setUserInfo] = useState<IUserInfo>({
    name: "",
    surname: "",
    emailAddress: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    userClients: [],
    languageId: "",
    participantLanguageId: "",
    isActive: true,
    startDate: null,
    roles: [],
    shouldChangePasswordOnNextLogin: false,
    isTwoFactorEnabled: false,
    isLockoutEnabled: false,
    isConsultant: false,
    sourceType: "",
    sourceAddress: "",
    isManager: false,
  });
  const [facilitatorLanguages, setFacilitatorLanguages] = useState<
    IDropdownList[]
  >([]); // List of facilitator lanuages
  const [participantLanguages, setParticipantLanguages] = useState<
    IDropdownList[]
  >([]); // List of participant lanuages
  const [clients, setClients] = useState<IClient[]>([]); // List of all clients
  const [phoneNumberError, setPhoneNumberError] = useState<boolean>(false);
  const [focusInput, setFocusInput] = useState<IFocusInput>({
    name: initialFocusInputState,
    surname: initialFocusInputState,
    emailAddress: initialFocusInputState,
    password: initialFocusInputState,
    confirmPassword: initialFocusInputState,
    startDate: initialFocusInputState,
    languageId: initialFocusInputState,
    participantLanguageId: initialFocusInputState,
    nonParticipantClients: initialFocusInputState,
    participantClients: initialFocusInputState,
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [activationEmailModal, setActivationEmailModal] =
    useState<boolean>(false);
  const [editEmailAddress, setEditEmailAddress] = useState<string>(""); // Required to check whether email is changed when editing user
  const [editMode, setEditMode] = useState<EditRoleMode | undefined>(undefined);
  const [showClientRoleModal, setShowClientRoleModal] =
    useState<boolean>(false);
  const [editClientId, setEditClientId] = useState<number | undefined>(
    undefined,
  );
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
  const [isEmailCheckBtnDisabled, setIsEmailCheckBtnDisabled] =
    useState<boolean>(true);
  const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false);
  const [isValidText, setIsValidText] = useState("");
  const [isParticipantLanguage, setIsParticipantLanguage] =
    useState<boolean>(false);

  /** TODO: what does this mean? common language where? */
  const [isCommonLanguage, setIsCommonLanguage] = useState<boolean>(false);

  const getUserData = (
    facilitatorLanguage: IDropdownList[],
    participantLanguage: IDropdownList[],
  ): void => {
    getUserById(props.editUserId, dispatch)
      .then((response) => {
        if (response) {
          // Language
          const updatedLanguage = facilitatorLanguage.find(
            (language) => language.value === String(response.languageId),
          );
          // participant Language
          const participantUpdatedLanguage = participantLanguage.find(
            (language) =>
              language.value === String(response.participantLanguageId),
          );

          if (response.isEmailConfirmed) setIsEmailVerified(true);

          setUserInfo({
            name: response.name,
            surname: response.surname,
            emailAddress: response.emailAddress,
            phoneNumber: response.phoneNumber ?? "",
            password: "",
            confirmPassword: "",
            languageId: updatedLanguage?.value || "",
            participantLanguageId: participantUpdatedLanguage?.value || "",
            isActive: response.isActive ?? true,
            userClients: response.userClients,
            startDate: response.fromDate ? new Date(response.fromDate) : null,
            shouldChangePasswordOnNextLogin:
              response.shouldChangePasswordOnNextLogin ?? false,
            isTwoFactorEnabled: response.isTwoFactorEnabled ?? false,
            isLockoutEnabled: response.isLockoutEnabled ?? true,
            roles: response.roles,
            isConsultant: response.isConsultant ?? false,
            sourceType: response.sourceType,
            sourceAddress: response.sourceAddress,
            isManager: response.isManager,
          });
          setEditEmailAddress(response.emailAddress);
        }
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  useEffect(() => {
    const fetchDetails = async () => {
      dispatch(setSpinner(true));
      const facilitatorResponse = await getFacilitatorLanguages(dispatch);
      const participantResponse =
        props.roleId !== RoleEnum.Admin || props.clientIdFromAccount
          ? await getParticipantLanguages(dispatch)
          : null;

      /** FIXME: we should not need to load the entire database into memory here. */
      /**   yes, we really shouldn't. -johan, 2024-08-30 */
      const clientsFromBackend = await getClientList(
        "",
        "",
        1_000_000,
        0,
        dispatch,
      );

      let facilitatorLanguage: IDropdownList[] = [];
      let participantLanguage: IDropdownList[] = [];

      if (isParticipant || props.clientIdFromAccount) {
        setIsParticipantLanguage(true);
      } else {
        setIsCommonLanguage(true);
      }

      if (facilitatorResponse && facilitatorResponse?.length > 0) {
        facilitatorLanguage = facilitatorResponse.map((item) => ({
          id: item.id,
          displayName: (
            <RenderFlagComponent
              flagName={item.icon}
              value={item.displayName}
            />
          ),
          value: String(item.id),
        }));
      }
      if (props.roleId !== RoleEnum.Admin || props.clientIdFromAccount) {
        if (participantResponse && participantResponse?.length > 0) {
          participantLanguage = participantResponse.map((item) => ({
            id: item.id,
            displayName: (
              <RenderFlagComponent
                flagName={item.icon}
                value={item.displayName}
              />
            ),
            value: String(item.id),
          }));
        }
      }

      setFacilitatorLanguages(facilitatorLanguage);
      setParticipantLanguages(participantLanguage);

      const sortedClients = clientsFromBackend.items.slice();
      sortedClients.sort((itemA, itemB) => {
        return itemA.name?.localeCompare(itemB.name);
      });

      setClients(sortedClients);

      // what does it mean for 'editUserId' to be falsy here?
      // are we using '0' as the falsy value again?
      if (props.clientIdFromAccount && !props.editUserId) {
        setUserInfo({
          ...userInfo,
          roles: userInfo.roles.concat({
            clientId: props.clientIdFromAccount,
            roleId: RoleEnum.Facilitator,
            roleName: getRoleName(RoleEnum.Facilitator),
            isDefaultClient: true,
          }),
        });
      }
      if (props.editUserId) {
        getUserData(facilitatorLanguage, participantLanguage);
        setIsValidEmail(true);
      } else dispatch(setSpinner(false));
    };

    fetchDetails();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name as keyof IFocusInput;
    const value = e.target.value;

    setUserInfo({
      ...userInfo,
      [name]: value,
    });

    if (name === UserInputs.emailAddress) {
      setIsValidEmail(false);
      if (value && ValidationHelper.isEmailValid(value)) {
        if (isEmailCheckBtnDisabled) setIsEmailCheckBtnDisabled(false);
      } else {
        if (!isEmailCheckBtnDisabled) setIsEmailCheckBtnDisabled(true);
      }
    }

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  };

  useEffect(() => {
    if (props.roleId !== RoleEnum.Admin) {
      const isParticipant = userInfo.roles.some(
        (r) => r.roleId === RoleEnum.Participant,
      );
      const isFacilitator = userInfo.roles.some(
        (r) => r.roleId !== RoleEnum.Participant,
      );

      setIsCommonLanguage(isFacilitator);
      setIsParticipantLanguage(isParticipant);
    }
  }, [userInfo.roles]);

  const handlePhoneInputChange = (value: string): void => {
    setUserInfo({
      ...userInfo,
      phoneNumber: value,
    });
  };

  const validatePhonenumber = (value: E164Number): void => {
    const phoneNumber = parseInt(value);

    if (phoneNumber === 0) {
      setPhoneNumberError(false);
    } else if (value.length >= 1 && value.length <= 4) {
      setPhoneNumberError(true);
    } else {
      setPhoneNumberError(false);
    }
  };

  const handleDropdownSelect = (selectedItem: IDropdownSelectedItem): void => {
    const name = selectedItem.name as keyof IFocusInput;
    const value = selectedItem.value;

    setUserInfo({
      ...userInfo,
      [name]: value,
    });

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  };

  const handleAddClientClick = (mode: EditRoleMode): void => {
    setShowClientRoleModal(true);
    setEditMode(mode);
    setEditClientId(undefined);
  };

  const closeUserClientModal = (): void => {
    setShowClientRoleModal(false);
    setEditMode(undefined);
  };

  const handleEditClient = (clientId: number, mode: EditRoleMode): void => {
    setShowClientRoleModal(true);
    setEditMode(mode);
    setEditClientId(clientId);
  };

  const handleDeleteClient = (
    clientId: number,
    editMode: EditRoleMode,
  ): void => {
    const updatedRoles = removeAffectedRolesForEditMode(
      clientId,
      userInfo.roles,
      editMode,
    );
    const hasNonParticipantRoles = updatedRoles.some(
      (r) => r.roleId !== RoleEnum.Participant,
    );
    const hasParticipantRoles = updatedRoles.some(
      (r) => r.roleId === RoleEnum.Participant,
    );

    let updatedLanguageId = userInfo.languageId;
    let updatedParticipantLanguageId = userInfo.participantLanguageId;

    if (isParticipant && !hasNonParticipantRoles && updatedLanguageId) {
      updatedLanguageId = "";
    }
    if (
      !isParticipant &&
      !hasParticipantRoles &&
      updatedParticipantLanguageId
    ) {
      updatedParticipantLanguageId = "";
    }

    setUserInfo((prevState) => {
      return {
        ...prevState,
        languageId: updatedLanguageId,
        participantLanguageId: updatedParticipantLanguageId,
        roles: updatedRoles,
      };
    });
  };

  const handleEditUserRoles = (
    clientId: number,
    roleIds: Array<RoleEnum>,
    isDefaultClient: boolean,
    editRoleMode: EditRoleMode,
  ): void => {
    const nextRoles = removeAffectedRolesForEditMode(
      clientId,
      userInfo.roles,
      editMode!,
    ).map((r) => {
      return {
        ...r,

        // if we're setting a default client we must remove
        // the default flag from all other clients.
        isDefaultClient:
          (editRoleMode === "non_participant" &&
            r.roleId !== RoleEnum.Participant) ||
          (editRoleMode === "participant" && r.roleId === RoleEnum.Participant)
            ? isDefaultClient
              ? false
              : r.isDefaultClient
            : r.isDefaultClient,
      };
    });

    for (const roleId of roleIds) {
      nextRoles.push({
        roleId: roleId,
        roleName: getRoleName(roleId),
        clientId: clientId,
        isDefaultClient: isDefaultClient,
      });
    }

    setUserInfo({
      ...userInfo,
      roles: nextRoles,
    });

    closeUserClientModal();
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const name = e.target.name;
    if (e.target.name === UserInputs.isActive) {
      setUserInfo({
        ...userInfo,
        isActive: e.target.checked,
        startDate: null,
      });
    } else {
      setUserInfo({
        ...userInfo,
        [name]: e.target.checked,
      });
    }
  };

  const handleDateSelect = (name: string, date: Date) => {
    setUserInfo({
      ...userInfo,
      [name]: date,
    });

    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: "",
      },
    }));
  };

  const handleFormErrors = (name: string, value: unknown): void => {
    let errorMessage: string = "";

    const hasNonParticipantRoles = userInfo.roles.some(
      (r) => r.roleId !== RoleEnum.Participant,
    );
    const hasParticipantRoles = userInfo.roles.some(
      (r) => r.roleId === RoleEnum.Participant,
    );

    switch (name) {
      case UserInputs.name:
        if (!value) {
          errorMessage = getLanguageValue(
            props.languageText,
            "Name is required",
          );
        }
        break;
      case UserInputs.surname:
        if (!value) {
          errorMessage = getLanguageValue(
            props.languageText,
            "Surname is required",
          );
        }
        break;
      case UserInputs.emailAddress:
        if (!value) {
          errorMessage = getLanguageValue(
            props.languageText,
            "Email is required",
          );
          if (!isEmailCheckBtnDisabled) setIsEmailCheckBtnDisabled(true);
        } else if (!ValidationHelper.isEmailValid(value as string)) {
          errorMessage = getLanguageValue(props.languageText, "Invalid email");
          if (!isEmailCheckBtnDisabled) setIsEmailCheckBtnDisabled(true);
        } else {
          if (isEmailCheckBtnDisabled) setIsEmailCheckBtnDisabled(false);
        }
        break;
      case UserInputs.password:
        if (!value && !props.editUserId) {
          errorMessage = getLanguageValue(
            props.languageText,
            "Password is required",
          );
        } else if (value) {
          const isValid = ValidationHelper.isPasswordValid(value as string);
          if (!isValid)
            errorMessage = getLanguageValue(
              props.languageText,
              "Invalid password",
            );
        }
        break;
      case UserInputs.confirmPassword:
        if (!value && !props.editUserId) {
          errorMessage = getLanguageValue(
            props.languageText,
            "Confirm password is required",
          );
        } else if (value !== userInfo.password) {
          errorMessage = getLanguageValue(
            props.languageText,
            "Confirm Password does not match",
          );
        }
        break;
      case UserInputs.startDate:
        if (!value) {
          errorMessage = getLanguageValue(
            props.languageText,
            "Active from is required",
          );
        }
        break;
      case UserInputs.languageId:
        if (
          !value &&
          ((isCommonLanguage && !props.clientIdFromAccount) ||
            (props.clientIdFromAccount && hasNonParticipantRoles))
        ) {
          errorMessage = getLanguageValue(
            props.languageText,
            "Language is required",
          );
        }
        break;
      case UserInputs.participantLanguageId:
        if (
          !value &&
          ((isParticipantLanguage && !props.clientIdFromAccount) ||
            (props.clientIdFromAccount && hasParticipantRoles))
        ) {
          errorMessage = getLanguageValue(
            props.languageText,
            "Participant Language is required",
          );
        }
        break;
      case UserInputs.nonParticipantClients:
        if (!value) {
          errorMessage = getLanguageValue(
            props.languageText,
            "Default client is required",
          );
        }
        break;
      case UserInputs.participantClients:
        if (!value) {
          errorMessage = getLanguageValue(
            props.languageText,
            "Participant default client is required",
          );
        }
        break;
      default:
        break;
    }

    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }));
  };

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    const name = e.target.name;
    const value = e.target.value;
    handleFormErrors(name, value);
  };

  const handleValidationOnSubmit = (): boolean => {
    const hasNonParticipantRoles = userInfo.roles.some(
      (r) => r.roleId !== RoleEnum.Participant,
    );
    const hasParticipantRoles = userInfo.roles.some(
      (r) => r.roleId === RoleEnum.Participant,
    );

    let isError = false;
    if (
      !userInfo.name ||
      focusInput.name.errorMessage ||
      !userInfo.surname ||
      focusInput.surname.errorMessage ||
      !userInfo.emailAddress ||
      focusInput.emailAddress.errorMessage ||
      !userInfo.startDate ||
      focusInput.startDate.errorMessage ||
      (!props.editUserId &&
        (!userInfo.password ||
          focusInput.password.errorMessage ||
          !userInfo.confirmPassword ||
          focusInput.confirmPassword.errorMessage)) ||
      (props.editUserId &&
        ((userInfo.password && focusInput.password.errorMessage) ||
          (userInfo.confirmPassword &&
            focusInput.confirmPassword.errorMessage) ||
          (userInfo.password && !userInfo.confirmPassword))) ||
      (!userInfo.languageId &&
        ((isCommonLanguage && !props.clientIdFromAccount) ||
          (props.clientIdFromAccount && hasNonParticipantRoles))) ||
      (!userInfo.participantLanguageId &&
        ((isParticipantLanguage && !props.clientIdFromAccount) ||
          (props.clientIdFromAccount && hasParticipantRoles)))
    ) {
      for (const item of Object.values(UserInputs)) {
        if (item === UserInputs.nonParticipantClients) continue;
        if (item === UserInputs.participantClients) continue;
        if (item === UserInputs.clientId) continue;
        if (item === UserInputs.roleIds) continue;
        handleFormErrors(item, userInfo[item]);
      }
      isError = true;
    }
    // Clients validation
    if (props.roleId !== RoleEnum.Admin) {
      if (!isParticipant && !hasNonParticipantRoles) {
        handleFormErrors(UserInputs.nonParticipantClients, 0);
        isError = true;
      }
      if (isParticipant && !hasParticipantRoles) {
        handleFormErrors(UserInputs.participantClients, 0);
        isError = true;
      }
    }

    if (phoneNumberError) {
      isError = true;
    }
    return isError;
  };

  const verifyEmail = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const isValid = ValidationHelper.isEmailValid(userInfo.emailAddress);
    if (isValid) {
      dispatch(setSpinner(true));
      verifyUser(userInfo.emailAddress, dispatch)
        .then((res) => {
          if (
            props.roleId === RoleEnum.Facilitator &&
            !res.isFacilitator &&
            !res.isAdmin
          ) {
            if (!res.isParticipant) {
              setIsValidEmail(true);
              setIsValidText("");
            }
            if (res.isParticipant) {
              setIsValidText(
                `${getLanguageValue(
                  props.languageText,
                  "Email already exist in the Participant List. Please navigate to participants page to add facilitator role to the same user",
                )}.`,
              );
            }
          } else if (isParticipant && !res.isParticipant && !res.isAdmin) {
            if (!res.isFacilitator) {
              setIsValidEmail(true);
              setIsValidText("");
            }
            if (res.isFacilitator) {
              setIsValidText(
                `${getLanguageValue(
                  props.languageText,
                  "Email already exist as facilitator. Please navigate to facilitators to add participant role to the same user",
                )}.`,
              );
            }
          } else if (
            props.roleId === RoleEnum.Admin &&
            !res.isParticipant &&
            !res.isFacilitator &&
            !res.isAdmin
          ) {
            setIsValidText("");
            setIsValidEmail(true);
          } else {
            setIsValidText(
              `${getLanguageValue(props.languageText, "Email already exist in the list or as admin")}.`,
            );
          }
        })
        .finally(() => dispatch(setSpinner(false)));
    }
  };

  const handleSubmit = (activationEmail: boolean): void => {
    setIsSubmitting(true);
    dispatch(setSpinner(true));

    let userClientRoles: Array<IUserClientRoles>;

    if (props.roleId === RoleEnum.Admin && !props.clientIdFromAccount) {
      userClientRoles = [
        {
          assignedRoleName: getRoleName(Number(props.roleId)),
          clientId: null,
          isDefaultClient: false,
          userRoleId: RoleEnum.Admin,
        },
      ];
    } else {
      userClientRoles = userInfo.roles.map((r) => {
        return {
          assignedRoleName: getRoleName(r.roleId),
          clientId: r.clientId,
          isDefaultClient: r.isDefaultClient,
          userRoleId: r.roleId,
        };
      });
    }

    const body: ICreateOrUpdateUserBody = {
      user: {
        id: props.editUserId ?? 0,
        name: userInfo.name,
        surname: userInfo.surname,
        emailAddress: userInfo.emailAddress,
        phoneNumber: userInfo.phoneNumber,
        password: userInfo.password,
        languageId: userInfo.languageId ? Number(userInfo.languageId) : null,
        participantLanguageId: userInfo.participantLanguageId
          ? Number(userInfo.participantLanguageId)
          : null,
        isActive: userInfo.isActive,
        fromDate: userInfo.startDate
          ? getFormattedDateOnly(userInfo.startDate)
          : "",
        shouldChangePasswordOnNextLogin:
          userInfo.shouldChangePasswordOnNextLogin,
        isTwoFactorEnabled: userInfo.isTwoFactorEnabled,
        isLockoutEnabled: userInfo.isLockoutEnabled,
        isConsultant: userInfo.isConsultant,
        sourceType: userInfo.sourceType,
        sourceAddress: userInfo.sourceAddress,
        isManager: userInfo.isManager,
      },
      sendActivationEmail: activationEmail,
      userClientRoles: userClientRoles,
    };

    createOrUpdateUser(body, dispatch)
      .then((response) => {
        dispatch(addToast("Changes saved successfully") as AnyAction);
        closeAddEditUserModal(true);
      })
      .finally(() => {
        setIsSubmitting(false);
        dispatch(setSpinner(false));
        if (activationEmailModal) setActivationEmailModal(false);
      });
  };

  const onSubmitClick = (): void => {
    if (handleValidationOnSubmit()) return;
    if (props.editUserId && editEmailAddress !== userInfo.emailAddress) {
      setActivationEmailModal(true);
    } else if (props.editUserId) {
      handleSubmit(false);
    } else {
      handleSubmit(true);
    }
  };

  const onActivationEmailModalSubmit = () => handleSubmit(true);

  const onActivationEmailModalCancel = () => handleSubmit(false);

  const onActivationEmailModalClose = () => setActivationEmailModal(false);

  const closeAddEditUserModal = (value: boolean) => {
    props.closeUserModal(value);
  };

  const closeModal = () => closeAddEditUserModal(false);

  const closeAlertModal = () => setIsValidText("");

  return {
    isParticipant,
    isValidText,
    isValidEmail,
    isEmailCheckBtnDisabled,
    userInfo,
    focusInput,
    clients,
    isCommonLanguage,
    isParticipantLanguage,
    facilitatorLanguages,
    participantLanguages,
    isSubmitting,
    activationEmailModal,
    phoneNumberError,
    editMode,
    showClientRoleModal,
    editClientId,
    isEmailVerified,
    verifyEmail,
    validatePhonenumber,
    closeAlertModal,
    onActivationEmailModalCancel,
    onActivationEmailModalClose,
    onActivationEmailModalSubmit,
    onSubmitClick,
    handleDateSelect,
    handleCheckboxChange,
    handleDropdownSelect,
    handleAddClientClick,
    handleEditClient,
    handleDeleteClient,
    closeUserClientModal,
    handleEditUserRoles,
    handleBlurEvent,
    handleInputChange,
    handlePhoneInputChange,
    closeModal,
  };
};
