import { ModalComponent } from "@app/components/modals/modalComponent";
import * as React from "react";
import { ModelType } from "../commonInterfaces";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@app/store/configureStore";
import { getLanguageValue } from "@app/commonUtils/languageFunctionsHelper";
import { DynamicSearchInputField } from "@app/components/formComponents/dynamicSearchInputField";
import { IDropdownList } from "@app/components/formComponents/dropdownSelect";
import {
  ITinyClient,
  ITinyUser,
  getParticipantUserNames,
} from "../coursesList/actions";
import { rawAxiosApi } from "@app/api";
import { Dispatch } from "redux";
import { ApiResponse } from "@app/types";
import { setSpinner } from "../actions";
import { endPoints, useApiEndpoints } from "@app/api/end-points";

type Props = {
  modelType: ModelType;
  modelId: number;
  headerText: string;
  handleClose: (event: React.MouseEvent<HTMLElement>) => void;
};

type IModelPermission = {
  id?: number;
  modelType: ModelType;
  modelId: number;
  userId?: number;
  userName?: string;
  clientId?: number;
  clientName?: string;
};

function getModelPermissions(
  modelType: ModelType,
  modelId: number,
  dispatch: Dispatch,
): Promise<Array<IModelPermission>> {
  dispatch(setSpinner(true));

  return rawAxiosApi<ApiResponse<Array<IModelPermission>>>({
    method: "GET",
    url: endPoints.getModelPermissions(modelType, modelId),
  })
    .then((res) => {
      if (!res.data.success) {
        return Promise.reject(res.data.error);
      }
      return res.data.result;
    })
    .finally(() => {
      dispatch(setSpinner(false));
    });
}

type IUpdateModelPermissionBody = {
  modelType: ModelType;
  modelId: number;
  items: ReadonlyArray<IModelPermission>;
};

function updateModelPermissions(
  body: IUpdateModelPermissionBody,
  dispatch: Dispatch,
): Promise<unknown> {
  dispatch(setSpinner(true));

  return rawAxiosApi({
    method: "PUT",
    url: endPoints.updateModelPermissions,
    data: body,
  }).finally(() => {
    dispatch(setSpinner(false));
  });
}

export const ModelPermissionModal: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const api = useApiEndpoints(dispatch);
  const lang = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );
  const [permissions, setPermissions] = React.useState<Array<IModelPermission>>(
    [],
  );
  const [users, setUsers] = React.useState<Array<ITinyUser>>([]);
  const [clients, setClients] = React.useState<Array<ITinyClient>>([]);

  React.useEffect(() => {
    getModelPermissions(props.modelType, props.modelId, dispatch).then(
      setPermissions,
    );
  }, []);

  const handleSubmitPermissions = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const body: IUpdateModelPermissionBody = {
      modelType: props.modelType,
      modelId: props.modelId,
      items: permissions,
    };

    updateModelPermissions(body, dispatch).then((res) => {
      props.handleClose(event);
    });
  };

  const handleAddPermission = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setPermissions(
      permissions.concat({
        modelType: props.modelType,
        modelId: props.modelId,
      }),
    );
  };

  const handleDeletePermission = (
    event: React.MouseEvent<HTMLElement>,
    permission: IModelPermission,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    const index = permissions.indexOf(permission);
    if (index !== -1) {
      const next = permissions.slice();
      next.splice(index, 1);
      setPermissions(next);
    }
  };

  const handleSearchUser = (value: string): Promise<void> => {
    return getParticipantUserNames(value, dispatch).then(setUsers);
  };

  const handleSearchClient = (value: string): Promise<void> => {
    return api.getClientNames(value).then(setClients);
  };

  const userList: Array<IDropdownList> = users.map((u) => {
    return {
      id: u.id,
      displayName: u.name,
      value: u.name,
    };
  });

  const clientList: Array<IDropdownList> = clients.map((u) => {
    return {
      id: u.id,
      displayName: u.name,
      value: u.name,
    };
  });

  return (
    <ModalComponent
      width="lg"
      headerText={props.headerText}
      cancelButtonText={getLanguageValue(lang, "Cancel")}
      submitButtonText={getLanguageValue(lang, "Save")}
      handleCancelClick={props.handleClose}
      handleSubmitClick={handleSubmitPermissions}
    >
      <table className="table align-middle">
        <thead>
          <tr>
            <th className="col-6">{getLanguageValue(lang, "User")}</th>
            <th className="col-6">{getLanguageValue(lang, "Client")}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {permissions.map((p, index) => {
            return (
              <tr key={index}>
                <td>
                  <DynamicSearchInputField
                    languageText={lang}
                    placeholder={getLanguageValue(lang, "Search")}
                    list={userList}
                    selectedValue={{
                      id: p.userId || 0,
                      displayName: p.userName || "",
                      value: p.userName || "",
                    }}
                    fetchList={handleSearchUser}
                    handleSelect={(item) => {
                      const next = permissions.slice();
                      next[index] = {
                        ...p,
                        userId: item.id,
                        userName: item.value,
                      };
                      setPermissions(next);
                    }}
                  />
                </td>
                <td>
                  <DynamicSearchInputField
                    languageText={lang}
                    placeholder={getLanguageValue(lang, "Search")}
                    list={clientList}
                    selectedValue={{
                      id: p.clientId || 0,
                      displayName: p.clientName || "",
                      value: p.clientName || "",
                    }}
                    fetchList={handleSearchClient}
                    handleSelect={(item) => {
                      const next = permissions.slice();
                      next[index] = {
                        ...p,
                        clientId: item.id,
                        clientName: item.value,
                      };
                      setPermissions(next);
                    }}
                  />
                </td>
                <td className="text-danger fs-3">
                  <i
                    onClick={(event) => {
                      handleDeletePermission(event, p);
                    }}
                    className="bi bi-trash3"
                    role="button"
                    title={getLanguageValue(lang, "Delete")}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="d-grid">
        <button className="btn btn-light" onClick={handleAddPermission}>
          {getLanguageValue(lang, "Add permission")}
        </button>
      </div>
    </ModalComponent>
  );
};
