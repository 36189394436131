import React from "react";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../commonUtils/languageFunctionsHelper";
import {
  DropdownMenu,
  DropdownMenuItem,
} from "@app/components/formComponents/dropdownMenu";

interface ICoursesActionsCellProps {
  id: number;
  name: string;
  isEnabled: boolean;
  languageText: ITranslationObject;
  handleEditClick: (id: number) => void;
  handleDeleteClick: (id: number, name: string) => void;
  handleChangeActiveStatusClick: (id: number, isEnabled: boolean) => void;
  handleCoursePermission: (id: number, name: string) => void;
}

export const CoursesActionsCell: React.FC<ICoursesActionsCellProps> = (
  props,
) => {
  const onEditClick = (): void => {
    props.handleEditClick(props.id);
  };

  const onDeleteClick = (): void => {
    props.handleDeleteClick(props.id, props.name);
  };

  const onChangeActiveStatus = (): void => {
    props.handleChangeActiveStatusClick(props.id, props.isEnabled);
  };

  const showCoursePermission = () => {
    props.handleCoursePermission(props.id, props.name);
  };

  const dropdownItems: Array<DropdownMenuItem> = [
    {
      name: getLanguageValue(props.languageText, "Edit"),
      onClick: onEditClick,
    },
    {
      name: getLanguageValue(props.languageText, "Delete"),
      onClick: onDeleteClick,
    },
    {
      name: props.isEnabled
        ? getLanguageValue(props.languageText, "Disable course")
        : getLanguageValue(props.languageText, "Enable course"),
      onClick: onChangeActiveStatus,
    },
    {
      name: getLanguageValue(props.languageText, "Permission"),
      onClick: showCoursePermission,
    },
  ];

  return <DropdownMenu items={dropdownItems} />;
};
