import React, { useEffect, useMemo, useState } from "react";

import { RoleSettings } from "./roleSettings/roleSettings";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../commonUtils/languageFunctionsHelper";
import {
  ToggleButtonGroup,
  IButtonGroupList,
} from "../../../components/formComponents/toggleButtonGroup";
import {
  IndividualGroupBtnsEnum,
  ParticipantChangeRole,
  ProfileRoleStatus,
  RoleBtnsEnum,
} from "../activityEnums";

export interface IRoleStepData {
  roleSetBy: number;
  roleSameForAll: boolean;
  roleId: number;
  roleText: string;
  noOfRespondents: number;
  roleCanBeChangedByParticipant: boolean;
}

interface IActivityRoleStepProps {
  languageText: ITranslationObject;
  roleFromEditProfile?: boolean;
  selectedIndividualGroupBtn: string;
  typeOfRoleButtonList: IButtonGroupList[];
  selectedSetRoleBtn: string;
  selectedSameRoleBtn: string;
  selectedTypeOfRoleBtn: string;
  typeOfRoleInput: string;
  customTypeOfRole: string;
  roleInputSelected: boolean;
  selectedNoOfRespondentsBtn: string;
  noOfRespondentsInput: string;
  customNoOfRespondents: string;
  respondentsInputSelected: boolean;
  respondentsConfirmModal: boolean;
  selectedParticipantChangeBtn: string;
  roleStepError: string;
  showRoleSaveBtn?: boolean;
  showEditModeSelectedParticipantBtnClick?: boolean;
  showEditModeSelectedSameRoleBtn?: boolean;
  showEditModeNoOfRespondent?: boolean;
  showEditModeRoleButtonList?: boolean;
  showEditModeRoleType?: boolean;
  commonText?: boolean;
  setShowEditModeNoOfRespondent?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowEditModeRoleType?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowEditModeRoleButtonList?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowEditModeSelectedSameRoleBtn?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setshowEditModeSelectedParticipantBtnClick?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  onSetRoleBtnClick: (name: string) => void;
  onSameRoleBtnClick: (name: string) => void;
  onTypeOfRoleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onTypeOfRoleBtnClick: (name: string) => void;
  handleTypeOfRoleFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  onNoOfRespondentsChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onNoOfRespondentsClick: (name: string) => void;
  handleNoOfRespondentsFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  cancelRespondentsConfirmModal: () => void;
  SubmitRespondentsConfirmModal: () => void;
  handleParticipantChangeRoleClick: (name: string) => void;
  onSaveClick?: (data: any) => void;
}

export const ActivityRoleStep = (
  props: IActivityRoleStepProps,
): JSX.Element => {
  const setRoleButtonList = useMemo(
    () =>
      [
        {
          id: 1,
          name: ProfileRoleStatus.RoleSetByFacilitator,
          title: getLanguageValue(
            props.languageText,
            "Role is set by facilitator",
          ),
        },
        {
          id: 2,
          name: ProfileRoleStatus.RoleSetByParticipant,
          title: getLanguageValue(
            props.languageText,
            "Role is set by participant",
          ),
        },
        // {
        //   id: 3,
        //   name: ProfileRoleStatus.DisableRole,
        //   title: getLanguageValue(languageText, 'Disable role'),
        // },
      ] as IButtonGroupList[],
    [props.languageText],
  );
  const sameRoleButtonList = useMemo(
    () =>
      [
        {
          id: 1,
          name: RoleBtnsEnum.sameRole,
          title: getLanguageValue(
            props.languageText,
            "Same role setting for everyone",
          ),
        },
        {
          id: 2,
          name: RoleBtnsEnum.individualRole,
          title: getLanguageValue(
            props.languageText,
            "Individual role setting",
          ),
        },
      ] as IButtonGroupList[],
    [props.languageText],
  );
  const participantRoleButtonList = useMemo(
    () =>
      [
        {
          id: 1,
          name: ParticipantChangeRole.canChange,
          title: getLanguageValue(props.languageText, "Yes"),
        },
        {
          id: 2,
          name: ParticipantChangeRole.cannotChange,
          title: getLanguageValue(props.languageText, "No"),
        },
      ] as IButtonGroupList[],
    [props.languageText],
  );
  const [rolesTitle, setRolesTitle] = useState<string>("");
  const [changeRoleTitle, setChangeRoleTitle] = useState<string>("");
  const [sameRoleTitle, setSameRoleTitle] = useState<string>("");

  useEffect(() => {
    if (Number(props.selectedSetRoleBtn) > 0) {
      const filteredArray = setRoleButtonList.filter((value) =>
        props.selectedSetRoleBtn.includes(value.name),
      );
      setRolesTitle(filteredArray[0].title);
    } else {
      setRolesTitle("");
    }
    if (props.selectedSameRoleBtn !== "") {
      const filteredArray = sameRoleButtonList.filter((value) =>
        props.selectedSameRoleBtn.includes(value.name),
      );
      setSameRoleTitle(filteredArray[0].title);
    } else {
      setSameRoleTitle("");
    }
    if (props.selectedParticipantChangeBtn !== "") {
      const filteredArray = participantRoleButtonList.filter((value) =>
        props.selectedParticipantChangeBtn.includes(value.name),
      );
      setChangeRoleTitle(filteredArray[0].title);
    } else {
      setChangeRoleTitle("");
    }
  }, [
    rolesTitle,
    props.selectedSetRoleBtn,
    props.selectedSameRoleBtn,
    sameRoleTitle,
    changeRoleTitle,
    props.selectedParticipantChangeBtn,
    props.languageText,
  ]);

  const onSelectRoleEditBtnClick = () => {
    if (props.setShowEditModeRoleButtonList)
      props.setShowEditModeRoleButtonList(true);
    if (props.setShowEditModeSelectedSameRoleBtn)
      props.setShowEditModeSelectedSameRoleBtn(true);
    if (props.setShowEditModeNoOfRespondent)
      props.setShowEditModeNoOfRespondent(true);
    if (props.setShowEditModeRoleType) props.setShowEditModeRoleType(true);
    if (props.setshowEditModeSelectedParticipantBtnClick)
      props.setshowEditModeSelectedParticipantBtnClick(true);
  };

  const onSelectRoleSameEditBtnClick = () => {
    if (props.setShowEditModeSelectedSameRoleBtn)
      props.setShowEditModeSelectedSameRoleBtn(true);
    if (props.setShowEditModeNoOfRespondent)
      props.setShowEditModeNoOfRespondent(true);
    if (props.setShowEditModeRoleType) props.setShowEditModeRoleType(true);
    if (props.setshowEditModeSelectedParticipantBtnClick)
      props.setshowEditModeSelectedParticipantBtnClick(true);
  };

  const onSelectedParticipantBtnClick = () => {
    if (props.setshowEditModeSelectedParticipantBtnClick)
      props.setshowEditModeSelectedParticipantBtnClick(true);
  };

  return (
    <>
      <div id="activityRoleStep">
        {!props.roleFromEditProfile && (
          <div className="fs-5 fw-bold mb-3">
            {getLanguageValue(props.languageText, "Role")}
          </div>
        )}

        {/* SET ROLE BUTTONS */}
        <div className="mb-4">
          <div className="form-label">
            {getLanguageValue(props.languageText, "Role is set by")}
          </div>
          {props.showEditModeRoleButtonList ? (
            <ToggleButtonGroup
              buttonList={setRoleButtonList}
              selectedBtn={props.selectedSetRoleBtn}
              handleButtonClick={props.onSetRoleBtnClick}
            />
          ) : (
            <div className="d-flex align-items-center">
              <div className="btn me-3 border">{rolesTitle}</div>
              <button
                className="btn btn-light"
                onClick={onSelectRoleEditBtnClick}
              >
                <i className="bi bi-pencil" />
              </button>
            </div>
          )}
        </div>

        {/* ROLE SETTING BUTTONS */}
        {props.selectedIndividualGroupBtn === IndividualGroupBtnsEnum.group &&
          props.selectedSetRoleBtn ===
            ProfileRoleStatus.RoleSetByFacilitator && (
            <div className="mb-4">
              <div className="form-label">
                {getLanguageValue(props.languageText, "Role setting")}
              </div>
              {props.showEditModeSelectedSameRoleBtn ? (
                <ToggleButtonGroup
                  buttonList={sameRoleButtonList}
                  selectedBtn={props.selectedSameRoleBtn}
                  handleButtonClick={props.onSameRoleBtnClick}
                />
              ) : (
                <div className="d-flex align-items-center">
                  <div className="btn border me-3">{sameRoleTitle}</div>
                  <button
                    className="btn btn-light"
                    onClick={onSelectRoleSameEditBtnClick}
                  >
                    <i className="bi bi-pencil" />
                  </button>
                </div>
              )}
            </div>
          )}

        {/* ROLE SETTINGS */}
        {props.selectedSetRoleBtn === ProfileRoleStatus.RoleSetByFacilitator &&
          (props.selectedSameRoleBtn === RoleBtnsEnum.sameRole ||
            props.selectedIndividualGroupBtn ===
              IndividualGroupBtnsEnum.individual) && (
            <>
              <RoleSettings
                languageText={props.languageText}
                selectedTypeOfRoleBtn={props.selectedTypeOfRoleBtn}
                typeOfRoleButtonList={props.typeOfRoleButtonList}
                roleInputSelected={props.roleInputSelected}
                typeOfRoleInput={props.typeOfRoleInput}
                selectedNoOfRespondentsBtn={props.selectedNoOfRespondentsBtn}
                respondentsInputSelected={props.respondentsInputSelected}
                noOfRespondentsInput={props.noOfRespondentsInput}
                respondentsConfirmModal={props.respondentsConfirmModal}
                onTypeOfRoleBtnClick={props.onTypeOfRoleBtnClick}
                onTypeOfRoleChange={props.onTypeOfRoleChange}
                handleTypeOfRoleFocus={props.handleTypeOfRoleFocus}
                onNoOfRespondentsClick={props.onNoOfRespondentsClick}
                onNoOfRespondentsChange={props.onNoOfRespondentsChange}
                handleNoOfRespondentsFocus={props.handleNoOfRespondentsFocus}
                SubmitRespondentsConfirmModal={
                  props.SubmitRespondentsConfirmModal
                }
                cancelRespondentsConfirmModal={
                  props.cancelRespondentsConfirmModal
                }
                setShowEditModeNoOfRespondent={
                  props.setShowEditModeNoOfRespondent
                }
                showEditModeNoOfRespondent={props.showEditModeNoOfRespondent}
                showEditModeRoleType={props.showEditModeRoleType}
                setShowEditModeRoleType={props.setShowEditModeRoleType}
              />
            </>
          )}

        {/* PARTICIPANT CHANGE ROLE BUTTON */}
        {((props.selectedIndividualGroupBtn ===
          IndividualGroupBtnsEnum.individual &&
          props.selectedSetRoleBtn === ProfileRoleStatus.RoleSetByFacilitator &&
          (props.selectedTypeOfRoleBtn || props.customTypeOfRole)) ||
          (props.selectedIndividualGroupBtn === IndividualGroupBtnsEnum.group &&
            props.selectedSetRoleBtn ===
              ProfileRoleStatus.RoleSetByFacilitator &&
            (props.selectedSameRoleBtn === RoleBtnsEnum.individualRole ||
              (props.selectedSameRoleBtn === RoleBtnsEnum.sameRole &&
                (props.selectedTypeOfRoleBtn || props.customTypeOfRole))))) && (
          <>
            <div className="form-label">
              {getLanguageValue(
                props.languageText,
                "Should the participant be able to change role setting",
              )}
              ?
            </div>
            {props.showEditModeSelectedParticipantBtnClick ? (
              <ToggleButtonGroup
                buttonList={participantRoleButtonList}
                selectedBtn={props.selectedParticipantChangeBtn}
                handleButtonClick={props.handleParticipantChangeRoleClick}
              />
            ) : (
              <div className="d-flex align-items-center">
                <div className="btn border me-3">{changeRoleTitle}</div>
                <button
                  className="btn btn-light"
                  onClick={onSelectedParticipantBtnClick}
                >
                  <i className="bi bi-pencil" />
                </button>
              </div>
            )}
          </>
        )}

        {props.commonText && (
          <>
            <p className="mt-4 text-danger">
              {getLanguageValue(
                props.languageText,
                "The changes will affect the participants",
              )}
            </p>
          </>
        )}

        {/* ERROR ELEMENT */}
        {props.roleStepError && (
          <div className="invalid-feedback d-inline-block">
            {props.roleStepError}
          </div>
        )}

        {/* SAVE BUTTON */}
        {props.showRoleSaveBtn && !props.roleFromEditProfile && (
          <div className="mt-4">
            <span
              className="fs-5 fw-bold text-decoration-underline"
              onClick={props.onSaveClick}
              role="button"
            >
              {getLanguageValue(props.languageText, "Save and continue")}
            </span>
          </div>
        )}
      </div>
    </>
  );
};
