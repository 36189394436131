import { Dispatch } from "redux";
import { rawAxiosApi, apiEndPoints } from "../../../api";
import { setNotification } from "../../actions";
import { IMyIDIProfiles } from "./hooks";

export const getMyIDIProfiles = async (
  languageCode: string,
  dispatch: Dispatch,
): Promise<IMyIDIProfiles> => {
  try {
    const response = await rawAxiosApi.get(apiEndPoints.getMyIDIProfiles, {
      params: { languageCode },
    });
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
