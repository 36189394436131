import React from "react";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../commonUtils/languageFunctionsHelper";
import { RoleEnum } from "../commonEnums";
import {
  DropdownMenu,
  DropdownMenuItem,
} from "@app/components/formComponents/dropdownMenu";
import { UserId } from "../reducer";

interface IUserActionsCellProps {
  id: UserId;
  roleId: number;
  isActive: boolean;
  email: string;
  name: string;
  languageText: ITranslationObject;
  handleEditClick: (id: UserId) => void;
  handleDeleteClick: (id: UserId, name: string) => void;
  handleResetPasswordClick: (id: UserId, name: string, email: string) => void;
  handleLoginUserClick: (id: UserId, name: string, email: string) => void;
}

export const UserActionsCell: React.FC<IUserActionsCellProps> = (props) => {
  const onEditClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    props.handleEditClick(props.id);
  };

  const onDeleteClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    props.handleDeleteClick(props.id, props.name);
  };

  const onResetPasswordClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    props.handleResetPasswordClick(props.id, props.name, props.email);
  };

  const onLoginUserClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();

    props.handleLoginUserClick(props.id, props.name, props.email);
  };

  const dropdownItems: Array<DropdownMenuItem> = [
    {
      name: getLanguageValue(props.languageText, "Edit"),
      onClick: onEditClick,
    },
    {
      name: getLanguageValue(props.languageText, "Delete"),
      onClick: onDeleteClick,
    },
    {
      name: getLanguageValue(props.languageText, "Reset Password"),
      onClick: onResetPasswordClick,
    },
  ];

  if (props.isActive && props.roleId !== RoleEnum.Admin) {
    dropdownItems.push({
      name: getLanguageValue(props.languageText, "Login as user"),
      onClick: onLoginUserClick,
    });
  }

  return <DropdownMenu items={dropdownItems} />;
};
