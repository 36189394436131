import React, { useState } from "react";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../commonUtils/languageFunctionsHelper";
import { UserId } from "@app/containers/reducer";
import { ILanguage } from "@app/containers/commonInterfaces";

export interface IListRespondent {
  /** This is a user ID */
  id: UserId;
  name: string;
  emailAddress: string;
  phoneNumber: string;
  instructionLanguageId: number | null;
}

interface ISelectFromListProps {
  languageText: ITranslationObject;
  languages: Array<ILanguage>;
  respondentsList: IListRespondent[];
  searchText: string;
  selectedRespondents: IListRespondent[];
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectRespondent: (respondent: IListRespondent) => void;
  handleRemoveRespondent: (respondent: IListRespondent) => void;
}

export const SelectFromList = (props: ISelectFromListProps) => {
  const [visibleItems, setVisibleItems] = useState<number>(5);

  const handleShowMore = () => {
    setVisibleItems(visibleItems + 5); // Increase the number of visible items
  };
  const handleShowless = () => {
    setVisibleItems(5); // Increase the number of visible items
  };

  const renderedItems = props.respondentsList
    .filter(
      (r) => !props.selectedRespondents.some((other) => other.id === r.id),
    )
    .slice(0, visibleItems);

  return (
    <>
      <div className="select-from-list">
        <div className="d-flex position-relative align-items-center mb-4">
          <i className="bi bi-search position-absolute me-3 end-0"></i>
          <input
            type="text"
            data-kt-user-table-filter="search"
            className="form-control form-control-solid border-secondary-subtle"
            placeholder="Search..."
            value={props.searchText}
            onChange={props.handleSearch}
          />
        </div>

        {/* Respondent List */}
        <div className="respondents-list-wrapper">
          {renderedItems.map((respondent) => {
            return (
              <div
                key={respondent.id}
                className="d-flex align-items-center py-2"
              >
                <button
                  className="btn btn-light"
                  onClick={() => props.handleSelectRespondent(respondent)}
                >
                  <i className="bi bi-plus-lg fs-5" />
                </button>
                <span className="fs-5 fw-bold ms-3">{respondent.name}</span>
              </div>
            );
          })}
        </div>
        {props.respondentsList.length > 5 && (
          <div className="d-flex justify-content-center">
            {visibleItems < props.respondentsList.length && (
              <button
                className="btn fw-semibold fs-5 bg-white"
                onClick={handleShowMore}
              >
                {getLanguageValue(props.languageText, "Show More")}
                <i className="bi bi-chevron-down ms-2" />
              </button>
            )}
            {visibleItems >= props.respondentsList.length && (
              <button
                className="btn fw-semibold fs-5 bg-white"
                onClick={handleShowless}
              >
                {getLanguageValue(props.languageText, "Show less")}
                <i className="bi bi-chevron-up ms-2" />
              </button>
            )}
          </div>
        )}
      </div>
    </>
  );
};
