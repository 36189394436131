import React from "react";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../commonUtils/languageFunctionsHelper";

interface IDefaultClientCellProps {
  languageText: ITranslationObject;
  name: string;
  isDefault: boolean;
}

export const DefaultClientCell = (props: IDefaultClientCellProps) => {
  return (
    <>
      {props.name}
      {props.isDefault && (
        <span className="badge badge-light fw-bold fs-7 mx-3">
          {getLanguageValue(props.languageText, "Default")}
        </span>
      )}
    </>
  );
};
