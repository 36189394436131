import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ColumnDef } from "@tanstack/react-table";

import { ReactTable } from "../../../../components/reactTable/reactTable";
import { MultiLabelToggleCheckbox } from "../../../../components/formComponents/multiLabelToggleCheckbox";
import { ToggleCardCheckbox } from "../../../../components/formComponents/toggleCardCheckbox";
import { ReactDatePicker } from "../../../../components/datePicker/ReactDatePicker";
import { Input } from "../../../../components/formComponents/input";
import { SubscriptionAddInvoice } from "./subscriptionAddInvoice/subscriptionAddInvoice";
import {
  DropdownSelect,
  IDropdownList,
  IDropdownSelectedItem,
} from "@app/components/formComponents/dropdownSelect";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../commonUtils/languageFunctionsHelper";
import { BillingInputs } from "../clientEnums";
import { IPaymentTypes } from "../../../../commonUtils/planSuscriptionHelper";
import {
  ISelectedPlan,
  IBillingFocusInput,
  IBillingInfo,
} from "../addEditAccountModalHooks";
import {
  FortnoxInvoiceStatus,
  IDIInvoice,
} from "../../../../components/invoice/invoiceInterface";
import { CustomActionsCell } from "../../../../components/reactTable/customActionsCell";
import { isLoggedInRoleAdmin } from "@app/commonUtils/roleHelper";
import { RootState } from "@app/store/configureStore";
import { Plan, PlanType } from "@app/types";
import { IClient } from "@app/containers/commonInterfaces";
import { IClientSubscription } from "../../hooks";

interface IClientBillingProps {
  languageText: ITranslationObject;
  accountInfo: IClient;
  plans: Plan[];
  selectedPlan: ISelectedPlan;
  paymentTermsList: IDropdownList[];
  billingInfo: IBillingInfo;
  billingFocusInput: IBillingFocusInput;
  paymentTypes: IPaymentTypes[];
  paymentStatusList: IDropdownList[];
  paymentList: IDropdownList[];
  paymentAfterEndList: IDropdownList[];
  currencyList: IDropdownList[];
  invoiceList: IDIInvoice[];
  selectedClientSubscription: IClientSubscription;
  handlePlanChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectPlan: (
    selectedPlanId: number,
    price: number,
    type: number,
    individual: boolean,
  ) => void;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlurEvent: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleDropdownSelect: (selectedItem: IDropdownSelectedItem) => void;
  handleDateSelect: (name: string, date: Date) => void;
  handleSelectPaymentMethod: (id: number) => void;
  handleSubscription: (isActive: boolean) => void;
  handleBackToSubscriptionList: () => void;
  getClientSubscription: (clientSubscriptionId: number) => void;
}

export const ClientBilling = (props: IClientBillingProps): JSX.Element => {
  const loggedInUserRole = useSelector(
    (state: RootState) => state.loginReducer.loggedInUserRole,
  );

  const [editedInvoiceId, setEditedInvoiceId] = useState<number>(0);

  const handleEditInvoice = (id: number): void => {
    setOpenInvoiceForm(true);
    setEditedInvoiceId(id);
  };

  const invoiceTableHeader = useMemo<ColumnDef<IDIInvoice, unknown>[]>(
    () => [
      {
        header: "",
        accessorKey: "actions",
        disableSortBy: true,
        cell: ({ ...rowProps }) => (
          <CustomActionsCell
            editOption
            languageText={props.languageText}
            id={rowProps.row.original.id}
            handleEditClick={handleEditInvoice}
          />
        ),
      },
      {
        header: `${getLanguageValue(props.languageText, "Invoice")} #`,
        accessorKey: "fortnoxInvoiceNumber",
        enableSorting: false,
        cell: ({ ...rowProps }) =>
          rowProps.row.original.fortnoxInvoiceNumber
            ? rowProps.row.original.fortnoxInvoiceNumber
            : "",
      },
      {
        header: getLanguageValue(props.languageText, "Issue Date"),
        accessorKey: "date",
        enableSorting: false,
        cell: ({ ...rowProps }) => (
          <>{rowProps.row.original.date?.substring(0, 10)}</>
        ),
      },
      {
        header: getLanguageValue(props.languageText, "Due Date"),
        accessorKey: "dueDate",
        enableSorting: false,
      },
      {
        header: getLanguageValue(props.languageText, "Amount"),
        accessorKey: "total",
        enableSorting: false,
        cell: ({ ...rowProps }) => (
          <>
            {rowProps.row.original.total} {rowProps.row.original.currency}
          </>
        ),
      },
      {
        header: getLanguageValue(props.languageText, "Amount Due"),
        accessorKey: "",
        enableSorting: false,
      },
      {
        header: getLanguageValue(props.languageText, "Status"),
        accessorKey: "fortnoxInvoiceStatus",
        enableSorting: false,
        cell: ({ ...rowProps }) => (
          <>
            {rowProps.row.original.fortnoxInvoiceStatus &&
              getLanguageValue(
                props.languageText,
                FortnoxInvoiceStatus[
                  rowProps.row.original.fortnoxInvoiceStatus
                ].toString(),
              )}
          </>
        ),
      },
      {
        header: getLanguageValue(props.languageText, "Payment Method"),
        accessorKey: "paymentMethod",
        enableSorting: false,
      },
    ],
    [props.languageText],
  );

  const [openInvoiceForm, setOpenInvoiceForm] = useState<boolean>(false);

  const addInvoiceClick = (): void => setOpenInvoiceForm(true);

  const handleClientSubscription = (
    clientSubscriptionId: number,
    invoiceId?: number,
  ): void => {
    if (invoiceId) setEditedInvoiceId(invoiceId);
    props.getClientSubscription(clientSubscriptionId);
  };

  const closeInvoiceForm = (): void => {
    setOpenInvoiceForm(false);
    if (editedInvoiceId) setEditedInvoiceId(0);
  };

  const isEdit = false;
  const isAdmin = isLoggedInRoleAdmin(loggedInUserRole);

  return (
    <>
      <form>
        <div className="d-flex flex-wrap justify-content-between align-items-center">
          <h5>
            {getLanguageValue(props.languageText, "Subscription and Plans")}
          </h5>
        </div>

        {/* Subscription Plans */}
        <div className="mb-3">
          <div className="row g-3">
            {props.plans.map((plan, index) => {
              const currencyUnit =
                plan.type === PlanType.Plan ? "SEK/Profile" : "SEK/User";
              const price = plan.prices[0]?.unitPrice ?? 0;
              const isPriceUpdated =
                plan.id === props.selectedPlan.selectedPlanId &&
                props.billingInfo.price &&
                Number(props.billingInfo.price) !== price;
              const currentPrice = isPriceUpdated
                ? props.billingInfo.price
                : price.toString();

              return (
                <ToggleCardCheckbox
                  key={index}
                  header={plan.name}
                  containerClass="col-md-3 col-12"
                  checked={plan.id === props.selectedPlan.selectedPlanId}
                  description={plan.description}
                  additionalInfoHeader={currentPrice}
                  strikeThroughHeader={isPriceUpdated ? price.toString() : ""}
                  handleClick={() =>
                    props.handleSelectPlan(plan.id!, price, plan.type, false)
                  }
                  additionalInfoDescription={currencyUnit}
                />
              );
            })}
          </div>
          {props.billingFocusInput.selectedPlan.errorMessage && (
            <div className="invalid-feedback d-inline-block">
              {props.billingFocusInput.selectedPlan.errorMessage}
            </div>
          )}
        </div>

        {/* Admin Billing Details */}
        {isAdmin && (
          <>
            <div className="row">
              <div className="col-md-3 col-12">
                <DropdownSelect
                  name={BillingInputs.paymentTerms}
                  label={getLanguageValue(props.languageText, "Payment terms")}
                  defaultLabel={getLanguageValue(
                    props.languageText,
                    "Select payment terms",
                  )}
                  list={props.paymentTermsList}
                  value={props.billingInfo.paymentTerms}
                  focusInput={props.billingFocusInput.paymentTerms}
                  handleDropdownSelect={props.handleDropdownSelect}
                  required
                />
              </div>
              <div className="col-md-3 col-12">
                <Input
                  name={BillingInputs.price}
                  label={getLanguageValue(props.languageText, "Price")}
                  placeholder={getLanguageValue(props.languageText, "Price")}
                  type="number"
                  value={props.billingInfo.price}
                  errorMessage={props.billingFocusInput.price.errorMessage}
                  handleBlurEvent={props.handleBlurEvent}
                  handleInputChange={props.handleInputChange}
                  required
                />
              </div>

              {props.selectedPlan.type > 0 &&
                props.selectedPlan.type !== PlanType.Plan &&
                !props.selectedPlan.individual && (
                  <div className="col-md-3 col-12">
                    <Input
                      name={BillingInputs.teamSize}
                      label={getLanguageValue(props.languageText, "Team Size")}
                      placeholder={getLanguageValue(
                        props.languageText,
                        "Team Size",
                      )}
                      type="number"
                      value={props.billingInfo.teamSize}
                      errorMessage={
                        props.billingFocusInput.teamSize.errorMessage
                      }
                      handleBlurEvent={props.handleBlurEvent}
                      handleInputChange={props.handleInputChange}
                      required
                    />
                  </div>
                )}
            </div>
            <div className="row mb-6">
              <ReactDatePicker
                className="col-md-3 col-12"
                name={BillingInputs.startDate}
                label={getLanguageValue(props.languageText, "Start Date")}
                placeholder={getLanguageValue(props.languageText, "Start Date")}
                date={props.billingInfo.startDate}
                minDate={new Date()}
                focusInput={props.billingFocusInput.startDate}
                handleDateSelect={props.handleDateSelect}
                required
              />
              <ReactDatePicker
                className="col-md-3 col-12"
                name={BillingInputs.endDate}
                label={getLanguageValue(props.languageText, "End Date")}
                placeholder={getLanguageValue(props.languageText, "End Date")}
                date={props.billingInfo.endDate}
                minDate={new Date()}
                handleDateSelect={props.handleDateSelect}
              />
            </div>
          </>
        )}

        {/* Facilitator Billing Details */}
        {!isAdmin && (
          <div className="row mb-10">
            <ReactDatePicker
              className="col-md-3 col-12"
              name={BillingInputs.startDate}
              label={getLanguageValue(props.languageText, "Start Date")}
              placeholder={getLanguageValue(props.languageText, "Start Date")}
              date={props.billingInfo.startDate}
              minDate={new Date()}
              handleDateSelect={props.handleDateSelect}
              required
            />
            <ReactDatePicker
              className="col-md-3 col-12"
              name={BillingInputs.endDate}
              label={getLanguageValue(props.languageText, "End Date")}
              placeholder={getLanguageValue(props.languageText, "End Date")}
              date={props.billingInfo.endDate}
              minDate={new Date()}
              handleDateSelect={props.handleDateSelect}
            />
            {props.selectedPlan.type > 0 &&
              props.selectedPlan.type !== PlanType.Plan &&
              !props.selectedPlan.individual && (
                <div className="col-md-3 col-12">
                  <Input
                    name={BillingInputs.teamSize}
                    label={getLanguageValue(props.languageText, "Team Size")}
                    placeholder={getLanguageValue(
                      props.languageText,
                      "Team Size",
                    )}
                    type="number"
                    value={props.billingInfo.teamSize}
                    errorMessage={props.billingFocusInput.teamSize.errorMessage}
                    handleBlurEvent={props.handleBlurEvent}
                    handleInputChange={props.handleInputChange}
                    required
                  />
                </div>
              )}
          </div>
        )}

        {/* Payment Types */}
        <div>
          <h5 className="required">
            {getLanguageValue(props.languageText, "Payment method")}
          </h5>
          <div className="row g-3 mb-3">
            {props.paymentTypes.map((paymentType, index) => (
              <ToggleCardCheckbox
                key={index}
                header={paymentType.name}
                description={paymentType.description}
                containerClass="col-md-4 col-12"
                icon={paymentType.icon}
                checked={
                  paymentType.id === props.billingInfo.selectedPaymentMethodId
                }
                handleClick={() =>
                  props.handleSelectPaymentMethod(paymentType.id)
                }
              />
            ))}
          </div>
          {props.billingFocusInput.selectedPaymentMethodId.errorMessage && (
            <div className="invalid-feedback d-block mt-n2 mb-3">
              {props.billingFocusInput.selectedPaymentMethodId.errorMessage}
            </div>
          )}
        </div>

        {/* admin status, payment, After end, currency field*/}
        {isAdmin && (
          <div className="row mt-8">
            <div className="col-md-3 col-12">
              <DropdownSelect
                name={BillingInputs.status}
                label={getLanguageValue(props.languageText, "Status")}
                defaultLabel={getLanguageValue(
                  props.languageText,
                  "Select Status",
                )}
                list={props.paymentStatusList}
                value={props.billingInfo.status}
                handleDropdownSelect={props.handleDropdownSelect}
              />
            </div>
            <div className="col-md-3 col-12">
              <DropdownSelect
                name={BillingInputs.payment}
                label={getLanguageValue(props.languageText, "Payment")}
                defaultLabel={getLanguageValue(
                  props.languageText,
                  "Select Payment",
                )}
                list={props.paymentList}
                value={props.billingInfo.payment}
                handleDropdownSelect={props.handleDropdownSelect}
              />
            </div>
            <div className="col-md-3 col-12">
              <DropdownSelect
                name={BillingInputs.paymentAfterEnd}
                label={getLanguageValue(props.languageText, "After End")}
                defaultLabel={getLanguageValue(
                  props.languageText,
                  "Select After End",
                )}
                list={props.paymentAfterEndList}
                value={props.billingInfo.paymentAfterEnd}
                handleDropdownSelect={props.handleDropdownSelect}
              />
            </div>
            <div className="col-md-3 col-12">
              <DropdownSelect
                name={BillingInputs.currencyId}
                label={getLanguageValue(props.languageText, "Currency")}
                defaultLabel={getLanguageValue(
                  props.languageText,
                  "Select Currency",
                )}
                list={props.currencyList}
                value={props.billingInfo.currencyId}
                handleDropdownSelect={props.handleDropdownSelect}
              />
            </div>
          </div>
        )}

        {/* Projected Bill */}
        {Number(props.billingInfo.teamSize) > 0 &&
          props.selectedPlan.selectedPlanId > 0 && (
            <div className="border border-secondary rounded p-3">
              <h5>{getLanguageValue(props.languageText, "Projected Bill")}</h5>
              <br />
              <h3>
                {Number(props.billingInfo.teamSize) *
                  Number(
                    props.billingInfo.price
                      ? props.billingInfo.price
                      : props.selectedPlan.price,
                  )}{" "}
                SEK
                {props.selectedPlan.price !==
                  Number(props.billingInfo.price) && (
                  <>
                    {" "}
                    (
                    <s>
                      {props.selectedPlan.price *
                        Number(props.billingInfo.teamSize)}
                    </s>{" "}
                    SEK)
                  </>
                )}
              </h3>
              <p className="mb-10">
                {getLanguageValue(
                  props.languageText,
                  "Estimated invoice amount including Tax",
                )}
                .
              </p>
              <p>
                {getLanguageValue(props.languageText, "Services included")}:
              </p>
              <p>
                -{" "}
                {getLanguageValue(
                  props.languageText,
                  "Unlimited Profiles/User",
                )}
                <br />-{" "}
                {getLanguageValue(
                  props.languageText,
                  "Unlimited Access to IDI Academy Courses",
                )}
                <br />-{" "}
                {getLanguageValue(
                  props.languageText,
                  "Facilitator consultation",
                )}
              </p>
            </div>
          )}

        <button
          className="btn btn-success mt-3"
          onClick={props.handleBackToSubscriptionList}
        >
          {getLanguageValue(props.languageText, "Go back")}
        </button>

        {isAdmin &&
          !openInvoiceForm &&
          props.selectedClientSubscription!.id > 0 && (
            <div className="mt-10">
              <ReactTable
                headerText={getLanguageValue(props.languageText, "Invoices")}
                buttonText={getLanguageValue(props.languageText, "Add Invoice")}
                minHeight="min-h-150px"
                tableHeader={invoiceTableHeader}
                data={props.invoiceList}
                paginationRequired={false}
                handleButtonClick={addInvoiceClick}
              />
            </div>
          )}

        {openInvoiceForm && (
          <SubscriptionAddInvoice
            languageText={props.languageText}
            clientSubscriptionId={props.selectedClientSubscription!.id ?? 0}
            invoiceId={editedInvoiceId}
            handleClientSubscription={handleClientSubscription}
            handleCloseClick={closeInvoiceForm}
          />
        )}
      </form>
    </>
  );
};
