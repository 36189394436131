import React from "react";

import { CourseCardFooter } from "./courseCardFooter";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../commonUtils/languageFunctionsHelper";
import { ICourse, ILesson } from "../../types";
import { ConvertCourseDurationToMinutes } from "../../../../commonUtils/coursesHelper";

interface ICourseCardProps {
  index: number;
  course: ICourse | ILesson;
  languageText: ITranslationObject;
}

export const CourseCard = (props: ICourseCardProps): JSX.Element => {
  return (
    <div
      id={props.index === 0 ? "academyInstruction7" : ""}
      className={`card h-100 rounded ${
        !(
          props.course &&
          props.course.progress !== undefined &&
          props.course.progress < 100
        )
          ? "bg-success border-0 bg-opacity-10"
          : "bg-white border-0 shadow-sm"
      }`}
    >
      <div className="card-body p-4">
        {props.course.markAsFinish && (
          <i className="bi bi-check-square-fill text-success float-end fs-3"></i>
        )}
        <img src={props.course.logo} height={45} />
        <h4 className="fw-bolder mt-4">
          {props.course.title && props.course.title}
        </h4>
        <div className="d-flex flex-wrap mt-3">
          <div className="d-flex align-items-center me-4 mb-2">
            <i className="bi bi-file-earmark me-2 fs-4"></i>
            {props.course.partsCount}{" "}
            {getLanguageValue(props.languageText, "parts")}
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="bi bi-stopwatch me-2 fs-4" />
            <span>
              {ConvertCourseDurationToMinutes(props.course.duration)}{" "}
              {getLanguageValue(props.languageText, "mins")}
            </span>
          </div>
        </div>
        <div className="my-2">
          {/* Parent Categories */}
          {(props.course as ICourse)?.parentCategories &&
            (props.course as ICourse).parentCategories.length > 0 &&
            [...(props.course as ICourse).parentCategories]
              .reverse()
              .map((info, idx) => (
                <span className="small" key={idx}>
                  {info.name} /{" "}
                </span>
              ))}

          {/* Sub Category */}
          {(props.course as ICourse)?.category && (
            <span className="small">
              {(props.course as ICourse).category.name}
            </span>
          )}
        </div>
        <div className="mb-3">
          {props.course.tags &&
            Array.isArray(props.course.tags) &&
            props.course.tags.map((tag, index) => (
              <span
                key={index}
                className="py-1 px-2 small rounded bg-success text-white me-1"
              >
                {tag.name}
              </span>
            ))}
        </div>

        <p className="academy-card-text-ellipsis text-gray">
          {props.course.description}
        </p>
      </div>
      <CourseCardFooter
        index={props.index}
        progress={props.course.progress ?? 0}
        courseId={props.course.id}
        languageText={props.languageText}
        canStartCourse={!!props.course.canStartCourse}
      />
    </div>
  );
};
