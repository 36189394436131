import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

import { EditProfileDescriptionModal } from "./editProfileDescriptionModal";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../../commonUtils/languageFunctionsHelper";
import { getWindowDimensions } from "../../../../../commonUtils/screenWidthHelper";
import { ActivityStatus, activityStatusColors } from "../../../activityEnums";
import { getAllCountries } from "../../../../clientList/addEditModal/action";
import { RootState } from "@app/store/configureStore";
import { IDropdownList } from "@app/components/formComponents/dropdownSelect";
import { IEditActivity } from "../../interface";
import { ClientSelectModal } from "../../modals/clientSelectModal/clientSelectModal";
import { ReactTooltip } from "../../../../../components/tooltip/reactTooltip";
import {
  CustomModal as CancelModal,
  CustomModal,
} from "../../../../../components/modals/customModal";
import { cancelActivity, getClientFacilitators } from "../../actions";
import { DeleteModal } from "@app/components/modals/deleteModal";
import { deleteActivity } from "@app/containers/activityList/actions";
import { routePath } from "@app/containers/routePaths";
import { ICountry, ILanguage } from "@app/containers/commonInterfaces";
import { addToast, setSpinner } from "@app/containers/actions";
import { AnyAction } from "redux";
import { isLoggedInRoleAdmin } from "@app/commonUtils/roleHelper";
import { Instruction } from "@app/components/instruction/instruction";
import { GuidePages, Roles } from "@app/containers/commonEnums";
import { editProfileInstructionSteps } from "@app/components/instruction/instructionSteps";
import { ActivityId } from "@app/containers/reducer";

export enum EditModalEnum {
  status = "status",
  completionDate = "completionDate",
  name = "name",
  notes = "notes",
  language = "language",
  country = "country",
  facilitator = "facilitator",
  sourceType = "sourceType",
  isProBono = "isProBono",
}

interface IFacilitatorInfo {
  title: string;
  value: string;
}

export interface IClientFacilitator {
  id: number;
  name: string;
  email: string;
  phoneNumber: string;
}

interface IEditProfileDescriptionStepProps {
  id: ActivityId;
  languageText: ITranslationObject;
  clientIdFromAccount: number;
  profileData: IEditActivity;
  cultures: ReadonlyArray<ILanguage>;
  isActivityCancelled: boolean;
  isActivityInvoiced: boolean;
  handleProfileCancel: () => void;
  onBillingButtonClick: () => void;
  handleInstructionHelpClick: (guidePage: GuidePages) => void;
  handleExitGuide: (guidePage: GuidePages, isSkipped: boolean) => void;
  handleClientSelect: (
    clientId: number,
    clientName: string,
    isFacilitatorRemoved: boolean,
  ) => void;
  handleLanguageSelect: (languageId: number) => void;
  refetchFullProfile: () => void;
}

export const EditProfileDescriptionStep = (
  props: IEditProfileDescriptionStepProps,
): JSX.Element => {
  const isMobileView = getWindowDimensions().width < 768;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loggedInUserRole = useSelector(
    (state: RootState) => state.loginReducer.loggedInUserRole,
  );
  const userDetails = useSelector(
    (state: RootState) => state.loginReducer.userDetails,
  );
  const instructionSteps = useMemo(
    () => editProfileInstructionSteps(props.languageText),
    [props.languageText],
  );

  const [name, setName] = useState<string>(""); // Profile name
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [countries, setCountries] = useState<IDropdownList[]>([]);
  const [facilitators, setFacilitators] = useState<IClientFacilitator[]>([]);
  const [status, setStatus] = useState<ActivityStatus>(ActivityStatus.Created);
  const [completionDate, setCompletionDate] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [languageName, setLanguageName] = useState<string | JSX.Element>("");
  const [countryName, setCountryName] = useState<string | JSX.Element>("");
  const [facilitatorName, setFacilitatorName] = useState<string>("");
  const [facilitatorId, setFacilitatorId] = useState<number>(0);
  const [sourceType, setSourceType] = useState<string>(
    props.profileData.sourceType,
  );
  const [editModalName, setEditModalName] = useState<EditModalEnum | undefined>(
    undefined,
  );
  const [clientEditMode, setClientEditMode] = useState<boolean>(false);
  const [profileCancelModal, setProfileCancelModal] = useState<boolean>(false);
  const [facilitatorInfoModal, setFacilitatorInfoModal] =
    useState<boolean>(false);
  const canEditFacilitator =
    loggedInUserRole === Roles.Admin ||
    (loggedInUserRole === Roles.Facilitator && userDetails.isManager);

  const getCountries = async (): Promise<void> => {
    await getAllCountries(dispatch).then((response: ICountry[]) => {
      if (response?.length > 0) {
        const countries: IDropdownList[] = response.map((item) => ({
          id: item.id,
          displayName: item.name,
          value: item.name,
        }));
        setCountries(countries);
      }
    });
  };

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    if (canEditFacilitator && props.profileData.clientId) {
      getClientFacilitators(props.profileData.clientId, dispatch).then(
        (response) => {
          if (response?.length > 0) {
            setFacilitators(response);
          }
        },
      );
    }
  }, [props.profileData.clientId, canEditFacilitator]);

  useEffect(() => {
    const languageName =
      props.cultures.find(
        (culture) => culture.id === props.profileData.idiLanguageId,
      )?.name ?? "";
    const countryName =
      countries.find((culture) => culture.id === props.profileData.countryId)
        ?.displayName ?? "";

    setStatus(props.profileData.status);
    setCompletionDate(props.profileData.completionDate.substring(0, 10));
    setNotes(props.profileData.notes);
    setName(props.profileData.name);
    setLanguageName(languageName);
    setCountryName(countryName);
    setSourceType(props.profileData.sourceType);
  }, [props.profileData]);

  useEffect(() => {
    setFacilitatorName(props.profileData.facilitator);
    setFacilitatorId(props.profileData.facilitatorId);
  }, [props.profileData.facilitatorId]);

  const handleFacilitatorSelect = (facilitatorId: number): void => {
    setFacilitatorId(facilitatorId);
    const updatedName =
      facilitators
        .find((facilitator) => facilitator.id === facilitatorId)
        ?.name.toString() ?? "";
    setFacilitatorName(updatedName);
  };

  const handleFacilitatorInfo = (): void => {
    setFacilitatorInfoModal(true);
  };

  const closeFacilitatorInfoModal = (): void => {
    setFacilitatorInfoModal(false);
  };

  const renderFacilitatorInfo = (): JSX.Element => {
    const selectedFacilitator =
      facilitators.find((facilitator) => facilitator.id === facilitatorId) ??
      facilitators[0];

    const facilitatorInfo: IFacilitatorInfo[] = [
      {
        title: getLanguageValue(props.languageText, "Name"),
        value: facilitatorName,
      },
      {
        title: getLanguageValue(props.languageText, "Email"),
        value: selectedFacilitator?.email,
      },
      {
        title: getLanguageValue(props.languageText, "Phone"),
        value: selectedFacilitator?.phoneNumber,
      },
    ];

    return (
      <div className="">
        {facilitatorInfo.map((item, index) => (
          <div key={index} className="d-flex mb-3">
            <div className="col-3">{item.title}:</div>
            <div>{item.value ?? "-"}</div>
          </div>
        ))}
      </div>
    );
  };

  const handleDeleteBtnClick = (): void => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = (): void => {
    setShowDeleteModal(false);
  };

  const handleDeleteProfile = (): void => {
    dispatch(setSpinner(true));
    deleteActivity(props.id, dispatch)
      .then((response) => {
        if (response) {
          if (
            isLoggedInRoleAdmin(loggedInUserRole) &&
            props.clientIdFromAccount
          ) {
            navigate(routePath.allaccounts);
          } else if (props.clientIdFromAccount) {
            navigate(routePath.account);
          } else if (isLoggedInRoleAdmin(loggedInUserRole)) {
            navigate(routePath.activities);
          } else {
            navigate(routePath.activities);
          }
          dispatch(addToast("Profile deleted successfully") as AnyAction);
        }
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  const closeClientSelectModal = (): void => {
    setClientEditMode(false);
  };

  const openSelectClientEditMode = (): void => {
    setClientEditMode(true);
  };

  const handleToggle = () => setIsExpanded(!isExpanded);

  const openEditModal = (name: EditModalEnum): void => {
    setEditModalName(name);
  };

  const closeEditModal = (): void => {
    setEditModalName(undefined);
  };

  const openCancelModal = (): void => {
    setProfileCancelModal(true);
  };

  const closeCancelModal = (): void => {
    setProfileCancelModal(false);
  };

  const handleCancelActivity = (): void => {
    cancelActivity(props.profileData.id, dispatch).then((response) => {
      if (response) {
        setStatus(ActivityStatus.Cancelled);
        closeCancelModal();
        !isLoggedInRoleAdmin(loggedInUserRole) && props.handleProfileCancel();
      }
    });
  };

  const cultureDisplayName = props.cultures.find(
    (c) => c.name === languageName,
  )?.displayName;

  return (
    <>
      <div className="row mb-3">
        <div className="col-md-6">
          <h4 id="selector1" className="fw-bold mb-4 mb-md-2 mb-xl-2">
            {getLanguageValue(props.languageText, "Activity")} / {name}
            {!props.isActivityCancelled && !props.isActivityInvoiced && (
              <span
                className="border btn btn-sm rounded mb-1 ms-3"
                onClick={() => openEditModal(EditModalEnum.name)}
                role="button"
              >
                <i className="bi bi-pencil fs-6"></i>
              </span>
            )}
          </h4>
        </div>
        <div className="col-md-6">
          <div className="align-items-center d-flex justify-content-md-end">
            {isLoggedInRoleAdmin(loggedInUserRole) && (
              <div
                role="button"
                className="d-flex align-items-center me-3"
                onClick={() => openEditModal(EditModalEnum.sourceType)}
              >
                {/* N/A because we need something to click when the thing is empty. */}
                <i className="bi bi-geo-alt-fill me-1"></i>{" "}
                {sourceType || "N/A"}
              </div>
            )}
            {props.profileData.showBilling &&
              isLoggedInRoleAdmin(loggedInUserRole) && (
                <div
                  role="button"
                  className="d-flex align-items-center me-3"
                  onClick={props.onBillingButtonClick}
                >
                  <i className="bi bi-receipt me-1"></i>{" "}
                  {getLanguageValue(props.languageText, "Billing")}
                </div>
              )}

            {(isLoggedInRoleAdmin(loggedInUserRole) ||
              status === ActivityStatus.Created) && (
              <div
                role="button"
                onClick={handleDeleteBtnClick}
                className="d-flex align-items-center me-3 text-danger"
              >
                <i className="bi bi-trash3 me-1" />
                {getLanguageValue(props.languageText, "Delete")}
              </div>
            )}
            {!props.isActivityCancelled && props.profileData.id > 0 && (
              <Instruction
                showHelpButton={true}
                targetElement={
                  props.profileData.deliveredProfileCount > 0 &&
                  props.profileData.profileCount !==
                    props.profileData.deliveredProfileCount
                    ? "editProfileAddParticipants"
                    : "respondentSettings"
                }
                guidePage={GuidePages.Edit_Profile}
                instructionSteps={instructionSteps}
                stepsToSkip={
                  props.profileData.deliveredProfileCount > 0 &&
                  props.profileData.profileCount ===
                    props.profileData.deliveredProfileCount
                    ? [6]
                    : []
                }
                skipPostGuideRequest={true}
                handleInstructionHelpClick={props.handleInstructionHelpClick}
                handleExitGuide={props.handleExitGuide}
              />
            )}
          </div>
        </div>
      </div>

      <div id="status" className="p-3 rounded bg-white">
        <div>
          <div className="row">
            <div className="col-lg-2 col-md-4 col-6 border-end mb-3">
              <div className="text-secondary">
                {getLanguageValue(props.languageText, "Status")}
              </div>
              <div className="d-flex align-items-center flex-wrap">
                <span
                  className={clsx(
                    "fw-bold",
                    isLoggedInRoleAdmin(loggedInUserRole) && "cursor-pointer",
                  )}
                  onClick={() =>
                    isLoggedInRoleAdmin(loggedInUserRole) &&
                    openEditModal(EditModalEnum.status)
                  }
                >
                  <span
                    className={clsx(
                      "badge fs-6 pt-2",
                      activityStatusColors[status],
                    )}
                  >
                    {status
                      ? getLanguageValue(
                          props.languageText,
                          ActivityStatus[status],
                        )
                      : ""}
                  </span>
                  {(isLoggedInRoleAdmin(loggedInUserRole) ||
                    (!isLoggedInRoleAdmin(loggedInUserRole) &&
                      (status === ActivityStatus.Created ||
                        status === ActivityStatus.Active))) &&
                    status !== ActivityStatus.Cancelled && (
                      <span
                        className="text-decoration-underline ms-2"
                        onClick={openCancelModal}
                      >
                        {getLanguageValue(props.languageText, "Cancel")}
                      </span>
                    )}
                </span>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 border-end mb-3">
              <div className="text-secondary">
                {getLanguageValue(props.languageText, "Completion date")}
              </div>
              <div
                className={clsx(
                  "fs-5 fw-bold text-dark opacity-75",
                  !props.isActivityCancelled &&
                    !props.isActivityInvoiced &&
                    "cursor-pointer",
                )}
                role="button"
                onClick={() =>
                  !props.isActivityCancelled &&
                  !props.isActivityInvoiced &&
                  openEditModal(EditModalEnum.completionDate)
                }
              >
                {completionDate}
              </div>
            </div>

            {isMobileView && !isExpanded && (
              <div className="d-flex justify-content-center">
                <span className="text-primary me-3" onClick={handleToggle}>
                  <i className="bi bi-chevron-down" />
                </span>
              </div>
            )}

            {((isMobileView && isExpanded) || !isMobileView) && (
              <>
                <div className="col-lg-2 col-md-4 col-6 border-end mb-3">
                  <div className="text-secondary">
                    {getLanguageValue(props.languageText, "Norm")}
                  </div>
                  <div
                    className={clsx(
                      "fs-5 fw-bold text-dark opacity-75",
                      !props.isActivityCancelled && "cursor-pointer",
                    )}
                    role="button"
                    onClick={() =>
                      !props.isActivityCancelled &&
                      openEditModal(EditModalEnum.language)
                    }
                  >
                    {cultureDisplayName}
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-6 border-end mb-3">
                  <div className="text-secondary">
                    {getLanguageValue(props.languageText, "Country")}
                  </div>
                  <div className="d-flex justify-content-between fs-5 fw-bold text-dark opacity-75">
                    <span
                      className={clsx(
                        !props.isActivityCancelled &&
                          !props.isActivityInvoiced &&
                          "cursor-pointer",
                      )}
                      role="button"
                      onClick={() =>
                        !props.isActivityCancelled &&
                        !props.isActivityInvoiced &&
                        openEditModal(EditModalEnum.country)
                      }
                    >
                      {countryName}
                    </span>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-6 border-end mb-3">
                  <div className="text-secondary">
                    {getLanguageValue(props.languageText, "Facilitator")}
                    {canEditFacilitator && facilitatorName && (
                      <i
                        className="bi bi-info-circle-fill text-success ms-2"
                        onClick={handleFacilitatorInfo}
                        role={canEditFacilitator ? "button" : undefined}
                      />
                    )}
                  </div>
                  <div
                    title={facilitatorName}
                    className={clsx(
                      "fs-5 fw-bold text-dark opacity-75",
                      canEditFacilitator && "cursor-pointer",
                    )}
                    role={canEditFacilitator ? "button" : undefined}
                    onClick={(event) => {
                      event.preventDefault();

                      if (!canEditFacilitator) {
                        return;
                      }

                      openEditModal(EditModalEnum.facilitator);
                    }}
                  >
                    {facilitatorName ?? "--"}
                  </div>
                </div>

                {isLoggedInRoleAdmin(loggedInUserRole) && (
                  <div className="col-lg-2 col-md-8 col-6">
                    <div className="text-secondary">
                      {getLanguageValue(props.languageText, "Client")}
                    </div>
                    <div
                      className="fs-5 text-dark opacity-75 fw-bold cursor-pointer"
                      role="button"
                      onClick={openSelectClientEditMode}
                    >
                      <ReactTooltip
                        id={props.profileData.clientId.toString()}
                        tooltipText={props.profileData.clientName}
                      >
                        {props.profileData.clientName}
                      </ReactTooltip>
                    </div>
                  </div>
                )}

                {isLoggedInRoleAdmin(loggedInUserRole) && (
                  <div className="col-lg-2 col-md-8 col-6">
                    <div className="text-secondary">
                      {getLanguageValue(props.languageText, "Pro Bono")}
                    </div>
                    <div
                      className={clsx(
                        "fs-5 text-success fw-bold",
                        !props.profileData.isProBono && "text-danger",
                      )}
                      role="button"
                      onClick={() => {
                        openEditModal(EditModalEnum.isProBono);
                      }}
                    >
                      {getLanguageValue(
                        props.languageText,
                        props.profileData.isProBono ? "Yes" : "No",
                      )}
                    </div>
                  </div>
                )}

                <div className="col-12 col-md-8">
                  <div className="text-secondary">
                    {getLanguageValue(props.languageText, "Notes")}
                  </div>
                  <div
                    className={clsx(
                      "fs-5 fw-bold text-dark opacity-75",
                      !props.isActivityCancelled &&
                        !props.isActivityInvoiced &&
                        "cursor-pointer",
                    )}
                    role="button"
                    onClick={() =>
                      !props.isActivityCancelled &&
                      !props.isActivityInvoiced &&
                      openEditModal(EditModalEnum.notes)
                    }
                    title={notes}
                  >
                    {notes ? notes : "________________"}
                  </div>
                </div>
                {isMobileView && isExpanded && (
                  <div className="d-flex justify-content-center">
                    <span className="text-primary me-3" onClick={handleToggle}>
                      <i className="bi bi-chevron-up" />
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {showDeleteModal && (
        <DeleteModal
          languageText={props.languageText}
          headerText={getLanguageValue(props.languageText, "Profile")}
          bodyText={name}
          handleDeleteClick={handleDeleteProfile}
          closeDeleteModal={closeDeleteModal}
        />
      )}

      {editModalName && (
        <EditProfileDescriptionModal
          id={props.id}
          languageText={props.languageText}
          editModalName={editModalName}
          statusValue={status}
          completionDateValue={completionDate}
          nameValue={name}
          notesValue={notes}
          languages={props.cultures}
          countries={countries}
          facilitators={facilitators}
          languageName={languageName}
          countryName={countryName}
          facilitatorId={facilitatorId}
          sourceType={sourceType}
          profileData={props.profileData}
          setStatusValue={setStatus}
          setCompletionDateValue={setCompletionDate}
          setNameValue={setName}
          setNotesValue={setNotes}
          setLanguageName={setLanguageName}
          setCountryName={setCountryName}
          setSourceType={setSourceType}
          handleLanguageSelect={props.handleLanguageSelect}
          handleFacilitatorSelect={handleFacilitatorSelect}
          refetchFullProfile={props.refetchFullProfile}
          closeModal={closeEditModal}
        />
      )}

      {clientEditMode && (
        <ClientSelectModal
          languageText={props.languageText}
          clientId={props.profileData.clientId}
          activityId={props.profileData.id}
          facilitatorId={props.profileData.facilitatorId}
          handleClientSelect={props.handleClientSelect}
          closeClientSelectModal={closeClientSelectModal}
        />
      )}

      {profileCancelModal && (
        <CancelModal
          headerText={getLanguageValue(props.languageText, "Cancel Profile")}
          bodyText={`${getLanguageValue(props.languageText, "Do you really want to cancel profile")} - "${
            props.profileData.name
          }" ?`}
          cancelButtonText={getLanguageValue(props.languageText, "No")}
          submitButtonText={getLanguageValue(props.languageText, "Yes")}
          isSubmitDangerButton={true}
          handleCancelClick={closeCancelModal}
          handleSubmitClick={handleCancelActivity}
        />
      )}

      {facilitatorInfoModal && (
        <CustomModal
          headerText={getLanguageValue(props.languageText, "Facilitator Info")}
          bodyText={renderFacilitatorInfo()}
          cancelButtonText={getLanguageValue(props.languageText, "Close")}
          handleCancelClick={closeFacilitatorInfoModal}
        />
      )}
    </>
  );
};
