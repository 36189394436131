import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import {
  getLanguageValue,
  ITranslationObject,
} from "../../../../commonUtils/languageFunctionsHelper";
import {
  DropdownSelect,
  IDropdownList,
  IDropdownSelectedItem,
} from "@app/components/formComponents/dropdownSelect";
import { TextArea } from "../../../../components/formComponents/textArea";
import { ISelectedProfilesList } from "../activityInviteParticipantsStep";
import {
  InvitationMessageListModal,
  ISavedMessage,
} from "./invitationMessageListModal";
import { PreviewPopup } from "./previewPopup";
import { MaxLength } from "../../../commonEnums";
import {
  getEmailStatusMessage,
  getSMSStatusMessage,
} from "../../editActivity/interface";
import { getLanguageTextByName } from "@app/containers/actions";
import { ILanguage } from "@app/containers/commonInterfaces";

interface IEmailSMSInvitationProps {
  isEmail: boolean;
  languageText: ITranslationObject;
  messageTranslation: ITranslationObject;
  languages: ReadonlyArray<ILanguage>;
  languageValue: string;
  invitationText: string;
  sameInvitationForAll: boolean;
  invitationSent: boolean;
  profile?: ISelectedProfilesList;
  previewHTML?: string;
  sendingInvite: boolean;
  handleLanguageSelect: (selectedItem: IDropdownSelectedItem) => void;
  handleInvitationTextChange: (value: string) => void;
  handleSelectedMessage: (selectedMessage: ISavedMessage) => void;
  handleSendClick: (isEmail: boolean, profile: ISelectedProfilesList) => void;
  handlePreviewClick?: (profile: ISelectedProfilesList) => void;
  closePreviewModal?: () => void;
}

export const EmailSMSInvitation = (props: IEmailSMSInvitationProps) => {
  const dispatch = useDispatch();

  const [updatedTranslation, setUpdatedTranslation] =
    useState<ITranslationObject>({} as ITranslationObject);
  const [invitationMessageModal, setInvitationMessageModal] =
    useState<boolean>(false);

  useEffect(() => {
    setUpdatedTranslation(props.messageTranslation);
  }, [props.messageTranslation]);

  useEffect(() => {
    if (!props.isEmail && !props.invitationText) {
      const defaultMessage = getLanguageValue(
        updatedTranslation,
        "SMS-Delegate-invite-message",
      );
      props.handleInvitationTextChange(defaultMessage);
    }
  }, [updatedTranslation]);

  const onLanguageSelect = (selectedItem: IDropdownSelectedItem): void => {
    if (props.invitationSent) return;

    getLanguageTextByName(selectedItem.value, dispatch)
      .then((lang) => {
        setUpdatedTranslation(lang);
      })
      .catch((err) => {
        // The notification is already set in the getLanguageTextByName function
        // but there we rethrow the error so we need to catch it here as well
        console.error(err);
      });

    props.handleLanguageSelect(selectedItem);
  };

  const onSelectMessageClick = (): void => {
    if (props.invitationSent) return;

    setInvitationMessageModal(true);
  };

  const onInvitationTextChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    if (props.invitationSent) return;
    props.handleInvitationTextChange(e.target.value);
  };

  const handleCancelClick = (): void => {
    setInvitationMessageModal(false);
  };

  const handleSaveClick = (selectedMessage: ISavedMessage): void => {
    handleCancelClick();
    props.handleSelectedMessage(selectedMessage);
  };

  const onSendClick = (): void => {
    props.handleSendClick(props.isEmail, props.profile!);
  };

  const onPreviewClick = (): void => {
    props.handlePreviewClick && props.handlePreviewClick(props.profile!);
  };

  const onCloseClick = (): void => {
    props.closePreviewModal && props.closePreviewModal();
  };

  const languageDropdownItems: Array<IDropdownList> = props.languages.map(
    (l) => {
      return {
        id: l.id,
        displayName: l.displayName,
        value: l.name,
      };
    },
  );

  return (
    <>
      <div className="row my-4">
        <div className="col-md-3">
          <label className="fw-normal">
            {getLanguageValue(props.languageText, "Language")}:
          </label>

          <DropdownSelect
            name="language"
            list={languageDropdownItems}
            value={props.languageValue}
            handleDropdownSelect={onLanguageSelect}
            searchOption
          />
        </div>

        {!props.sameInvitationForAll && (
          <>
            <div className="col-md-3">
              <div>{getLanguageValue(props.languageText, "Name")}:</div>
              <div className="fw-bold fs-5 mb-3">{props.profile?.name}</div>
            </div>
            <div className="col-md-6">
              {props.isEmail && (
                <>
                  <div>{getLanguageValue(props.languageText, "Email")}:</div>
                  <div className="fw-bold fs-5 mb-3">
                    {props.profile?.email}
                  </div>
                </>
              )}
              {
                // AM: Show Phonenumber here
              }
            </div>
          </>
        )}
      </div>

      {props.isEmail && (
        <div className="fs-5 mb-6 mt-3">
          {getLanguageValue(updatedTranslation, "EM-AL-CO-Greeting")}{" "}
          {props.profile && props.profile.id > 0 && `${props.profile.name}!`}
          <br />
          {props.profile && (props.profile.emailStatus ?? 0) > 1 && (
            <>
              {getLanguageValue(
                updatedTranslation,
                "EM-AL-CO-FriendlyReminder",
              )}
              <br />
            </>
          )}
          {getLanguageValue(updatedTranslation, "EM-DE-IN-Title")}
          <br />
          <br />
          {getLanguageValue(updatedTranslation, "EM-DE-IN-Text")}
        </div>
      )}

      {props.isEmail && (
        <div
          className="fw-bold fs-5 my-4 text-decoration-underline"
          role="button"
          onClick={onSelectMessageClick}
        >
          {getLanguageValue(props.languageText, "Add a saved message")}
        </div>
      )}

      {invitationMessageModal && (
        <InvitationMessageListModal
          isEmail={props.isEmail}
          languageText={props.languageText}
          handleCancelClick={handleCancelClick}
          handleSaveClick={handleSaveClick}
        />
      )}

      <TextArea
        name="template"
        value={props.invitationText}
        onChange={onInvitationTextChange}
        maxLength={props.isEmail ? 0 : MaxLength.L124}
      />
      <div className="my-4 d-flex align-items-center">
        <button
          className={clsx(
            "btn me-4",
            props.invitationSent || props.sendingInvite
              ? "btn-light"
              : "btn-success",
          )}
          onClick={onSendClick}
          disabled={props.invitationSent || props.sendingInvite}
        >
          <i className="bi bi-send-fill me-2"></i>
          {getLanguageValue(
            props.languageText,
            props.profile && (props.profile.emailStatus ?? 0) > 1
              ? "Reminder"
              : "Send",
          )}
        </button>

        {props.isEmail && (
          <div
            className="text-decoration-underline"
            onClick={onPreviewClick}
            role="button"
          >
            {getLanguageValue(props.languageText, "Preview")}
          </div>
        )}
      </div>

      {props.isEmail && props.previewHTML && (
        <PreviewPopup
          languageText={props.languageText}
          previewHTML={props.previewHTML}
          onCloseClick={onCloseClick}
        />
      )}

      {props.invitationSent && !props.sameInvitationForAll && (
        <div className="fs-6 text-white mt-4 mb-2">
          <span className="bg-success rounded p-2">
            {props.isEmail
              ? getLanguageValue(
                  props.languageText,
                  getEmailStatusMessage(props.profile!.emailStatus!),
                )
              : getLanguageValue(
                  props.languageText,
                  getSMSStatusMessage(props.profile!.smsStatus!),
                )}
          </span>
        </div>
      )}
    </>
  );
};
