import { Dispatch } from "redux";
import { rawAxiosApi, apiEndPoints } from "../../../../../api";
import { setNotification } from "../../../../actions";
import { ISubscriptionInvoiceDetails } from "../../../../../components/invoice/invoiceInterface";

export const getClientSubscriptionInvoiceDetails = async (
  clientSubscriptionId: number,
  idiInvoiceId: number,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.get(
      apiEndPoints.getClientSubscriptionInvoiceDetails,
      {
        params: { clientSubscriptionId, idiInvoiceId },
      },
    );
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const createOrUpdateSubscriptionInvoice = async (
  body: ISubscriptionInvoiceDetails,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.post(
      apiEndPoints.createOrUpdateSubscriptionInvoice,
      JSON.stringify(body),
    );
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const sendSubscriptionInvoiceToFortnox = async (
  clientSubscriptionId: number,
  idiInvoiceId: number,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.post(
      apiEndPoints.sendSubscriptionInvoiceToFortnox,
      {},
      {
        params: { clientSubscriptionId, idiInvoiceId },
      },
    );
    return response.data.success;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
