import React, { useEffect, useState } from "react";

import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import {
  ITabNavItem,
  TabNavItem,
} from "@app/components/multiTabComponent/tabNavItem";
import { NavTabs } from "@app/containers/clientList/addEditModal/clientEnums";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@app/store/configureStore";
import { TabContent } from "@app/components/multiTabComponent/tabContent";

import { useApiEndpoints } from "@app/api/end-points";
import { UserProfile } from "../userProfile";
import { ClientDetails } from "../clientDetails";
import { isLoggedInRoleFacilitator } from "@app/commonUtils/roleHelper";

type SettingsProps = {
  startingTab?: NavTabs.billing | NavTabs.settings | NavTabs.clientDetails;
};

export const Settings = (props: SettingsProps): JSX.Element => {
  const dispatch = useDispatch();
  const api = useApiEndpoints(dispatch);
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );
  const currentClient = useSelector(
    (state: RootState) => state.loginReducer.currentClient,
  );

  const userDetails = useSelector(
    (state: RootState) => state.loginReducer.userDetails,
  );

  const loggedInUserRole = useSelector(
    (state: RootState) => state.loginReducer.loggedInUserRole,
  );
  const [activeTab, setActiveTab] = useState<string>("");

  const tabs: ITabNavItem<NavTabs>[] = [
    {
      id: NavTabs.settings,
      title: getLanguageValue(languageText, "Settings"),
    },
  ];

  if (isLoggedInRoleFacilitator(loggedInUserRole)) {
    tabs.push({
      id: NavTabs.clientDetails,
      title: getLanguageValue(languageText, "Account"),
    });
  }

  useEffect(() => {
    setActiveTab(props.startingTab || NavTabs.settings);
  }, [props.startingTab]);

  return (
    <>
      <div className="bg-white mt-4 p-3 p-md-4 rounded shadow-sm">
        <h1 className="fs-4 fw-bold">{userDetails.name}</h1>

        <div className="d-flex flex-column">
          <TabNavItem
            navTabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />

          <TabContent id={NavTabs.settings} activeTab={activeTab}>
            <UserProfile />
          </TabContent>
          {isLoggedInRoleFacilitator(loggedInUserRole) && (
            <>
              <TabContent id={NavTabs.clientDetails} activeTab={activeTab}>
                <>
                  <ClientDetails clientId={currentClient.currentClientId} />
                </>
              </TabContent>
            </>
          )}
        </div>
      </div>
    </>
  );
};
