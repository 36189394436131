// containers/resources/latestNews/viewNews/index.tsx
import React from "react";
import { useGetNewsById } from "./hooks";
import { getLanguageValue } from "../../../../commonUtils/languageFunctionsHelper";
import { NewsType } from "../../newsList/types";

export const LatestNewsPage = (): JSX.Element => {
  const { languageText, newsType, latestNews, handleNavigateBack } =
    useGetNewsById();

  return (
    <>
      <div className="p-2 pt-4 p-md-4">
        <h3 className="fw-bold mb-3">
          <span role="button" onClick={() => handleNavigateBack()}>
            {getLanguageValue(languageText, "Resources")}
          </span>
          <span className="px-2">/</span>
          {getLanguageValue(
            languageText,
            newsType == NewsType.News ? "Latest News" : "Latest Blogs",
          )}
        </h3>

        <div className="bg-white mt-3 p-2 p-md-4 rounded shadow-sm">
          <h3 className="card-title">{latestNews?.title}</h3>
          <p className="card-text">
            {latestNews.publishedDate?.toString().slice(0, -8)}
          </p>
          {/* Only render image section if image exists */}
          {latestNews.image && (
            <img
              src={latestNews.image}
              className="d-block mx-auto mw-100 object-fit-contain"
              style={{ maxHeight: "40vh" }}
              alt={latestNews.title}
            />
          )}

          <div
            className="my-3"
            dangerouslySetInnerHTML={{ __html: latestNews.post }}
          ></div>

          <button
            type="button"
            className="btn btn-primary"
            onClick={handleNavigateBack}
          >
            <i className="bi bi-arrow-left me-2" />
            {getLanguageValue(languageText, "Go Back")}
          </button>
        </div>
      </div>
    </>
  );
};
