import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { ParticipantProfileDropdown } from "../modals/participantProfileDropdown";
import { EditProfileParticipantModal } from "../modals/editProfileParticipantModal/editProfileParticipantModal";
import { ParticipantAccountDetailsModal } from "../modals/participantAccountDetailsModal/participantAccountDetailsModal";
import { RoleSettingsModalWithEditOption } from "../../addActivity/roleSettings/roleSettingsModalWithEditOption";
import { Checkbox } from "../../../../components/formComponents/checkbox";
import { ReactTooltip } from "../../../../components/tooltip/reactTooltip";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../commonUtils/languageFunctionsHelper";
import { IEditActivity, IEditActivityDeliveredProfile } from "../interface";
import { GetTypeOfRoleName, ProfileStatus } from "../../activityEnums";
import { IRoleData } from "../../addActivity/roleSettings/roleSettingsModal";
import { IndividualPreviewModal } from "../modals/reports/preview/individualPreview/individualPreviewModal";
import { GroupPreviewModal } from "../modals/reports/preview/groupPreview/groupPreviewModal";
import { IndividualDownloadModal } from "../modals/reports/download/individualDownload/individualDownloadModal";
import { GroupDownloadModal } from "../modals/reports/download/groupDownload/groupDownloadModal";
import { MultipleParticipantDownloadModal } from "../modals/reports/download/multipleDownload/multipleParticipantDownloadModal";
import { getDateAndShortMonthFormat } from "../../../../commonUtils/dateFunctionsHelper";
import { EpNoOfRespondents } from "../modals/epNoOfRespondents";
import { unDeliverProfilesManually } from "../actions";
import { RootState } from "@app/store/configureStore";
import { addToast } from "@app/containers/actions";
import { AnyAction } from "redux";
import { isLoggedInRoleAdmin } from "@app/commonUtils/roleHelper";
import { GuidePages } from "@app/containers/commonEnums";
import { editProfileDeliveredStatusInstructionSteps } from "@app/components/instruction/instructionSteps";
import { Instruction } from "@app/components/instruction/instruction";
import { ActivityId, ProfileId } from "@app/containers/reducer";
import { ILanguage } from "@app/containers/commonInterfaces";

interface IEditProfileDeliveredParticipantProps {
  id: ActivityId;
  languageText: ITranslationObject;
  profileData: IEditActivity;
  isInstructionHelpClicked: boolean;
  completedStatusEditProfileGuideDone: boolean;
  languages: ReadonlyArray<ILanguage>;
  cultureValue: string;
  messageTranslation: ITranslationObject;
  isActivityCancelled: boolean;
  isActivityInvoiced: boolean;
  handleExitGuide: (guidePage: GuidePages, isSkipped: boolean) => void;
  fetchActivity: () => void;
  refetchCompletedParticipants: () => void;
  refetchDeliveredParticipants: () => void;
  handleUnPublishProfile: () => void;
  handleTransferProfile: () => void;
}

export const EditProfileDeliveredParticipant = (
  props: IEditProfileDeliveredParticipantProps,
): JSX.Element => {
  const dispatch = useDispatch();
  const instructionSteps = useMemo(
    () => editProfileDeliveredStatusInstructionSteps(props.languageText),
    [props.languageText],
  );

  const loggedInUserRole = useSelector(
    (state: RootState) => state.loginReducer.loggedInUserRole,
  );

  const initialRoleState: IRoleData<ProfileId> = {
    id: 0 as ProfileId,
    roleId: 0,
    roleText: "",
    noOfRespondents: 0,
  };
  const [editProfileId, setEditProfileId] = useState<ProfileId>(0 as ProfileId);
  const [selectedProfileIds, setSelectedProfileIds] = useState<
    Array<ProfileId>
  >([]); // Checkbox selected participant
  const [selectedParticipantRoleSettings, setSelectedParticipantRoleSettings] =
    useState<IRoleData<ProfileId>>(initialRoleState);
  const [profileAccountDetailsId, setProfileAccountDetailsId] =
    useState<ProfileId>(0 as ProfileId);
  // Preview states
  const [previewProfileId, setPreviewProfileId] = useState<ProfileId>(
    0 as ProfileId,
  );
  const [showGroupPreviewModal, setShowGroupPreviewModal] =
    useState<boolean>(false);
  const [isSelf, setIsSelf] = useState<boolean>(false);
  const [downloadProfileId, setDownloadProfileId] = useState<ProfileId>(
    0 as ProfileId,
  );
  const [isGroupDownloadOpen, setIsGroupDownloadOpen] =
    useState<boolean>(false);
  const [isMultipleDownloadOpen, setIsMultipleDownloadOpen] =
    useState<boolean>(false);

  // Edit Settings fn's
  const onSettingsClick = (id: ProfileId): void => {
    setEditProfileId(id);
  };

  const closeSettingsModal = (): void => {
    setEditProfileId(0 as ProfileId);
  };

  // Role settings modal fn's
  const onRoleClick = (participant: IEditActivityDeliveredProfile): void => {
    setSelectedParticipantRoleSettings({
      id: participant.id,
      roleId: participant.roleId,
      roleText: participant.roleText,
      noOfRespondents: participant.noOfRespondents,
    });
  };

  const closeRoleSettingsModal = (): void => {
    setSelectedParticipantRoleSettings(initialRoleState);
  };

  // Participant account details fn's
  const onProfileClick = (id: ProfileId): void => {
    setProfileAccountDetailsId(id);
  };

  const closeAccountDetailsModal = (): void => {
    setProfileAccountDetailsId(0 as ProfileId);
  };

  // Checkbox fn's
  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.checked) {
      // Select all participants
      const allParticipants = props.profileData.deliveredProfiles.map(
        (p) => p.id,
      );
      setSelectedProfileIds(allParticipants);
    } else {
      // Unselect all participants
      setSelectedProfileIds([]);
    }
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: ProfileId,
  ): void => {
    setSelectedProfileIds((prevSelectedParticipant) => {
      if (e.target.checked) {
        return [...prevSelectedParticipant, id];
      } else {
        return prevSelectedParticipant.filter((item) => item !== id);
      }
    });
  };

  // Preview Group Participants
  function onGroupPreviewClick(_: ProfileId, isSelf: boolean): void {
    if (selectedProfileIds.length > 0) {
      setShowGroupPreviewModal(true);
      setIsSelf(isSelf);
    }
  }

  const closeGroupPreview = (): void => {
    setShowGroupPreviewModal(false);
  };

  // Preview Individual Participant
  const onIndividualPreviewClick = (profileId: ProfileId): void => {
    setPreviewProfileId(profileId);
  };

  const closeIndividualPreview = (): void => {
    setPreviewProfileId(0 as ProfileId);
  };

  // Download Individual Participant
  const onIndividualDownloadClick = (profileId: ProfileId): void => {
    setDownloadProfileId(profileId);
  };

  const closeIndividualDownload = (): void => {
    setDownloadProfileId(0 as ProfileId);
  };

  // Download Group Participants
  const onGroupDownloadClick = (): void => {
    if (selectedProfileIds.length === 0) return;
    setIsGroupDownloadOpen(true);
  };

  const closeGroupDownload = (): void => {
    setIsGroupDownloadOpen(false);
  };

  // Download Multiple Participants
  const onMultipleDownloadClick = (): void => {
    if (selectedProfileIds.length === 0) return;
    setIsMultipleDownloadOpen(true);
  };

  const closeMultipleDownload = (): void => {
    setIsMultipleDownloadOpen(false);
  };

  // UnDeliver participants
  const handleUnDeliverClick = (): void => {
    if (selectedProfileIds.length === 0) return;

    unDeliverProfilesManually(props.id, selectedProfileIds, dispatch).then(
      (response) => {
        if (response) {
          dispatch(addToast("Undeliver successful") as AnyAction);
          props.refetchCompletedParticipants();
          props.refetchDeliveredParticipants();
          setSelectedProfileIds([]);
        }
      },
    );
  };

  let allParticipantsSelected = false;
  if (props.profileData.deliveredProfileCount > 0) {
    allParticipantsSelected =
      selectedProfileIds.length === props.profileData.deliveredProfiles.length;
  }

  return (
    <div className="rounded border mb-3 bg-white">
      <div
        id="editProfileDeliveredStatusHeader"
        className="p-3 pe-4 d-flex justify-content-between align-items-center flex-wrap bg-secondary-subtle"
      >
        <span className="fs-5 fw-bold text-secondary">
          <span className="badge fs-5 bg-dark pt-2 me-2 bg-opacity-25">
            {props.profileData.deliveredProfileCount}
          </span>
          {getLanguageValue(props.languageText, "Delivered")}
        </span>
        {props.profileData.deliveredProfileCount > 0 && (
          <>
            <div id="deliveredMassAction" className="d-flex align-items-center">
              <ParticipantProfileDropdown
                languageText={props.languageText}
                isGroup={true}
                isUnDeliver={isLoggedInRoleAdmin(loggedInUserRole)}
                handlePreviewClick={onGroupPreviewClick}
                handleDownloadClick={onMultipleDownloadClick}
                handleDownloadGroupClick={onGroupDownloadClick}
                handleDeliverOrUnDeliverClick={handleUnDeliverClick}
              />
              <Checkbox
                className="fs-3 ms-4"
                inputClass={clsx(
                  "border border-secondary-subtle",
                  !allParticipantsSelected && "bg-white border grey-border",
                )}
                value={allParticipantsSelected}
                handleCheckboxChange={handleSelectAll}
              />
            </div>

            {/* Instruction steps */}
            {props.completedStatusEditProfileGuideDone ? (
              <Instruction
                showHelpButton={false}
                targetElement="deliveredIndividualCheckbox"
                guidePage={GuidePages.Edit_Profile_Delivered_Status}
                instructionSteps={instructionSteps}
                skipPostGuideRequest={true}
                manualTrigger={props.isInstructionHelpClicked}
                handleExitGuide={props.handleExitGuide}
              />
            ) : null}
          </>
        )}
      </div>

      {props.profileData.deliveredProfileCount > 0 && (
        <div>
          {props.profileData.deliveredProfiles.map((profile) => {
            const isSelected = selectedProfileIds.includes(profile.id);
            return (
              <div key={profile.id} className="d-flex flex-wrap border-top p-4">
                <div
                  id="deliveredParticipantDetails"
                  className="col-lg-6 d-flex col-12 p-0"
                >
                  {/* Participant name */}
                  <div
                    className={clsx(
                      "w-100 m-auto fs-5 fw-bold",
                      !props.isActivityCancelled &&
                        !props.isActivityInvoiced &&
                        "cursor-pointer",
                    )}
                    role="button"
                    onClick={() =>
                      !props.isActivityCancelled &&
                      !props.isActivityInvoiced &&
                      onSettingsClick(profile.id)
                    }
                  >
                    <ReactTooltip
                      id={profile.id.toString()}
                      tooltipText={profile.name}
                    >
                      {profile.name}
                    </ReactTooltip>
                    {(profile.noOfRespondents ||
                      profile.roleId ||
                      profile.roleText) && (
                      <div className="fs-6 fw-normal">
                        {profile.noOfRespondents ? profile.noOfRespondents : ""}{" "}
                        {profile.roleId
                          ? getLanguageValue(
                              props.languageText,
                              GetTypeOfRoleName[profile.roleId],
                            )
                          : profile.roleText
                            ? profile.roleText
                            : getLanguageValue(
                                props.languageText,
                                "Respondents",
                              )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 d-flex col-12 p-0 d-flex justify-content-between">
                  <div className="d-flex py-1">
                    {/* No of respondents */}
                    <EpNoOfRespondents
                      id="deliveredNoOfRespondents"
                      profileId={profile.id}
                      noOfRespondents={profile.noOfRespondents}
                      respondentsInvited={profile.respondentsInvited}
                      respondentsAnswered={profile.respondentsAnswered}
                      isActivityCancelled={props.isActivityCancelled}
                      isActivityInvoiced={props.isActivityInvoiced}
                      onNoOfRespondentsClick={onProfileClick}
                    />
                  </div>

                  <div className="d-flex py-1 justify-content-end align-items-center">
                    {/* Presentation icon */}
                    {profile.presentationId && (
                      <div className={clsx("btn bg-success-subtle me-4")}>
                        <span className="d-none d-md-inline text-truncate">
                          {profile.presentationDate
                            ? getDateAndShortMonthFormat(
                                profile.presentationDate,
                              )
                            : "Invalid date"}
                        </span>
                        <i className="bi bi-easel-fill fs-5 ms-2"></i>
                      </div>
                    )}

                    {/* Profile dropdown */}
                    <ParticipantProfileDropdown
                      languageText={props.languageText}
                      id="deliveredIndividualDropdown"
                      isDeliver={false}
                      profileId={profile.id}
                      handlePreviewClick={onIndividualPreviewClick}
                      handleDownloadClick={onIndividualDownloadClick}
                    />

                    {/* Checkbox icon */}
                    <div className="mt-1">
                      <Checkbox
                        id="deliveredIndividualCheckbox"
                        className="ms-4 fs-3"
                        inputClass={clsx(
                          "border border-secondary-subtle",
                          !isSelected && "bg-white border grey-border",
                        )}
                        value={isSelected}
                        handleCheckboxChange={(e) =>
                          handleCheckboxChange(e, profile.id)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {/* Edit Settings Modal */}
      {editProfileId > 0 && (
        <EditProfileParticipantModal
          languageText={props.languageText}
          profileId={editProfileId}
          languageCode={props.cultureValue}
          languages={props.languages}
          messageTranslation={props.messageTranslation}
          status={ProfileStatus.Delivery}
          handleCloseClick={closeSettingsModal}
          fetchActivity={props.fetchActivity}
          refetchParticipants={props.refetchDeliveredParticipants}
          handleUnPublishProfile={props.handleUnPublishProfile}
          handleTransferProfile={props.handleTransferProfile}
        />
      )}

      {/* Role Settings Modal */}
      {selectedParticipantRoleSettings.id > 0 && (
        <RoleSettingsModalWithEditOption
          languageText={props.languageText}
          profileId={selectedParticipantRoleSettings.id}
          status={ProfileStatus.Delivery}
          roleId={selectedParticipantRoleSettings.roleId}
          roleText={selectedParticipantRoleSettings.roleText}
          noOfRespondents={selectedParticipantRoleSettings.noOfRespondents}
          refetchParticipants={props.refetchDeliveredParticipants}
          handleCloseClick={closeRoleSettingsModal}
        />
      )}

      {/* Participant account details Modal */}
      {profileAccountDetailsId > 0 && (
        <ParticipantAccountDetailsModal
          languageText={props.languageText}
          languages={props.languages}
          handleCloseClick={closeAccountDetailsModal}
          profileId={profileAccountDetailsId}
        />
      )}

      {/* Preview Group Participants */}
      {showGroupPreviewModal && (
        <GroupPreviewModal
          id={props.id}
          languageText={props.languageText}
          profileData={props.profileData}
          isSelf={isSelf}
          selectedProfileIds={selectedProfileIds}
          handleCloseClick={closeGroupPreview}
        />
      )}

      {/* Preview Individual Participant */}
      {previewProfileId > 0 && (
        <IndividualPreviewModal
          languageText={props.languageText}
          profileId={previewProfileId}
          handleCloseClick={closeIndividualPreview}
        />
      )}

      {/* Download Participant Profile */}
      {downloadProfileId > 0 && (
        <IndividualDownloadModal
          languageText={props.languageText}
          profileId={downloadProfileId}
          handleCloseClick={closeIndividualDownload}
          profileParticipantName={
            props.profileData.deliveredProfiles.find(
              (par) => par.id === downloadProfileId,
            )?.name ?? ""
          }
        />
      )}

      {/* Download Group Participant */}
      {isGroupDownloadOpen && (
        <GroupDownloadModal
          id={props.id}
          languageText={props.languageText}
          name={props.profileData.name}
          profileIds={selectedProfileIds}
          handleCloseClick={closeGroupDownload}
          profileData={props.profileData}
        />
      )}
      {isMultipleDownloadOpen && (
        <MultipleParticipantDownloadModal
          languageText={props.languageText}
          profileIds={selectedProfileIds}
          handleCloseClick={closeMultipleDownload}
        />
      )}
    </div>
  );
};
