import React from "react";

interface ITabContentProps {
  id: string;
  activeTab: string;
  children: JSX.Element;
}

export const TabContent = (props: ITabContentProps) => {
  return props.activeTab === props.id ? (
    <div className="TabContent">{props.children}</div>
  ) : null;
};
