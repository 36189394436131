import React from "react";

import { useActivityList } from "./hooks";
import { getLanguageValue } from "@app/commonUtils/languageFunctionsHelper";
import { ReactTable } from "@app/components/reactTable/reactTable";
import { CustomModal } from "@app/components/modals/customModal";
import { ActivityFilters } from "@app/components/activityFilters/filters";
import {
  isLoggedInRoleAdmin,
  isLoggedInRoleFacilitator,
} from "@app/commonUtils/roleHelper";

export interface IProfileListProps {
  clientIdFromAccount?: number;
}

export const ActivityList = (props: IProfileListProps): React.JSX.Element => {
  const {
    languageText,
    loggedInUserRole,
    tableHeader,
    data,
    totalCount,
    pageCount,
    filter,
    sorting,
    pageLimit,
    pageSelected,
    defaultClientMessageModal,
    initialFilterObject,
    clearFilterOptions,
    showClearFiltersButton,
    setFilter,
    setSorting,
    setPageLimit,
    setPageSelected,
    addProfileClick,
    handleCloseModal,
    navigateToProfilesClick,
    handleUpdatedFilterOptions,
    handleClearCustomFilters,
  } = useActivityList(props);

  return (
    <>
      <ReactTable
        wrapperClass={!props.clientIdFromAccount ? "p-2 pt-4 p-md-4" : ""}
        headerText={getLanguageValue(languageText, "Activities")}
        searchPlaceholder={getLanguageValue(languageText, "Search")}
        buttonText={
          isLoggedInRoleFacilitator(loggedInUserRole) &&
          !props.clientIdFromAccount
            ? getLanguageValue(languageText, "Add Profile")
            : ""
        }
        tableHeader={tableHeader}
        data={data}
        minHeight={props.clientIdFromAccount ? "" : ""}
        totalCount={totalCount}
        pageCount={pageCount}
        filter={filter}
        sorting={sorting}
        pageLimit={pageLimit}
        pageSelected={pageSelected}
        storePageConfiguredInfo={props.clientIdFromAccount ? false : true}
        showClearFiltersButton={showClearFiltersButton}
        handleClearCustomFilters={handleClearCustomFilters}
        handleButtonClick={addProfileClick}
        handleSecondButtonClick={navigateToProfilesClick}
        setFilter={setFilter}
        setSorting={setSorting}
        setPageLimit={setPageLimit}
        setPageSelected={setPageSelected}
        customFilters={
          isLoggedInRoleAdmin(loggedInUserRole) ? (
            <ActivityFilters
              initialFilterObject={initialFilterObject}
              clearFilterOptions={clearFilterOptions}
              handleUpdatedFilterOptions={handleUpdatedFilterOptions}
              languageText={languageText}
            />
          ) : undefined
        }
      />

      {defaultClientMessageModal && (
        <CustomModal
          headerText={getLanguageValue(languageText, "No default account")}
          bodyText={getLanguageValue(
            languageText,
            "Profile cannot be created without default client. Please select default client",
          )}
          submitButtonText={getLanguageValue(languageText, "Ok")}
          handleCancelClick={handleCloseModal}
          handleSubmitClick={handleCloseModal}
        />
      )}
    </>
  );
};
