import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import PhoneInput from "react-phone-number-input";

import { CountryCode, E164Number } from "libphonenumber-js/types";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../commonUtils/languageFunctionsHelper";
import {
  getCountryCode,
  ICountryLocateResponse,
} from "@app/commonUtils/countryHelper";
import "@app/css/components/custom-phone-input.scss";
import { classNames } from "@app/containers/utils";

interface IPhoneNumberInputProps {
  value: string;
  label?: string;
  name?: string;
  languageText: ITranslationObject;
  errorFieldClassName?: string;
  placeholder?: string;
  phoneNumberError?: boolean;
  phoneNumberErrorMsg?: string;
  onChange: (event: E164Number) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  narrow?: boolean;
}

export const PhoneNumberInput: React.FC<IPhoneNumberInputProps> = (props) => {
  const dispatch = useDispatch();
  const [countryCode, setCountryCode] = useState<string>("");

  useEffect(() => {
    getCountryCode(dispatch).then((response: ICountryLocateResponse) => {
      setCountryCode(response ? response.isoCode : "");
    });
  }, []);

  const containerClasses = classNames({
    "mb-3": !props.narrow,
  });

  return (
    <div className={containerClasses}>
      {props.label && <label className="mb-1">{props.label}</label>}
      <PhoneInput
        defaultCountry={(countryCode as CountryCode) || "GB"}
        name={name ?? ""}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        onBlur={props.onBlur}
        className="form-control"
        disabled={props.disabled}
      />
      {(props.phoneNumberError || props.phoneNumberErrorMsg) && (
        <p className={clsx("text-danger", props.errorFieldClassName)}>
          {props.phoneNumberErrorMsg
            ? props.phoneNumberErrorMsg
            : getLanguageValue(props.languageText, "Invalid phonenumber")}
        </p>
      )}
    </div>
  );
};
