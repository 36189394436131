import React, { useEffect, useRef, useState } from "react";

import { DeleteModal } from "../../components/modals/deleteModal";
import { ReactTable } from "../../components/reactTable/reactTable";
import { AddEditPlan } from "./addEditPlan";
import { getLanguageValue } from "../../commonUtils/languageFunctionsHelper";
import { useDispatch, useSelector } from "react-redux";
import { CustomActionsCell } from "@app/components/reactTable/customActionsCell";
import { ColumnDef } from "@tanstack/react-table";
import { BooleanCell } from "@app/components/reactTable/booleanCell";
import { RootState } from "@app/store/configureStore";
import { Plan, PlanType } from "@app/types";
import { useApiEndpoints } from "@app/api/end-points";
import { ITableCommonParams } from "../commonInterfaces";

export const PlanList = (): JSX.Element => {
  const dispatch = useDispatch();
  const api = useApiEndpoints(dispatch);
  const isMounted = useRef(false);

  // Handling Language
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const [data, setData] = useState<Array<Plan>>([]);
  const [filter, setFilter] = useState<string>("");
  const [sorting, setSorting] = useState<string>("");
  const [editRowId, setEditRowId] = useState<number | undefined>(undefined);
  const [deleteRowId, setDeleteRowId] = useState<number | undefined>(undefined);
  const [selectedActionRowName, setSelectedActionRowName] =
    useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0); // total_data_count
  const [pageLimit, setPageLimit] = useState<number>(10); // items_per_page
  const [pageCount, setPageCount] = useState<number>(0); // total_page_count
  const [pageSelected, setPageSelected] = useState<number>(0); // page_number_selected
  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState<boolean>(false);

  const fetchPlans = (skipCount: number): void => {
    const params: ITableCommonParams = {
      filter: filter,
      sorting: sorting,
      skipCount: skipCount,
      maxResultCount: pageLimit,
    };

    api.getPlans(params).then((response) => {
      const pageSize = Math.ceil(response.totalCount / pageLimit);
      setData(response.items.slice());
      setTotalCount(response.totalCount);
      setPageCount(pageSize);
    });
  };

  useEffect(() => {
    fetchPlans(0);
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      const skipCount = pageLimit * pageSelected;
      fetchPlans(skipCount);
    }
  }, [pageSelected, sorting]);

  useEffect(() => {
    if (isMounted.current) {
      fetchPlans(0);
    }
    if (!isMounted.current) isMounted.current = true;
  }, [filter, pageLimit]);

  const handleEditClick = (id: number): void => {
    setEditRowId(id);
    setIsAddEditModalOpen(true);
  };

  const handleDeleteClick = (id: number, name: string): void => {
    setDeleteRowId(id);
    setSelectedActionRowName(name);
    setIsAddEditModalOpen(false);
    fetchPlans(0);
  };

  const handleDeleteSubscription = (): void => {
    if (!deleteRowId) {
      return;
    }
    api.deletePlan(deleteRowId).then(() => {
      setDeleteRowId(undefined);
      setSelectedActionRowName("");
      fetchPlans(0);
    });
  };

  const handleAddSubscription = (): void => {
    setEditRowId(undefined);
    setIsAddEditModalOpen(true);
  };

  const closeDeleteModal = (): void => {
    setDeleteRowId(undefined);
  };

  const closeAddEditModal = (): void => {
    setIsAddEditModalOpen(false);
    setEditRowId(undefined);
  };

  const tableHeader: Array<ColumnDef<Plan, unknown>> = [
    {
      header: "",
      accessorKey: "actions",
      enableSorting: false,
      cell: ({ ...props }) => (
        <CustomActionsCell
          editOption
          deleteOption
          languageText={languageText}
          id={props.row.original.id}
          name={props.row.original.name}
          handleEditClick={handleEditClick}
          handleDeleteClick={handleDeleteClick}
        />
      ),
    },
    {
      header: getLanguageValue(languageText, "ID"),
      accessorKey: "id",
    },
    {
      header: getLanguageValue(languageText, "Name"),
      accessorKey: "name",
    },
    {
      header: getLanguageValue(languageText, "Type"),
      accessorKey: "type",
      cell: ({ ...props }) => <span>{PlanType[props.row.original.type]}</span>,
    },
    {
      header: getLanguageValue(languageText, "Enabled"),
      accessorKey: "enabled",
      cell: ({ ...props }) => (
        <BooleanCell
          languageText={languageText}
          boolValue={props.row.original.enabled}
        />
      ),
    },
  ];

  return (
    <>
      <ReactTable
        wrapperClass="p-2 pt-4 p-md-4"
        headerText={getLanguageValue(languageText, "Subscriptions")}
        searchPlaceholder={getLanguageValue(
          languageText,
          "Search Subscription",
        )}
        buttonText={getLanguageValue(languageText, "Add Subscription")}
        tableHeader={tableHeader}
        data={data}
        totalCount={totalCount}
        pageCount={pageCount}
        filter={filter}
        pageLimit={pageLimit}
        pageSelected={pageSelected}
        handleButtonClick={handleAddSubscription}
        setFilter={setFilter}
        setSorting={setSorting}
        setPageLimit={setPageLimit}
        setPageSelected={setPageSelected}
      />

      {deleteRowId && (
        <DeleteModal
          languageText={languageText}
          headerText={getLanguageValue(languageText, "Subscription")}
          bodyText={selectedActionRowName}
          handleDeleteClick={handleDeleteSubscription}
          closeDeleteModal={closeDeleteModal}
        />
      )}

      {isAddEditModalOpen && (
        <AddEditPlan
          planId={editRowId || 0}
          languageText={languageText}
          onSubmit={(plan) => {
            const promise = plan.id
              ? api.updatePlan(plan.id, plan)
              : api.createPlan(plan);

            return promise.then(() => {
              setIsAddEditModalOpen(false);
              setEditRowId(undefined);
              fetchPlans(0);
            });
          }}
          onClose={closeAddEditModal}
        />
      )}
    </>
  );
};
