import React, { useState } from "react";
import { E164Number } from "libphonenumber-js/types";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";

import { Input } from "../../../../components/formComponents/input";
import { TextArea } from "../../../../components/formComponents/textArea";
import { PhoneNumberInput } from "../../../employeeList/addEmployeeModal/phoneNumberInput";
import { ToggleCardCheckbox } from "../../../../components/formComponents/toggleCardCheckbox";
import {
  DropdownSelect,
  IDropdownList,
  IDropdownSelectedItem,
} from "@app/components/formComponents/dropdownSelect";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../commonUtils/languageFunctionsHelper";
import { IFocusInputClientDetails } from "../addEditAccountModalHooks";
import { AccountInputs } from "../clientEnums";
import { Checkbox } from "@app/components/formComponents/checkbox";
import { DeleteModal } from "@app/components/modals/deleteModal";
import { addToast, setSpinner } from "@app/containers/actions";
import { RootState } from "@app/store/configureStore";
import {
  deleteFacilitatorClient,
  updateFacilitatorDefaultClient,
} from "@app/containers/usersProfile/account/actions";
import { deleteClient } from "../../actions";
import { IUpdateFacilitatorDefaultClientBody } from "@app/containers/usersProfile/account/hooks";
import {
  isLoggedInRoleAdmin,
  isLoggedInRoleFacilitator,
} from "@app/commonUtils/roleHelper";
import { IClient } from "@app/containers/commonInterfaces";

interface IClientDetails {
  languageText: ITranslationObject;
  clientId: number;
  accountInfo: IClient;
  focusInput: IFocusInputClientDetails;
  countries: IDropdownList[];
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlurEvent: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleCountrySelect: (selectedItem: IDropdownSelectedItem) => void;
  handleTextAreaChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleTextAreaBlurEvent: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  handlePhoneInputChange: (value: E164Number) => void;
  handleDifferentInvoiceAddress: () => void;
  refreshClientList: () => void;
  closeClientModal: () => void;
}

/** @deprecated @see ClientDetails instead */
export const DeprecatedClientDetails = (props: IClientDetails) => {
  const dispatch = useDispatch();

  const loggedInUserRole = useSelector(
    (state: RootState) => state.loginReducer.loggedInUserRole,
  );
  const currentClient = useSelector(
    (state: RootState) => state.loginReducer.currentClient,
  );
  const userId = useSelector((state: RootState) => state.loginReducer.userId);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const handleDefaultClientChange = (): void => {
    dispatch(setSpinner(true));
    const body: IUpdateFacilitatorDefaultClientBody = {
      userId: userId,
      clientId: props.clientId,
      isDefaultClient: true,
    };
    updateFacilitatorDefaultClient(body, dispatch)
      .then((response) => {
        if (response) {
          dispatch(addToast("Changes saved successfully") as AnyAction);
          props.refreshClientList();
        }
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  const handleDeleteBtnClick = (): void => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = (): void => {
    setShowDeleteModal(false);
  };

  const handleDeleteAccount = (): void => {
    dispatch(setSpinner(true));
    if (isLoggedInRoleAdmin(loggedInUserRole)) {
      deleteClient(props.clientId, dispatch)
        .then((response) => {
          if (response) {
            props.closeClientModal();
            props.refreshClientList();
          }
        })
        .finally(() => dispatch(setSpinner(false)));
    } else {
      deleteFacilitatorClient(props.clientId, dispatch)
        .then((response) => {
          if (response) {
            props.closeClientModal();
            props.refreshClientList();
          }
        })
        .finally(() => dispatch(setSpinner(false)));
    }
  };

  const showDeleteBtn =
    props.clientId &&
    !props.accountInfo.isDefaultClient &&
    currentClient.currentClientId !== props.clientId;

  return (
    <>
      {props.clientId > 0 && (
        <div className="d-flex align-items-center justify-content-end gap-3 mb-3">
          {!isLoggedInRoleAdmin(loggedInUserRole) && (
            <Checkbox
              label={getLanguageValue(
                props.languageText,
                "Set as default client",
              )}
              value={props.accountInfo.isDefaultClient}
              disable={props.accountInfo.isDefaultClient}
              handleCheckboxChange={handleDefaultClientChange}
            />
          )}
          {showDeleteBtn && (
            <div className="d-flex justify-content-end text-danger">
              <span
                className="d-inline"
                role="button"
                onClick={handleDeleteBtnClick}
              >
                <i className="bi bi-trash3 me-1" />
                {getLanguageValue(props.languageText, "Delete")}
              </span>
            </div>
          )}
        </div>
      )}

      <form>
        <div>
          <div className="row">
            <div className="col-12 col-md-3">
              <Input
                name={AccountInputs.name}
                label={getLanguageValue(props.languageText, "Company Name")}
                placeholder={getLanguageValue(
                  props.languageText,
                  "Company Name",
                )}
                value={props.accountInfo.name}
                errorMessage={props.focusInput.name.errorMessage}
                handleBlurEvent={props.handleBlurEvent}
                handleInputChange={props.handleInputChange}
                required
              />
            </div>
            <div className="col-12 col-md-3">
              <Input
                name={AccountInputs.businessIdentification}
                label={`${getLanguageValue(props.languageText, "Business identification")} #`}
                placeholder={getLanguageValue(
                  props.languageText,
                  "Business identification",
                )}
                value={props.accountInfo.businessIdentification}
                handleInputChange={props.handleInputChange}
              />
            </div>
            <div className="col-12 col-sm-6">
              <Input
                name={AccountInputs.invoiceReference}
                label={getLanguageValue(
                  props.languageText,
                  "Invoice Reference",
                )}
                placeholder={getLanguageValue(
                  props.languageText,
                  "Invoice Reference",
                )}
                value={props.accountInfo.invoiceReference}
                handleInputChange={props.handleInputChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-9">
              <Input
                name={AccountInputs.email}
                label={getLanguageValue(props.languageText, "Email")}
                placeholder={getLanguageValue(props.languageText, "Email")}
                value={props.accountInfo.email}
                errorMessage={props.focusInput.email.errorMessage}
                handleBlurEvent={props.handleBlurEvent}
                handleInputChange={props.handleInputChange}
              />
            </div>
            <div className="col-12 col-md-3">
              <PhoneNumberInput
                languageText={props.languageText}
                name={AccountInputs.telephone}
                label={getLanguageValue(props.languageText, "Phone")}
                placeholder="+123 45 67 89"
                value={(props.accountInfo.telephone as E164Number) || ""}
                phoneNumberErrorMsg={props.focusInput.telephone.errorMessage}
                onChange={props.handlePhoneInputChange}
                onBlur={props.handleBlurEvent}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <TextArea
                name={AccountInputs.address}
                label={getLanguageValue(props.languageText, "Address")}
                placeholder={getLanguageValue(props.languageText, "Address")}
                value={props.accountInfo.address}
                focusInput={props.focusInput.address}
                onChange={props.handleTextAreaChange}
                handleBlurEvent={props.handleTextAreaBlurEvent}
                checkMarkNotRequired={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-3">
              <Input
                name={AccountInputs.city}
                label={getLanguageValue(props.languageText, "City")}
                placeholder={getLanguageValue(props.languageText, "City")}
                value={props.accountInfo.city}
                errorMessage={props.focusInput.city.errorMessage}
                handleBlurEvent={props.handleBlurEvent}
                handleInputChange={props.handleInputChange}
              />
            </div>
            <div className="col-12 col-md-3">
              <Input
                name={AccountInputs.zip}
                label={getLanguageValue(props.languageText, "Zip Code")}
                placeholder={getLanguageValue(props.languageText, "Zip Code")}
                type="number"
                value={props.accountInfo.zip}
                errorMessage={props.focusInput.zip.errorMessage}
                handleBlurEvent={props.handleBlurEvent}
                handleInputChange={props.handleInputChange}
              />
            </div>
            <div className="col-12 col-md-3">
              <DropdownSelect
                name={AccountInputs.countryId}
                label={getLanguageValue(props.languageText, "Country")}
                defaultLabel={getLanguageValue(
                  props.languageText,
                  "Select Country",
                )}
                list={props.countries}
                value={String(props.accountInfo.countryId)}
                focusInput={props.focusInput.countryId}
                handleDropdownSelect={props.handleCountrySelect}
                searchOption
                required
              />
            </div>
            <div className="col-12 col-md-3">
              <Input
                name={AccountInputs.euvatid}
                label={getLanguageValue(
                  props.languageText,
                  "EU VAT ID (optional)",
                )}
                placeholder={getLanguageValue(props.languageText, "EU VAT ID")}
                value={props.accountInfo.euvatid}
                handleInputChange={props.handleInputChange}
              />
            </div>
          </div>

          <ToggleCardCheckbox
            header={getLanguageValue(
              props.languageText,
              "Different invoice address",
            )}
            description={getLanguageValue(
              props.languageText,
              "Add a separate invoice address",
            )}
            containerClass="col-12 col-md-5 mb-3"
            checked={props.accountInfo.differentInvoiceAddress}
            handleClick={props.handleDifferentInvoiceAddress}
          />

          {props.accountInfo.differentInvoiceAddress && (
            <>
              <div className="row">
                <div className="col-12 col-md-12">
                  <Input
                    name={AccountInputs.invoiceCompanyName}
                    label={getLanguageValue(props.languageText, "Company Name")}
                    placeholder={getLanguageValue(
                      props.languageText,
                      "Company Name",
                    )}
                    value={props.accountInfo.invoiceCompanyName}
                    errorMessage={
                      props.focusInput.invoiceCompanyName.errorMessage
                    }
                    handleBlurEvent={props.handleBlurEvent}
                    handleInputChange={props.handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-12">
                  <TextArea
                    name={AccountInputs.invoiceAddress}
                    label={getLanguageValue(props.languageText, "Address")}
                    placeholder={getLanguageValue(
                      props.languageText,
                      "Address",
                    )}
                    value={props.accountInfo.invoiceAddress}
                    focusInput={props.focusInput.invoiceAddress}
                    handleBlurEvent={props.handleTextAreaBlurEvent}
                    onChange={props.handleTextAreaChange}
                    checkMarkNotRequired={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-3">
                  <Input
                    name={AccountInputs.invoiceCity}
                    label={getLanguageValue(props.languageText, "City")}
                    placeholder={getLanguageValue(props.languageText, "City")}
                    value={props.accountInfo.invoiceCity}
                    errorMessage={props.focusInput.invoiceCity.errorMessage}
                    handleBlurEvent={props.handleBlurEvent}
                    handleInputChange={props.handleInputChange}
                  />
                </div>
                <div className="col-12 col-md-3">
                  <Input
                    name={AccountInputs.invoiceZip}
                    label={getLanguageValue(props.languageText, "Zip Code")}
                    placeholder={getLanguageValue(
                      props.languageText,
                      "Zip Code",
                    )}
                    type="number"
                    value={props.accountInfo.invoiceZip}
                    errorMessage={props.focusInput.invoiceZip.errorMessage}
                    handleBlurEvent={props.handleBlurEvent}
                    handleInputChange={props.handleInputChange}
                  />
                </div>
                <div className="col-12 col-md-3">
                  <DropdownSelect
                    name={AccountInputs.invoiceCountryId}
                    label={getLanguageValue(props.languageText, "Country")}
                    defaultLabel={getLanguageValue(
                      props.languageText,
                      "Select Country",
                    )}
                    list={props.countries}
                    value={String(props.accountInfo.invoiceCountryId)}
                    focusInput={props.focusInput.invoiceCountryId}
                    handleDropdownSelect={props.handleCountrySelect}
                    searchOption
                    required
                  />
                </div>
                <div className="col-12 col-md-3">
                  <Input
                    name={AccountInputs.invoiceEUVATID}
                    label={getLanguageValue(
                      props.languageText,
                      "EU VAT ID (optional)",
                    )}
                    placeholder={getLanguageValue(
                      props.languageText,
                      "EU VAT ID",
                    )}
                    value={props.accountInfo.invoiceEUVATID}
                    handleInputChange={props.handleInputChange}
                  />
                </div>
              </div>
            </>
          )}

          {isLoggedInRoleAdmin(loggedInUserRole) ||
            (isLoggedInRoleFacilitator(loggedInUserRole) && (
              <div className="mb-3">
                <Checkbox
                  name={AccountInputs.enableParticipantCreateProfile}
                  label={getLanguageValue(
                    props.languageText,
                    "Can participants create profiles",
                  )}
                  value={props.accountInfo.enableParticipantCreateProfile}
                  handleCheckboxChange={props.handleCheckboxChange}
                />
              </div>
            ))}
          {isLoggedInRoleAdmin(loggedInUserRole) && (
            <>
              <Input
                name={AccountInputs.sourceType}
                label={getLanguageValue(props.languageText, "Source type")}
                placeholder={getLanguageValue(
                  props.languageText,
                  "Source type",
                )}
                value={props.accountInfo.sourceType}
                handleInputChange={props.handleInputChange}
              />

              <Input
                name={AccountInputs.sourceAddress}
                label={getLanguageValue(props.languageText, "Source address")}
                placeholder={getLanguageValue(
                  props.languageText,
                  "Source address",
                )}
                value={props.accountInfo.sourceAddress}
                handleInputChange={props.handleInputChange}
              />
            </>
          )}
        </div>
      </form>

      {showDeleteModal && (
        <DeleteModal
          languageText={props.languageText}
          headerText={getLanguageValue(props.languageText, "Account")}
          bodyText={props.accountInfo.name}
          handleDeleteClick={handleDeleteAccount}
          closeDeleteModal={closeDeleteModal}
        />
      )}
    </>
  );
};
