import * as React from "react";
import { Input, KeyCode } from "@app/components/formComponents/input";

type DigitCodeInputProps = {
  /** The input field will automatically submit itself when all required digits are filled. */
  handleSubmit: () => unknown;
  handleChange: (value: string) => unknown;

  /** Describes how many digits the input requires. */
  length: number;
  value: string;
};

/**
 * Represents an input field that requires a fixed number of digits.
 */
export const DigitCodeInput: React.FC<DigitCodeInputProps> = (props) => {
  const digitRefs = new Array(props.length).fill(0).map(() => {
    return React.useRef<HTMLInputElement>(null);
  });
  const enableDigitAtIndex = Math.min(props.length, props.value.length);

  React.useEffect(() => {
    if (enableDigitAtIndex === props.length) {
      props.handleSubmit();
      return;
    }
    digitRefs[enableDigitAtIndex].current?.focus();
  }, [enableDigitAtIndex]);

  return (
    <form onSubmit={props.handleSubmit} className="form w-100">
      <div className="d-flex gap-2">
        {digitRefs.map((ref, index) => {
          return (
            <Input
              key={index}
              type="number"
              handleInputChange={(event) => {
                const value = event.target.value.trim();

                // this looks like a paste event.
                if (index === 0 && value.length === props.length) {
                  props.handleChange(value);
                  return;
                }

                const next = props.value.slice(0, index) + value.charAt(0);
                props.handleChange(next);
              }}
              handleKeyDown={(event) => {
                if (event.code === KeyCode.Backspace) {
                  const next = props.value.slice(0, Math.max(0, index - 1));
                  props.handleChange(next);
                }
              }}
              value={props.value.charAt(index)}
              inputRef={ref}
              placeholder=""
              inputClass="text-center"
              disable={index !== enableDigitAtIndex}
              requireLeadingZero={true}
            />
          );
        })}
      </div>
    </form>
  );
};
