import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ColumnDef } from "@tanstack/react-table";

import { CustomActionsCell } from "../../components/reactTable/customActionsCell";
import { getLanguageValue } from "../../commonUtils/languageFunctionsHelper";
import { getProfileDescriptionList, deleteProfileDescription } from "./actions";
import { RootState } from "@app/store/configureStore";
import { addToast, setSpinner } from "../actions";
import { IProfileDescriptionData } from "./interface";

export const useProfileDescription = () => {
  const dispatch = useDispatch();
  const isMounted = useRef(false);

  // Handling Language
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const [data, setdata] = useState<IProfileDescriptionData[]>([]);
  const [editedRowId, setEditedRowId] = useState<number>(0);
  const [deleteRowId, setDeleteRowId] = useState<number>(0);
  const [selectedActionRowName, setSelectedActionRowName] =
    useState<string>("");
  const [filter, setFilter] = useState<string>("");
  const [sorting, setSorting] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0); // total_data_count
  const [pageLimit, setPageLimit] = useState<number>(10); // items_per_page
  const [pageCount, setPageCount] = useState<number>(0); // total_page_count
  const [pageSelected, setPageSelected] = useState<number>(0); // page_number_selected
  const [openProfileDescriptionModal, setOpenProfileDescriptionModal] =
    useState<boolean>(false); // Add/Edit Profile Description popup
  const [profileDescriptionsUpdated, setProfileDescriptionsUpdated] =
    useState<boolean>(false); // This flag is used to re-reder the profile descriptions data on requirement

  useEffect(() => {
    if (isMounted.current) {
      const skipCount = pageLimit * pageSelected;
      fetchProfileDescription(skipCount);
    }
  }, [pageSelected, sorting]);

  useEffect(() => {
    setPageSelected(0);
    fetchProfileDescription(0);
    if (!isMounted.current) isMounted.current = true;
  }, [filter, pageLimit]);

  useEffect(() => {
    if (profileDescriptionsUpdated) {
      const skipCount = pageLimit * pageSelected;
      fetchProfileDescription(skipCount);
    }
  }, [profileDescriptionsUpdated]);

  const fetchProfileDescription = (skipCount: number): void => {
    dispatch(setSpinner(true));
    getProfileDescriptionList(filter, sorting, pageLimit, skipCount, dispatch)
      .then((response) => {
        if (response) {
          const pageSize = Math.ceil(response.totalCount / pageLimit);
          setdata(response.items);
          setTotalCount(response.totalCount);
          setPageCount(pageSize);
          if (profileDescriptionsUpdated) setProfileDescriptionsUpdated(false);
        }
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  const addProfileDecriptionClick = (): void => {
    setOpenProfileDescriptionModal(true);
  };

  const refetchProfileDescriptionList = (): void =>
    setProfileDescriptionsUpdated(true);

  const closeProfileDecriptionModal = (): void => {
    if (editedRowId) {
      setEditedRowId(0);
    }
    setOpenProfileDescriptionModal(false);
  };

  const handleEditClick = (id: number): void => {
    setEditedRowId(id);
  };

  const handleDeleteClick = (id: number, name: string): void => {
    setDeleteRowId(id);
    setSelectedActionRowName(name);
  };

  const handleDeleteProfileDecription = (): void => {
    deleteProfileDescription(deleteRowId, dispatch).then((response) => {
      if (response) {
        setDeleteRowId(0);
        setSelectedActionRowName("");
        setProfileDescriptionsUpdated(true);
        dispatch(addToast("Deleted successfully") as AnyAction);
      }
    });
  };

  const closeDeleteModal = (): void => setDeleteRowId(0);

  const tableHeader: ColumnDef<IProfileDescriptionData, any>[] = [
    {
      header: "",
      accessorKey: "actions",
      enableSorting: false,
      cell: ({ ...props }) => (
        <CustomActionsCell
          editOption
          deleteOption
          languageText={languageText}
          id={props.row.original.id}
          name={props.row.original.title}
          handleEditClick={handleEditClick}
          handleDeleteClick={handleDeleteClick}
        />
      ),
    },
    {
      header: getLanguageValue(languageText, "ID"),
      accessorKey: "id",
    },
    {
      header: getLanguageValue(languageText, "Directiveness"),
      accessorKey: "directiveness",
    },
    {
      header: getLanguageValue(languageText, "Affiliation"),
      accessorKey: "affiliation",
    },
    {
      header: getLanguageValue(languageText, "Title"),
      accessorKey: "title",
    },
    {
      header: getLanguageValue(languageText, "Culture"),
      accessorKey: "language",
    },
  ];

  return {
    languageText,
    tableHeader,
    data,
    totalCount,
    pageCount,
    filter,
    pageLimit,
    pageSelected,
    openProfileDescriptionModal,
    editedRowId,
    deleteRowId,
    selectedActionRowName,
    setFilter,
    setSorting,
    setPageLimit,
    setPageSelected,
    addProfileDecriptionClick,
    refetchProfileDescriptionList,
    closeProfileDecriptionModal,
    handleDeleteProfileDecription,
    closeDeleteModal,
  };
};
