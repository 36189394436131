import { Respondent } from "./api/end-points";
import {
  ActivityStatus,
  ProfileStatus,
  TypeOfRole,
} from "./containers/activityList/activityEnums";
import { ActivityId, ProfileId, UserId } from "./containers/reducer";

export type Map<T> = { [key: string]: T };

export enum ReportTypes {
  SELF = "SELF",
  OTHER = "OTHER",
  BOTH = "BOTH",
}

export type ReportType =
  | ReportTypes.SELF
  | ReportTypes.OTHER
  | ReportTypes.BOTH;

export type Paged<T> = {
  totalCount: number;
  items: ReadonlyArray<T>;
};

/** @deprecated Use 'Paged<T>' instead. */
export type ApiResult<T> = Paged<T>;

export interface ValidationErrorInfo {
  message: string;
  members: string[];
}

//TODO: Joakim - Naming here is weird - find a better name.
export interface ErrorObject {
  code: number;
  message: string;
  details: string;
  validationErrors: ValidationErrorInfo[];
}

export type ApiResponse<T> =
  | { success: true; result: T }
  | { success: false; error: ErrorObject | undefined };

export interface CheckValidProfileOrRespondentLink {
  isProfile: boolean;
  isRespondent: boolean;
  languageId: number;
  languageName: string;
  isPasswordSet: boolean;
  profileId: ProfileId;
  profileRespondentId: number;
  emailAddress: string;
  participantName: string;
  /** FIXME: Trashcan name alert. Form partly filled out */
  isFormFilledData: boolean;
  isDemographicsFilled: boolean;
  /** FIXME: Trashcan name alert. Form fully filled out */
  isFormFilled: boolean;
  activityStatus: ActivityStatus;
  profileStatus: ProfileStatus;
}

/** PROFILE RELATED TYPES */
export interface IMyProfiles {
  activityId: ActivityId;
  calculatedDate: string;
  isDemographicsFilled: boolean;
  isRespondentsInvited: boolean;
  isRoleFilled: boolean;
  isSelfFormFilled: boolean;
  noOfRespondents: number;
  oAffPos: number | null;
  oDirPos: number | null;
  oFlex: number | null;
  presentationId: number;
  profileDate: string;
  profileId: ProfileId;
  roleId: number;
  roleText: string;
  sFlex: number | null;
  status: ProfileStatus;
  title: string;
  uniqueCode: string;
}

/** @see IMyProfiles */
/** @see IProfileFormInfoResponse */
export interface IProfileDetails {
  emailAddress: string;
  isDemographicsFilled: boolean;
  isFormFilledData: boolean; // some part of form is filled
  isFormFilled: boolean; // full form is filled & submitted
  isLocked: boolean;
  isParticipant: boolean;
  isPasswordSet: boolean;
  isSocialLogin: boolean;
  languageName: string;
  noOfRespondents: number;
  participantInviteColleagues: boolean;
  participantInviteExternal: boolean;
  participantInviteOtherParticipant: boolean;
  participantName: string;
  presentationId: number;
  profileId: ProfileId;

  // FIXME: this should be declared as the 'TypeOfRole' enum.
  //   doing a global search for '\s+roleId:' yields 30+ results
  //   which is kind of surprising, as this field only exists
  //   in one model. so much for reuse.
  roleId: TypeOfRole;
  roleText: string;
  respondents: ReadonlyArray<Respondent>;
  roleCanBeChangedByParticipant: boolean;
  selfDeliverySlideIndex: number;
  selfDeliveryTemplateId: number | null;
  status: ProfileStatus;
}

/** @see IMyProfiles */
/** @see IProfileDetails */
export interface IProfileStatus {
  activityId: ActivityId;
  activityName: string;
  facilitatorName: string;
  isDemographicsFilled: boolean;
  isPresentationCreated: boolean;
  isRespondentsInvited: boolean;
  isRoleFilled: boolean;
  isSelfFormFilled: boolean;
  presentationDate: string;
  presentationId: number;
  presentationName: string;
  presentationStatus: number;
  presentationTemplateId: number;
  profileDate: string;
  profileId: ProfileId;
  status: ProfileStatus;
  uniqueCode: string;
}

/** @see IProfileDetails Probable copy-pasta? */
export interface IProfileFormInfoResponse {
  emailAddress: string;
  isDemographicsFilled: boolean;
  isLocked: boolean;
  isParticipant: boolean;
  isPasswordSet: boolean;

  /** Naming jesus lord save us */
  isFormFilledData: boolean; // some part of form is filled

  /** Naming jesus lord save us */
  isFormFilled: boolean; // full form is filled & submitted
  languageId: number;
  languageName: string;
  noOfRespondents: number;
  participantInviteColleagues: boolean;
  participantInviteExternal: boolean;
  participantInviteOtherParticipant: boolean;
  participantName: string;
  presentationId: number;
  profileId: ProfileId;
  profileRespondentId: number;
  respondents: ReadonlyArray<Respondent>;
  roleCanBeChangedByParticipant: boolean;
  roleId: number;
  roleText: string;
  selfDeliverySlideIndex: number;
  selfDeliveryTemplateId: number | null;
  status: ProfileStatus;
  userId: UserId;
}
export interface IProfileTimelines {
  date: string;
  id: ProfileId;
  isActivity: boolean;
  name: string;
  numberOfRespondents: number;
  roleId: number;
  roleText: string;
}

// Individual Reports
export interface IProfileReport {
  name: string;
  title: string;
  culture: string;
  date: string;
  /** @deprecated Will be superseeded by the IDIMAST (profile results) in the future */
  roleId: number;
  /** @deprecated Will be superseeded by the IDIMAST (profile results) in the future */
  roleText: string;
  /** @deprecated Will be superseeded by the IDIMAST (profile results) in the future */
  noOfRespondents: number;
  respondents: string[];
  /** @deprecated Will be superseeded by the IDIMAST (profile results) in the future */
  sFlex: number;
  /** @deprecated Will be superseeded by the IDIMAST (profile results) in the future */
  oFlex: number;
  /** @deprecated Will be superseeded by the IDIMAST (profile results) in the future */
  oDirPos: number;
  /** @deprecated Will be superseeded by the IDIMAST (profile results) in the future */
  sDirPos: number;
  /** @deprecated Will be superseeded by the IDIMAST (profile results) in the future */
  oAffPos: number;
  /** @deprecated Will be superseeded by the IDIMAST (profile results) in the future */
  sAffPos: number;
  /** @deprecated Will be superseeded by the IDIMAST (profile results) in the future */
  outsideStdDevD: number;
  /** @deprecated Will be superseeded by the IDIMAST (profile results) in the future */
  outsideStdDevA: number;
  /** @deprecated Will be superseeded by the IDIMAST (profile results) in the future */
  outsideStdDevF: number;
  flexMessage?: string;
  flexMeasure?: number;
  directivenessMessage?: string;
  directivenessMeasure?: number;
  affiliationMessage?: string;
  affiliationMeasure?: number;
  reportProfileDescription?: string;
  directivenessSpreadText?: string;
  affiliationSpreadText?: string;
  adaptabilityText1?: string;
  adaptabilityRespondentsText?: string;
  adaptabilityText2?: string;
  adaptabilitySpreadText?: string;
  adaptabilityText3?: string;
}

/** /PROFILE RELATED TYPES */

export type Plan = {
  id?: number;
  name: string;
  description: string;
  type: PlanType;
  enabled: boolean;
  prices: Array<PlanPrice>;
};

export type PlanPrice = {
  currencyId: number;
  unitPrice: number;
};

// TODO: this should change into the yet-to-be-named 'Plan 1' and 'Plan 2'.
export enum PlanType {
  Plan = 1, // per profile
  Subscription = 2, // monthly/yearly
}
