import React, { useEffect, useState } from "react";

import { LogModal } from "./logModal";
import { PhoneNumberInput } from "../../../../employeeList/addEmployeeModal/phoneNumberInput";
import { PersonalizedInvitationModal } from "../../../addActivity/emailSMSInvitation/personalizedInvitationModal";
import { Input } from "../../../../../components/formComponents/input";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../../commonUtils/languageFunctionsHelper";
import { E164Number } from "libphonenumber-js/types";
import { InvitationType } from "../../../activityEnums";
import { ISelectedProfilesList } from "../../../addActivity/activityInviteParticipantsStep";
import { CustomModal } from "../../../../../components/modals/customModal";
import { ProfileId } from "@app/containers/reducer";
import { ILanguage } from "@app/containers/commonInterfaces";

interface IProfileParticipantInviteProps {
  languageText: ITranslationObject;
  languages: ReadonlyArray<ILanguage>;
  languageCode: string;
  name: string;
  profileId: ProfileId;
  emailAddress: string;
  emailSentLog: Array<string>;
  latestEmailSentLog: string;
  phoneNumber: string;
  smsOwnMessage: string;
  emailOwnMessage: string;
  smsSentLog: Array<string>;
  latestSmsSentLog: string;
  messageTranslation: ITranslationObject;
  emailStatus: number;
  smsStatus: number;
  handleMessageSentSuccess: () => void;
}

export const ProfileParticipantInvite = (
  props: IProfileParticipantInviteProps,
): JSX.Element => {
  const initialInviteState: ISelectedProfilesList = {
    id: 0 as ProfileId,
    name: "",
    email: "",
    emailOwnMessage: "",
    smsOwnMessage: "",
    emailSent: false,
    smsSent: false,
  };
  const [selectedProilesForInvite, setSelectedProfilesForInvite] =
    useState<ISelectedProfilesList>(initialInviteState); // To send invite once more
  const [phoneNumberValue, setPhoneNumberValue] = useState<string>("");
  const [openLogModal, setOpenLogModal] = useState<boolean>(false);
  const [openInvitationModal, setOpenInvitationModal] =
    useState<boolean>(false);
  const [smsWarningModal, setSmsWarningModal] = useState<boolean>(false);
  const [isEmail, setIsEmail] = useState<boolean>(false);

  useEffect(() => {
    setSelectedProfilesForInvite((prev) => ({
      ...prev,
      id: props.profileId,
      name: props.name,
      email: props.emailAddress,
      emailOwnMessage: props.emailOwnMessage,
      smsOwnMessage: props.smsOwnMessage,
      emailStatus: props.emailStatus,
      smsStatus: props.smsStatus,
    }));
    setPhoneNumberValue(props.phoneNumber);
  }, [props.name, props.phoneNumber, props.emailStatus, props.smsStatus]);

  const onLogClick = (isEmail: boolean): void => {
    setOpenLogModal(true);
    setIsEmail(isEmail);
  };

  const closeLogModal = (): void => setOpenLogModal(false);

  const onPhoneNumberChange = (value: E164Number): void => {
    setPhoneNumberValue(value);
  };

  // Send Email fn's
  const onSendEmailClick = (): void => {
    setIsEmail(true);
    setOpenInvitationModal(true);
  };

  const closeInvitationModal = (): void => setOpenInvitationModal(false);

  const onMessageSentSuccess = (
    isEmail: boolean,
    participant: ISelectedProfilesList,
    emailOrSMSStatus: number,
  ): void => {
    setSelectedProfilesForInvite((prev) => ({
      ...prev,
      emailStatus: isEmail ? emailOrSMSStatus : prev.emailStatus,
      smsStatus: !isEmail ? emailOrSMSStatus : prev.smsStatus,
      emailSent: isEmail ? true : prev.emailSent,
      smsSent: !isEmail ? true : prev.smsSent,
    }));

    props.handleMessageSentSuccess();
  };

  // Send SMS fn's
  const onSendSmsClick = (): void => {
    if (phoneNumberValue && phoneNumberValue.length > 7) {
      setIsEmail(false);
      setOpenInvitationModal(true);
    } else {
      setSmsWarningModal(true);
    }
  };

  const handleCancelClick = (): void => setSmsWarningModal(false);

  return (
    <>
      <div id="editParticipantInvite" className="mt-2">
        <div className="fs-5 fw-bold mb-3">
          {getLanguageValue(props.languageText, "Invite")}
        </div>

        <form className="row">
          {/* Email Section */}
          <div className="col-lg-6 mb-3">
            <Input
              label={getLanguageValue(props.languageText, "Email")}
              placeholder={getLanguageValue(props.languageText, "Email")}
              value={props.emailAddress}
              disable={true}
            />
            <div className="d-flex align-items-center mt-3 gap-2">
              <div className="form-control">
                {props.latestEmailSentLog ? (
                  <>
                    {getLanguageValue(props.languageText, "Email")}{" "}
                    {props.latestEmailSentLog}
                    <span
                      role="button"
                      className="text-success ms-2"
                      onClick={() => onLogClick(true)}
                    >
                      {getLanguageValue(props.languageText, "Log")}
                    </span>
                  </>
                ) : (
                  getLanguageValue(props.languageText, "Email invite not sent ")
                )}
              </div>
              <div className="btn btn-light border" onClick={onSendEmailClick}>
                <i className="bi bi-send-fill"></i>
              </div>
            </div>
          </div>

          {/* SMS Section */}
          <div className="mt-8 d-lg-none d-xl-none d-md-none"></div>
          <div className="col-lg-6">
            <PhoneNumberInput
              value={(phoneNumberValue as E164Number) || ""}
              onChange={onPhoneNumberChange}
              languageText={props.languageText}
              disabled={true}
              label={getLanguageValue(props.languageText, "Phonenumber")}
            />
            <div className="d-flex align-items-center mt-3 gap-2">
              <div className="form-control">
                {props.latestSmsSentLog ? (
                  <>
                    {getLanguageValue(props.languageText, "SMS")}{" "}
                    {props.latestSmsSentLog}
                    <span
                      className="text-success cursor-pointer ms-2"
                      onClick={() => onLogClick(false)}
                    >
                      {getLanguageValue(props.languageText, "Log")}
                    </span>
                  </>
                ) : (
                  getLanguageValue(props.languageText, "SMS invite not sent ")
                )}
              </div>
              <div className="btn btn-light border" onClick={onSendSmsClick}>
                <i className="bi bi-send-fill"></i>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="border-bottom mt-2 mb-4"></div>
      {openLogModal && (
        <LogModal
          languageText={props.languageText}
          isEmail={isEmail}
          inviteDetails={isEmail ? props.emailSentLog : props.smsSentLog}
          handleCloseClick={closeLogModal}
        />
      )}

      {/* Invitation Modal */}
      {openInvitationModal && (
        <PersonalizedInvitationModal
          languageText={props.languageText}
          messageTranslation={props.messageTranslation}
          languages={props.languages}
          latestEmailSentLog={props.latestEmailSentLog}
          cultureValue={props.languageCode}
          profile={selectedProilesForInvite}
          selectedInvitationTypeBtn={
            isEmail ? InvitationType.email : InvitationType.sms
          }
          phoneNumber={phoneNumberValue}
          handleCloseClick={closeInvitationModal}
          handleMessageSentSuccess={onMessageSentSuccess}
        />
      )}

      {smsWarningModal && (
        <CustomModal
          headerText={getLanguageValue(props.languageText, "No Phone Number")}
          bodyText={getLanguageValue(
            props.languageText,
            "Phone number is required to send the sms",
          )}
          submitButtonText={getLanguageValue(props.languageText, "Ok")}
          handleCancelClick={handleCancelClick}
          handleSubmitClick={handleCancelClick}
        />
      )}
    </>
  );
};
