import React from "react";
import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import { ITableHeaders } from "../uploadEmployeeModal/uploadEmployeeHooks";
import { IAddEmployeeModalProps, useAddEmployee } from "./hooks";
import { E164Number } from "libphonenumber-js/types";
import { PhoneNumberInput } from "./phoneNumberInput";
import { ModalComponent } from "@app/components/modals/modalComponent";
import { Input } from "@app/components/formComponents/input";

export const AddEmployeeModal: React.FC<IAddEmployeeModalProps> = (props) => {
  const {
    languageText,
    employees,
    tableHeaders,
    addRow,
    onSubmitClick,
    closeModal,
    handleChange,
    validateEmail,
    validateFirstName,
    validateLastName,
    validatePhoneNumber,
    deleteRow,
    handlePhoneInputChange,
  } = useAddEmployee(props);

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(languageText, "Add Employee")}
        submitButtonText={getLanguageValue(languageText, "Submit")}
        secondarySubmitButtonText={getLanguageValue(languageText, "Add Row")}
        cancelButtonText={getLanguageValue(languageText, "Close")}
        handleSubmitClick={onSubmitClick}
        handleSecondarySubmitClick={addRow}
        handleCancelClick={closeModal}
        width="xl"
      >
        <div className="table-responsive overflow-x-unset">
          <table className="table">
            <thead>
              {employees.length > 0 && (
                <tr>
                  {tableHeaders.map((header: ITableHeaders, idx: number) => (
                    <th key={idx} className={header.required ? "required" : ""}>
                      <i className={header.icon}></i>
                      <span className="p-2">{header.name}</span>
                    </th>
                  ))}
                </tr>
              )}
            </thead>
            <tbody>
              {employees.map((employee, index) => (
                <tr key={index}>
                  <td className="px-1">
                    <Input
                      placeholder={getLanguageValue(languageText, "Firstname")}
                      value={employee.name}
                      handleInputChange={(e) => handleChange(e, index, "name")}
                      handleBlurEvent={(e) =>
                        validateFirstName(e.target.value, index)
                      }
                      errorMessage={employee.firstNameError ?? ""}
                    />
                  </td>
                  <td className="px-1">
                    <Input
                      placeholder={getLanguageValue(languageText, "Lastname")}
                      value={employee.surname}
                      handleInputChange={(e) =>
                        handleChange(e, index, "surname")
                      }
                      handleBlurEvent={(e) =>
                        validateLastName(e.target.value, index)
                      }
                      errorMessage={employee.lastNameError ?? ""}
                    />
                  </td>
                  <td className="px-1">
                    <Input
                      placeholder={getLanguageValue(languageText, "Email")}
                      value={employee.emailAddress}
                      handleInputChange={(e) =>
                        handleChange(e, index, "emailAddress")
                      }
                      handleBlurEvent={(e) =>
                        validateEmail(e.target.value, index)
                      }
                      errorMessage={
                        employee.isEmailExists
                          ? getLanguageValue(
                              languageText,
                              "Email already exists",
                            )
                          : (employee.emailError ?? "")
                      }
                    />
                  </td>
                  <td className="px-1">
                    <PhoneNumberInput
                      languageText={languageText}
                      placeholder="+123 45 67 89"
                      value={(employee.phoneNumber as E164Number) || ""}
                      onChange={(value) =>
                        handlePhoneInputChange(value, index, "phoneNumber")
                      }
                      onBlur={(e) =>
                        validatePhoneNumber(e.target.value as E164Number, index)
                      }
                      phoneNumberErrorMsg={employee.phoneNumberError}
                    />
                  </td>
                  <td style={{ paddingTop: "12px" }}>
                    <button
                      className="btn btn-light-danger h-40px btn-sm d-flex justify-content-center align-items-center"
                      onClick={() => deleteRow(index)}
                    >
                      <i className="bi bi-trash3 pe-0"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </ModalComponent>
    </>
  );
};
