import React from "react";

import {
  ITranslationObject,
  getLanguageValue,
} from "@app/commonUtils/languageFunctionsHelper";
import { DeviationValue } from "./deviationValues/lowDeviationValue";

interface IDirectivenessProps {
  languageText: ITranslationObject;
  profileMeasure: number;
  directivenessMessage: string;
  directivenessSpreadText: string;
}

export const Directiveness = (props: IDirectivenessProps): JSX.Element => {
  return (
    <>
      <h5 className="fw-bold mb-2">
        {getLanguageValue(props.languageText, "Directiveness")}
      </h5>
      <DeviationValue
        profileMeasure={props.profileMeasure}
        deviationMessage={props.directivenessMessage}
        deviationSpreadText={props.directivenessSpreadText}
      />
    </>
  );
};
