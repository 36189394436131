import React from "react";

import { CourseBreadcumb } from "../components";
import { useCourseSummary } from "./hooks";
import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import { ICourse, ICourseParts } from "../types";
import { CustomModal } from "../../../components/modals/customModal";
import { renderHTMLAcademy } from "../../../commonUtils/renderHtmlHelper";
import { ModalComponent } from "@app/components/modals/modalComponent";

const calculateTimeSpent = (
  course: ICourse,
): {
  timespentInSecs: number;
  timespentInMins: number;
  correctAnswers: number;
} => {
  let timespentInSecs = 0;
  let timespentInMins = 0;
  let correctAnswers = 0;
  course.courseParts?.forEach((cp: ICourseParts) => {
    timespentInSecs += cp.timeSpent;
  });
  course.quizes.forEach((q) => {
    if (q.userQuizDetail && q.userQuizDetail.isCorrect) correctAnswers++;
  });
  if (timespentInSecs <= 60) {
    timespentInMins = 1; // For the first minute, even with 1 second, it'll be shown as 1 minute.
  } else if (timespentInSecs % 60 <= 29) {
    timespentInMins = Math.floor(timespentInSecs / 60); // Round down to the lower minute
  } else {
    timespentInMins = Math.ceil(timespentInSecs / 60); // Round up to the next highest minute
  }
  return { timespentInSecs, timespentInMins, correctAnswers };
};

export const CourseSummary = (): JSX.Element => {
  const {
    courseSummary,
    languageText,
    isResetCourse,
    timeSpentMessageModal,
    openResetConfirmModal,
    closeConfirmModal,
    markAsFinishedHandler,
    handleRestartCourse,
    backClickHandler,
    handleCompleteCourse,
    closeTimeSpentMessageModal,
  } = useCourseSummary();

  const { timespentInSecs, timespentInMins, correctAnswers } =
    calculateTimeSpent(courseSummary);

  return (
    <>
      {courseSummary &&
        // FIXME: see above
        courseSummary.quizes.length <= (courseSummary.quizesCompleted ?? 0) && (
          <div className="mt-3 p-2 p-md-4 col-md-8 mx-auto">
            <CourseBreadcumb
              languageText={languageText}
              course={courseSummary}
            />
            <div className="shadow-sm p-4 rounded bg-white">
              <div className="d-flex flex-column flex-md-row gap-3 flex-wrap align-items-md-center">
                <h3 className=" flex-grow-1 fw-bold">
                  {getLanguageValue(languageText, "Summary")}
                </h3>
                <div className="d-flex gap-2 text-secondary">
                  <i className="bi bi-clock-history fs-2"></i>
                  <div className="d-flex flex-column">
                    <span className="fw-bolder fs-5">
                      {timespentInMins} {getLanguageValue(languageText, "min")}
                    </span>
                    <span className="small">
                      {getLanguageValue(
                        languageText,
                        "Time spent on this course",
                      )}
                    </span>
                  </div>
                </div>

                <div className="d-flex gap-2 text-secondary">
                  <i className="bi bi-question-circle fs-2"></i>
                  <div className="d-flex flex-column">
                    <span className="fw-bolder fs-5">
                      {correctAnswers}/{courseSummary.quizes.length}{" "}
                      {getLanguageValue(languageText, "Questions")}
                    </span>
                    <span className="small">
                      {getLanguageValue(languageText, "Answered correctly")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-4 pt-4">
                <h5 className="mb-3 fw-bold">
                  {getLanguageValue(
                    languageText,
                    "The key message in this course",
                  )}
                  :
                </h5>
                {renderHTMLAcademy(courseSummary.keyMessage || "")}
              </div>

              <div className="mt-4 pt-4 p-3 bg-success text-white rounded">
                <h5 className="mb-3 fw-bold">
                  {getLanguageValue(languageText, "Actionable advice")}:
                </h5>
                {renderHTMLAcademy(courseSummary.actionableAdvice || "")}
              </div>

              <div className="mt-4 pt-4 p-3 bg-light rounded">
                <h5 className="mb-3 fw-bold">
                  {getLanguageValue(languageText, "Suggested further reading")}:
                </h5>
                {renderHTMLAcademy(courseSummary.suggestedFurtherReading || "")}
              </div>

              <div className="mt-4 pt-4 p-3 bg-light rounded">
                <h5 className="mb-3 fw-bold">
                  {getLanguageValue(languageText, "References")}:
                </h5>
                {renderHTMLAcademy(courseSummary.references || "")}
              </div>

              <div className="d-flex justify-content-between mt-4 gap-3">
                <button
                  className="btn btn-lg btn-outline-secondary py-1 px-4"
                  onClick={backClickHandler}
                >
                  <i className="bi bi-arrow-left fs-3"></i>
                </button>
                <div>
                  <button
                    type="button"
                    className="btn btn-lg btn-primary py-1 pe-md-4 me-2"
                    onClick={openResetConfirmModal}
                  >
                    <i className="bi bi-arrow-clockwise fs-3 me-md-2 float-start"></i>
                    <div className="mt-1 d-none d-md-inline-block">
                      {getLanguageValue(languageText, "Restart")}
                    </div>
                  </button>
                  <button
                    type="button"
                    className="btn btn-lg btn-success py-1 pe-md-4"
                    onClick={() => markAsFinishedHandler(timespentInSecs)}
                  >
                    <i className="bi bi-check-lg fs-3 me-md-2 float-start"></i>
                    <div className="mt-1 d-none d-md-inline-block">
                      {getLanguageValue(languageText, "Mark as finished")}
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

      {isResetCourse && (
        <CustomModal
          headerText={getLanguageValue(languageText, "Restart confirmation")}
          bodyText={`${getLanguageValue(
            languageText,
            "Restarting the course will delete usage data. Are you sure, want to restart the course",
          )}?`}
          handleSubmitClick={handleRestartCourse}
          handleCancelClick={closeConfirmModal}
          cancelButtonText={getLanguageValue(languageText, "Cancel")}
          submitButtonText={getLanguageValue(languageText, "Confirm")}
        />
      )}

      {timeSpentMessageModal && (
        <ModalComponent
          headerText={getLanguageValue(languageText, "Course Finish Message")}
          submitButtonText={getLanguageValue(languageText, "Proceed")}
          secondarySubmitButtonText={getLanguageValue(
            languageText,
            "Restart Course",
          )}
          secondarySubmitButtonColor="btn-primary"
          handleSecondarySubmitClick={handleRestartCourse}
          handleSubmitClick={() => handleCompleteCourse()}
          handleCancelClick={closeTimeSpentMessageModal}
        >
          {getLanguageValue(
            languageText,
            "You have not spent the required time on this course. Do you still want to complete the course?",
          )}
        </ModalComponent>
      )}
    </>
  );
};
