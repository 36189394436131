import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

import { getActivityList } from "./actions";
import { RootState } from "@app/store/configureStore";
import { ColumnDef } from "@tanstack/react-table";
import { getLanguageValue } from "@app/commonUtils/languageFunctionsHelper";
import { getFullDateTimeFormat } from "../../commonUtils/dateFunctionsHelper";
import {
  ActivityStatus,
  activityStatusColors,
  InvoicedFilter,
} from "./activityEnums";
import { routePath } from "../routePaths";
import { setSpinner } from "../actions";
import { IPageConfiguredInfo } from "../reducer";
import { getInitialPageConfiguredInfoState } from "@app/components/reactTable/reactTable";
import { IProfileListProps } from "./index";
import { IFilterObject } from "@app/components/activityFilters/hooks";
import { PlanType } from "@app/types";
import { shallowEqual } from "@app/commonUtils/objectsHelper";
import {
  isLoggedInRoleAdmin,
  isLoggedInRoleFacilitator,
} from "@app/commonUtils/roleHelper";
import { IActivity } from "../commonInterfaces";

export interface IFetchActivitiesParams {
  filter: string;
  sorting: string;
  maxResultCount: number;
  skipCount: number;
  isFacilitator: boolean;
  clientId?: number;
  status?: ActivityStatus | null;
  clientName?: string;
  facilitatorName?: string;
  dateFrom?: Date | null;
  dateTo?: Date | null;
  addedProfilesMin?: number | null;
  addedProfilesMax?: number | null;
  completedProfilesMin?: number | null;
  completedProfilesMax?: number | null;
  source?: string;
  invoiced?: InvoicedFilter | null;
  planType?: PlanType | null;
}

export const useActivityList = (props: IProfileListProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMounted = useRef(false);
  const pathname = window.location.pathname;

  const loggedInUserRole = useSelector(
    (state: RootState) => state.loginReducer.loggedInUserRole,
  );
  const currentClient = useSelector(
    (state: RootState) => state.loginReducer.currentClient,
  );
  const pageConfiguredInfo: IPageConfiguredInfo =
    useSelector(
      (state: RootState) => state.mainReducer.pageConfiguredInfo,
    ).find((info) => info.pathname === pathname) ??
    getInitialPageConfiguredInfoState();

  // Handling Language
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );
  const userDetails = useSelector(
    (state: RootState) => state.loginReducer.userDetails,
  );

  const [data, setdata] = useState<any[]>([]);
  const [filter, setFilter] = useState<string>("");
  const [sorting, setSorting] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0); // total_data_count
  const [pageLimit, setPageLimit] = useState<number>(10); // items_per_page
  const [pageCount, setPageCount] = useState<number>(0); // total_page_count
  const [pageSelected, setPageSelected] = useState<number>(0); // page_number_selected
  const [profileUpdated, setProfileUpdated] = useState<boolean>(false); // This flag is used to re-reder the profiles data on requirement
  const [defaultClientMessageModal, setDefaultClientMessageModal] =
    useState<boolean>(false);

  const initialFilterObject: IFilterObject = {
    status: null,
    clientName: "",
    facilitatorName: "",
    dateFrom: null,
    dateTo: null,
    addedProfilesMin: null,
    addedProfilesMax: null,
    completedProfilesMin: null,
    completedProfilesMax: null,
    source: "",
    invoiced: InvoicedFilter.All,
    planType: null,
  };

  const [updatedFilterOptions, setUpdatedFilterOptions] =
    useState<IFilterObject>(initialFilterObject);
  const [showClearFiltersButton, setShowClearFiltersButton] =
    useState<boolean>(false);
  const [clearFilterOptions, setClearFilterOptions] = useState<boolean>(false);

  const fetchProfiles = (
    skipCount: number,
    existingFilter?: string,
    existingSorting?: string,
    existingPageLimit?: number,
  ): void => {
    dispatch(setSpinner(true));
    const params: IFetchActivitiesParams = {
      filter: existingFilter ? existingFilter : filter,
      sorting: existingSorting ? existingSorting : sorting,
      maxResultCount: existingPageLimit ? existingPageLimit : pageLimit,
      skipCount: skipCount,
      isFacilitator: isLoggedInRoleFacilitator(loggedInUserRole),
      clientId: props.clientIdFromAccount
        ? props.clientIdFromAccount
        : isLoggedInRoleFacilitator(loggedInUserRole)
          ? currentClient.currentClientId
          : undefined,
      status: updatedFilterOptions.status,
      clientName: updatedFilterOptions.clientName,
      facilitatorName: updatedFilterOptions.facilitatorName,
      dateFrom: updatedFilterOptions.dateFrom,
      dateTo: updatedFilterOptions.dateTo,
      addedProfilesMin: updatedFilterOptions.addedProfilesMin,
      addedProfilesMax: updatedFilterOptions.addedProfilesMax,
      completedProfilesMin: updatedFilterOptions.completedProfilesMin,
      completedProfilesMax: updatedFilterOptions.completedProfilesMax,
      source: updatedFilterOptions.source,
      invoiced: updatedFilterOptions.invoiced,
      planType: updatedFilterOptions.planType,
    };
    getActivityList(params, dispatch)
      .then((response) => {
        if (response) {
          const pageSize = Math.ceil(response.totalCount / pageLimit);
          setdata([...response.items]);
          setTotalCount(response.totalCount);
          setPageCount(pageSize);
          if (profileUpdated) setProfileUpdated(false);
          if (!isMounted.current) isMounted.current = true;
        }
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  useEffect(() => {
    if (isMounted.current || profileUpdated) {
      const skipCount = pageLimit * pageSelected;
      fetchProfiles(skipCount);
    }
  }, [pageSelected, sorting, profileUpdated]);

  useEffect(() => {
    if (isMounted.current) {
      fetchProfiles(0);
    }
  }, [filter, updatedFilterOptions, pageLimit]);

  useEffect(() => {
    if (
      loggedInUserRole &&
      (currentClient.currentClientId || isLoggedInRoleAdmin(loggedInUserRole))
    ) {
      if (props.clientIdFromAccount) {
        fetchProfiles(0);
      } else {
        const { searchText, sorting, pageLimit, pageSelected } =
          pageConfiguredInfo;
        setFilter(searchText);
        setSorting(sorting);
        setPageLimit(pageLimit);
        setPageSelected(pageSelected);
        fetchProfiles(pageLimit * pageSelected, searchText, sorting, pageLimit);
      }
    }
  }, [currentClient, loggedInUserRole]);

  const addProfileClick = (): void => {
    if (currentClient.currentClientId) {
      navigate(routePath.addActivity);
    } else {
      setDefaultClientMessageModal(true);
    }
  };

  const handleCloseModal = (): void => setDefaultClientMessageModal(false);

  const handleEditClick = (id: number): void => {
    if (props.clientIdFromAccount) {
      navigate(routePath.editActivity.replace(":id", id.toString()), {
        state: props.clientIdFromAccount,
      });
    } else {
      navigate(routePath.editActivity.replace(":id", id.toString()));
    }
  };

  function navigateToProfilesClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    event.stopPropagation();
    navigate(routePath.profiles);
  }

  const handleUpdatedFilterOptions = (options: IFilterObject) => {
    setUpdatedFilterOptions(options);
    if (!shallowEqual(options, initialFilterObject)) {
      setShowClearFiltersButton(true);
    }
  };

  const handleClearCustomFilters = (): void => {
    setClearFilterOptions(!clearFilterOptions);
    setShowClearFiltersButton(false);
  };

  const tableHeader: Array<ColumnDef<IActivity>> = [
    {
      header: "",
      accessorKey: "actions",
      enableSorting: false,
      cell: ({ ...props }) => (
        <div
          className="btn btn-lg border-end rounded-0 my-n3 ms-n3"
          onClick={() => handleEditClick(props.row.original.id)}
        >
          <i className="bi bi-pencil-fill text-secondary" />
        </div>
      ),
    },
    {
      header: getLanguageValue(languageText, "ID"),
      accessorKey: "id",
    },
    {
      header: getLanguageValue(languageText, "Status"),
      accessorKey: "status",
      cell: ({ ...props }) => (
        <span
          className={clsx(
            "badge fw-bold fs-6",
            activityStatusColors[props.row.original.status],
          )}
        >
          {getLanguageValue(
            languageText,
            ActivityStatus[props.row.original.status],
          )}
        </span>
      ),
    },
    {
      header: getLanguageValue(languageText, "Name"),
      accessorKey: "name",
    },
    {
      header: getLanguageValue(languageText, "Date"),
      accessorKey: "date",
      cell: ({ ...props }) =>
        new Date(props.row.original.date).toLocaleDateString(),
    },
    {
      header: getLanguageValue(languageText, "Added profiles"),
      accessorKey: "registeredProfiles",
    },
    {
      header: getLanguageValue(languageText, "Done"),
      accessorKey: "completedProfiles",
    },
  ];

  if (
    userDetails?.userRoles &&
    isLoggedInRoleAdmin(loggedInUserRole) &&
    !props.clientIdFromAccount
  ) {
    tableHeader.splice(7, 0, {
      header: getLanguageValue(languageText, "Accounts"),
      accessorKey: "client",
    });
    tableHeader.push({
      header: getLanguageValue(languageText, "Source"),
      accessorKey: "sourceType",
    });
  }

  if (!props.clientIdFromAccount) {
    tableHeader.push(
      {
        header: getLanguageValue(languageText, "Facilitator"),
        accessorKey: "facilitator",
      },
      {
        header: getLanguageValue(languageText, "Created"),
        accessorKey: "created",
        cell: ({ ...props }) =>
          getFullDateTimeFormat(props.row.original.created),
      },
    );
  }

  return {
    languageText,
    loggedInUserRole,
    tableHeader,
    data,
    totalCount,
    pageCount,
    filter,
    sorting,
    pageLimit,
    pageSelected,
    defaultClientMessageModal,
    initialFilterObject,
    clearFilterOptions,
    showClearFiltersButton,
    setFilter,
    setSorting,
    setPageLimit,
    setPageSelected,
    addProfileClick,
    handleCloseModal,
    navigateToProfilesClick,
    handleUpdatedFilterOptions,
    handleClearCustomFilters,
  };
};
