const AccountInputs = {
  name: "name",
  countryId: "countryId",
  invoiceCompanyName: "invoiceCompanyName",
  invoiceCountryId: "invoiceCountryId",
  businessIdentification: "businessIdentification",
  invoiceReference: "invoiceReference",
  email: "email",
  telephone: "telephone",
  address: "address",
  city: "city",
  zip: "zip",
  differentInvoiceAddress: "differentInvoiceAddress",
  invoiceAddress: "invoiceAddress",
  invoiceCity: "invoiceCity",
  invoiceZip: "invoiceZip",
  euvatid: "euvatid",
  invoiceEUVATID: "invoiceEUVATID",
  enableParticipantCreateProfile: "enableParticipantCreateProfile",
  sourceType: "sourceType",
  sourceAddress: "sourceAddress",
} as const;

const BillingInputs = {
  selectedPaymentMethodId: "selectedPaymentMethodId",
  paymentTerms: "paymentTerms",
  price: "price",
  teamSize: "teamSize",
  startDate: "startDate",
  selectedPlan: "selectedPlan",
  endDate: "endDate",
  status: "status",
  payment: "payment",
  paymentAfterEnd: "paymentAfterEnd",
  currencyId: "currencyId",
} as const;

enum NavTabs {
  settings = "settings",
  clientDetails = "clientDetails",
  billing = "billing",
  users = "users",
  profiles = "profiles",
}

enum BillingPeriodType {
  Annual = 1,
  Monthly = 2,
}

enum PaymentTerms {
  "30 days" = 30,
  "60 days" = 60,
  "90 days" = 90,
}

enum PaymentTermsType {
  D30 = 30,
  D60 = 60,
  D90 = 90,
}

enum PaymentType {
  Invoice = 1,
  CreditCard = 2,
}

enum PaymentStatus {
  Trail = 1,
  Active = 2,
  Paused = 3,
  Cancelled = 4,
}

enum Payment {
  NotInvoiced = 1,
  Invoiced = 2,
  Paid = 3,
  Overdue = 4,
  Probono = 5,
}

enum PaymentAfterEnd {
  AskToRenew = 1,
  AutoRenew = 2,
  AskToStart = 3,
  Cancel = 4,
}

export {
  AccountInputs,
  BillingInputs,
  NavTabs,
  BillingPeriodType,
  PaymentTerms,
  PaymentTermsType,
  PaymentType,
  PaymentStatus,
  Payment,
  PaymentAfterEnd,
};
