import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  ILanguageObject,
  ITranslationObject,
  getLanguageValue,
} from "@app/commonUtils/languageFunctionsHelper";
import { IndividualDownload } from "./individualDownload";
import { setSpinner } from "@app/containers/actions";
import { LanguageModal } from "@app/components/modals/languageModal";
import { getParticipantLanguages } from "@app/containers/languageList/languageTexts/actions";
import { ProfileId } from "@app/containers/reducer";

interface IIndividualDownloadModalProps {
  languageText: ITranslationObject;
  profileId: ProfileId;
  status?: number;
  pdfProfileDownloaded?: boolean;
  handleCloseClick: () => void;
  refetchCompletedParticipants?: () => void;
  profileParticipantName?: string;
}

export const IndividualDownloadModal = (
  props: IIndividualDownloadModalProps,
): JSX.Element => {
  const dispatch = useDispatch();

  const [reportReady, setReportReady] = useState<boolean>(false);
  const [languages, setLanguages] = useState<ILanguageObject[]>([]);
  const [selectedLanguageForDownload, setSelectedLanguageForDownload] =
    useState<string>("");

  const fetchLanguages = async () => {
    dispatch(setSpinner(true));
    const languagesResponse = await getParticipantLanguages(dispatch);
    let languages: ILanguageObject[] = [];
    if (languagesResponse && languagesResponse?.length > 0) {
      languages = languagesResponse.map((item) => {
        const displayName = item.displayName.split(" ")[0];
        return {
          id: item.id,
          icon: item.icon,
          name: item.name,
          displayName: displayName,
        };
      });
    }
    setLanguages(languages);
    dispatch(setSpinner(false));
  };

  useEffect(() => {
    fetchLanguages();
  }, []);

  const onLanguageClick = (language: ILanguageObject): void => {
    const languageCode = language.name;
    setSelectedLanguageForDownload(languageCode);
    setReportReady(true);
  };

  return (
    <>
      {reportReady ? (
        <IndividualDownload
          languageText={props.languageText}
          profileId={props.profileId}
          participantName={props.profileParticipantName!}
          status={props.status ? props.status : 0}
          pdfProfileDownloaded={
            props.pdfProfileDownloaded ? props.pdfProfileDownloaded : false
          }
          languageCode={selectedLanguageForDownload}
          refetchCompletedParticipants={props.refetchCompletedParticipants!}
          handleCloseClick={props.handleCloseClick}
        />
      ) : (
        <LanguageModal
          languageText={props.languageText}
          languages={languages}
          headerText={`${getLanguageValue(props.languageText, "Profile")} - ${props.profileParticipantName}`}
          selectedProfileLanguageId={0}
          handleChangeLanguage={onLanguageClick}
          closeLanguageModal={props.handleCloseClick}
        />
      )}
    </>
  );
};
