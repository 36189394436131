import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";

import { RootState } from "@app/store/configureStore";
import { IAddEditProfileDescriptionModalProps } from "./addEditProfileDescriptionModal";
import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import { Directiveness, Affiliation } from "../../../commonUtils/behaveHelper";
import { IFocusError } from "../../../components/formComponents/input";
import {
  IDropdownList,
  IDropdownSelectedItem,
} from "@app/components/formComponents/dropdownSelect";
import { addToast, setSpinner } from "../../actions";
import {
  createProfileDescription,
  updateProfileDescription,
  getProfileDescriptionById,
} from "../actions";
import { IProfileDescriptionData } from "../interface";
import { ILanguage } from "@app/containers/commonInterfaces";
import { getParticipantLanguages } from "@app/containers/languageList/languageTexts/actions";
import { useApiEndpoints } from "@app/api/end-points";

const ProfileDescriptionInputs = {
  title: "title",
  directiveness: "directiveness",
  affiliation: "affiliation",
  description: "description",
  affiliationText: "affiliationText",
  directivenessText: "directivenessText",
  function: "function",
  success: "success",
  pitfalls: "pitfalls",
  idiLanguageId: "idiLanguageId",
  combinationText: "combinationText",
} as const;

export interface IFocusInput {
  directiveness: IFocusError;
  affiliation: IFocusError;
  title: IFocusError;
  description: IFocusError;
  idiLanguageId: IFocusError;
  affiliationText: IFocusError;
  directivenessText: IFocusError;
  function: IFocusError;
  success: IFocusError;
  pitfalls: IFocusError;
  combinationText: IFocusError;
}

export const useAddEditModal = (
  props: IAddEditProfileDescriptionModalProps,
) => {
  const dispatch = useDispatch();
  const api = useApiEndpoints(dispatch);

  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const initiaIProfileDescriptionInfoInfoState: IProfileDescriptionData = {
    directiveness: "",
    affiliation: "",
    title: "",
    description: "",
    idiLanguageId: 0,
    affiliationText: "",
    directivenessText: "",
    function: "",
    success: "",
    pitfalls: "",
    combinationText: "",
  };
  const [profileDescriptionInfo, setProfileDescriptionInfo] =
    useState<IProfileDescriptionData>(initiaIProfileDescriptionInfoInfoState);

  const [focusInput, setFocusInput] = useState<IFocusInput>({
    directiveness: {
      touched: false,
      errorMessage: "",
    },
    affiliation: {
      touched: false,
      errorMessage: "",
    },
    title: {
      touched: false,
      errorMessage: "",
    },
    description: {
      touched: false,
      errorMessage: "",
    },
    idiLanguageId: {
      touched: false,
      errorMessage: "",
    },
    affiliationText: {
      touched: false,
      errorMessage: "",
    },
    directivenessText: {
      touched: false,
      errorMessage: "",
    },
    function: {
      touched: false,
      errorMessage: "",
    },
    success: {
      touched: false,
      errorMessage: "",
    },
    pitfalls: {
      touched: false,
      errorMessage: "",
    },
    combinationText: {
      touched: false,
      errorMessage: "",
    },
  });
  const [languages, setLanguages] = useState<Array<ILanguage>>([]);

  // Check for edit click
  let isEditClicked: boolean = false;
  if (props.editId > 0) isEditClicked = true;

  useEffect(() => {
    if (!isEditClicked) {
      setProfileDescriptionInfo((prevProfileInfo) => ({
        ...prevProfileInfo,
        directiveness: Directiveness.A,
        affiliation: Affiliation.One.toString(),
      }));
    }
  }, []);

  useEffect(() => {
    api.getLanguages().then((response) => {
      setLanguages(response.items);
    });
    if (isEditClicked) {
      getProfileDescriptionById(props.editId, dispatch).then((response) => {
        if (response) {
          setProfileDescriptionInfo(response);
        }
      });
    }
  }, []);

  const handleFormErrors = (name: string, value: unknown): void => {
    let errorMessage: string = "";

    if (!value) {
      const errorMessages: { [key: string]: string } = {
        [ProfileDescriptionInputs.directiveness]: "Directiveness is required",
        [ProfileDescriptionInputs.affiliation]: "Affiliation is required",
        [ProfileDescriptionInputs.title]: "Title is required",
        [ProfileDescriptionInputs.description]: "Description is required",
        [ProfileDescriptionInputs.idiLanguageId]: "Culture is required",
      };
      errorMessage =
        errorMessages[name] &&
        getLanguageValue(languageText, errorMessages[name]);
    }

    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }));
  };

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    const name = e.target.name;
    const value = e.target.value;
    handleFormErrors(name, value);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name as keyof IFocusInput;
    const value = e.target.value;

    setProfileDescriptionInfo({
      ...profileDescriptionInfo,
      [name]: value,
    });

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  };

  const handleTextEditorChange = (content: string, name: string): void => {
    setProfileDescriptionInfo({
      ...profileDescriptionInfo,
      [name]: content,
    });
    handleFormErrors(name, content);
  };

  function handleDropdownSelect<T>(
    selectedItem: IDropdownSelectedItem<T>,
  ): void {
    const name = selectedItem.name as keyof IFocusInput;
    const value = selectedItem.value;

    setProfileDescriptionInfo({
      ...profileDescriptionInfo,
      [name]: value,
    });

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  }

  const handleValidationOnSubmit = (): boolean => {
    if (
      !profileDescriptionInfo.directiveness ||
      focusInput.description.errorMessage ||
      !profileDescriptionInfo.affiliation ||
      focusInput.description.errorMessage ||
      !profileDescriptionInfo.title ||
      focusInput.description.errorMessage ||
      !profileDescriptionInfo.description ||
      focusInput.description.errorMessage ||
      !profileDescriptionInfo.idiLanguageId ||
      focusInput.idiLanguageId.errorMessage
    ) {
      for (const item of Object.values(ProfileDescriptionInputs)) {
        handleFormErrors(item, profileDescriptionInfo[item]);
      }
      return false;
    }
    return true;
  };

  const handleSaveClick = (): void => {
    if (!handleValidationOnSubmit()) return;

    dispatch(setSpinner(true));
    const body: IProfileDescriptionData = {
      id: props.editId ?? 0,
      ...profileDescriptionInfo,
    };

    if (props.editId > 0) {
      updateProfileDescription(body, dispatch)
        .then((response) => {
          if (response) {
            props.refetchProfileDescriptionList();
            props.closeProfileDecriptionModal();
            dispatch(addToast("Changes saved successfully") as AnyAction);
          }
        })
        .finally(() => dispatch(setSpinner(false)));
    } else {
      createProfileDescription(body, dispatch)
        .then((response) => {
          if (response) {
            props.refetchProfileDescriptionList();
            props.closeProfileDecriptionModal();
            dispatch(addToast("Saved successfully") as AnyAction);
          }
        })
        .finally(() => dispatch(setSpinner(false)));
    }
  };

  return {
    languageText,
    isEditClicked,
    ProfileDescriptionInputs,
    profileDescriptionInfo,
    focusInput,
    languages,
    handleInputChange,
    handleBlurEvent,
    handleTextEditorChange,
    handleDropdownSelect,
    handleSaveClick,
  };
};
