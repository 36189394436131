import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLanguageValue } from "../../../../commonUtils/languageFunctionsHelper";
import {
  IResolveParams,
  LoginSocialGoogle,
  LoginSocialMicrosoft,
} from "reactjs-social-login";
import { addToast, setNotification, setSpinner } from "../../../actions";
import { ValidationBox } from "../../../../components/newPasswordInputBox/validationBox";
import {
  DropdownSelect,
  IDropdownList,
  IDropdownSelectedItem,
} from "@app/components/formComponents/dropdownSelect";
import {
  IFocusError,
  Input,
} from "../../../../components/formComponents/input";
import { CustomModal } from "../../../../components/modals/customModal";
import {
  getTypeOfUsers,
  IRegisterParticipantAccountInfo,
  IRegisterParticipantFocusInput,
  IRegisterParticipantPayload,
  RegisterParticipantInputs,
  RegisterParticipantTypeOfUser,
} from "./interface";
import { ExternalUserLogin } from "../externalUserLogin";
import { RootState } from "@app/store/configureStore";
import { Link, useNavigate } from "react-router-dom";
import { AuthLayout } from "../layout";
import { routePath } from "@app/containers/routePaths";
import { pushToDataLayer } from "@app/commonUtils/google";
import { pushToMatomo } from "@app/commonUtils/matomo";
import { PhoneNumberInput } from "@app/containers/employeeList/addEmployeeModal/phoneNumberInput";
import { getAllCountries } from "@app/containers/clientList/addEditModal/action";
import { getCountryCode } from "@app/commonUtils/countryHelper";
import { SocialProviderTypes } from "@app/containers/commonEnums";
import { ValidationHelper } from "@app/containers/validationHelper";
import { AnyAction } from "redux";
import { useApiEndpoints } from "@app/api/end-points";
import { GoogleIcon, MicrosoftIcon } from "../signUpResources";

const initialFocusInputState: IFocusError = {
  touched: false,
  errorMessage: "",
};

export const RegisterPage: React.FC<unknown> = (props) => {
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const api = useApiEndpoints(dispatch);
  const [provider, setProvider] = useState<string>("");
  const [profile, setProfile] = useState<any>();
  const [countries, setCountries] = useState<IDropdownList[]>([]);
  const [accountInfo, setAccountInfo] =
    useState<IRegisterParticipantAccountInfo>({
      name: "",
      surname: "",
      emailAddress: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      country: "",
      company: "",
      plan: "",
    });
  const [focusInput, setFocusInput] = useState<IRegisterParticipantFocusInput>({
    name: initialFocusInputState,
    surname: initialFocusInputState,
    emailAddress: initialFocusInputState,
    phoneNumber: initialFocusInputState,
    password: initialFocusInputState,
    confirmPassword: initialFocusInputState,
    country: initialFocusInputState,
    company: initialFocusInputState,
    plan: initialFocusInputState,
  });
  const [isCompanyFieldRequired, setIsCompanyFieldRequired] =
    useState<boolean>(false);
  const [isUserExists, setIsUserExists] = useState<boolean>(false);
  const [isUserRegistered, setIsUserRegistered] = useState<boolean>(false);
  const [isUserSocialRegistered, setIsUserSocialRegistered] =
    useState<boolean>(false);

  const typeOfUsers = useMemo(
    () => getTypeOfUsers(languageText),
    [languageText],
  );

  const getCountries = async (): Promise<void> => {
    await getAllCountries(dispatch).then((response) => {
      if (response?.length > 0) {
        const countries: IDropdownList[] = response.map((item) => ({
          id: item.id,
          displayName: item.name,
          value: String(item.id),
        }));
        setCountries(countries);
      }
    });
  };

  useEffect(() => {
    getCountries();

    if (window.location.pathname.endsWith("/facilitator")) {
      setAccountInfo((prevAccountInfo) => ({
        ...prevAccountInfo,
        plan: RegisterParticipantTypeOfUser.Facilitator.toString(),
      }));
      setIsCompanyFieldRequired(true);
    } else if (window.location.pathname.endsWith("/organization")) {
      setAccountInfo((prevAccountInfo) => ({
        ...prevAccountInfo,
        plan: RegisterParticipantTypeOfUser.Organization.toString(),
      }));
      setIsCompanyFieldRequired(true);
    } else if (window.location.pathname.endsWith("/participant")) {
      setAccountInfo((prevAccountInfo) => ({
        ...prevAccountInfo,
        plan: RegisterParticipantTypeOfUser.Individual.toString(),
      }));
      setIsCompanyFieldRequired(false);
    }

    getCountryCode(dispatch).then((response) => {
      if (response && response.countryId) {
        setAccountInfo((prevAccountInfo) => ({
          ...prevAccountInfo,
          country:
            response.countryId === undefined
              ? ""
              : response.countryId.toString(),
        }));
      }
    });
  }, []);

  useEffect(() => {
    if (profile && provider) {
      setAccountInfo((prevAccountInfo) => {
        if (provider === SocialProviderTypes.MICROSOFT) {
          if (!profile.given_name && profile.displayName) {
            const parts = profile.displayName.split(" ");
            return {
              ...prevAccountInfo,
              name: parts[0] || "",
              surname: parts.slice(1).join(" ") || "",
              emailAddress: profile.mail || "",
            };
          } else {
            return {
              ...prevAccountInfo,
              name: profile.givenName || "",
              surname: profile.surname || "",
              emailAddress: profile.mail || "",
            };
          }
        }

        // Fallback for other providers or if conditions are not met
        return {
          ...prevAccountInfo,
          name: profile.given_name || prevAccountInfo.name,
          surname: profile.family_name || prevAccountInfo.surname,
          emailAddress: profile.email || prevAccountInfo.emailAddress,
        };
      });
    }
  }, [profile, provider]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const name = e.target.name as keyof IRegisterParticipantFocusInput;
    const value = e.target.value;
    setAccountInfo({
      ...accountInfo,
      [name]: value,
    });

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  };

  const handleDropdownSelect = (selectedItem: IDropdownSelectedItem): void => {
    const name = selectedItem.name as keyof IRegisterParticipantFocusInput;
    const value = selectedItem.value;

    // If user choose “organization“ then we should show company field.
    if (name === RegisterParticipantInputs.plan) {
      if (value !== RegisterParticipantTypeOfUser.Individual.toString()) {
        setAccountInfo({
          ...accountInfo,
          [name]: value,
        });
        setIsCompanyFieldRequired(true);
      } else {
        setAccountInfo({
          ...accountInfo,
          [name]: value,
          company: "", // Resetting company field when user chooses individual
        });
        setIsCompanyFieldRequired(false);
      }
    } else {
      setAccountInfo({
        ...accountInfo,
        [name]: value,
      });
    }

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  };

  const handleFormErrors = (name: string, value: string): void => {
    let errorMessage: string = "";

    switch (name) {
      case RegisterParticipantInputs.name:
        if (!value) {
          errorMessage = getLanguageValue(languageText, "Name is required");
        }
        break;
      case RegisterParticipantInputs.surname:
        if (!value) {
          errorMessage = getLanguageValue(languageText, "Surname is required");
        }
        break;
      case RegisterParticipantInputs.country:
        if (!value) {
          errorMessage = getLanguageValue(languageText, "Country is required");
        }
        break;
      case RegisterParticipantInputs.company:
        if (!value) {
          errorMessage = getLanguageValue(languageText, "Company is required");
        }
        break;
      case RegisterParticipantInputs.plan:
        if (!value) {
          errorMessage = getLanguageValue(languageText, "Plan is required");
        }
        break;
      case RegisterParticipantInputs.emailAddress:
        if (!value) {
          errorMessage = getLanguageValue(languageText, "Email is required");
        } else {
          const isValid = ValidationHelper.isEmailValid(value);
          if (!isValid)
            errorMessage = getLanguageValue(languageText, "Invalid email");
        }
        break;
      case RegisterParticipantInputs.password:
        if (!value) {
          errorMessage = getLanguageValue(languageText, "Password is required");
        } else if (value) {
          const isValid = ValidationHelper.isPasswordValid(value);
          if (!isValid)
            errorMessage = getLanguageValue(languageText, "Invalid password");
        }
        break;
      case RegisterParticipantInputs.confirmPassword:
        if (!value) {
          errorMessage = getLanguageValue(
            languageText,
            "Confirm password is required",
          );
        } else if (value !== accountInfo.password) {
          errorMessage = getLanguageValue(
            languageText,
            "Confirm Password does not match",
          );
        }
        break;
      default:
        break;
    }

    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }));
  };

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    const name = e.target.name;
    const value = e.target.value;
    handleFormErrors(name, value);
  };

  const isSocialLoginUsed = (): boolean => {
    return provider !== "" && profile !== "";
  };

  const handleValidationOnSubmit = (): boolean => {
    if (
      !accountInfo.name ||
      focusInput.name.errorMessage ||
      !accountInfo.surname ||
      focusInput.surname.errorMessage ||
      !accountInfo.emailAddress ||
      focusInput.emailAddress.errorMessage ||
      !accountInfo.country ||
      focusInput.country.errorMessage ||
      (isCompanyFieldRequired &&
        (!accountInfo.company || focusInput.company.errorMessage)) ||
      (!isSocialLoginUsed() &&
        (!accountInfo.password ||
          focusInput.password.errorMessage ||
          !accountInfo.confirmPassword ||
          focusInput.confirmPassword.errorMessage))
    ) {
      for (const item of Object.values(RegisterParticipantInputs)) {
        handleFormErrors(item, accountInfo[item]);
      }

      return false;
    }

    return true;
  };

  const handleSubmit = (e: { preventDefault: () => void }): void => {
    e.preventDefault();
    if (!handleValidationOnSubmit()) return;
    dispatch(setSpinner(true));

    let providerKey = "";
    if (isSocialLoginUsed()) {
      if (provider === SocialProviderTypes.GOOGLE) {
        providerKey = profile.sub;
      } else if (provider === SocialProviderTypes.MICROSOFT) {
        // what the actual fuck?
        providerKey = profile.id_token
          ? JSON.parse(atob(profile.id_token.split(".")[1])).oid
          : "";
      }
    }

    const body: IRegisterParticipantPayload = {
      name: accountInfo.name,
      surname: accountInfo.surname,
      emailAddress: accountInfo.emailAddress,
      phoneNumber: accountInfo.phoneNumber,
      password: !provider ? accountInfo.password : "",
      countryId: Number(accountInfo.country),
      company: accountInfo.company,
      typeOfUser: Number(accountInfo.plan),
      isSocialLogin: isSocialLoginUsed(),

      // what exactly are we doing here?
      authProvider: provider
        ? provider.charAt(0).toUpperCase() + provider.slice(1)
        : "",
      providerAccessToken: profile ? profile.access_token : "",
      providerKey: providerKey,
    };
    api
      .registerParticipantUser(body)
      .then((response) => {
        if (response) {
          if (response.isUserExists) {
            setIsUserExists(true);
          }
          if (response.isRegistered) {
            dispatch(addToast("New account registered") as AnyAction);
          }
          if (response.verificationEmailSent) {
            setIsUserRegistered(true);
          }
          if (response.isSocialLogin) {
            setIsUserSocialRegistered(true);
          }

          pushToDataLayer({
            event: "register_user",
            fields: {
              name: accountInfo.name,
              surname: accountInfo.surname,
              emailAddress: accountInfo.emailAddress,
              countries: accountInfo.country,
              typeOfUsers: accountInfo.plan,
            },
          });
          // If registration is successful, trigger Matomo event
          pushToMatomo({
            event: "register_user",
            fields: {
              name: accountInfo.name,
              surname: accountInfo.surname,
              emailAddress: accountInfo.emailAddress,
              countries: accountInfo.country,
              typeOfUsers: accountInfo.plan,
            },
          });
        }
      })
      .finally(() => {
        dispatch(setSpinner(false));
      });
  };

  const handleCloseUserExistsPopup = (): void => setIsUserExists(false);

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    pushToDataLayer({
      event: "formSubmit",
      formType: "registration",
      fields: {
        name: accountInfo.name,
        surname: accountInfo.surname,
        emailAddress: accountInfo.emailAddress,
        countries: accountInfo.country,
        typeOfUsers: accountInfo.plan,
      },
    });
    // Assuming you have access to accountInfo in your registration page
    pushToMatomo({
      event: "register_user",
      fields: {
        name: accountInfo.name,
        surname: accountInfo.surname,
        emailAddress: accountInfo.emailAddress,
        countries: accountInfo.country,
        typeOfUsers: accountInfo.plan,
      },
    });

    handleSubmit(event);
  };

  return (
    <AuthLayout page="register" language={languageText}>
      {!isUserRegistered && (
        <div>
          <div className="mb-2 fs-4 fw-bolder">
            {getLanguageValue(languageText, "Create your IDI account")}
          </div>
          <div className="pb-2">
            <span>
              {getLanguageValue(languageText, "Already have an account?")}{" "}
            </span>
            <Link className="fw-bold" to={routePath.signIn}>
              {getLanguageValue(languageText, "Sign in")}
            </Link>
          </div>
          <div className="row mt-3 mb-4">
            <div className="col-6">
              <LoginSocialGoogle
                client_id={process.env.REACT_APP_GG_APP_ID || ""}
                redirect_uri={process.env.REDIRECT_URI}
                scope="openid profile email"
                onResolve={({ provider, data }: IResolveParams) => {
                  if (!data || (!data.access_token && !data.code)) {
                    dispatch(setNotification("User cancelled"));
                  } else {
                    setProvider(provider);
                    setProfile(data);
                    pushToDataLayer({
                      event: "social_login",
                      provider: "google",
                      userData: data,
                    });
                  }
                }}
                onReject={(error: any) => {
                  dispatch(setNotification("User cancelled"));
                }}
              >
                <button className="btn border border-secondary-subtle p-2 w-100">
                  <span>
                    {getLanguageValue(languageText, "Register with")} <br />
                    <div className="d-flex align-items-end justify-content-center">
                      <span>
                        <GoogleIcon />
                      </span>
                      <span className="ms-2 fw-bold">Google</span>
                    </div>
                  </span>
                </button>
              </LoginSocialGoogle>
            </div>

            <div className="col-6">
              <LoginSocialMicrosoft
                client_id={process.env.REACT_APP_MICROSOFT_APP_ID || ""}
                redirect_uri={process.env.REDIRECT_URI || ""}
                scope="profile openid email user.read"
                onResolve={({ provider, data }: IResolveParams) => {
                  setProvider(provider);
                  setProfile(data);
                  pushToDataLayer({
                    event: "social_login",
                    provider: "microsoft",
                    userData: data,
                  });
                }}
                onReject={(error: any) => {
                  dispatch(setNotification(error));
                  throw error;
                }}
              >
                <button className="btn border border-secondary-subtle p-2 w-100">
                  <span>
                    {getLanguageValue(languageText, "Register with")} <br />
                    <div className="d-flex align-items-end justify-content-center">
                      <span>
                        <MicrosoftIcon />
                      </span>
                      <span className="ms-2 fw-bold">Microsoft</span>
                    </div>
                  </span>
                </button>
              </LoginSocialMicrosoft>
            </div>
          </div>
          <div className="border-top border-secondary-light mb-3"></div>

          <div className="py-3">
            {getLanguageValue(languageText, "Or register with email")}
          </div>

          <form onSubmit={handleFormSubmit}>
            <Input
              name={RegisterParticipantInputs.name}
              label={getLanguageValue(languageText, "Firstname")}
              value={accountInfo.name}
              errorMessage={focusInput.name.errorMessage}
              placeholder={getLanguageValue(languageText, "Firstname")}
              required={true}
              handleBlurEvent={handleBlurEvent}
              handleInputChange={handleInputChange}
            />
            <Input
              name={RegisterParticipantInputs.surname}
              label={getLanguageValue(languageText, "Lastname")}
              value={accountInfo.surname}
              errorMessage={focusInput.surname.errorMessage}
              placeholder={getLanguageValue(languageText, "Lastname")}
              required={true}
              handleBlurEvent={handleBlurEvent}
              handleInputChange={handleInputChange}
            />
            <Input
              name={RegisterParticipantInputs.emailAddress}
              label={getLanguageValue(languageText, "Email")}
              value={accountInfo.emailAddress}
              errorMessage={focusInput.emailAddress.errorMessage}
              placeholder={getLanguageValue(languageText, "Email")}
              required={true}
              handleBlurEvent={handleBlurEvent}
              handleInputChange={handleInputChange}
            />
            <PhoneNumberInput
              name={RegisterParticipantInputs.phoneNumber}
              label={getLanguageValue(languageText, "Phone")}
              value={accountInfo.phoneNumber}
              languageText={languageText}
              onChange={(value) => {
                setAccountInfo((prev) => {
                  return {
                    ...prev,
                    phoneNumber: value,
                  };
                });
              }}
            />
            {!(provider !== "" && profile !== "") && ( // Only show password and confirm password fields if social login is not used
              <>
                <div className="mb-3">
                  <Input
                    name={RegisterParticipantInputs.password}
                    label={getLanguageValue(languageText, "Password")}
                    placeholder={getLanguageValue(languageText, "Password")}
                    value={accountInfo.password}
                    errorMessage={focusInput.password.errorMessage}
                    handleBlurEvent={handleBlurEvent}
                    handleInputChange={handleInputChange}
                    required={!(provider !== "" && profile !== "")}
                    type="new-password"
                  />
                </div>

                <ValidationBox
                  value={accountInfo.password}
                  languageText={languageText}
                />
                <div className="mb-3">
                  <Input
                    name={RegisterParticipantInputs.confirmPassword}
                    label={getLanguageValue(languageText, "Confirm Password")}
                    placeholder={getLanguageValue(
                      languageText,
                      "Confirm Password",
                    )}
                    type="new-password"
                    value={accountInfo.confirmPassword}
                    errorMessage={focusInput.confirmPassword.errorMessage}
                    handleBlurEvent={handleBlurEvent}
                    handleInputChange={handleInputChange}
                    required={!(provider !== "" && profile !== "")}
                  />
                </div>
              </>
            )}

            <DropdownSelect
              name={RegisterParticipantInputs.country}
              label={getLanguageValue(languageText, "Country")}
              defaultLabel={getLanguageValue(languageText, "Select Country")}
              list={countries}
              value={accountInfo.country}
              focusInput={focusInput.country}
              handleDropdownSelect={handleDropdownSelect}
              searchOption
              required={true}
            />
            <DropdownSelect
              name={RegisterParticipantInputs.plan}
              label={getLanguageValue(
                languageText,
                "Choose plan How you intend to use IDI",
              )}
              defaultLabel={getLanguageValue(languageText, "Choose type")}
              list={typeOfUsers}
              value={accountInfo.plan}
              focusInput={focusInput.plan}
              handleDropdownSelect={handleDropdownSelect}
              required={true}
            />
            {isCompanyFieldRequired && (
              <Input
                label={getLanguageValue(languageText, "Company")}
                value={accountInfo.company}
                placeholder={getLanguageValue(languageText, "Company")}
                name={RegisterParticipantInputs.company}
                errorMessage={focusInput.company.errorMessage}
                handleBlurEvent={handleBlurEvent}
                handleInputChange={handleInputChange}
                required={true}
              />
            )}

            <button type="submit" className="btn btn-success w-100 mt-4">
              {getLanguageValue(languageText, "Register")}
            </button>
          </form>
        </div>
      )}

      {isUserExists && (
        <CustomModal
          headerText={getLanguageValue(languageText, "Account Exists")}
          bodyText={`${getLanguageValue(
            languageText,
            "The account already exists. Do you want to login",
          )} ?`}
          cancelButtonText={getLanguageValue(languageText, "No")}
          submitButtonText={getLanguageValue(languageText, "Yes")}
          handleCancelClick={handleCloseUserExistsPopup}
          handleSubmitClick={() => {
            navigate(routePath.signIn);
          }}
        />
      )}

      {isUserRegistered && (
        <div className="h-400px py-auto px-10">
          <p className="fs-4">
            {getLanguageValue(
              languageText,
              "Thank you for registering. You may have got an activation email to activate your account.",
            )}
          </p>
        </div>
      )}

      {isUserSocialRegistered && (
        <ExternalUserLogin provider={provider} profile={profile} />
      )}
    </AuthLayout>
  );
};
