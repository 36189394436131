import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";

import {
  ITranslationObject,
  getLanguageValue,
} from "@app/commonUtils/languageFunctionsHelper";
import {
  updateProfileParticipantPdfDownload,
  downloadParticipantProfilePdfReport,
} from "@app/containers/activityList/editActivity/actions";
import {
  ProfileStatus,
  ProfileOutputPage,
  ProfileOutputPages,
} from "@app/containers/activityList/activityEnums";
import { ModalComponent } from "@app/components/modals/modalComponent";
import { addToast, setSpinner } from "@app/containers/actions";
import { downloadFileFromBlob } from "@app/commonUtils/downloadHepler";
import { AnyAction } from "redux";
import { ActivityId, ProfileId } from "@app/containers/reducer";

interface IIndividualDownloadProps {
  languageText: ITranslationObject;
  profileId: ProfileId;
  participantName: string;
  status: number;
  pdfProfileDownloaded: boolean;
  languageCode: string;
  refetchCompletedParticipants: () => void;
  handleCloseClick: () => void;
}

export const IndividualDownload = (props: IIndividualDownloadProps) => {
  const dispatch = useDispatch();
  const activityId = (Number(useParams().id) || 0) as ActivityId;

  const reportName = `IDIProfile_${props.participantName}_${props.profileId}`;

  const [file1Clicked, setFile1Clicked] = useState<boolean>(false);
  const [file2Clicked, setFile2Clicked] = useState<boolean>(false);

  const updateDownloadStatus = () => {
    // Update profile downloaded status
    if (
      props.status === ProfileStatus.Completed &&
      !props.pdfProfileDownloaded
    ) {
      updateProfileParticipantPdfDownload(
        activityId,
        [props.profileId],
        dispatch,
      ).then((response) => {
        if (response?.success) {
          dispatch(addToast("Downloaded successfully") as AnyAction);
        }
        props.refetchCompletedParticipants();
      });
    }
  };

  useEffect(() => {
    if (file1Clicked && file2Clicked) {
      updateDownloadStatus();
    }
  }, [file1Clicked, file2Clicked]);

  const onDownloadClick = async (
    fileType: ProfileOutputPage,
  ): Promise<void> => {
    dispatch(setSpinner(true));
    const fileNameSuffix =
      fileType == ProfileOutputPages.Page1
        ? "p1"
        : fileType == ProfileOutputPages.Page2
          ? "p2"
          : "";
    try {
      const blobData = await downloadParticipantProfilePdfReport(
        [props.profileId],
        props.languageCode,
        fileType,
        dispatch,
      );

      downloadFileFromBlob(blobData, `${reportName + fileNameSuffix}.pdf`);
      if (fileType === ProfileOutputPages.All) {
        //await generatePdf(reportName, [page1Ref, page2Ref])
        updateDownloadStatus();
      }
      if (fileType === ProfileOutputPages.Page1) {
        setFile1Clicked(true);
      }
      if (fileType === ProfileOutputPages.Page2) {
        setFile2Clicked(true);
      }
    } catch (e: any) {
      //Error is suppressed as the error is aleady handled in the downloadParticipantProfilePdfReport function
    }
    dispatch(setSpinner(false));
  };

  return (
    <>
      <ModalComponent
        headerText={`${getLanguageValue(props.languageText, "Profile")} - ${props.participantName}`}
        cancelButtonText={getLanguageValue(props.languageText, "Close")}
        handleCancelClick={props.handleCloseClick}
      >
        <div className="d-flex flex-column gap-10">
          <div>
            <div className="fs-7 fw-bold text-gray-600 pb-2">
              {getLanguageValue(props.languageText, "Download as 1 file")}
            </div>
            <button
              onClick={() => {
                onDownloadClick(ProfileOutputPages.All);
              }}
              className="btn btn-success"
            >
              {getLanguageValue(props.languageText, "Single file (page 1-2)")}
            </button>
          </div>
          <div>
            <div className="fs-7 fw-bold text-gray-600 mt-5">
              {getLanguageValue(props.languageText, "Download as 2 files")}
            </div>

            <div className="d-flex my-4 gap-3">
              <button
                onClick={() => {
                  onDownloadClick(ProfileOutputPages.Page1);
                }}
                className="btn btn-success"
              >
                {getLanguageValue(props.languageText, "Page")} 1
              </button>

              <button
                onClick={() => {
                  onDownloadClick(ProfileOutputPages.Page2);
                }}
                className="btn btn-success"
              >
                {getLanguageValue(props.languageText, "Page")} 2
              </button>
            </div>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};
