import React, { useMemo } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";

import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import {
  IDropdownList,
  IDropdownSelectedItem,
} from "@app/components/formComponents/dropdownSelect";
import { IFocusError } from "../../../components/formComponents/input";
import { RootState } from "@app/store/configureStore";
import { createWord, updateWord } from "./actions";
import { getBehaveOptions } from "../interface";
import { addToast, setSpinner } from "../../actions";
import { Behave, IWords } from "../hooks";

export const WordInputs = {
  wordNo: "wordNo",
  leftWord: "leftWord",
  rightWord: "rightWord",
  display: "display",
  calculate: "calculate",
  isScoredLeftToRight: "isScoredLeftToRight",
  behave: "behave",
  countryCode: "countryCode",
  idiLanguageId: "idiLanguageId",
} as const;

export interface IAddEditWordModalProps {
  rowData: IWords;
  cultures: IDropdownList<number>[];
  countriesList: IDropdownList<number>[];
  closeWordModal: (refreshWordList: boolean) => void;
}

interface IFocusInput {
  wordNo: IFocusError;
  leftWord: IFocusError;
  rightWord: IFocusError;
  countryCode: IFocusError;
  idiLanguageId: IFocusError;
  behave: IFocusError;
}

interface IPayloadBody {
  id?: number;
  wordNo: string;
  leftWord: string;
  rightWord: string;
  calculate: boolean;
  display: boolean;
  behave: number;
  isScoredLeftToRight: boolean;
  countryCode: number;
  idiLanguageId: number;
  englishRightWord?: string;
  englishLeftWord?: string;
}

export const useAddEditWordModal = (props: IAddEditWordModalProps) => {
  const dispatch = useDispatch();
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const [wordInfo, setWordInfo] = useState<IWords>({
    wordNo: "",
    leftWord: "",
    rightWord: "",
    calculate: false,
    display: false,
    behave: Behave.Affiliation,
    isScoredLeftToRight: false,
    countryCode: 0,
    idiLanguageId: 0,
  });

  const [focusInput, setFocusInput] = useState<IFocusInput>({
    wordNo: {
      touched: false,
      errorMessage: "",
    },
    leftWord: {
      touched: false,
      errorMessage: "",
    },
    rightWord: {
      touched: false,
      errorMessage: "",
    },
    countryCode: {
      touched: false,
      errorMessage: "",
    },
    idiLanguageId: {
      touched: false,
      errorMessage: "",
    },
    behave: {
      touched: false,
      errorMessage: "",
    },
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  let isEditClicked: boolean = false;
  if (Object.keys(props.rowData).length > 0) isEditClicked = true;

  const bhaveOptions = getBehaveOptions(languageText);
  useEffect(() => {
    if (isEditClicked) {
      updatewordInfo(props.countriesList, props.cultures);
    }
  }, []);

  const updatewordInfo = (
    countries: IDropdownList<number>[],
    cultures: IDropdownList<number>[],
  ): void => {
    setWordInfo({
      ...wordInfo,
      wordNo: props.rowData.wordNo.substring(1),
      leftWord: props.rowData.leftWord,
      rightWord: props.rowData.rightWord,
      calculate: props.rowData.calculate,
      display: props.rowData.display,
      behave: props.rowData.behave,
      isScoredLeftToRight: props.rowData.isScoredLeftToRight,
      countryCode: props.rowData.countryCode,
      idiLanguageId: props.rowData.idiLanguageId,
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name as keyof IFocusInput;
    const value = e.target.value;

    if (name === WordInputs.wordNo && value && isNaN(Number(value))) {
      return;
    }

    setWordInfo({
      ...wordInfo,
      [name]: value,
    });

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  };

  function handleDropdownSelect<T>(
    selectedItem: IDropdownSelectedItem<T>,
  ): void {
    const name = selectedItem.name as keyof IFocusInput;
    const value = selectedItem.value;

    setWordInfo({
      ...wordInfo,
      [name]: value,
    });

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  }

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const name = e.target.name;
    if (e.target.name === WordInputs.display) {
      setWordInfo({
        ...wordInfo,
        display: e.target.checked,
      });
    } else {
      setWordInfo({
        ...wordInfo,
        [name]: e.target.checked,
      });
    }
  };

  const handleFormErrors = (name: string, value: unknown): void => {
    let errorMessage: string = "";

    switch (name) {
      case WordInputs.wordNo:
        if (!value) {
          errorMessage = getLanguageValue(languageText, "Word No. is required");
        }
        break;
      case WordInputs.leftWord:
        if (!value) {
          errorMessage = getLanguageValue(
            languageText,
            "Left word is required",
          );
        }
        break;
      case WordInputs.rightWord:
        if (!value) {
          errorMessage = getLanguageValue(
            languageText,
            "Right word is required",
          );
        }
        break;
      case WordInputs.behave:
        if (!value) {
          errorMessage = getLanguageValue(
            languageText,
            "Behave value is required",
          );
        }
        break;
      case WordInputs.idiLanguageId:
        if (!value) {
          errorMessage = getLanguageValue(languageText, "Culture is required");
        }
        break;
      default:
        break;
    }

    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }));
  };

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    const name = e.target.name;
    const value = e.target.value;
    handleFormErrors(name, value);
  };

  const handleValidationOnSubmit = (): boolean => {
    if (
      !wordInfo.wordNo ||
      focusInput.wordNo.errorMessage ||
      !wordInfo.leftWord ||
      focusInput.leftWord.errorMessage ||
      !wordInfo.rightWord ||
      focusInput.rightWord.errorMessage ||
      !wordInfo.behave ||
      focusInput.behave.errorMessage ||
      !wordInfo.idiLanguageId ||
      focusInput.idiLanguageId.errorMessage
    ) {
      for (const item of Object.values(WordInputs)) {
        handleFormErrors(item, wordInfo[item]);
      }
      return false;
    }
    return true;
  };

  const handleSubmit = (e: boolean): void => {
    setIsSubmitting(true);

    const body: IPayloadBody = {
      wordNo: wordInfo.wordNo,
      leftWord: wordInfo.leftWord,
      rightWord: wordInfo.rightWord,
      calculate: wordInfo.calculate,
      display: wordInfo.display,
      behave: Number(wordInfo.behave),
      isScoredLeftToRight: wordInfo.isScoredLeftToRight,
      countryCode: Number(wordInfo.countryCode),
      idiLanguageId: Number(wordInfo.idiLanguageId),
    };

    if (isEditClicked) {
      body.id = props.rowData.id ?? 0;
      body.englishRightWord = props.rowData.englishRightWord;
      body.englishLeftWord = props.rowData.englishLeftWord;
    }

    dispatch(setSpinner(true));
    if (isEditClicked) {
      updateWord(body, dispatch)
        .then((response) => {
          if (response?.status === 200) {
            dispatch(addToast("Changes saved successfully") as AnyAction);
            closeAddEditWordModal(true);
          }
        })
        .finally(() => {
          setIsSubmitting(false);
          dispatch(setSpinner(false));
        });
    } else {
      createWord(body, dispatch)
        .then((response) => {
          if (response?.status === 200) {
            dispatch(addToast("Word created successfully") as AnyAction);
            closeAddEditWordModal(true);
          }
        })
        .finally(() => {
          setIsSubmitting(false);
          dispatch(setSpinner(false));
        });
    }
  };

  const onSubmitClick = (): void => {
    if (!handleValidationOnSubmit()) return;
    else if (isEditClicked) {
      handleSubmit(false);
    } else {
      handleSubmit(true);
    }
  };

  const closeAddEditWordModal = (value: boolean) => {
    props.closeWordModal(value);
  };

  const closeModal = () => closeAddEditWordModal(false);

  return {
    isEditClicked,
    languageText,
    wordInfo,
    focusInput,
    isSubmitting,
    bhaveOptions,
    onSubmitClick,
    handleCheckboxChange,
    handleDropdownSelect,
    closeModal,
    handleBlurEvent,
    handleInputChange,
  };
};
