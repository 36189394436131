import * as React from "react";
import { ITranslationObject } from "@app/commonUtils/languageFunctionsHelper";
import { LanguageNavbar } from "@app/components/languageNavbarComponent/languageNavbar";

type Props = React.PropsWithChildren<{
  page: "login" | "register";
  language: ITranslationObject;
  hideLanguageSelection?: boolean;
}>;

export const AuthLayout: React.FC<Props> = (props) => {
  return (
    <div className="min-vh-100 d-flex align-items-md-center justify-content-center">
      <div className="container">
        <div className="row p-3 p-md-0 justify-content-center">
          <div className="col-12 col-md-9 col-lg-7 col-xl-4 shadow-sm p-4 p-md-5 bg-white rounded">
            <LanguageNavbar
              hideLanguageSelection={props.hideLanguageSelection}
              languageCodeInURL={true}
            />
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};
