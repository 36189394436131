import * as React from "react";

import {
  CorrectAnswerCard,
  CourseBreadcumb,
  NavigationButtons,
} from "../components";
import { useQuiz } from "./hooks";
import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import { classNames } from "@app/containers/utils";

export const Quiz = (): JSX.Element => {
  const {
    courseQuiz,
    activeQuiz,
    languageText,
    userQuizDetails,
    handleOptionChange,
    forwardClickHandler,
    backClickHandler,
  } = useQuiz();

  const quizzes = courseQuiz?.quizes ?? [];
  const isAnswered = !!userQuizDetails?.quizOptionId;
  const isCorrect = quizzes[activeQuiz]?.quizOptions.some(
    (o) => o.id === userQuizDetails?.quizOptionId && o.isCorrect,
  );
  const selectedOption = userQuizDetails?.quizOptionId;

  return (
    <>
      {courseQuiz && (
        <div className="mt-3 p-2 p-md-4 col-md-8 mx-auto">
          <CourseBreadcumb languageText={languageText} course={courseQuiz} />
          <div className="shadow-sm p-4 rounded bg-white">
            <div className="d-flex justify-content-between">
              <h3 className="mb-4 fw-bold pt-1">
                <i className="bi bi-question-circle me-2 fs-4 text-dark"></i>
                {getLanguageValue(languageText, "Quiz")}
              </h3>
              <div className="d-flex flex-nowrap mb-4">
                {quizzes.length > 0 ? (
                  quizzes.map((q, index) => (
                    <div
                      className={classNames({
                        "rounded-circle p-2 py-1 px-md-3 py-md-2 me-1": true,
                        "bg-success text-white": activeQuiz > index,
                        "bg-white border text-dark": activeQuiz < index,
                        "bg-dark border text-white": activeQuiz === index,
                      })}
                      key={index}
                    >
                      {index + 1}
                    </div>
                  ))
                ) : (
                  <div className="fs-5">
                    {getLanguageValue(languageText, "No quiz available")}
                  </div>
                )}
              </div>
            </div>
            <p className="fw-bold fs-4 mb-4 pt-2">
              {quizzes && quizzes[activeQuiz]?.question}
            </p>
            {/* Choices */}
            <div className="d-flex flex-column content-justify-center flex-row-fluid">
              {quizzes.length > 0 &&
                quizzes[activeQuiz].quizOptions &&
                quizzes[activeQuiz].quizOptions.map((option) => {
                  return (
                    <div
                      key={option.id}
                      className="d-flex align-items-center mb-1 fs-5"
                    >
                      <div className="mb-3">
                        <div className="d-flex align-items-center">
                          <input
                            className="btn-check"
                            type="checkbox"
                            value={option.id}
                            id={String(option.id)}
                            disabled={isAnswered}
                            checked={selectedOption === option.id}
                            onChange={(event) => {
                              handleOptionChange(option.id);
                            }}
                          />
                          <label
                            htmlFor={String(option.id)}
                            className={classNames({
                              "me-4 btn border p-1 px-2": isAnswered,
                              "me-4 btn border border-dark p-1 px-2":
                                !isAnswered,
                              "bg-success": isAnswered && option.isCorrect,
                              "bg-danger":
                                isAnswered &&
                                option.id === userQuizDetails?.quizOptionId &&
                                !option.isCorrect,
                            })}
                            style={{
                              opacity: 1,
                              cursor: "pointer",
                            }}
                          >
                            {isAnswered ? (
                              option.isCorrect ? (
                                <i className="bi bi-check-lg fs-4 text-white"></i>
                              ) : (
                                <i className="bi bi-x-lg fs-4 text-white"></i>
                              )
                            ) : (
                              <i className="bi bi-check-lg fs-4 text-white"></i>
                            )}
                          </label>
                          <div>{option.answerOption}</div>
                        </div>
                      </div>
                    </div>
                  );
                })}

              {selectedOption && (
                <CorrectAnswerCard
                  languageText={languageText}
                  label={quizzes[activeQuiz].correctAnswerReason}
                  isCorrect={isCorrect}
                />
              )}
            </div>

            <NavigationButtons
              onForwardClick={forwardClickHandler}
              onBackClick={backClickHandler}
              isDisabled={!isAnswered}
              forwardLabel={
                activeQuiz === quizzes.length - 1 &&
                getLanguageValue(languageText, "Finish")
              }
            />
          </div>
        </div>
      )}
    </>
  );
};
