import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { CreateNewProfile } from "./createNewProfileModal";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../commonUtils/languageFunctionsHelper";
import {
  CoursePresentationStatus,
  ProfileStatus,
} from "../../../activityList/activityEnums";
import { routePath } from "../../../routePaths";
import { RootState } from "@app/store/configureStore";
import { clearParticipantProfileDetails } from "../../actions";
import { ucQueryKey } from "../../useParticipantProfileAuth";
import { IProfileStatus } from "@app/types";

interface IStatusProps {
  languageText: ITranslationObject;
  profileStatus: IProfileStatus;
  readOnly: boolean;
}

export const Status = (props: IStatusProps): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userDetails = useSelector(
    (state: RootState) => state.loginReducer.userDetails,
  );

  // what does this mean? a profile cannot _not_ have an activity ID.
  const isRoledisabled = props.profileStatus.activityId ? false : true;
  const isRoleFilled = props.profileStatus.isRoleFilled;
  const isSelfFormDisabled = !props.profileStatus.isRoleFilled;
  const isSelfFormFilled = props.profileStatus.isSelfFormFilled;
  const isRespondentsdisabled = !props.profileStatus.isSelfFormFilled;
  const isRespondentsInvited = props.profileStatus.isRespondentsInvited;
  const isPresentationCreated = props.profileStatus.isPresentationCreated;

  // Profile navigation
  const handleProfileNavigation = (): void => {
    dispatch(clearParticipantProfileDetails());

    // nice copy pasta, bro.
    if (props.profileStatus.profileId) {
      // Profile Presentation detail page
      navigate(
        `${routePath.participantCreate}?${ucQueryKey}=${props.profileStatus.uniqueCode}`,
      );
    } else {
      navigate(
        `${routePath.participantWelcomePage}?${ucQueryKey}=${props.profileStatus.uniqueCode}`,
      );
    }
  };

  // Profile report
  const handleProfileReport = (): void => {
    if (props.readOnly) return;

    navigate(
      routePath.participantProfileReport.replace(
        ":profileId",
        props.profileStatus.profileId.toString(),
      ),
    );
  };

  return (
    <div className="card h-100 shadow-sm bg-white rounded border-0">
      <div className="card-header border-0 p-3 fs-5 text-body-tertiary">
        {getLanguageValue(props.languageText, "Status")}
      </div>
      <div className="card-body p-4">
        {/* No profile - Create new one */}
        {isRoledisabled && (
          <div className="fs-5 fw-bold mb-3 d-flex justify-content-between align-items-center">
            {getLanguageValue(props.languageText, "Get started")} -{" "}
            {getLanguageValue(props.languageText, "create your IDI Profile")}
            {userDetails.enableParticipantCreateProfile && (
              <CreateNewProfile
                languageText={props.languageText}
                readOnly={props.readOnly ?? false}
              />
            )}
          </div>
        )}

        {/* Ongoing profiles  */}
        {!isRoledisabled && !isRespondentsInvited && (
          <div className="d-flex justify-content-between mb-3">
            <div className="fs-5 fw-bold">
              {getLanguageValue(
                props.languageText,
                "Your profile is in progress",
              )}
              .
            </div>
            <div className="d-none float-end">
              <span className="badge bg-light color-999 p-2">
                {props.profileStatus.activityName} -{" "}
                {props.profileStatus.profileDate.slice(0, 10)}
              </span>
            </div>
            <button
              className="btn btn-sm btn-success"
              disabled={props.readOnly}
              onClick={handleProfileNavigation}
            >
              {getLanguageValue(props.languageText, "Go to profile")}
              <i className="bi bi-arrow-right ms-2"></i>
            </button>
          </div>
        )}

        {/* Completed Profiles */}
        {!isRoledisabled &&
          isRespondentsInvited &&
          props.profileStatus.status !== ProfileStatus.Delivery && (
            <>
              <h5>
                {getLanguageValue(
                  props.languageText,
                  "Great, your IDI profile is completed",
                )}
                !
              </h5>
              {!isPresentationCreated ? (
                <>
                  <div>
                    {getLanguageValue(
                      props.languageText,
                      "Profile presentation is not planned yet",
                    )}
                    .
                  </div>
                  <button
                    className="btn btn-sm btn-success"
                    disabled={props.readOnly}
                    onClick={handleProfileNavigation}
                  >
                    {getLanguageValue(props.languageText, "Go to profile")}
                  </button>
                </>
              ) : (
                <div>
                  {props.profileStatus.presentationStatus ===
                  CoursePresentationStatus.Ongoing ? (
                    <div className="my-2">
                      {getLanguageValue(
                        props.languageText,
                        "Profile presentation is ongoing",
                      )}
                    </div>
                  ) : (
                    <div className="my-2">
                      {getLanguageValue(
                        props.languageText,
                        "Presentation is planned at",
                      )}
                      : {props.profileStatus.presentationDate.slice(0, 10)}{" "}
                      {getLanguageValue(props.languageText, "at")}{" "}
                      {props.profileStatus.presentationDate.slice(11, 16)}
                    </div>
                  )}
                  <button
                    className="btn btn-sm btn-success mb-3"
                    disabled={props.readOnly}
                    onClick={handleProfileNavigation}
                  >
                    {getLanguageValue(
                      props.languageText,
                      "Profile presentation",
                    )}
                  </button>
                </div>
              )}
            </>
          )}

        {/* Delivered Profiles */}
        {props.profileStatus.status === ProfileStatus.Delivery && (
          <div className="mb-4">
            <div className="float-end mt-2">
              <button
                className="btn btn-success"
                onClick={handleProfileReport}
                disabled={props.readOnly}
              >
                {getLanguageValue(props.languageText, "Show profile")}{" "}
                <i className="bi bi-arrow-right fs-6 ms-2"></i>
              </button>
              {userDetails.enableParticipantCreateProfile && (
                <CreateNewProfile
                  languageText={props.languageText}
                  readOnly={props.readOnly || false}
                />
              )}
            </div>
            <div className="fs-4 fw-bold">
              {getLanguageValue(
                props.languageText,
                "Your Profile is delivered",
              )}
              !
            </div>
            {getLanguageValue(
              props.languageText,
              "Continue with further studies in IDI Academy",
            )}
            .
          </div>
        )}

        {/* Profile status */}
        <div className="row mx-0">
          {/* Role */}
          <div
            className={clsx(
              "col-md-3 justify-content-between align-items-center bg-gradient border p-2 d-flex rounded",
              isRoleFilled
                ? "bg-success bg-opacity-25 border-white"
                : "bg-success text-white",
            )}
            role={
              !props.readOnly && !isRoledisabled && !isRoleFilled
                ? "button"
                : ""
            }
            onClick={
              !props.readOnly && !isRoledisabled && !isRoleFilled
                ? handleProfileNavigation
                : () => {}
            }
          >
            <div className="small">
              1. {getLanguageValue(props.languageText, "Role")}
            </div>

            {isRoleFilled ? (
              <i className="bi bi-check-lg fs-5 text-success"></i>
            ) : (
              <i className="bi bi-arrow-right fs-5 text-grey"></i>
            )}
          </div>

          {/* Self Assessment */}
          <div
            className={clsx(
              "col-md-3 justify-content-between align-items-center bg-gradient border p-2 d-flex rounded",
              isSelfFormDisabled
                ? ""
                : isSelfFormFilled
                  ? "bg-success bg-opacity-25 border-white"
                  : "bg-success text-white",
            )}
            role={
              !props.readOnly && !isSelfFormDisabled && !isSelfFormFilled
                ? "button"
                : ""
            }
            onClick={
              !props.readOnly && !isSelfFormDisabled && !isSelfFormFilled
                ? handleProfileNavigation
                : () => {}
            }
          >
            <div className="small">
              2. {getLanguageValue(props.languageText, "Self assessment")}
            </div>

            {!isSelfFormDisabled &&
              (isSelfFormFilled ? (
                <i className="bi bi-check-lg fs-5 text-success"></i>
              ) : (
                <i className="bi bi-arrow-right fs-5"></i>
              ))}
          </div>

          {/* Respondents */}
          <div
            className={clsx(
              "col-md-3 justify-content-between align-items-center bg-gradient border p-2 d-flex rounded",
              isRespondentsdisabled
                ? ""
                : isRespondentsInvited
                  ? "bg-success bg-opacity-25 border-white"
                  : "bg-success text-white",
            )}
            role={
              !props.readOnly && !isRespondentsdisabled && !isRespondentsInvited
                ? "button"
                : ""
            }
            onClick={
              !props.readOnly && !isRespondentsdisabled && !isRespondentsInvited
                ? handleProfileNavigation
                : () => {}
            }
          >
            <div className="small">
              3. {getLanguageValue(props.languageText, "Respondents")}
            </div>

            {!isRespondentsdisabled &&
              (isRespondentsInvited ? (
                <i className="bi bi-check-lg fs-5 text-success"></i>
              ) : (
                <i className="bi bi-arrow-right fs-5"></i>
              ))}
          </div>

          {/* Profile */}
          <div
            className={clsx(
              "col-md-3 justify-content-between align-items-center bg-gradient border p-2 d-flex rounded",
              !isPresentationCreated
                ? ""
                : isPresentationCreated &&
                    (props.profileStatus.presentationStatus ===
                      CoursePresentationStatus.Completed ||
                      props.profileStatus.status === ProfileStatus.Delivery)
                  ? "bg-success bg-opacity-25 border-white"
                  : "bg-success text-white",
            )}
            role={
              !props.readOnly &&
              isPresentationCreated &&
              (props.profileStatus.presentationStatus ===
                CoursePresentationStatus.Planned ||
                props.profileStatus.presentationStatus ===
                  CoursePresentationStatus.Ongoing)
                ? "button"
                : ""
            }
            onClick={
              !props.readOnly &&
              isPresentationCreated &&
              (props.profileStatus.presentationStatus ===
                CoursePresentationStatus.Planned ||
                props.profileStatus.presentationStatus ===
                  CoursePresentationStatus.Ongoing)
                ? handleProfileNavigation
                : () => {}
            }
          >
            <div className="small">
              4. {getLanguageValue(props.languageText, "Profile")}
            </div>

            {isPresentationCreated &&
              (props.profileStatus.presentationStatus ===
                CoursePresentationStatus.Completed ||
              props.profileStatus.status === ProfileStatus.Delivery ? (
                <i className="bi bi-check-lg fs-5 text-success"></i>
              ) : (
                <i className="bi bi-arrow-right fs-5"></i>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
