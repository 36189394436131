import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnSort,
  AccessorKeyColumnDef,
  OnChangeFn,
  SortingState,
} from "@tanstack/react-table";
import clsx from "clsx";
import { Collapse } from "react-bootstrap";

import { IPageSelected, IReactTableProps, TableSort } from "./interface";
import { RootState } from "@app/store/configureStore";
import {
  getWindowDimensions,
  IWindowDimensions,
} from "../../commonUtils/screenWidthHelper";
import { getLanguageValue } from "../../commonUtils/languageFunctionsHelper";
import {
  DropdownSelect,
  IDropdownSelectedItem,
} from "@app/components/formComponents/dropdownSelect";
import { classNames } from "@app/containers/utils";
import { updatePageConfiguredInfo } from "@app/containers/actions";
import { IPageConfiguredInfo } from "@app/containers/reducer";
import { DEFAULT_TABLE_ROWS_PER_PAGE } from "@app/consts";
import { Pagination } from "./pagination";

type HackyColumnDefWithMonkeyPatchedStuff<T> = AccessorKeyColumnDef<T> & {
  className?: string;
  width?: number;
};

export const getInitialPageConfiguredInfoState = (): IPageConfiguredInfo => ({
  pathname: window.location.pathname,
  searchText: "",
  sorting: "",
  pageLimit: DEFAULT_TABLE_ROWS_PER_PAGE,
  pageSelected: 0,
});

function parseStringIntoColumnSort(value: string): Array<ColumnSort> {
  const splitSort = value.split(" ");
  const sort: ColumnSort = {
    id: splitSort[0],
    desc: splitSort[1] === TableSort.desc,
  };
  return [sort];
}

export function ReactTable<T>(props: IReactTableProps<T>): JSX.Element {
  const dispatch = useDispatch();
  const pathname = window.location.pathname;

  const pageConfiguredInfo: IPageConfiguredInfo[] = useSelector(
    (state: RootState) => state.mainReducer.pageConfiguredInfo,
  );
  const currentPageInfo = props.storePageConfiguredInfo
    ? (pageConfiguredInfo.find((info) => info.pathname === pathname) ??
      getInitialPageConfiguredInfoState())
    : undefined;

  // Handling Language
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );
  const filterButtonRef = useRef<HTMLButtonElement>(null);
  const filterDropdownRef = useRef<HTMLDivElement>(null);
  const isSortMounted = useRef<boolean>(false);

  const [windowDimensions, setWindowDimensions] = useState<IWindowDimensions>(
    getWindowDimensions(),
  );
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [filterDropdownValue, setFilterDropdownValue] = useState<string>("");
  const [filteredButtonText, setFilteredButtonText] = useState<
    string | JSX.Element
  >("");
  const [showCustomFilters, setShowCustomFilters] = useState<boolean>(
    !!props.showCustomFilters,
  );

  useEffect(() => {
    const handleResize = (): void => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // handleClickOutside event listener
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (
      !props.data.length &&
      props.pageSelected !== undefined &&
      props.pageSelected > 0
    ) {
      props.setPageSelected?.(props.pageSelected! - 1);
      if (props.storePageConfiguredInfo) {
        const updatedInfo: IPageConfiguredInfo = {
          ...currentPageInfo!,
          pageSelected: props.pageSelected! - 1,
        };
        dispatch(updatePageConfiguredInfo(updatedInfo));
      }
    }
  }, [props.data]);

  const handleClickOutside = (event: MouseEvent): void => {
    if (
      filterDropdownRef.current &&
      !filterDropdownRef.current.contains(event.target as Node)
    ) {
      setShowDropdown(false);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    props.setPageSelected?.(0);
    props.setFilter?.(e.target.value);
    if (props.storePageConfiguredInfo) {
      const updatedInfo: IPageConfiguredInfo = {
        ...currentPageInfo!,
        searchText: e.target.value,
        pageSelected: 0,
      };
      dispatch(updatePageConfiguredInfo(updatedInfo));
    }
  };

  const clearSearch = () => {
    props.setFilter && props.setFilter("");
    if (props.storePageConfiguredInfo) {
      const updatedInfo: IPageConfiguredInfo = {
        ...currentPageInfo!,
        searchText: "",
      };
      dispatch(updatePageConfiguredInfo(updatedInfo));
    }
  };

  const handleClearAllFilters = () => {
    props.setFilter && props.setFilter("");
    props.setSorting && props.setSorting("");
    props.setPageLimit && props.setPageLimit(DEFAULT_TABLE_ROWS_PER_PAGE);
    props.setPageSelected && props.setPageSelected(0);
    if (props.storePageConfiguredInfo) {
      const updatedInfo: IPageConfiguredInfo =
        getInitialPageConfiguredInfoState();
      dispatch(updatePageConfiguredInfo(updatedInfo));
    }
    if (props.handleClearCustomFilters) props.handleClearCustomFilters();
  };

  const handlePageLimit = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const value = Number(e.target.value);
    let limit = 0;
    if (value) {
      limit = value;
    } else {
      limit = props.totalCount!;
    }
    props.setPageLimit?.(limit);
    props.setPageSelected?.(0);
    window.scrollTo(0, 0);
    if (props.storePageConfiguredInfo) {
      const updatedInfo: IPageConfiguredInfo = {
        ...currentPageInfo!,
        pageLimit: limit,
        pageSelected: 0,
      };
      dispatch(updatePageConfiguredInfo(updatedInfo));
    }
  };

  const handlePageChange = (e: IPageSelected): void => {
    props.setPageSelected?.(e.selected);
    window.scrollTo(0, 0);
    if (props.storePageConfiguredInfo) {
      const updatedInfo: IPageConfiguredInfo = {
        ...currentPageInfo!,
        pageSelected: e.selected,
      };
      dispatch(updatePageConfiguredInfo(updatedInfo));
    }
  };

  const handleTableSorting: OnChangeFn<SortingState> = (updater): void => {
    const parsed = parseStringIntoColumnSort(props.sorting || "");
    const sortedHeader: ColumnSort = (
      typeof updater === "function" ? updater(parsed) : updater
    )[0];
    if (
      sortedHeader.id === "actions" ||
      sortedHeader.id === "isTempDefaultClient"
    )
      return;

    // Setting sort format to send to api
    const { id, desc } = sortedHeader;
    const sortType = desc ? TableSort.desc : TableSort.asc;
    const next = `${id} ${sortType}`;
    props.setSorting?.(next);
    if (props.storePageConfiguredInfo) {
      const updatedInfo: IPageConfiguredInfo = {
        ...currentPageInfo!,
        sorting: next,
      };
      dispatch(updatePageConfiguredInfo(updatedInfo));
      isSortMounted.current = true;
    }
  };

  const reactTableSort = parseStringIntoColumnSort(props.sorting || "");
  const table = useReactTable<T>({
    data: props.data,
    columns: props.tableHeader,
    state: {
      sorting: reactTableSort,
    },
    onSortingChange: handleTableSorting,
    getCoreRowModel: getCoreRowModel(),
    sortDescFirst: false,
    enableSortingRemoval: false,
  });

  const toggleDropdown = (event: SyntheticEvent): void => {
    event.preventDefault();
    setShowDropdown(!showDropdown);
  };

  const handleDropdownSelect = (selectedItem: IDropdownSelectedItem): void => {
    const value = selectedItem.value;
    setFilterDropdownValue(value);
  };

  const onApplyFilterClick = (): void => {
    if (!filterDropdownValue) return;

    const selectedOption =
      props.filterDropdownList &&
      props.filterDropdownList.find(
        (option) => option.value === filterDropdownValue,
      );
    const updatedFilteredButtonText = selectedOption
      ? selectedOption.displayName
      : getLanguageValue(languageText, "Filter");
    setFilteredButtonText(updatedFilteredButtonText);
    props.setFilterDropdownTable?.(filterDropdownValue);
    setShowDropdown(false);
  };

  const onResetFilterClick = (): void => {
    props.setFilterDropdownTable?.("");
    setFilterDropdownValue("");
    setFilteredButtonText("");
    setShowDropdown(false);
  };

  const responsive = props.responsive ?? true;
  const paginationRequired = props.paginationRequired ?? true;

  return (
    <div className={clsx(props.wrapperClass && props.wrapperClass)}>
      <div className="d-md-flex justify-content-between">
        {props.headerText ? (
          <h3 className="mb-3 text-nowrap pe-3 fw-bold">{props.headerText}</h3>
        ) : (
          <div />
        )}
        {(props.filter !== undefined ||
          props.handleButtonClick ||
          props.customFilters) && (
          <div className="d-flex mb-4 justify-content-between gap-2">
            {props.customFilters && (
              <button
                onClick={() => setShowCustomFilters(!showCustomFilters)}
                className={clsx(
                  "btn border",
                  showCustomFilters ? "btn-success" : "bg-white",
                )}
                aria-expanded={showCustomFilters}
              >
                <i className="bi bi-filter pe-2" />
                {getLanguageValue(languageText, "Filter")}
              </button>
            )}
            {props.filter !== undefined && (
              <button
                type="button"
                className={clsx(
                  "btn btn-light",
                  props.showClearFiltersButton
                    ? "bg-primary-subtle border-secondary-subtle"
                    : !props.filter &&
                        !reactTableSort.length &&
                        props.pageLimit !== undefined &&
                        props.pageLimit === DEFAULT_TABLE_ROWS_PER_PAGE &&
                        props.pageSelected !== undefined &&
                        props.pageSelected === 0
                      ? "d-none"
                      : "bg-primary-subtle border-secondary-subtle",
                )}
                onClick={handleClearAllFilters}
              >
                {getLanguageValue(languageText, "Clear")}
              </button>
            )}
            {props.filter !== undefined && (
              <div className="position-relative">
                <input
                  type="text"
                  data-kt-user-table-filter="search"
                  className="form-control bg-white"
                  placeholder={props.searchPlaceholder}
                  value={props.filter}
                  onChange={handleSearch}
                />
                {props.filter && (
                  <button
                    type="button"
                    className="btn btn-clear-search position-absolute top-50 end-0 translate-middle-y p-2"
                    onClick={clearSearch}
                  >
                    <i className="bi bi-x-lg text-danger"></i>
                  </button>
                )}
              </div>
            )}

            {props.handleButtonClick && (
              <div className="d-flex ms-0 gap-2 justify-content-md-end justify-content-between">
                {props.filterDropdownLabel && (
                  <div className="border-start border-secondary-light ps-3">
                    <button
                      ref={filterButtonRef}
                      onClick={toggleDropdown}
                      type="button"
                      className="btn btn-light-success"
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-end"
                    >
                      <i className="bi bi-filter"></i>
                      {getLanguageValue(languageText, "Filter")}
                    </button>
                    {showDropdown && (
                      <div
                        ref={filterDropdownRef}
                        className={clsx(
                          "position-absolute menu menu-sub menu-sub-dropdown menu-rounded menu-gray-600 menu-state-bg-light-primary show text-nowrap",
                        )}
                        tabIndex={-1}
                      >
                        <div className="menu-item p-3 bg-white">
                          <div className="px-1">
                            <DropdownSelect
                              label={props.filterDropdownLabel}
                              name={filterDropdownValue}
                              defaultLabel={props.filterDropdownDefaultLabel}
                              value={filterDropdownValue}
                              handleDropdownSelect={handleDropdownSelect}
                              list={props.filterDropdownList || []}
                              searchOption
                            />
                          </div>
                          <div className="d-flex gap-2">
                            <button
                              className="btn btn-light"
                              onClick={onResetFilterClick}
                            >
                              {getLanguageValue(languageText, "Reset")}
                            </button>
                            <button
                              className="btn btn-success"
                              onClick={onApplyFilterClick}
                            >
                              {getLanguageValue(languageText, "Apply")}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {props.buttonText && (
                  <div className="border-start border-secondary-light ps-3 ms-3">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={props.handleButtonClick}
                    >
                      <i className="bi bi-plus-lg"></i>
                      {
                        // buttonText
                      }
                    </button>
                  </div>
                )}
                {props.buttonSecondOptionalText && (
                  <div>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={props.handleSecondButtonClick}
                    >
                      {props.buttonSecondOptionalText}
                    </button>
                  </div>
                )}
                {props.customButtons && props.customButtons}
              </div>
            )}
          </div>
        )}

        {filteredButtonText && (
          <div className="ms-6 me-5 mb-4 border rounded bg-gray-100 d-flex align-items-center justify-content-between">
            <p className="mt-4 px-2">
              {getLanguageValue(languageText, "Filters added")}:
            </p>
            <div className="d-flex column align-items-center">
              <p className="mt-4 px-1">
                {getLanguageValue(languageText, "Culture")}:
              </p>
              <p className="mt-4">{filteredButtonText}</p>
              <i
                onClick={onResetFilterClick}
                className="cursor-pointer px-4 bi bi-trash3"
              ></i>
            </div>
          </div>
        )}
      </div>

      {props.customFilters && (
        <Collapse in={showCustomFilters}>
          {/* Don't delete this below div, else collapse won't work */}
          <div>{props.customFilters}</div>
        </Collapse>
      )}

      {/* // Container for responsive table with conditional height adjustments */}
      <div
        className={clsx(
          responsive && "table-responsive bg-white rounded border",
          !props.isTranslationList && false,
          props.minHeight && windowDimensions.width > 599
            ? props.minHeight
            : windowDimensions.width > 599
              ? ""
              : "",
        )}
      >
        {table.getCoreRowModel().rows.length > 0 ? (
          <table
            id="kt_table_users"
            className={classNames({
              "table dataTable mb-0": true,
              "align-middle": responsive,
              "table-hover": !!props.handleRowClick,
            })}
            style={{
              /**
               * '.table-custom .table' has a style 'width: max-content' and we definitely don't want
               * that for non-responsive tables.
               * */
              width: responsive ? undefined : "inherit",
            }}
          >
            <thead>
              {table.getHeaderGroups().map((headerGroup, i) => (
                <tr key={i}>
                  {headerGroup.headers.map((headers, index) => {
                    const { header, className, accessorKey, width } = headers
                      .column
                      .columnDef as HackyColumnDefWithMonkeyPatchedStuff<T>;
                    const { getIsSorted, getToggleSortingHandler } =
                      headers.column;
                    return (
                      <th
                        className={clsx(
                          "fw-normal py-4 pe-2 ps-0 text-secondary small",
                          className && className,
                          width && width,
                          index === 0 && "ps-2",
                        )}
                        key={
                          typeof accessorKey === "string" ? accessorKey : index
                        }
                        role="button"
                        onClick={getToggleSortingHandler()}
                      >
                        {flexRender(header, headers.getContext())}
                        {getIsSorted() ? (
                          getIsSorted() === "asc" ? (
                            <i className="bi bi-chevron-up ms-2 my-1" />
                          ) : (
                            <i className="bi bi-chevron-down ms-2 my-1" />
                          )
                        ) : (
                          ""
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getCoreRowModel().rows.map((row) => (
                <tr
                  onClick={(event) => {
                    props.handleRowClick?.(event, row);
                  }}
                  role={props.handleRowClick ? "button" : undefined}
                  key={row.id}
                  style={{
                    // Adjust row height and alignmenta for =< 2 rows
                    height:
                      table.getCoreRowModel().rows.length <= 2
                        ? "200px"
                        : undefined,
                    verticalAlign:
                      table.getCoreRowModel().rows.length <= 2
                        ? "top"
                        : undefined,
                  }}
                >
                  {row.getVisibleCells().map((cell, index) => (
                    <td
                      key={cell.id}
                      className={clsx(
                        "py-3 fs-6 bg-white text-nowrap ps-0",
                        index === 0 && "ps-3",
                      )}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="text-center w-100 p-5 border-top border-bottom bg-white fs-4">
            {getLanguageValue(languageText, "No matching records found")}
          </div>
        )}
      </div>

      {paginationRequired && (
        <Pagination
          languageText={languageText}
          pageCount={props.pageCount}
          handlePageChange={handlePageChange}
          handlePageLimit={handlePageLimit}
          pageLimit={props.pageLimit}
          pageSelected={props.pageSelected}
          totalCount={props.totalCount}
        />
      )}
    </div>
  );
}
