// newsList/addEditNews/actions.ts
import { Dispatch } from "redux";
import { rawAxiosApi as api, apiEndPoints } from "../../../../api";
import { setNotification } from "../../../actions";
import { INewsList } from "../types";

export const createNews = async (
  body: INewsList,
  dispatch: Dispatch,
): Promise<any> => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  try {
    const response = await api.post(apiEndPoints.createNewsPost, body, {
      headers: headers,
    });
    return response.data.success;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const updateNews = async (
  body: INewsList,
  dispatch: Dispatch,
): Promise<any> => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  try {
    const formData = new FormData();
    (Object.keys(body) as Array<keyof INewsList>).forEach((key) => {
      if (key === "imageFile" && !body[key]) {
        return;
      }
      formData.append(key, body[key] as string | Blob);
    });

    const response = await api.put(apiEndPoints.updateNewsPost, formData, {
      headers: headers,
    });
    return response.data.success;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
