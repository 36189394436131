import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@app/store/configureStore";
import { getFacilitatorDashboardDetails } from "./actions";
import {
  IFacilitatorDashboardAccount,
  IFacilitatorDashboardDetails,
  IFacilitatorDashboardLatestProfile,
  IFacilitatorDashboardOrganizationView,
} from "./interface";

export const useFacilitatorDashboard = () => {
  const dispatch = useDispatch();

  const userId = useSelector((state: RootState) => state.loginReducer.userId);
  const currentClient = useSelector(
    (state: RootState) => state.loginReducer.currentClient,
  );
  // Handling Language
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const initialAccountState: IFacilitatorDashboardAccount = {
    users: 0,
    profiles: 0,
    courses: 0,
  };
  const initialLatestProfileState: IFacilitatorDashboardLatestProfile = {
    id: 0,
    completionDate: "",
    addedProfiles: 0,
    invitedProfiles: 0,
    deliveredProfiles: 0,
  };
  const initialOrganizationViewState: IFacilitatorDashboardOrganizationView = {
    directivenessSelfValue: 0,
    directivenessOtherValue: 0,
    affiliationSelfValue: 0,
    affiliationOtherValue: 0,
    adaptabilitySelfValue: 0,
    adaptabilityOtherValue: 0,
  };
  const initialDashboardDetailsState: IFacilitatorDashboardDetails = {
    account: initialAccountState,
    latestProfile: initialLatestProfileState,
    categoryCourses: [],
    news: [],
    organizationView: initialOrganizationViewState,
  };
  const [dashboardDetails, setDashboardDetails] =
    useState<IFacilitatorDashboardDetails>(initialDashboardDetailsState);

  useEffect(() => {
    if (userId && currentClient.currentClientId) {
      getFacilitatorDashboardDetails(
        userId,
        currentClient.currentClientId,
        dispatch,
      ).then((response: IFacilitatorDashboardDetails) => {
        if (response) {
          setDashboardDetails(response);
        }
      });
    }
  }, [userId, currentClient]);

  return {
    languageText,
    currentClient,
    dashboardDetails,
  };
};
