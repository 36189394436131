import React from "react";

import { Input } from "../../components/formComponents/input";
import { TextArea } from "../../components/formComponents/textArea";
import {
  DropdownSelect,
  IDropdownList,
} from "@app/components/formComponents/dropdownSelect";
import {
  getLanguageValue,
  ITranslationObject,
} from "../../commonUtils/languageFunctionsHelper";
import { ModalComponent } from "../../components/modals/modalComponent";
import { Plan, PlanType } from "@app/types";
import { useDispatch } from "react-redux";
import { useApiEndpoints } from "@app/api/end-points";
import { ToggleCheckbox } from "@app/components/formComponents/toggleCheckbox";
import { Currency } from "../commonInterfaces";

type Props = {
  planId: number;
  languageText: ITranslationObject;
  onSubmit: (plan: Plan) => Promise<unknown>;
  onClose: () => unknown;
};

export const AddEditPlan = (props: Props) => {
  const dispatch = useDispatch();
  const api = useApiEndpoints(dispatch);
  const __t = getLanguageValue.bind(undefined, props.languageText);
  const [plan, setPlan] = React.useState<Plan>({
    name: "",
    type: PlanType.Plan,
    description: "",
    enabled: true,
    prices: [
      {
        currencyId: 1,
        unitPrice: 100.0,
      },
    ],
  });
  const [currencies, setCurrencies] = React.useState<ReadonlyArray<Currency>>(
    [],
  );

  React.useEffect(() => {
    if (!props.planId) {
      return;
    }
    api.getPlanById(props.planId).then(setPlan);
  }, [props.planId]);

  React.useEffect(() => {
    api.getAllCurrencies().then((res) => setCurrencies(res.items));
  }, []);

  const currencyDropdownItems: Array<IDropdownList<number>> = currencies.map(
    (c) => {
      return {
        id: c.id!,
        displayName: c.code,
        value: c.id!,
      };
    },
  );

  return (
    <>
      <ModalComponent
        width="lg"
        headerText={getLanguageValue(
          props.languageText,
          props.planId ? "Edit Plan" : "Add Plan",
        )}
        cancelButtonText={getLanguageValue(props.languageText, "Cancel")}
        submitButtonText={getLanguageValue(props.languageText, "Submit")}
        handleCancelClick={props.onClose}
        handleSubmitClick={(event) => {
          props.onSubmit(plan);
        }}
      >
        <Input
          label={__t("Name")}
          value={plan.name}
          handleInputChange={(event) => {
            setPlan({
              ...plan,
              name: event.target.value,
            });
          }}
        />
        <TextArea
          label={__t("Description")}
          value={plan.description}
          rows={8}
          onChange={(event) => {
            setPlan({
              ...plan,
              description: event.target.value,
            });
          }}
        />
        <DropdownSelect
          name=""
          label={__t("Plan type")}
          list={[
            {
              id: 1,
              displayName: __t("Plan 1"),
              value: PlanType.Plan,
            },
            {
              id: 2,
              displayName: __t("Plan 2"),
              value: PlanType.Subscription,
            },
          ]}
          handleDropdownSelect={(selected) => {
            setPlan({
              ...plan,
              type: selected.value,
            });
          }}
          value={plan.type}
        />
        <label>{__t("Pricing")}</label>
        <table className="table align-middle">
          <tbody>
            {plan.prices.map((price, index) => {
              return (
                <tr key={index}>
                  <td className="col-6">
                    <Input
                      value={price.unitPrice}
                      narrow={true}
                      handleInputChange={(event) => {
                        const prices = plan.prices.slice();
                        prices[index] = {
                          ...price,

                          // TODO: we need an input with separate state for the parsed numeric value.
                          //   this will be very hard to type in if the input is empty.
                          unitPrice: Number(event.target.value),
                        };
                        setPlan({
                          ...plan,
                          prices: prices,
                        });
                      }}
                    />
                  </td>
                  <td className="col-6">
                    <DropdownSelect
                      name=""
                      handleDropdownSelect={(selected) => {
                        const prices = plan.prices.slice();
                        prices[index] = {
                          ...price,
                          currencyId: selected.id,
                        };
                        setPlan({
                          ...plan,
                          prices: prices,
                        });
                      }}
                      narrow={true}
                      list={currencyDropdownItems}
                      value={price.currencyId}
                    />
                  </td>
                  <td>
                    <div className="d-flex justify-content-center text-danger">
                      <span
                        className="d-inline"
                        role="button"
                        onClick={(event) => {
                          event.preventDefault();

                          const prices = plan.prices.slice();
                          prices.splice(index, 1);
                          setPlan({
                            ...plan,
                            prices: prices,
                          });
                        }}
                        title={__t("Delete")}
                      >
                        <i className="bi bi-trash3 me-1" />
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })}
            <tr>
              <td colSpan={3}>
                <button
                  className="btn btn-light w-100"
                  onClick={(event) => {
                    event.preventDefault();

                    const selectedCurrencies = plan.prices.map(
                      (p) => p.currencyId,
                    );
                    const otherCurrency = currencies.find(
                      (c) => !selectedCurrencies.includes(c.id!),
                    );

                    setPlan({
                      ...plan,
                      prices: plan.prices.concat({
                        unitPrice: 0,
                        currencyId: otherCurrency!.id!,
                      }),
                    });
                  }}
                >
                  {__t("Add price")}
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <ToggleCheckbox
          label={__t("Enabled")}
          value={plan.enabled}
          handleCheckboxChange={(event) => {
            setPlan({
              ...plan,
              enabled: event.target.checked,
            });
          }}
        />
      </ModalComponent>
    </>
  );
};
