import React from "react";
import famfamfam_flags from "../../../public/DeliveryImages/famfamfam_flags.png";

interface IRenderFlagComponentProps {
  flagName: string;
  value: string;
}

export const RenderFlagComponent = (
  props: IRenderFlagComponentProps,
): JSX.Element => {
  return (
    <span className="d-flex align-items-center">
      <i
        className={`me-4 ${props.flagName}`}
        style={{ backgroundImage: `url(${famfamfam_flags})` }}
      />
      {props.value}
    </span>
  );
};
