import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../../commonUtils/languageFunctionsHelper";
import { AddEditAccountModal } from "../../../../clientList/addEditModal/addEditAccountModal";
import { ITinyClient } from "../../../../coursesList/actions";
import { IUpdateActivityClientBody } from "../../../addActivity/addActivityHooks";
import { updateActivityClient } from "../../../addActivity/addActivityActions";
import { CustomModal as WarningModal } from "../../../../../components/modals/customModal";
import { ModalComponent } from "@app/components/modals/modalComponent";
import { addToast } from "@app/containers/actions";
import { AnyAction } from "redux";
import { Input } from "@app/components/formComponents/input";
import { IClient } from "@app/containers/commonInterfaces";
import { ActivityId, UserId } from "@app/containers/reducer";
import { useApiEndpoints } from "@app/api/end-points";

interface ISelectedClient {
  clientId: number;
  clientName: string;
}

interface IClientSelectProps {
  languageText: ITranslationObject;
  clientId: number;
  activityId?: ActivityId; // From edit-profile
  facilitatorId?: UserId;
  clientData?: ReadonlyArray<IClient>; // From add-profile
  refetchClientList?: () => void;
  closeClientSelectModal: () => void;
  handleClientSelect: (
    clientId: number,
    clientName: string,
    isFacilitatorRemoved: boolean,
  ) => void;
}

export const ClientSelectModal: React.FC<IClientSelectProps> = (props) => {
  const dispatch = useDispatch();
  const api = useApiEndpoints(dispatch);

  const initialSelectedClientState: ISelectedClient = {
    clientId: 0,
    clientName: "",
  };
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [addAccountModal, setAddAccountModal] = useState<boolean>(false);
  const [clientsList, setClientsList] = useState<ReadonlyArray<ITinyClient>>(
    [],
  );
  const [clientWarningModal, setClientWarningModal] = useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState<ISelectedClient>(
    initialSelectedClientState,
  );

  const rowData = {};

  useEffect(() => {
    if (!props.activityId && props.clientData && props.clientData?.length > 0) {
      setClientsList(props.clientData);
    }
  }, [props.clientData]);

  const fetchClientDetails = async (searchValue: string): Promise<void> => {
    const clientsList = await api.getClientNames(searchValue);
    let clients: ITinyClient[] = [];
    if (clientsList?.length > 0) {
      clients = clientsList.map((item) => ({
        id: item.id,
        name: item.name,
      }));
      setClientsList(clients);
    } else setClientsList([]);
  };

  useEffect(() => {
    if (props.activityId) {
      fetchClientDetails(searchQuery);
    }
  }, [searchQuery, props.activityId]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    setSearchQuery(value);
  };

  const updateClient = (clientId: number, clientName: string): void => {
    const body: IUpdateActivityClientBody = {
      activityId: props.activityId ? props.activityId : (0 as ActivityId),
      clientId: clientId,
    };
    updateActivityClient(body, dispatch).then((response) => {
      props.handleClientSelect(clientId, clientName, response);
      props.closeClientSelectModal();
      dispatch(addToast("Client Updated") as AnyAction);
    });
  };

  const onSelectClient = (clientId: number, clientName: string): void => {
    if (props.activityId && props.facilitatorId) {
      api
        .isFacilitatorInClientRole(props.facilitatorId, clientId)
        .then((response) => {
          if (response) {
            updateClient(clientId, clientName);
          } else {
            setClientWarningModal(true);
            setSelectedClient({ clientId, clientName });
          }
        });
    } else {
      props.handleClientSelect(clientId, clientName, false);
    }
  };

  const closeClientWarningModal = (): void => {
    setSelectedClient(initialSelectedClientState);
    setClientWarningModal(false);
  };

  const handleClientChange = (): void => {
    closeClientWarningModal();
    updateClient(selectedClient.clientId, selectedClient.clientName);
  };

  const openClientModal = (): void => {
    setAddAccountModal(true);
  };

  const closeClientModal = (): void => {
    setAddAccountModal(false);
  };

  const refreshClientList = (): void => {
    if (props.activityId) {
      fetchClientDetails(searchQuery);
    } else {
      props.refetchClientList?.();
    }
  };

  let filteredData = clientsList;
  if (!props.activityId) {
    filteredData =
      props.clientData?.filter((item) =>
        String(item.name).toLowerCase().includes(searchQuery.toLowerCase()),
      ) ?? [];
  }

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(props.languageText, "Change Account")}
        cancelButtonText={getLanguageValue(props.languageText, "Close")}
        handleCancelClick={props.closeClientSelectModal}
        width="lg"
      >
        <div className="d-flex flex-wrap justify-content-between">
          <Input
            placeholder={getLanguageValue(props.languageText, "Search")}
            value={searchQuery}
            handleInputChange={handleSearch}
          />
          <div>
            <button onClick={openClientModal} className="btn btn-success">
              {getLanguageValue(props.languageText, "Add New Account")}
            </button>
          </div>
        </div>

        {filteredData?.length > 0 ? (
          filteredData.map((item, index) => {
            return (
              <div key={index} className="d-flex align-items-center py-2">
                {props.clientId === item.id ? (
                  <i className="bi bi-check-square text-success fs-4" />
                ) : (
                  <div
                    role="button"
                    onClick={() => onSelectClient(item.id, item.name)}
                  >
                    <i className="bi bi-square text-secondary fs-4" />
                  </div>
                )}

                <div
                  className={clsx(
                    "ps-3",
                    props.clientId === item.id ? "text-success" : "",
                  )}
                >
                  {item.name}
                </div>
              </div>
            );
          })
        ) : (
          <p className="text-center mt-10 fs-5">
            {getLanguageValue(props.languageText, "0 records found")}
          </p>
        )}

        {addAccountModal && (
          <AddEditAccountModal
            rowData={rowData}
            isActivity={true}
            refreshClientList={refreshClientList}
            closeClientModal={closeClientModal}
          />
        )}

        {clientWarningModal && (
          <WarningModal
            headerText={getLanguageValue(props.languageText, "Warning Message")}
            bodyText={`${getLanguageValue(
              props.languageText,
              "Selected client is not conected to facilitator. Do you really want to change the client",
            )} ?`}
            cancelButtonText={getLanguageValue(props.languageText, "No")}
            submitButtonText={getLanguageValue(props.languageText, "Yes")}
            isSubmitDangerButton={true}
            handleCancelClick={closeClientWarningModal}
            handleSubmitClick={handleClientChange}
          />
        )}
      </ModalComponent>
    </>
  );
};
