import { IFocusError } from "./components/formComponents/input";
import { IClientSubscription } from "./containers/clientList/hooks";
import { IClient } from "./containers/commonInterfaces";
import { Plan, PlanType } from "./types";

export const emptyClient: IClient = {
  active: false,
  address: "",
  businessIdentification: "",
  city: "",
  company: "",
  countryId: 0,
  countryName: "",
  deliveryCountryName: "",
  differentInvoiceAddress: false,
  email: "",
  enableParticipantCreateProfile: false,
  euvatid: "",
  facilitator: "",
  id: 0,
  invoiceAddress: "",
  invoiceCity: "",
  invoiceCompanyName: "",
  invoiceCountryId: 0,
  invoiceCountryName: "",
  invoiceEUVATID: "",
  invoiceReference: "",
  invoiceZip: "",
  isDefaultClient: false,
  name: "",
  numberOfFacilitators: 0,
  numberOfParticipants: 0,
  numberOfProfiles: 0,
  numberOfProfileParticipants: 0,
  numberOfSubscriptions: 0,
  numberOfUsers: 0,
  orgNo: "",
  sourceAddress: "",
  sourceType: "",
  telephone: "",
  userRoleId: 0,
  validFrom: "",
  validTo: "",
  vat: "",
  zip: "",
};

export const initialPlanState: Plan = {
  id: 0,
  name: "",
  type: PlanType.Plan,
  enabled: true,
  description: "",
  prices: [],
};

export const initialIClientSubscritionState: IClientSubscription = {
  clientId: 0,
  subscriptionId: 0,
  articleId: 0,
  paymentTerms: 0,
  price: 0,
  billingPeriod: 0,
  teamSize: 0,
  startDate: "",
  endDate: "",
  invoice: false,
  creditCard: false,
  status: 0,
  payment: 0,
  paymentAfterEnd: 0,
  currencyId: 0,
  id: 0,
  idiInvoices: [],
  total: 0,
  plan: initialPlanState,
};

// Client details states
export const initialFocusInputState: IFocusError = {
  touched: false,
  errorMessage: "",
};
