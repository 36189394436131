import React from "react";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../commonUtils/languageFunctionsHelper";
import { IDropdownList } from "@app/components/formComponents/dropdownSelect";
import { ClientSelectModal } from "../editActivity/modals/clientSelectModal/clientSelectModal";
import { IClient } from "@app/containers/commonInterfaces";

interface IActivityClientSelectProps {
  languageText: ITranslationObject;
  clientData: ReadonlyArray<IClient>;
  clientId: number;
  clientEditMode: boolean;
  refreshClientList: () => void;
  openSelectClientEditMode: () => void;
  closeClientSelectModal: () => void;
  handleClientSelect: (
    clientId: number,
    clientName: string,
    isFacilitatorRemoved: boolean,
  ) => void;
}

export const ActivityClientSelectionStep = (
  props: IActivityClientSelectProps,
) => {
  return (
    <div className="mb-4 p-4 rounded shadow-sm bg-white">
      <div className="fs-5 fw-bold mb-3">
        {getLanguageValue(props.languageText, "Account")}
      </div>
      {props.clientEditMode ? (
        <ClientSelectModal
          languageText={props.languageText}
          clientData={props.clientData}
          clientId={props.clientId}
          handleClientSelect={props.handleClientSelect}
          closeClientSelectModal={props.closeClientSelectModal}
          refetchClientList={props.refreshClientList}
        />
      ) : (
        <>
          <button className="btn me-3 border">
            {props.clientData.find((item) => item.id === props.clientId)?.name}
          </button>
          <button
            className="btn btn-light"
            onClick={props.openSelectClientEditMode}
          >
            <i className="bi bi-pencil"></i>
          </button>
        </>
      )}
    </div>
  );
};
