import React from "react";

import { IPlacements, usePlacementList } from "./hooks";
import { getLanguageValue } from "../../commonUtils/languageFunctionsHelper";
import { ReactTable } from "../../components/reactTable/reactTable";
import { AddEditPlacementModal } from "./addEditPlacementModal";
import { IDropdownList } from "@app/components/formComponents/dropdownSelect";
import { Behave } from "../allWordsList/hooks";

export const PlacementList = (): JSX.Element => {
  const {
    languageText,
    tableHeader,
    data,
    totalCount,
    pageCount,
    filter,
    pageLimit,
    pageSelected,
    openPlacementModal,
    editedRowId,
    cultures,
    countriesList,
    setFilterCulture,
    setFilter,
    setSorting,
    setPageLimit,
    setPageSelected,
    addPlacementClick,
    closePlacementModal,
  } = usePlacementList();

  let rowData: IPlacements = {
    position: 0,
    low: 0,
    high: 0,
    behave: Behave.Affiliation,
    self: false,
    description: "",
    countryCode: 0,
    idiLanguageId: 0,
  };
  if (editedRowId) {
    rowData = data.find((item) => item.id === editedRowId)!;
  }

  const cultureDropdownItems: Array<IDropdownList> = cultures.map((c) => {
    return {
      id: c.id,
      displayName: c.displayName,
      value: String(c.id),
    };
  });

  return (
    <>
      <ReactTable
        wrapperClass="p-2 pt-4 p-md-4"
        headerText={getLanguageValue(languageText, "Placement list")}
        searchPlaceholder={getLanguageValue(languageText, "Search Placement")}
        buttonText={getLanguageValue(languageText, "Add Placement")}
        tableHeader={tableHeader}
        data={data}
        totalCount={totalCount}
        pageCount={pageCount}
        filter={filter}
        filterDropdownDefaultLabel={getLanguageValue(
          languageText,
          "Select Culture",
        )}
        filterDropdownLabel={getLanguageValue(languageText, "Select Culture")}
        filterDropdownList={cultureDropdownItems}
        pageLimit={pageLimit}
        pageSelected={pageSelected}
        setFilterDropdownTable={setFilterCulture}
        handleButtonClick={addPlacementClick}
        setFilter={setFilter}
        setSorting={setSorting}
        setPageLimit={setPageLimit}
        setPageSelected={setPageSelected}
      />

      {openPlacementModal || editedRowId ? (
        <AddEditPlacementModal
          rowData={rowData}
          cultures={cultures}
          countriesList={countriesList}
          closePlacementModal={closePlacementModal}
        />
      ) : (
        ""
      )}
    </>
  );
};
