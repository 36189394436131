import React, { useEffect, useState } from "react";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../../commonUtils/languageFunctionsHelper";
import {
  deleteParticipant,
  getEditProfileByIdAndStatus,
  updateParticipantProfileLock,
} from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@app/store/configureStore";
import {
  IEditProfileByIdAndStatus,
  IEditProfileByIdAndStatusBody,
} from "../../interface";
import { ProfileParticipantName } from "./profileParticipantName";
import { ProfileParticipantRole } from "./profileParticipantRole";
import { ProfileParticipantInvite } from "./profileParticipantInvite";
import { ProfileParticipantForm } from "./profileParticipantForm";
import { ProfileParticipantProfile } from "./profileParticipantProfile";
import { DeleteModal } from "../../../../../components/modals/deleteModal";
import { addToast, setNotification, setSpinner } from "@app/containers/actions";
import { ModalComponent } from "@app/components/modals/modalComponent";
import { AnyAction } from "redux";
import { isLoggedInRoleAdmin } from "@app/commonUtils/roleHelper";
import { ProfileId, UserId } from "@app/containers/reducer";
import { ILanguage } from "@app/containers/commonInterfaces";
import { ProfileStatus } from "@app/containers/activityList/activityEnums";
import { useApiEndpoints } from "@app/api/end-points";
import { IFocusError, Input } from "@app/components/formComponents/input";
import { ToggleCheckbox } from "@app/components/formComponents/toggleCheckbox";
import { ISaveParticipantFieldValueBody } from "@app/containers/activityList/addActivity/roleSettings/roleSettingsWithEditOption";
import {
  DropdownSelect,
  IDropdownList,
} from "@app/components/formComponents/dropdownSelect";
import { IPresentationList } from "@app/containers/presentationsList/hooks";

interface IEditProfileParticipantModalProps {
  languageText: ITranslationObject;
  profileId: ProfileId;
  languageCode: string;
  languages: ReadonlyArray<ILanguage>;
  messageTranslation: ITranslationObject;
  status: number;
  showDeleteButton?: boolean;
  handleCloseClick: () => void;
  fetchActivity: () => void;
  refetchParticipants: () => void;
  refetchOngoingParticipants?: () => void;
  refetchCompletedParticipants?: () => void;
  handleUnPublishProfile?: () => void;
  handlePublishProfile?: (profileId: ProfileId) => void;
  handleTransferProfile?: () => void;
}

const initialProfileState: IEditProfileByIdAndStatus = {
  name: "",
  surname: "",
  roleId: 0,
  roleText: "",
  noOfRespondents: 0,
  emailAddress: "",
  emailStatus: 0,
  emailSentLog: [],
  latestEmailSentLog: "",
  phoneNumber: "",
  smsStatus: 0,
  smsSentLog: [],
  emailOwnMessage: "",
  smsOwnMessage: "",
  latestSmsSentLog: "",
  respondentMsg1: "",
  respondentMsg2: "",
  respondentsAnswered: 0,
  respondentsInvited: 0,
  pdfProfileDownloaded: false,
  isLocked: false,
  enableSelfDelivery: false,
  selfDeliveryTemplateId: null,
};

export const EditProfileParticipantModal = (
  props: IEditProfileParticipantModalProps,
): JSX.Element => {
  const dispatch = useDispatch();
  const api = useApiEndpoints(dispatch);
  const loggedInUserRole = useSelector(
    (state: RootState) => state.loginReducer.loggedInUserRole,
  );

  const [profileParticipantData, setProfileParticipantData] =
    useState<IEditProfileByIdAndStatus>(initialProfileState);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [refetchParticipantData, setRefetchParticipantData] =
    useState<boolean>(false);
  const [isDownload, setIsDownload] = useState<boolean>(false);
  const [presentationTemplates, setPresentationTemplates] = useState<
    ReadonlyArray<IPresentationList>
  >([]);
  const isAdmin = loggedInUserRole === "Admin";

  const [transferUserId, setTransferUserId] = useState<UserId>(0 as UserId);
  const [showTransferElements, setShowTransferElements] =
    useState<boolean>(false);
  const [focusInput, setFocusInput] = useState<IFocusError>({
    touched: false,
    errorMessage: "",
  });

  useEffect(() => {
    const body: IEditProfileByIdAndStatusBody = {
      profileId: props.profileId,
      status: props.status,
      languageCode: props.languageCode,
    };
    getEditProfileByIdAndStatus(body, dispatch).then((response) => {
      if (response) {
        setProfileParticipantData(response);
      }
    });
  }, [refetchParticipantData]);

  useEffect(() => {
    if (!isAdmin) {
      return;
    }
    api.getAllPresentationTemplates("", "", 1_000, 0).then((res) => {
      setPresentationTemplates(res.items);
    });
  }, [isAdmin]);

  const onDeleteParticipantClick = (): void => setOpenDeleteModal(true);

  const closeDeleteModal = (): void => setOpenDeleteModal(false);

  const handleDeleteParticipant = (): void => {
    deleteParticipant(props.profileId, dispatch).then((response) => {
      if (response?.success) {
        dispatch(addToast("Participant Deleted") as AnyAction);
        props.fetchActivity();
        //This should probably now be moot since everything *should* be fetched with the above fetchActivity
        props.refetchParticipants();
        props.handleCloseClick();
      }
    });
  };

  const handleProfileLock = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(setSpinner(true));
    const { checked } = e.target;

    updateParticipantProfileLock(props.profileId, checked, dispatch)
      .then(() => {
        setProfileParticipantData({
          ...profileParticipantData,
          isLocked: checked,
        });
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  const updatedownloadedStatus = () => {
    setIsDownload(true);
  };

  const handleMessageSentSuccess = (): void => {
    setRefetchParticipantData(!refetchParticipantData);
    props.refetchParticipants();
  };

  function saveFieldValue(fieldName: string, value: string): Promise<boolean> {
    const body: ISaveParticipantFieldValueBody = {
      id: props.profileId,
      fieldName: fieldName,
      fieldValue: value,
    };
    return api.saveParticipantFieldValue(body);
  }

  const handleUnPublishClick = (): void => {
    api.unPublishProfile(props.profileId).then((response) => {
      if (response) {
        dispatch(addToast("Profile un-published") as AnyAction);
        props.handleUnPublishProfile && props.handleUnPublishProfile();
        props.handleCloseClick();
      }
    });
  };

  const handleTransferClick = (): void => {
    if (transferUserId > 0) {
      api.transferProfile(props.profileId, transferUserId).then(
        (response) => {
          if (response) {
            dispatch(addToast("Profile transferred") as AnyAction);
            props.handleTransferProfile && props.handleTransferProfile();
            props.handleCloseClick();
          }
        },
        (error) => {
          dispatch(setNotification(error));
        },
      );
    }
  };

  const onTransferUserChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { value } = e.target;
    let errorMessage: string = "";
    setTransferUserId(Number(value) as UserId);

    if (e.target.value === "" || Number(e.target.value) <= 0) {
      errorMessage = getLanguageValue(props.languageText, "UserId required");
    }

    setFocusInput((prev) => ({
      ...prev,
      touched: true,
      errorMessage: errorMessage,
    }));
  };

  const presentationTemplateOptions: ReadonlyArray<
    IDropdownList<number | null>
  > = presentationTemplates.map((p) => {
    return {
      id: p.id,
      displayName: p.description,
      value: p.id,
    };
  });

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(props.languageText, "Edit Participant")}
        submitButtonText={
          isLoggedInRoleAdmin(loggedInUserRole) || props.showDeleteButton
            ? getLanguageValue(props.languageText, "Delete participant")
            : undefined
        }
        cancelButtonText={getLanguageValue(props.languageText, "Close")}
        handleSubmitClick={onDeleteParticipantClick}
        handleCancelClick={props.handleCloseClick}
        isSubmitDangerButton={true}
        width="lg"
      >
        {/* Participant Name & Surname */}
        <ProfileParticipantName
          languageText={props.languageText}
          profileId={props.profileId}
          name={profileParticipantData.name}
          surname={profileParticipantData.surname}
          refetchParticipants={props.refetchParticipants}
        />

        {/* Participant Role */}
        {
          <ProfileParticipantRole
            languageText={props.languageText}
            profileId={props.profileId}
            status={props.status}
            roleId={profileParticipantData.roleId}
            roleText={profileParticipantData.roleText}
            noOfRespondents={profileParticipantData.noOfRespondents}
            respondentsAnswered={profileParticipantData.respondentsAnswered}
            refetchParticipants={props.refetchParticipants}
            refetchOngoingParticipants={props.refetchOngoingParticipants}
            refetchCompletedParticipants={props.refetchCompletedParticipants}
          />
        }

        {/* Participant Invite */}
        <ProfileParticipantInvite
          languageText={props.languageText}
          languages={props.languages}
          languageCode={props.languageCode}
          name={profileParticipantData.name}
          profileId={props.profileId}
          emailAddress={profileParticipantData.emailAddress}
          emailSentLog={profileParticipantData.emailSentLog}
          latestEmailSentLog={profileParticipantData.latestEmailSentLog}
          phoneNumber={profileParticipantData.phoneNumber}
          smsSentLog={profileParticipantData.smsSentLog}
          emailOwnMessage={profileParticipantData.emailOwnMessage}
          smsOwnMessage={profileParticipantData.smsOwnMessage}
          latestSmsSentLog={profileParticipantData.latestSmsSentLog}
          messageTranslation={props.messageTranslation}
          emailStatus={profileParticipantData.emailStatus}
          smsStatus={profileParticipantData.smsStatus}
          handleMessageSentSuccess={handleMessageSentSuccess}
        />

        {/* Participant Form */}
        <ProfileParticipantForm
          languageText={props.languageText}
          status={props.status}
          respondentMsg1={profileParticipantData.respondentMsg1}
          respondentMsg2={profileParticipantData.respondentMsg2}
          respondentsAnswered={profileParticipantData.respondentsAnswered}
          respondentsInvited={profileParticipantData.respondentsInvited}
          profileParticipantData={profileParticipantData}
        />

        {/* Participant Profile */}
        <ProfileParticipantProfile
          languageText={props.languageText}
          status={props.status}
          profileId={props.profileId}
          profileParticipantData={profileParticipantData}
          isDownloaded={isDownload}
          pdfProfileDownloaded={profileParticipantData.pdfProfileDownloaded}
          handleProfileLock={handleProfileLock}
          updatedownloadedStatus={updatedownloadedStatus}
          setProfileParticipantData={setProfileParticipantData}
          handleCloseClick={props.handleCloseClick}
          handlePublishProfile={props.handlePublishProfile}
        />

        {isAdmin && (
          <>
            <div className="border-bottom my-4"></div>
            {/********************** ADMIN TASKS  *****************/}
            <h5>{getLanguageValue(props.languageText, "Admin tasks")}</h5>
            <div className="row">
              <div className="col">
                <div className="">
                  <ToggleCheckbox
                    label={getLanguageValue(
                      props.languageText,
                      "Profile locked",
                    )}
                    value={profileParticipantData.isLocked}
                    handleCheckboxChange={handleProfileLock}
                  />
                </div>
                <div className="">
                  <ToggleCheckbox
                    label={getLanguageValue(
                      props.languageText,
                      "Enable self delivery",
                    )}
                    handleCheckboxChange={(event) => {
                      setProfileParticipantData((prev) => {
                        return {
                          ...prev,
                          enableSelfDelivery: event.target.checked,
                        };
                      });
                      saveFieldValue(
                        "EnableSelfDelivery",
                        String(event.target.checked),
                      );
                    }}
                    value={profileParticipantData.enableSelfDelivery}
                  />
                </div>
              </div>
              {props.status === ProfileStatus.Delivery && (
                <div className="col">
                  <div>
                    <div
                      className="btn btn-sm btn-outline-secondary"
                      onClick={handleUnPublishClick}
                    >
                      {getLanguageValue(
                        props.languageText,
                        "Undeliver profile",
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className="col">
                <span
                  className="text-decoration-underline cursor-pointer"
                  onClick={() => {
                    setShowTransferElements(!showTransferElements);
                  }}
                >
                  <div className="btn btn-sm btn-outline-secondary">
                    {getLanguageValue(
                      props.languageText,
                      "Transfer profile to another user",
                    )}
                  </div>
                </span>
                {showTransferElements && (
                  <div className="d-flex gap-2">
                    <Input
                      type="number"
                      label={getLanguageValue(
                        props.languageText,
                        "Enter User Id",
                      )}
                      placeholder={getLanguageValue(
                        props.languageText,
                        "User Id",
                      )}
                      errorMessage={focusInput.errorMessage}
                      value={transferUserId}
                      handleInputChange={onTransferUserChange}
                    />
                    <div
                      className="btn btn-sm btn-danger mt-4 mb-3"
                      onClick={handleTransferClick}
                    >
                      {getLanguageValue(props.languageText, "Transfer")}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {profileParticipantData.enableSelfDelivery && (
              <DropdownSelect
                label={getLanguageValue(
                  props.languageText,
                  "Self delivery template",
                )}
                list={presentationTemplateOptions}
                name="selfDeliveryTemplateId"
                value={profileParticipantData.selfDeliveryTemplateId}
                handleDropdownSelect={(item) => {
                  setProfileParticipantData((prev) => {
                    return {
                      ...prev,
                      selfDeliveryTemplateId: item.value,
                    };
                  });
                  saveFieldValue("SelfDeliveryTemplateId", String(item.value));
                }}
              />
            )}
          </>
        )}

        {/* Delete Participant Modal */}
        {openDeleteModal && (
          <DeleteModal
            languageText={props.languageText}
            headerText={getLanguageValue(props.languageText, "participant")}
            bodyText={getLanguageValue(
              props.languageText,
              "Self and Profile form data",
            )}
            handleDeleteClick={handleDeleteParticipant}
            closeDeleteModal={closeDeleteModal}
          />
        )}
      </ModalComponent>
    </>
  );
};
