import { Dispatch } from "redux";
import { rawAxiosApi, apiEndPoints } from "../../api";
import { setNotification } from "../actions";
import { IFacilitatorProfilesReportParams } from "./interface";
import { IProfileGroupReport } from "../activityList/editActivity/interface";

export const getFacilitatorDashboardDetails = async (
  facilitatorId: number,
  /** @deprecated Remove 'clientId' and read it from the access token. */
  clientId: number,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.get(
      apiEndPoints.getFacilitatorDashboardDetails,
      {
        params: { facilitatorId, clientId },
      },
    );
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getFacilitatorParticipantsProfilesReport = async (
  params: IFacilitatorProfilesReportParams,
  dispatch: Dispatch,
): Promise<IProfileGroupReport> => {
  try {
    const response = await rawAxiosApi.get(
      apiEndPoints.getFacilitatorParticipantsProfilesReport,
      {
        params,
      },
    );
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
