import React from "react";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../commonUtils/languageFunctionsHelper";
import { ModalComponent } from "@app/components/modals/modalComponent";

interface IChangeProfileModalProps {
  languageText: ITranslationObject;
  bodyText: string;
  handleCancelClick: () => void;
  handleSaveClick: () => void;
}

export const ChangeProfileModal: React.FC<IChangeProfileModalProps> = (
  props,
) => {
  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(props.languageText, "Change Profile Type")}
        submitButtonText={getLanguageValue(props.languageText, "Yes")}
        cancelButtonText={getLanguageValue(props.languageText, "No")}
        handleSubmitClick={props.handleSaveClick}
        handleCancelClick={props.handleCancelClick}
      >
        {props.bodyText}
      </ModalComponent>
    </>
  );
};
