import React from "react";
import Alert from "react-bootstrap/Alert";

import { KTSVG } from "@app/commonUtils/metronic";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../commonUtils/languageFunctionsHelper";
import { IPresentationProfile } from "../hooks";
import { Checkbox } from "../../../components/formComponents/checkbox";
import { useSelector } from "react-redux";
import { RootState } from "@app/store/configureStore";
import { ProfileId } from "@app/containers/reducer";

interface IStartPageProps {
  languageText: ITranslationObject;
  startPresentation: () => void;
}

interface IFinalPageProps {
  languageText: ITranslationObject;
  profiles: IPresentationProfile[];
  profilePublished: boolean;
  handleSelectAllProfilesForDelivery: (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  handleSelectProfileForDelivery: (
    e: React.ChangeEvent<HTMLInputElement>,
    profileId: ProfileId,
  ) => void;
  publishParticipants: () => void;
  handleFinalPageBackBtn: () => void;
}

export const StartPage = (props: IStartPageProps): JSX.Element => {
  const userDetails = useSelector(
    (state: RootState) => state.loginReducer.userDetails,
  );

  return (
    <div className="d-flex justify-content-center align-items-center mt-5 pt-5">
      <button
        className="btn btn-primary"
        onClick={props.startPresentation}
        disabled={userDetails.isManager}
      >
        {getLanguageValue(props.languageText, "Start Presentation")}
      </button>
    </div>
  );
};

export const FinalPage = (props: IFinalPageProps) => {
  return (
    <div className="d-flex justify-content-center align-items-center mt-5 pt-5">
      {props.profilePublished && (
        <Alert variant="success">
          {getLanguageValue(
            props.languageText,
            "The selected profiles are delivered",
          )}
          .
        </Alert>
      )}

      {!props.profilePublished && (
        <div className="card mb-5 mb-xl-8 p-3">
          <div className="card-header border-0">
            <h3 className="card-title align-items-start">
              <span>
                {getLanguageValue(
                  props.languageText,
                  "Select the participants to publish the profiles",
                )}
                .
              </span>
            </h3>
          </div>
          <div className="card-body py-3">
            <div className="table-responsive">
              <table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
                <thead>
                  <tr className="fw-bolder text-muted">
                    <th>
                      <Checkbox
                        labelClass="mx-5"
                        value={props.profiles.every(
                          (participant) => participant.checked,
                        )}
                        label={getLanguageValue(
                          props.languageText,
                          "Participants Name",
                        )}
                        handleCheckboxChange={
                          props.handleSelectAllProfilesForDelivery
                        }
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.profiles.map((participant) => (
                    <tr key={participant.profileId}>
                      <td>
                        <Checkbox
                          labelClass="mx-5"
                          label={`${participant.forename} ${participant.surname}`}
                          value={participant.checked}
                          handleCheckboxChange={(e) =>
                            props.handleSelectProfileForDelivery(
                              e,
                              participant.profileId,
                            )
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <button
              className="btn btn-light"
              onClick={props.handleFinalPageBackBtn}
            >
              <i className="bi bi-chevron-left me-1" />
              {getLanguageValue(props.languageText, "Back")}
            </button>
            <button
              className="btn btn-success w-25"
              onClick={props.publishParticipants}
            >
              {getLanguageValue(props.languageText, "Publish")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
