import React from "react";

import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import { useEditActivity } from "./hooks";
import { EditActivityFirstStep } from "./editProfileFirstStep/editActivityFirstStep";
import { EditProfileUnstartedParticipant } from "./editProfileUnstartedParticipant/editProfileUnstartedParticipant";
import { EditProfileOngoingParticipant } from "./editProfileOngoingParticipant/editProfileOngoingParticipant";
import { EditProfileCompletedOrPlannedParticipant } from "./editProfileCompletedOrPlannedParticipant/editProfileCompletedOrPlannedParticipant";
import { EditProfileDeliveredParticipant } from "./editProfileDeliveredParticipant/editProfileDeliveredParticipant";
import { ProfileInvoiceBillingModal } from "./profileInvoiceBillingModal/profileInvoiceBillingModal";
import { DeliveredParticipantsProfile } from "./editProfileDeliveredParticipant/deliveredParticipantsProfile";
import "../../../css/components/idiMatris.scss";
import { ProfileRoleStatus } from "../activityEnums";
import { CustomModal as EmailConfirmationModal } from "../../../components/modals/customModal";

export const EditActivity = (): JSX.Element => {
  const {
    id,
    languageText,
    isInstructionHelpClicked,
    editProfileGuideDone,
    newStatusEditProfileGuideDone,
    onGoingStatusEditProfileGuideDone,
    completedStatusEditProfileGuideDone,
    clientIdFromAccount,
    profileData,
    cultures,
    languages,
    cultureValue,
    messageTranslation,
    isActivityCancelled,
    isActivityInvoiced,
    showProfileBillingModal,
    deliverEmailProfileIds,
    handleInstructionHelpClick,
    handleExitGuide,
    handleProfileCancel,
    handleClientSelect,
    handleLanguageSelect,
    handleRoleChanges,
    handlePublishProfile,
    handleUnPublishProfile,
    refetchFullProfile,
    refetchNewParticipantsStep,
    refetchOngoingParticipantsStep,
    refetchCompletedParticipantsStep,
    refetchDeliveredParticipantsStep,
    refetchPlannedPresentationsStep,
    onBillingButtonClick,
    onHandleClose,
    sendEmailForDeliveredProfiles,
    handleCancelEmailConfirmation,
    handleSendEmailConfirmation,
    handleTransferProfile,
  } = useEditActivity();

  return (
    <>
      <div className="mt-3 p-2 p-md-4">
        <div>
          <EditActivityFirstStep
            id={id}
            languageText={languageText}
            clientIdFromAccount={clientIdFromAccount}
            profileData={profileData}
            cultures={cultures}
            isActivityCancelled={isActivityCancelled}
            isActivityInvoiced={isActivityInvoiced}
            handleProfileCancel={handleProfileCancel}
            onBillingButtonClick={onBillingButtonClick}
            handleInstructionHelpClick={handleInstructionHelpClick}
            handleExitGuide={handleExitGuide}
            handleClientSelect={handleClientSelect}
            handleLanguageSelect={handleLanguageSelect}
            handleRoleChanges={handleRoleChanges}
            refetchFullProfile={refetchFullProfile}
          />
        </div>
        {profileData.profileCount > 0 &&
        profileData.profileCount === profileData.deliveredProfileCount ? (
          <DeliveredParticipantsProfile
            id={id}
            languageText={languageText}
            profileData={profileData}
            isInstructionHelpClicked={isInstructionHelpClicked}
            completedStatusEditProfileGuideDone={
              completedStatusEditProfileGuideDone
            }
            deliveredProfileParticipants={profileData.deliveredProfiles}
            deliveredProfileParticipantsCount={
              profileData.deliveredProfileCount
            }
            languages={languages}
            cultureValue={cultureValue}
            messageTranslation={messageTranslation}
            isActivityCancelled={isActivityCancelled}
            handleExitGuide={handleExitGuide}
            refetchFullProfile={refetchFullProfile}
            refetchDeliveredParticipants={refetchDeliveredParticipantsStep}
            handleUnPublishProfile={handleUnPublishProfile}
            handleTransferProfile={handleTransferProfile}
          />
        ) : (
          <>
            <EditProfileUnstartedParticipant
              id={id}
              languageText={languageText}
              profileData={profileData}
              isInstructionHelpClicked={isInstructionHelpClicked}
              editProfileGuideDone={editProfileGuideDone}
              languages={languages}
              cultureValue={cultureValue}
              messageTranslation={messageTranslation}
              isActivityCancelled={isActivityCancelled}
              isActivityInvoiced={isActivityInvoiced}
              fetchActivity={refetchFullProfile}
              handleExitGuide={handleExitGuide}
              refetchNewParticipants={refetchNewParticipantsStep}
              handleTransferProfile={handleTransferProfile}
            />
            <EditProfileOngoingParticipant
              id={id}
              languageText={languageText}
              profileData={profileData}
              isInstructionHelpClicked={isInstructionHelpClicked}
              newStatusEditProfileGuideDone={newStatusEditProfileGuideDone}
              languages={languages}
              cultureValue={cultureValue}
              messageTranslation={messageTranslation}
              isActivityCancelled={isActivityCancelled}
              isActivityInvoiced={isActivityInvoiced}
              handleExitGuide={handleExitGuide}
              fetchActivity={refetchFullProfile}
              refetchOngoingParticipants={refetchOngoingParticipantsStep}
              refetchCompletedParticipants={refetchCompletedParticipantsStep}
              handleTransferProfile={handleTransferProfile}
            />
            <EditProfileCompletedOrPlannedParticipant
              id={id}
              languageText={languageText}
              profileData={profileData}
              isInstructionHelpClicked={isInstructionHelpClicked}
              onGoingStatusEditProfileGuideDone={
                onGoingStatusEditProfileGuideDone
              }
              cultures={cultures}
              languages={languages}
              cultureValue={cultureValue}
              messageTranslation={messageTranslation}
              isActivityCancelled={isActivityCancelled}
              isActivityInvoiced={isActivityInvoiced}
              handleExitGuide={handleExitGuide}
              refetchFullProfile={refetchFullProfile}
              refetchCompletedParticipants={refetchCompletedParticipantsStep}
              refetchOngoingParticipants={refetchOngoingParticipantsStep}
              refetchPlannedPresentations={refetchPlannedPresentationsStep}
              refetchDeliveredParticipants={refetchDeliveredParticipantsStep}
              handlePublishProfile={handlePublishProfile}
              sendEmailForDeliveredProfiles={sendEmailForDeliveredProfiles}
              handleTransferProfile={handleTransferProfile}
            />
            <EditProfileDeliveredParticipant
              id={id}
              languageText={languageText}
              profileData={profileData}
              isInstructionHelpClicked={isInstructionHelpClicked}
              completedStatusEditProfileGuideDone={
                completedStatusEditProfileGuideDone
              }
              languages={languages}
              cultureValue={cultureValue}
              messageTranslation={messageTranslation}
              isActivityCancelled={isActivityCancelled}
              isActivityInvoiced={isActivityInvoiced}
              handleExitGuide={handleExitGuide}
              fetchActivity={refetchFullProfile}
              refetchCompletedParticipants={refetchCompletedParticipantsStep}
              refetchDeliveredParticipants={refetchDeliveredParticipantsStep}
              handleUnPublishProfile={handleUnPublishProfile}
              handleTransferProfile={handleTransferProfile}
            />
          </>
        )}
      </div>

      {/* billing modal */}
      {showProfileBillingModal && (
        <ProfileInvoiceBillingModal
          languageText={languageText}
          profileData={profileData}
          handleCloseClick={onHandleClose}
        />
      )}

      {/* Modal for asking to send email once participants are delivered/published */}
      {deliverEmailProfileIds.length > 0 && (
        <EmailConfirmationModal
          headerText={getLanguageValue(languageText, "Email Confirmation")}
          bodyText={`${getLanguageValue(languageText, "Do you want to send delivery email")}?`}
          cancelButtonText={getLanguageValue(languageText, "No")}
          submitButtonText={getLanguageValue(languageText, "Yes")}
          handleCancelClick={handleCancelEmailConfirmation}
          handleSubmitClick={handleSendEmailConfirmation}
        />
      )}
    </>
  );
};
