import { Dispatch } from "redux";
import { rawAxiosApi, apiEndPoints } from "../../../api";
import { setNotification } from "../../actions";
import { ICreateOrUpdateLanguageBody } from "./hooks";
import { ApiResponse } from "@app/types";

export const createOrUpdateLanguage = async (
  body: ICreateOrUpdateLanguageBody,
  dispatch: Dispatch,
): Promise<unknown> => {
  try {
    const response = await rawAxiosApi.post<ApiResponse<unknown>>(
      apiEndPoints.createOrUpdateLanguage,
      JSON.stringify(body),
      {},
    );
    if (!response.data.success) {
      return Promise.reject(response.data);
    }
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

// this thing is an ABP invention.
type ComboboxItem = {
  value: string;
  displayText: string;
  isSelected: boolean;
};

/**
 * I'm almost certain this endpoint can go away completely. It lists
 * cultures defined in .NETs 'CultureInfo' and has nothing to do with
 * the languages our app supports.
 *   -johan, 2025-01-08
 */
export const getAllLanguageCultures = async (
  dispatch: Dispatch,
): Promise<Array<ComboboxItem>> => {
  try {
    const response = await rawAxiosApi.get<ApiResponse<Array<ComboboxItem>>>(
      apiEndPoints.getAllLanguageCultures,
    );
    if (!response.data.success) {
      return Promise.reject(response.data);
    }
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
