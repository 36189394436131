import { Dispatch } from "redux";
import { ApiResponse } from "@app/types";
import { setNotification } from "@app/containers/actions";
import { rawAxiosApi, apiEndPoints } from "@app/api";

export interface ICountryLocateResponse {
  name?: string;
  isoCode: string;
  countryId?: number;
}

export const getCountryCode = async (
  dispatch: Dispatch,
): Promise<ICountryLocateResponse> => {
  try {
    const response = await rawAxiosApi.get<ApiResponse<ICountryLocateResponse>>(
      apiEndPoints.countryLocate,
    );
    if (!response.data.success) {
      return await Promise.reject(response.data);
    }
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
