import React, { useMemo } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";

import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import {
  IDropdownList,
  IDropdownSelectedItem,
} from "@app/components/formComponents/dropdownSelect";
import { IFocusError } from "../../../components/formComponents/input";
import { RootState } from "@app/store/configureStore";
import { createPlacement, updatePlacement } from "./actions";
import { ValidationHelper } from "../../validationHelper";
import { getBehaveOptions } from "../../allWordsList/interface";
import { addToast, setSpinner } from "../../actions";
import { ICulture } from "@app/containers/commonInterfaces";
import { Behave } from "@app/containers/allWordsList/hooks";
import { IPlacements } from "../hooks";

export const PlacementsInput = {
  position: "position",
  description: "description",
  low: "low",
  high: "high",
  self: "self",
  behave: "behave",
  countryCode: "countryCode",
  idiLanguageId: "idiLanguageId",
} as const;

export interface IAddEditPlacementProps {
  rowData: IPlacements;
  cultures: ReadonlyArray<ICulture>;
  countriesList: IDropdownList<number>[];
  closePlacementModal: (refreshPlacementList: boolean) => void;
}

interface IFocusInput {
  position: IFocusError;
  low: IFocusError;
  high: IFocusError;
  self: IFocusError;
  behave: IFocusError;
  countryCode: IFocusError;
  idiLanguageId: IFocusError;
}

export interface IPayloadBody {
  id?: number;
  position: number;
  description?: string;
  low: number;
  high: number;
  self: boolean;
  behave: Behave;
  countryCode: number;
  idiLanguageId: number;
}

export const useAddEditPlacementModal = (props: IAddEditPlacementProps) => {
  const dispatch = useDispatch();
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const [placementInfo, setPlacementInfo] = useState<IPlacements>({
    position: 0,
    low: 0,
    high: 0,
    behave: Behave.Affiliation,
    self: false,
    description: "",
    countryCode: 0,
    idiLanguageId: 0,
  });

  const [focusInput, setFocusInput] = useState<IFocusInput>({
    countryCode: {
      touched: false,
      errorMessage: "",
    },
    idiLanguageId: {
      touched: false,
      errorMessage: "",
    },
    behave: {
      touched: false,
      errorMessage: "",
    },
    position: {
      touched: false,
      errorMessage: "",
    },
    low: {
      touched: false,
      errorMessage: "",
    },
    high: {
      touched: false,
      errorMessage: "",
    },
    self: {
      touched: false,
      errorMessage: "",
    },
  });

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const bhaveOptions = getBehaveOptions(languageText);
  let isEditClicked: boolean = false;
  if (Object.keys(props.rowData).length > 0) isEditClicked = true;

  useEffect(() => {
    if (isEditClicked) {
      updatePlacementInfo();
    }
  }, []);

  const updatePlacementInfo = (): void => {
    setPlacementInfo({
      ...placementInfo,
      position: props.rowData.position,
      low: props.rowData.low,
      high: props.rowData.high,
      self: props.rowData.self,
      description: props.rowData.description,
      behave: props.rowData.behave,
      countryCode: props.rowData.countryCode,
      idiLanguageId: props.rowData.idiLanguageId,
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name as keyof IFocusInput;
    const value = e.target.value;

    if (name === PlacementsInput.position) {
      const parsedValue = parseFloat(value);

      if (
        value !== "" &&
        (!/^(0|[1-9]\d*)$/.test(value) ||
          isNaN(parsedValue) ||
          (parsedValue !== 0 &&
            (parsedValue < 0 || parsedValue > 100 || /^0\d+/.test(value))))
      ) {
        return;
      }
    }

    if (name === PlacementsInput.low || name === PlacementsInput.high) {
      if (value === "") {
        setPlacementInfo({
          ...placementInfo,
          [name]: "",
        });
        return;
      }

      if (!ValidationHelper.isPlacementValueValid(value)) {
        return;
      }
    }

    setPlacementInfo({
      ...placementInfo,
      [name]: value,
    });

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  };

  function handleDropdownSelect<T>(
    selectedItem: IDropdownSelectedItem<T>,
  ): void {
    const name = selectedItem.name as keyof IFocusInput;
    const value = selectedItem.value;

    setPlacementInfo({
      ...placementInfo,
      [name]: value,
    });

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  }

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const name = e.target.name;
    if (e.target.name === PlacementsInput.self) {
      setPlacementInfo({
        ...placementInfo,
        self: e.target.checked,
      });
    } else {
      setPlacementInfo({
        ...placementInfo,
        [name]: e.target.checked,
      });
    }
  };

  const handleFormErrors = (name: string, value: unknown): void => {
    let errorMessage: string = "";

    switch (name) {
      case PlacementsInput.position:
        if (!value) {
          errorMessage = getLanguageValue(languageText, "Position is required");
        }
        break;
      case PlacementsInput.low:
        if (!value) {
          errorMessage = getLanguageValue(
            languageText,
            "Low value is required",
          );
        } else if (
          /^\d+\.$/.test(String(value)) ||
          /^\d+\.\d*[^\d]$/.test(String(value))
        ) {
          errorMessage = getLanguageValue(languageText, "Low value is invalid");
        } else if (
          parseFloat(String(value)) > parseFloat(String(placementInfo.high))
        ) {
          errorMessage = getLanguageValue(
            languageText,
            "Low value should be lower than high value",
          );
        } else if (
          parseFloat(String(value)) < 0.0 ||
          parseFloat(String(value)) > 6.999
        ) {
          errorMessage = getLanguageValue(
            languageText,
            "Low value should be between 0.0000 and 6.999",
          );
        }
        break;
      case PlacementsInput.high:
        if (!value) {
          errorMessage = getLanguageValue(
            languageText,
            "High value is required",
          );
        } else if (
          /^\d+\.$/.test(String(value)) ||
          /^\d+\.\d*[^\d]$/.test(String(value))
        ) {
          errorMessage = getLanguageValue(
            languageText,
            "High value is invalid",
          );
        } else if (
          parseFloat(String(value)) < parseFloat(String(placementInfo.low))
        ) {
          errorMessage = getLanguageValue(
            languageText,
            "High value should be greater than low value",
          );
        } else if (
          parseFloat(String(value)) < 0.1 ||
          parseFloat(String(value)) > 7
        ) {
          errorMessage = getLanguageValue(
            languageText,
            "High value should be between 0.0001 and 7",
          );
        }
        break;
      case PlacementsInput.behave:
        if (!value) {
          errorMessage = getLanguageValue(
            languageText,
            "Behave value is required",
          );
        }
        break;
      case PlacementsInput.countryCode:
        if (!value) {
          errorMessage = getLanguageValue(languageText, "Country is required");
        }
        break;
      case PlacementsInput.idiLanguageId:
        if (!value) {
          errorMessage = getLanguageValue(languageText, "Culture is required");
        }
        break;
      default:
        break;
    }

    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }));
  };

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    const name = e.target.name;
    const value = e.target.value;
    handleFormErrors(name, value);
  };

  const handleValidationOnSubmit = (): boolean => {
    if (
      !placementInfo.position ||
      focusInput.position.errorMessage ||
      !placementInfo.low ||
      focusInput.low.errorMessage ||
      !placementInfo.high ||
      focusInput.high.errorMessage ||
      !placementInfo.behave ||
      focusInput.behave.errorMessage ||
      !placementInfo.countryCode ||
      focusInput.countryCode.errorMessage ||
      !placementInfo.idiLanguageId ||
      focusInput.idiLanguageId.errorMessage
    ) {
      for (const item of Object.values(PlacementsInput)) {
        handleFormErrors(item, placementInfo[item]);
      }
      return false;
    }
    return true;
  };

  const handleSubmit = (e: boolean): void => {
    setIsSubmitting(true);

    const body: IPayloadBody = {
      low: Number(String(placementInfo.low).replace(",", ".")),
      high: Number(String(placementInfo.high).replace(",", ".")),
      position: Number(placementInfo.position),
      self: placementInfo.self,
      description: placementInfo.description,
      behave: Number(placementInfo.behave),
      countryCode: Number(placementInfo.countryCode),
      idiLanguageId: Number(placementInfo.idiLanguageId),
    };

    if (isEditClicked) {
      body.id = props.rowData.id ?? 0;
    }

    dispatch(setSpinner(true));
    if (isEditClicked) {
      updatePlacement(body, dispatch)
        .then((response) => {
          if (response?.status === 200) {
            dispatch(addToast("Changes saved successfully") as AnyAction);
            closeAddEditPlacementModal(true);
          }
        })
        .finally(() => {
          setIsSubmitting(false);
          dispatch(setSpinner(false));
        });
    } else {
      createPlacement(body, dispatch)
        .then((response) => {
          if (response?.status === 200) {
            dispatch(addToast("Placement created successfully") as AnyAction);
            closeAddEditPlacementModal(true);
          }
        })
        .finally(() => {
          setIsSubmitting(false);
          dispatch(setSpinner(false));
        });
    }
  };

  const onSubmitClick = (): void => {
    if (!handleValidationOnSubmit()) return;
    else if (isEditClicked) {
      handleSubmit(false);
    } else {
      handleSubmit(true);
    }
  };

  const closeAddEditPlacementModal = (value: boolean) => {
    props.closePlacementModal(value);
  };

  const closeModal = () => closeAddEditPlacementModal(false);

  return {
    isEditClicked,
    languageText,
    placementInfo,
    focusInput,
    isSubmitting,
    bhaveOptions,
    onSubmitClick,
    handleCheckboxChange,
    handleDropdownSelect,
    closeModal,
    handleBlurEvent,
    handleInputChange,
  };
};
