import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLanguageValue } from "../../commonUtils/languageFunctionsHelper";
import { deleteLanguage } from "./actions";
import { RootState } from "@app/store/configureStore";
import { ColumnDef } from "@tanstack/react-table";
import { CustomActionsCell } from "../../components/reactTable/customActionsCell";
import { BooleanCell } from "../../components/reactTable/booleanCell";
import { setSpinner } from "../actions";
import { useNavigate } from "react-router-dom";
import { routePath } from "../routePaths";
import { useApiEndpoints } from "@app/api/end-points";

interface ILanguage {
  id: number;
  displayName: string;
  name: string;
  defaultLanguage: string;
  isDisabled: boolean;
  showFacilitator: boolean;
  showParticipant: boolean;
  cultureNormUse: boolean;
  actions: JSX.Element;
}

export const useLanguageList = () => {
  const dispatch = useDispatch();
  const api = useApiEndpoints(dispatch);
  const navigate = useNavigate();
  const isMounted = useRef(false);
  // Handling Language
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const [data, setdata] = useState<any[]>([]);
  const [filter, setFilter] = useState<string>("");
  const [sorting, setSorting] = useState<string>("");
  const [editedRowId, setEditedRowId] = useState<number>(0);
  const [deleteRowId, setDeleteRowId] = useState<number>(0);
  const [selectedActionRowName, setSelectedActionRowName] =
    useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0); // total_data_count
  const [pageLimit, setPageLimit] = useState<number>(10); // items_per_page
  const [pageCount, setPageCount] = useState<number>(0); // total_page_count
  const [pageSelected, setPageSelected] = useState<number>(0); // page_number_selected
  const [openLanguageModal, setOpenLanguageModal] = useState<boolean>(false); // Add/Edit Client popup
  const [languageUpdated, setLanguageUpdated] = useState<boolean>(false); // This flag is used to re-reder the languages data on requirement

  useEffect(() => {
    if (isMounted.current) {
      const skipCount = pageLimit * pageSelected;
      fetchLanguages(skipCount);
    }
  }, [pageSelected, sorting]);

  useEffect(() => {
    setPageSelected(0);
    fetchLanguages(0);
    if (!isMounted.current) isMounted.current = true;
  }, [filter, pageLimit]);

  useEffect(() => {
    if (languageUpdated) {
      const skipCount = pageLimit * pageSelected;
      fetchLanguages(skipCount);
    }
  }, [languageUpdated]);

  const renderData = (response: any) => {
    const updatedResponse = [...response];

    return updatedResponse.map((language) => ({
      ...language,
      isDisabled: !language.isDisabled,
      showFacilitator: language.showFacilitator ?? false,
      showParticipant: language.showParticipant ?? false,
    }));
  };

  const fetchLanguages = (skipCount: number): void => {
    dispatch(setSpinner(true));

    const body = {
      filter,
      sorting,
      maxResultCount: pageLimit,
      skipCount,
    };

    api
      .getAllLanguages(body)
      .then((response) => {
        if (response) {
          const updatedResponse = renderData(response.items);
          const pageSize = Math.ceil(response.totalCount / pageLimit);
          setdata(updatedResponse);
          setTotalCount(response.totalCount);
          setPageCount(pageSize);
          if (languageUpdated) setLanguageUpdated(false);
        }
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  const addLanguageClick = (): void => {
    setOpenLanguageModal(true);
  };

  const closeLanguageModal = (refreshClientList: boolean): void => {
    if (refreshClientList) {
      setLanguageUpdated(true);
    }
    if (editedRowId) {
      setEditedRowId(0);
    }
    setOpenLanguageModal(false);
  };

  const handleEditClick = (id: number): void => {
    setEditedRowId(id);
  };

  const handleDeleteClick = (id: number, name: string): void => {
    setDeleteRowId(id);
    setSelectedActionRowName(name);
  };

  const handleDeleteLanguage = (): void => {
    deleteLanguage(deleteRowId, dispatch).then(() => {
      setDeleteRowId(0);
      setSelectedActionRowName("");
      setLanguageUpdated(true);
    });
  };

  const closeDeleteModal = (): void => {
    setDeleteRowId(0);
  };

  const handleChangeTextClick = (languageCode: string): void => {
    navigate({
      pathname: routePath.translations,
      search: new URLSearchParams({ languageCode }).toString(),
    });
  };

  const tableHeader = useMemo<ColumnDef<ILanguage>[]>(
    () => [
      {
        header: "",
        accessorKey: "actions",
        disableSortBy: true,
        cell: ({ ...props }) => (
          <CustomActionsCell
            editOption
            deleteOption
            changeTextOption
            languageText={languageText}
            id={props.row.original.id}
            name={props.row.original.displayName}
            changeTextValue={props.row.original.name}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            handleChangeTextClick={handleChangeTextClick}
          />
        ),
      },
      {
        header: getLanguageValue(languageText, "ID"),
        accessorKey: "id",
      },
      {
        header: getLanguageValue(languageText, "Name"),
        accessorKey: "displayName",
      },
      {
        header: getLanguageValue(languageText, "Code"),
        accessorKey: "name",
      },
      {
        header: getLanguageValue(languageText, "Is Enabled"),
        accessorKey: "isDisabled",
        cell: ({ ...props }) => (
          <BooleanCell
            languageText={languageText}
            boolValue={props.row.original.isDisabled}
          />
        ),
      },
      {
        header: getLanguageValue(languageText, "Facilitator"),
        accessorKey: "showFacilitator",
        cell: ({ ...props }) => (
          <BooleanCell
            languageText={languageText}
            boolValue={props.row.original.showFacilitator}
          />
        ),
      },
      {
        header: getLanguageValue(languageText, "Participant"),
        accessorKey: "showParticipant",
        cell: ({ ...props }) => (
          <BooleanCell
            languageText={languageText}
            boolValue={props.row.original.showParticipant}
          />
        ),
      },
      {
        header: getLanguageValue(languageText, "Culture Norm Use"),
        accessorKey: "cultureNormUse",
        cell: ({ ...props }) => (
          <BooleanCell
            languageText={languageText}
            boolValue={props.row.original.cultureNormUse}
          />
        ),
      },
    ],
    [languageText],
  );

  return {
    languageText,
    tableHeader,
    data,
    totalCount,
    pageCount,
    filter,
    pageLimit,
    pageSelected,
    openLanguageModal,
    editedRowId,
    deleteRowId,
    selectedActionRowName,
    setFilter,
    setSorting,
    setPageLimit,
    setPageSelected,
    addLanguageClick,
    closeLanguageModal,
    handleDeleteLanguage,
    closeDeleteModal,
  };
};
