import React, { useState } from "react";
import clsx from "clsx";

import { IPresentationProfile, IPresentationSlide } from "../hooks";
import {
  ITranslationObject,
  getLanguageValue,
} from "@app/commonUtils/languageFunctionsHelper";
import { classNames } from "@app/containers/utils";
import { ProfileId } from "@app/containers/reducer";

interface IPresentationHeaderProps {
  languageText: ITranslationObject;
  slideNumber: number;
  facilitatorSlides?: IPresentationSlide[];
  profileSlideCount?: number;
  profiles?: IPresentationProfile[];
  facilitatorName: string;
  participantName?: string;
  previousSlide?: () => void;
  nextSlide?: () => void;
  handleSlideClick?: (slideNumber: number) => void;
  handleCancelClick: () => void;
  openProfileDynamicSlides?: (profileId: ProfileId) => void;
  isConnected: (profileId: ProfileId) => boolean;
}

export const PresentationHeader = (props: IPresentationHeaderProps) => {
  const [showSlideList, setShowSlideList] = useState<boolean>(false);
  const [showSettingss, setShowSettings] = useState<boolean>(false);

  const handleProfileClick = (profileId: ProfileId): void => {
    props.openProfileDynamicSlides?.(profileId);
  };

  let totalSlideCount = 0;
  if (props.profileSlideCount) {
    // For Participant presentation
    totalSlideCount = props.profileSlideCount;
  } else {
    totalSlideCount = Math.max(
      ...(props.facilitatorSlides?.map((s) => s.slideNumber) ?? [0]),
    );
  }

  return (
    <>
      <nav className="navbar sticky-top navbar-expand-lg bg-white border-bottom border-secondary-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img src="/DeliveryImages/IDI_Logotyp.webp" className="idi-logo" />
          </a>

          <div className="btn-group" role="group">
            {!props.participantName && (
              <div
                className="btn d-flex align-items-center"
                role="button"
                onClick={props.previousSlide}
              >
                <i className="bi bi-caret-left-fill fs-4" />
              </div>
            )}
            <button
              className="btn"
              onClick={
                !props.participantName
                  ? () => setShowSlideList(!showSlideList)
                  : undefined
              }
            >
              <i className="bi bi-easel-fill pe-2" />
              {props.slideNumber}/{totalSlideCount}
            </button>
            {!props.participantName && (
              <div
                className="btn d-flex align-items-center"
                role="button"
                onClick={!props.participantName ? props.nextSlide : undefined}
              >
                <i className="bi bi-caret-right-fill fs-4" />
              </div>
            )}
          </div>

          <div
            className="btn d-flex align-items-center"
            onClick={() => setShowSettings(true)}
          >
            <i className="bi bi-people-fill pe-2" />
            {props.profiles && (
              <>
                {
                  props.profiles.filter((profilePresentation) =>
                    props.isConnected(profilePresentation.profileId),
                  ).length
                }{" "}
                /{props.profiles.length}
              </>
            )}
          </div>
        </div>
      </nav>

      {/* Left Slide List */}
      <div
        className={clsx("offcanvas offcanvas-start", showSlideList && "show")}
        id="slides"
      >
        <div className="offcanvas-header border-bottom border-secondary-light">
          <h5 className="offcanvas-title" id="offcanvasLeftLabel">
            {getLanguageValue(props.languageText, "Slides")}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setShowSlideList(false)}
          />
        </div>
        <div className="offcanvas-body">
          {props.facilitatorSlides?.map((slide, idx) => {
            const clazz = classNames({
              "p-3 mb-1 border text-center": true,
              "border-primary": slide.slideNumber === props.slideNumber,
              "border-secondary-light": slide.slideNumber !== props.slideNumber,
            });

            return (
              <div
                key={idx}
                className={clazz}
                onClick={
                  props.slideNumber !== slide.slideNumber
                    ? () => props.handleSlideClick!(slide.slideNumber)
                    : () => {}
                }
                role="button"
              >
                {slide.slideNumber}. {slide.description}
              </div>
            );
          })}
        </div>
      </div>

      {/* Right Settings Menu */}
      <div
        className={clsx("offcanvas offcanvas-end", showSettingss && "show")}
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header border-bottom border-secondary-light">
          <h5 className="offcanvas-title" id="offcanvasRightLabel">
            {getLanguageValue(props.languageText, "Settings")}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setShowSettings(false)}
          />
        </div>
        <div className="offcanvas-body">
          <div className="mb-3 p-3 rounded bg-secondary bg-opacity-25 fw-bold">
            <div
              className="float-end btn btn-sm btn-light"
              onClick={props.handleCancelClick}
            >
              <i className="bi bi-box-arrow-right pe-1" />
              {getLanguageValue(props.languageText, "Leave")}
            </div>
            <div className="py-1">
              {!props.participantName
                ? props.facilitatorName
                : props.participantName}
            </div>
          </div>

          {!props.participantName ? (
            props.profiles && (
              <>
                <div className="pt-3 pb-2">
                  {getLanguageValue(props.languageText, "Participants")} -{" "}
                  {
                    props.profiles.filter((profilePresentation) =>
                      props.isConnected(profilePresentation.profileId),
                    ).length
                  }
                  /{props.profiles.length}{" "}
                  {getLanguageValue(props.languageText, "connected")}:
                </div>
                {props.profiles.map((presentationProfile) => (
                  <div
                    key={presentationProfile.profileId}
                    className={clsx(
                      "p-2 mt-1 rounded bg-opacity-25",
                      props.isConnected(presentationProfile.profileId)
                        ? "bg-success"
                        : "border border-secondary-light",
                    )}
                    role="button"
                    onClick={() =>
                      handleProfileClick(presentationProfile.profileId)
                    }
                  >
                    <div className="dropdown-item">
                      <i
                        className={clsx(
                          "bi pe-1",
                          props.isConnected(presentationProfile.profileId)
                            ? "bi-circle-fill text-success"
                            : "bi-circle",
                        )}
                      />
                      {presentationProfile.forename}{" "}
                      {presentationProfile.surname}
                    </div>
                  </div>
                ))}
              </>
            )
          ) : (
            <div className="pt-3 pb-2">
              {getLanguageValue(props.languageText, "Facilitator")} -{" "}
              <span className="fw-bold">{props.facilitatorName}</span>
            </div>
          )}
        </div>
      </div>

      <div
        className={clsx(
          (showSlideList || showSettingss) && "offcanvas-backdrop show",
        )}
      ></div>
    </>
  );
};
