import React from "react";

import { AlertWindow } from "../../components/alert/alert";
import { RoleName } from "../commonEnums";

type AllowedRoles = Array<RoleName>;

export const roleAccessControl = (
  Component: JSX.Element,
  allowedRoles: AllowedRoles,
  loggedInUserRoleName: RoleName | null,
  loading: boolean,
): JSX.Element => {
  if (loggedInUserRoleName && allowedRoles.includes(loggedInUserRoleName)) {
    return Component;
  } else if (loading) {
    return <></>;
  } else {
    return <></>;
  }
};
