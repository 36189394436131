import React from "react";
import clsx from "clsx";

interface ICourseProgressBarProps {
  id: string;
  progress: number;
  bgColor: string;
  className?: string;
}

export const CourseProgressBar = (
  props: ICourseProgressBarProps,
): JSX.Element => {
  return (
    <div
      id={props.id}
      className={clsx(
        "w-100 progress",
        props.bgColor,
        props.className && props.className,
      )}
      role="progressbar"
      aria-valuemin={0}
      aria-valuenow={props.progress}
      aria-valuemax={100}
    >
      <div
        className={`progress-bar p-2 ${
          props.progress == 100
            ? "bg-success"
            : props.progress <= 50
              ? "bg-warning"
              : "bg-success bg-opacity-50"
        }`}
        style={{ width: `${props.progress.toFixed(0)}%`, minWidth: "1px" }}
      >
        {props.progress.toFixed(0)}%
      </div>
    </div>
  );
};
