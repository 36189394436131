export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";
export const SET_SPINNER = "SET_SPINNER";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
export const SET_CURRENT_USER_LANGUAGES = "SET_CURRENT_USER_LANGUAGES";
export const ROLES_SWITCHED = "ROLES_SWITCHED";
export const PAGE_CONFIGURATION_INFO = "PAGE_CONFIGURATION_INFO"; // Search, Sort, Pagination, NoOfRows
export const SET_TRANSLATIONS = "SET_TRANSLATIONS";
export const SET_ALERTS = "SET_ALERTS";
export const TRIGGER_ALERT = "TRIGGER_ALERT";
export const UPDATE_ALERT_WITH_ACTION_TAKEN = "UPDATE_ALERT_WITH_ACTION_TAKEN";
export const ADD_TOAST = "ADD_TOAST";
export const REMOVE_TOAST = "REMOVE_TOAST";
export const SET_GUIDE_DATA = "SET_GUIDE_DATA";
export const MARK_GUIDE_AS_COMPLETED = "MARK_GUIDE_AS_COMPLETED";
export const SHOW_CONFIRM_DIALOG = "SHOW_CONFIRM_DIALOG";
export const HIDE_CONFIRM_DIALOG = "HIDE_CONFIRM_DIALOG";
export const SHOW_ACCOUNT_SWITCH_MENU = "SHOW_ACCOUNT_SWITCH_MENU";
export const SHOW_RIGHT_MENU = "SHOW_RIGHT_MENU";
