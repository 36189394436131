import { getLanguageValue } from "@app/commonUtils/languageFunctionsHelper";
import { DeleteModal } from "@app/components/modals/deleteModal";
import { ReactTable } from "@app/components/reactTable/reactTable";
import { RootState } from "@app/store/configureStore";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProfileAdaptabilityDescription } from "./types";
import { ColumnDef } from "@tanstack/react-table";
import { CustomActionsCell } from "@app/components/reactTable/customActionsCell";
import { useApiEndpoints } from "@app/api/end-points";
import { ILanguage, ITableCommonParams } from "../commonInterfaces";
import { ModalComponent } from "@app/components/modals/modalComponent";
import { ILanguageText } from "../reducer";
import { TextArea } from "@app/components/formComponents/textArea";
import {
  DropdownSelect,
  IDropdownList,
} from "@app/components/formComponents/dropdownSelect";

type EditModalProps = {
  close: () => unknown;
  languages: Array<ILanguage>;
  languageText: ILanguageText;
  onChange: (next: ProfileAdaptabilityDescription) => unknown;
  onSave: () => unknown;
  value: ProfileAdaptabilityDescription;
};

const EditModal: React.FC<EditModalProps> = (props) => {
  // TODO: the frontend needs a function to interpolate strings with translation keys,
  //   where the string is 'something like this with [placeholder here]'
  const title =
    getLanguageValue(props.languageText, props.value.id ? "Edit" : "Create") +
    " " +
    getLanguageValue(props.languageText, "Profile adaptability description");

  function onChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void {
    const next: ProfileAdaptabilityDescription = {
      ...props.value,
      [event.target.name]: event.target.value,
    };
    props.onChange(next);
  }

  const textAreas = (["low", "mediumLow", "mediumHigh", "high"] as const).map(
    (prop, index) => {
      // the translation strings for 'mediumLow' is defined as 'Medium Low'.
      // we are case insensitive but we require the space to be there. maybe we should
      // normalize the translations even further so we can ignore spaces completely.
      //   -johan, 2025-01-08
      const propWithSpacesBetweenWords = prop.replace(
        /([a-z])([A-Z])/g,
        "$1 $2",
      );

      return (
        <TextArea
          key={index}
          name={prop}
          label={getLanguageValue(
            props.languageText,
            propWithSpacesBetweenWords,
          )}
          placeholder={getLanguageValue(
            props.languageText,
            propWithSpacesBetweenWords,
          )}
          onChange={onChange}
          value={props.value[prop]}
          rows={8}
        />
      );
    },
  );

  const languageItems: Array<IDropdownList<number>> = props.languages.map(
    (l) => {
      return {
        id: l.id,
        displayName: l.displayName,
        value: l.id,
      };
    },
  );

  return (
    <ModalComponent
      headerText={title}
      width="lg"
      handleSubmitClick={props.onSave}
      submitButtonText={getLanguageValue(props.languageText, "Save")}
      handleCancelClick={props.close}
      cancelButtonText={getLanguageValue(props.languageText, "Cancel")}
    >
      <form>
        <DropdownSelect
          label={getLanguageValue(props.languageText, "Language")}
          name="languageId"
          handleDropdownSelect={(next) => {
            props.onChange({
              ...props.value,
              languageId: next.value,
            });
          }}
          list={languageItems}
          value={props.value.languageId}
        />
        {textAreas}
      </form>
    </ModalComponent>
  );
};

export const ProfileAdaptabilityDescriptionList: React.FC = (props) => {
  const dispatch = useDispatch();
  const api = useApiEndpoints(dispatch);
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );
  const [data, setData] = React.useState<Array<ProfileAdaptabilityDescription>>(
    [],
  );
  const [filter, setFilter] = React.useState<string>("");
  const [sorting, setSorting] = React.useState<string>("");
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const [pageLimit, setPageLimit] = React.useState<number>(10);
  const [pageCount, setPageCount] = React.useState<number>(0);
  const [pageSelected, setPageSelected] = React.useState<number>(0);
  const [deleting, setDeleting] = React.useState<number | undefined>(undefined);
  const [editing, setEditing] = React.useState<
    ProfileAdaptabilityDescription | undefined
  >(undefined);
  const [languages, setLanguages] = React.useState<Array<ILanguage>>([]);

  function handleEditClick(id: number): void {
    const found = data.find((p) => p.id === id);
    if (found) {
      setEditing(found);
    }
  }

  const tableHeader: Array<ColumnDef<ProfileAdaptabilityDescription>> = [
    {
      header: "",
      accessorKey: "actions",
      enableSorting: false,
      cell: ({ ...props }) => (
        <CustomActionsCell
          editOption
          deleteOption
          languageText={languageText}
          id={props.row.original.id}
          name={props.row.original.id?.toString()}
          handleEditClick={handleEditClick}
          handleDeleteClick={setDeleting}
        />
      ),
    },
    {
      header: getLanguageValue(languageText, "ID"),
      accessorKey: "id",
    },
    {
      header: getLanguageValue(languageText, "Language"),
      cell: (ctx) => {
        const lang = languages.find(
          (l) => ctx.row.original.languageId === l.id,
        );

        return <React.Fragment>{lang?.displayName}</React.Fragment>;
      },
    },
    {
      header: getLanguageValue(languageText, "Low"),
      accessorKey: "low",
    },
    {
      header: getLanguageValue(languageText, "Medium low"),
      accessorKey: "mediumLow",
    },
    {
      header: getLanguageValue(languageText, "Medium high"),
      accessorKey: "mediumHigh",
    },
    {
      header: getLanguageValue(languageText, "High"),
      accessorKey: "high",
    },
  ];

  function loadProfileAdaptabilityDescriptions(page: number): void {
    setPageSelected(page);

    const params: ITableCommonParams = {
      filter: filter,
      sorting: sorting,
      maxResultCount: pageLimit,
      skipCount: page * pageLimit,
    };

    api.getProfileAdaptabilityDescriptions(params).then((res) => {
      setData(res.items.slice());
      setTotalCount(res.totalCount);
      setPageCount(Math.ceil(res.totalCount / pageLimit));
    });
  }

  React.useEffect(() => {
    loadProfileAdaptabilityDescriptions(0);
  }, [filter, sorting, pageSelected]);

  React.useEffect(() => {
    api.getLanguages().then((res) => {
      setLanguages(res.items);
    });
  }, []);

  function handleConfirmDelete(): void {
    if (!deleting) {
      return;
    }
    api.deleteProfileAdaptabilityDescriptions(deleting).then(() => {
      loadProfileAdaptabilityDescriptions(0);
      setDeleting(undefined);
    });
  }

  function handleCloseDeleteModal(): void {
    setDeleting(undefined);
  }

  function handleCloseEditModal(): void {
    setEditing(undefined);
  }

  function handleInputChange(next: ProfileAdaptabilityDescription): void {
    setEditing(next);
  }

  function handleCreateOrUpdate(): void {
    if (!editing) {
      return;
    }
    const promise = editing.id
      ? api.updateProfileAdaptabilityDescriptions(editing.id, editing)
      : api.createProfileAdaptabilityDescriptions(editing);

    promise.then(() => {
      loadProfileAdaptabilityDescriptions(0);
      setEditing(undefined);
    });
  }

  return (
    <React.Fragment>
      <ReactTable
        wrapperClass="p-2 pt-4 p-md-4"
        headerText={getLanguageValue(
          languageText,
          "Profile adaptability descriptions",
        )}
        searchPlaceholder={getLanguageValue(languageText, "Search")}
        buttonText={getLanguageValue(languageText, "Add description")}
        tableHeader={tableHeader}
        data={data}
        totalCount={totalCount}
        pageCount={pageCount}
        filter={filter}
        pageLimit={pageLimit}
        pageSelected={pageSelected}
        handleButtonClick={(event) => {
          event.preventDefault();
          event.stopPropagation();

          setEditing({
            low: "",
            mediumLow: "",
            mediumHigh: "",
            high: "",
            languageId: 1, // en-GB, hopefully.
          });
        }}
        setFilter={setFilter}
        setSorting={setSorting}
        setPageLimit={setPageLimit}
        setPageSelected={setPageSelected}
      />

      {editing ? (
        <EditModal
          close={handleCloseEditModal}
          languages={languages}
          languageText={languageText}
          onChange={handleInputChange}
          onSave={handleCreateOrUpdate}
          value={editing}
        />
      ) : undefined}

      {deleting ? (
        <DeleteModal
          languageText={languageText}
          headerText={getLanguageValue(
            languageText,
            "Profile adaptability description",
          )}
          bodyText={deleting.toString()}
          handleDeleteClick={handleConfirmDelete}
          closeDeleteModal={handleCloseDeleteModal}
        />
      ) : undefined}
    </React.Fragment>
  );
};
