import React from "react";
import { useEffect, useState, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ColumnDef } from "@tanstack/react-table";
import { setSpinner } from "../../actions";
import { getNewsList, deleteNews } from "./actions";
import { RootState } from "@app/store/configureStore";
import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import { INewsList, NewsType } from "./types";
import { CustomActionsCell } from "../../../components/reactTable/customActionsCell";
import { BooleanCell } from "../../../components/reactTable/booleanCell";
import { ITabNavItem } from "../../../components/multiTabComponent/tabNavItem";
import { TabEnum } from "./types";

export interface IProfileHeaderProps {
  id: number;
  name: string;
  content: JSX.Element;
  isActive: boolean;
  href: string;
}

export const useNewsList = () => {
  const dispatch = useDispatch();
  const isMounted = useRef(false);

  // Handling Language
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageLimit, setPageLimit] = useState<number>(10); // items_per_page
  const [pageCount, setPageCount] = useState<number>(0);
  const [filter, setFilter] = useState<string>("");
  const [pageSelected, setPageSelected] = useState<number>(0);
  const [sorting, setSorting] = useState<string>("");
  const [openNewsModal, setOpenNewsModal] = useState<boolean>(false);
  const [editedRowId, setEditedRowId] = useState<number>(0);
  const [newsUpdated, setNewsUpdated] = useState<boolean>(false);
  const [deleteRowId, setDeleteRowId] = useState<number>(0);
  const [selectedActionRowName, setSelectedActionRowName] =
    useState<string>("");
  const [news, setNews] = useState<Array<INewsList>>([]);
  const [newsType, setNewsType] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<TabEnum>(TabEnum.TAB_1);

  //useeffects to call api if clicking on tab nav item
  useEffect(() => {
    if (!activeTab) return;
    setNews([]);
    if (
      activeTab == NewsType.News.toString() ||
      activeTab == NewsType[NewsType.News]
    ) {
      setNewsType(NewsType.News);
      getAllNews(0, NewsType.News);
    } else {
      setNewsType(NewsType.Blogs);
      getAllNews(0, NewsType.Blogs);
    }
  }, [activeTab]);

  useEffect(() => {
    if (!newsType) return;
    if (isMounted.current) {
      const skipCount = pageLimit * pageSelected;
      getAllNews(skipCount, newsType);
    }
  }, [pageSelected, sorting]);

  useEffect(() => {
    setPageSelected(0);
    getAllNews(0, newsType);
    if (!isMounted.current) isMounted.current = true;
  }, [filter, pageLimit]);

  useEffect(() => {
    if (newsUpdated) {
      const skipCount = pageLimit * pageSelected;
      getAllNews(skipCount, newsType);
    }
  }, [newsUpdated]);

  const getAllNews = (skipCount: number, newsType: NewsType): void => {
    if (!newsType) return;
    dispatch(setSpinner(true));
    getNewsList(filter, sorting, newsType, pageLimit, skipCount, dispatch)
      .then((fetchedNews) => {
        if (fetchedNews) {
          const pageSize = Math.ceil(fetchedNews.totalCount / pageLimit);
          setTotalCount(fetchedNews.totalCount);
          setPageCount(pageSize);
          setNews(fetchedNews.items.slice());
        }
        if (newsUpdated) setNewsUpdated(false);
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  //fns for news list
  const tableHeader = useMemo<ColumnDef<INewsList, any>[]>(
    () => [
      {
        header: "",
        accessorKey: "actions",
        disableSortBy: true,
        cell: ({ ...props }) => (
          <CustomActionsCell
            editOption
            deleteOption
            languageText={languageText}
            id={props.row.original.id}
            name={props.row.original.title}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
          />
        ),
      },
      {
        header: getLanguageValue(languageText, "ID"),
        accessorKey: "id",
      },
      {
        header: getLanguageValue(languageText, "Title"),
        accessorKey: "title",
        cell: ({ ...props }) => (
          <div style={{ whiteSpace: "normal", width: "150px" }}>
            {props.row.original.title}
          </div>
        ),
      },
      {
        header: getLanguageValue(languageText, "Excerpt"),
        accessorKey: "excerpt",
        cell: ({ ...props }) => (
          <div
            className="text-truncate"
            style={{ maxWidth: "400px" }}
            title={props.row.original.excerpt}
          >
            {props.row.original.excerpt}
          </div>
        ),
      },

      {
        header: getLanguageValue(languageText, "Published Date"),
        accessorKey: "publishedDate",
        cell: ({ ...props }) => (
          <div>{props.row.original.publishedDate?.toString().slice(0, -8)}</div>
        ),
      },
      {
        header: getLanguageValue(languageText, "Published"),
        accessorKey: "published",
        cell: ({ ...props }) => (
          <BooleanCell
            trueValueText={getLanguageValue(languageText, "True")}
            falseValueText={getLanguageValue(languageText, "False")}
            languageText={languageText}
            boolValue={props.row.original.published}
          />
        ),
      },
    ],
    [languageText],
  );

  const navTabs = useMemo(() => {
    const tabs: ITabNavItem<TabEnum>[] = [
      {
        id: TabEnum.TAB_1,
        title: getLanguageValue(languageText, "News"),
      },
      {
        id: TabEnum.TAB_2,
        title: getLanguageValue(languageText, "Blogs"),
      },
    ];

    return tabs;
  }, [languageText]);

  const closeNewsModal = (refreshNewsList: boolean): void => {
    if (refreshNewsList) {
      setNewsUpdated(true);
    }
    setOpenNewsModal(false);
    if (editedRowId) {
      setEditedRowId(0);
    }
  };

  const addNewsClick = (): void => {
    setOpenNewsModal(true);
  };

  const handleEditClick = (id: number): void => {
    setEditedRowId(id);
  };

  const handleDeleteClick = (id: number, name: string): void => {
    setDeleteRowId(id);
    setSelectedActionRowName(name);
  };

  const handleDeleteNews = (): void => {
    deleteNews(deleteRowId, dispatch).then(() => {
      setDeleteRowId(0);
      setSelectedActionRowName("");
      setNewsUpdated(true);
    });
  };

  const closeDeleteModal = (): void => setDeleteRowId(0);

  return {
    languageText,
    editedRowId,
    deleteRowId,
    filter,
    newsType,
    navTabs,
    activeTab,
    news,
    openNewsModal,
    pageCount,
    pageLimit,
    pageSelected,
    sorting,
    selectedActionRowName,
    tableHeader,
    totalCount,
    setActiveTab,
    setFilter,
    setPageSelected,
    setSorting,
    setPageLimit,
    handleDeleteNews,
    addNewsClick,
    closeNewsModal,
    closeDeleteModal,
  };
};
