import React from "react";
import clsx from "clsx";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../commonUtils/languageFunctionsHelper";
import { IFacilitatorDashboardAcademy } from "../interface";

interface IFacilitatorDashboardAcademySectionProps {
  languageText: ITranslationObject;
  academy: IFacilitatorDashboardAcademy[];
}

export const FacilitatorDashboardAcademySection = (
  props: IFacilitatorDashboardAcademySectionProps,
): JSX.Element => {
  let progressBarWidth: number = 0;
  if (props.academy.length > 0) {
    props.academy.forEach((category) => {
      const eachCategoryProgress = 100 / props.academy.length;
      if (category.courseCount > 0) {
        progressBarWidth += eachCategoryProgress;
      }
    });
  }

  return (
    <div className="col-12 col-md-4">
      <div className="card h-100 shadow-sm bg-white rounded border-0">
        <div className="card-header border-0 p-3 fs-5 text-body-tertiary">
          {getLanguageValue(props.languageText, "Academy")}
        </div>
        <div className="card-body p-4">
          <div className="progress">
            <div
              className="progress-bar bg-success"
              style={{ width: `${progressBarWidth}%` }}
            ></div>
          </div>

          <div className="mt-3">
            {props.academy.length > 0 ? (
              props.academy.map((category) => (
                <div
                  key={category.categoryId}
                  className={clsx(
                    "d-flex mt-1 justify-content-between align-items-center p-2 flex-wrap rounded shadow-sm",
                    category.courseCount ? "grey-bg" : "grey-bg",
                  )}
                >
                  <div className="small ms-1">{category.categoryName}</div>
                  <div className="d-flex justify-content-center align-items-center">
                    <span
                      className={clsx(
                        "border px-2 rounded",
                        category.courseCount
                          ? "bg-success text-white"
                          : "bg-white",
                      )}
                    >
                      {category.courseCount}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <div className="color-999">
                {getLanguageValue(props.languageText, "No data")}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
