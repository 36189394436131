import { classNames } from "@app/containers/utils";
import * as React from "react";

export type DropdownMenuItem = {
  name: React.ReactNode;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  title?: string;
};

type Props = {
  icon?: React.ReactNode;
  id?: string;
  items: ReadonlyArray<DropdownMenuItem>;
};

export const DropdownMenu: React.FC<Props> = (props) => {
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState(false);
  const [shouldDropUp, setShouldDropUp] = React.useState(false);
  const [menuHeight, setMenuHeight] = React.useState(0);

  // Add effect to measure menu height when it opens
  React.useEffect(() => {
    if (open && dropdownRef.current) {
      const menu = dropdownRef.current.querySelector("ul");
      if (menu) {
        setMenuHeight(menu.getBoundingClientRect().height);
      }
    }
  }, [open]);

  // Check if dropdown should open upward
  const checkPosition = React.useCallback(() => {
    if (dropdownRef.current) {
      const rect = dropdownRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      setShouldDropUp(rect.top > windowHeight / 2);
    }
  }, []);

  React.useEffect(() => {
    checkPosition();
    window.addEventListener("scroll", checkPosition);
    window.addEventListener("resize", checkPosition);
    return () => {
      window.removeEventListener("scroll", checkPosition);
      window.removeEventListener("resize", checkPosition);
    };
  }, [checkPosition]);

  function handleClickOutside(event: MouseEvent): void {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setOpen(false);
    }
  }

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function onClick(
    event: React.MouseEvent<HTMLButtonElement>,
    item: DropdownMenuItem,
  ): void {
    event.preventDefault();
    if (item.disabled) return;
    item.onClick(event);
    setOpen(false);
  }

  const icon = props.icon || <i className="bi bi-gear-fill" />;

  return (
    <div
      className={classNames({
        dropdown: !shouldDropUp,
        dropup: shouldDropUp,
      })}
      ref={dropdownRef}
    >
      <button
        className="btn btn-light"
        type="button"
        id={props.id}
        onClick={() => setOpen(!open)}
      >
        {icon}
      </button>
      <ul
        className={classNames({
          "dropdown-menu": true,
          "shadow-sm": true,
          "d-block": open,
          "position-fixed": true,
          "border-0": true,
        })}
        style={{
          zIndex: 1050,
          ...(dropdownRef.current && {
            top: shouldDropUp
              ? dropdownRef.current.getBoundingClientRect().top - menuHeight
              : dropdownRef.current.getBoundingClientRect().bottom,
            left: dropdownRef.current.getBoundingClientRect().left,
          }),
        }}
      >
        {props.items.map((item, idx) => (
          <li key={idx}>
            <button
              className={classNames({
                "dropdown-item": true,
                disabled: item.disabled,
              })}
              onClick={(event) => onClick(event, item)}
              disabled={item.disabled}
              title={item.title}
            >
              {item.name}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};
