import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../../commonUtils/languageFunctionsHelper";
import {
  EditModalEnum,
  IClientFacilitator,
} from "./editProfileDescriptionStep";
import {
  DropdownSelect,
  IDropdownList,
  IDropdownSelectedItem,
} from "@app/components/formComponents/dropdownSelect";
import { ActivityStatus } from "../../../activityEnums";
import { ReactDatePicker } from "../../../../../components/datePicker/ReactDatePicker";
import { Input } from "../../../../../components/formComponents/input";
import {
  recalculateProfiles,
  saveActivityEditFieldValue,
  updateCompletionDate,
} from "../../actions";
import { RootState } from "@app/store/configureStore";
import { getFormattedDateOnly } from "../../../../../commonUtils/dateFunctionsHelper";
import { EditProfileConfirmationModal } from "./editProfileConfirmationModal";
import { IEditActivity } from "../../interface";
import { addToast, setSpinner } from "../../../../actions";
import { ModalComponent } from "@app/components/modals/modalComponent";
import { AnyAction } from "redux";
import { isLoggedInRoleAdmin } from "@app/commonUtils/roleHelper";
import { ActivityId } from "@app/containers/reducer";
import { ILanguage } from "@app/containers/commonInterfaces";
import { TextArea } from "@app/components/formComponents/textArea";
import { Checkbox } from "@app/components/formComponents/checkbox";

export interface ISaveProfileEditFieldValueBody {
  id: ActivityId;
  fieldName: string;
  fieldValue: string;
}

interface IEditProfileDescriptionModalProps {
  id: ActivityId;
  languageText: ITranslationObject;
  editModalName: string;
  statusValue: number;
  profileData: IEditActivity;
  completionDateValue: string;
  nameValue: string;
  notesValue: string;
  languages: ReadonlyArray<ILanguage>;
  countries: IDropdownList[];
  facilitators: IClientFacilitator[];
  languageName: string | JSX.Element;
  countryName: string | JSX.Element;
  facilitatorId: number;
  sourceType: string;
  setStatusValue: React.Dispatch<React.SetStateAction<number>>;
  setCompletionDateValue: React.Dispatch<React.SetStateAction<string>>;
  setNameValue: React.Dispatch<React.SetStateAction<string>>;
  setNotesValue: React.Dispatch<React.SetStateAction<string>>;
  setLanguageName: React.Dispatch<React.SetStateAction<string | JSX.Element>>;
  setCountryName: React.Dispatch<React.SetStateAction<string | JSX.Element>>;
  setSourceType: React.Dispatch<React.SetStateAction<string>>;
  handleLanguageSelect: (languageId: number) => void;
  handleFacilitatorSelect: (facilitatorId: number) => void;
  refetchFullProfile: () => void;
  closeModal: () => void;
}

export const EditProfileDescriptionModal: React.FC<
  IEditProfileDescriptionModalProps
> = (props) => {
  const dispatch = useDispatch();
  const loggedInUserRole = useSelector(
    (state: RootState) => state.loginReducer.loggedInUserRole,
  );

  const statusDropdown: Array<IDropdownList> = [
    {
      id: ActivityStatus.Created,
      displayName: getLanguageValue(
        props.languageText,
        ActivityStatus[ActivityStatus.Created],
      ),
      value: String(ActivityStatus.Created),
    },
    {
      id: ActivityStatus.Active,
      displayName: getLanguageValue(
        props.languageText,
        ActivityStatus[ActivityStatus.Active],
      ),
      value: String(ActivityStatus.Active),
    },
    {
      id: ActivityStatus.Completed,
      displayName: getLanguageValue(
        props.languageText,
        ActivityStatus[ActivityStatus.Completed],
      ),
      value: String(ActivityStatus.Completed),
    },
    {
      id: ActivityStatus.Invoiced,
      displayName: getLanguageValue(
        props.languageText,
        ActivityStatus[ActivityStatus.Invoiced],
      ),
      value: String(ActivityStatus.Invoiced),
    },
    {
      id: ActivityStatus.Cancelled,
      displayName: getLanguageValue(
        props.languageText,
        ActivityStatus[ActivityStatus.Cancelled],
      ),
      value: String(ActivityStatus.Cancelled),
    },
    {
      id: ActivityStatus.Delivered,
      displayName: getLanguageValue(
        props.languageText,
        ActivityStatus[ActivityStatus.Delivered],
      ),
      value: String(ActivityStatus.Delivered),
    },
  ];

  // BIG FIXME:
  //   all these copied properties are incredibly confusing. they are suffixed with 'value'
  //   so they won't conflict with the input property with the exact same name. but why do
  //   we repeat ourselves and have a prop, a change handler, a stateful value etc. for
  //   every single property? why not just make it generic over the key?
  //
  //   -johan, 2024-05-20
  const [status, setStatus] = useState<string>("");
  const [completionDate, setCompletionDate] = useState<Date | null>(null);
  const [name, setName] = useState<string>(""); // Description
  const [notes, setNotes] = useState<string>("");
  const [languageValue, setLanguageValue] = useState<string>("");
  const [countryValue, setCountryValue] = useState<string>("");
  const [selectedFacilitatorId, setSelectedFacilitatorId] = useState<number>(0);
  const [sourceTypeValue, setSourceTypeValue] = useState<string>(
    props.sourceType,
  );
  const [isProBono, setIsProBono] = useState(props.profileData.isProBono);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const completedAndDeliveredProfileCount =
    props.profileData.completedProfileCount +
    props.profileData.deliveredProfileCount;
  const [isWarningMsg, setIsWarningMsg] = useState<boolean>(false);

  useEffect(() => {
    const languageValue =
      props.languages.find((language) => language.name === props.languageName)
        ?.name ?? "";
    const countryValue =
      props.countries.find(
        (country) => country.displayName === props.countryName,
      )?.value ?? "";

    switch (props.editModalName) {
      case EditModalEnum.status:
        setStatus(String(props.statusValue));
        break;
      case EditModalEnum.completionDate:
        setCompletionDate(new Date(props.completionDateValue));
        break;
      case EditModalEnum.name:
        setName(props.nameValue);
        break;
      case EditModalEnum.notes:
        setNotes(props.notesValue);
        break;
      case EditModalEnum.language:
        setLanguageValue(languageValue);
        break;
      case EditModalEnum.country:
        setCountryValue(countryValue);
        break;
      case EditModalEnum.facilitator:
        setSelectedFacilitatorId(props.facilitatorId);
        break;
      default:
        break;
    }
  }, []);

  const renderStatusElement = (): JSX.Element => {
    const handleStatusSelect = (selectedItem: IDropdownSelectedItem): void => {
      setStatus(selectedItem.value);
    };
    return (
      <DropdownSelect
        name={EditModalEnum.status}
        label={getLanguageValue(props.languageText, "Status")}
        dropdownHeight="mh-325px"
        list={statusDropdown}
        value={status}
        handleDropdownSelect={handleStatusSelect}
      />
    );
  };

  const renderDateElement = (): JSX.Element => {
    const handleDateSelect = (name: string, date: Date): void => {
      setCompletionDate(date);
      // If admin selects previous date, show warning message
      if (isLoggedInRoleAdmin(loggedInUserRole) && date < new Date()) {
        setIsWarningMsg(true);
      } else setIsWarningMsg(false);
    };

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    return (
      <>
        <ReactDatePicker
          label={getLanguageValue(props.languageText, "Completion date")}
          name={props.editModalName}
          placeholder={getLanguageValue(
            props.languageText,
            "Select Completion Date",
          )}
          date={completionDate}
          handleDateSelect={handleDateSelect}
          minDate={
            !isLoggedInRoleAdmin(loggedInUserRole) ? tomorrow : undefined
          }
          required
        />
        {isWarningMsg && (
          <div className="text-danger">
            {getLanguageValue(
              props.languageText,
              `Don't forget to update status after changing the date`,
            )}
          </div>
        )}
      </>
    );
  };

  const renderDescriptionElement = (): JSX.Element => {
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setName(e.target.value);
    };
    return (
      <Input
        name={props.editModalName}
        label={getLanguageValue(props.languageText, "Description")}
        placeholder={getLanguageValue(props.languageText, "Description")}
        value={name}
        handleInputChange={handleInputChange}
        required
      />
    );
  };

  const renderNotesElement = (): JSX.Element => {
    return (
      <TextArea
        name={props.editModalName}
        label={getLanguageValue(props.languageText, "Notes")}
        placeholder={getLanguageValue(props.languageText, "Notes")}
        value={notes}
        onChange={(event) => {
          setNotes(event.target.value);
        }}
        rows={8}
      />
    );
  };

  const renderLanguageElement = (): JSX.Element => {
    const handleLanguageSelect = (
      selectedItem: IDropdownSelectedItem,
    ): void => {
      setLanguageValue(selectedItem.value);
    };
    const languageDropdownItems: Array<IDropdownList> = props.languages.map(
      (l) => {
        return {
          id: l.id,
          displayName: l.displayName,
          value: l.name,
        };
      },
    );

    return (
      <DropdownSelect
        name={EditModalEnum.language}
        label={getLanguageValue(props.languageText, "Norm")}
        list={languageDropdownItems}
        value={languageValue}
        handleDropdownSelect={handleLanguageSelect}
        searchOption
      />
    );
  };

  const renderCountryElement = (): JSX.Element => {
    const handleCountrySelect = (selectedItem: IDropdownSelectedItem): void => {
      setCountryValue(selectedItem.value);
    };
    return (
      <DropdownSelect
        name={EditModalEnum.country}
        label={getLanguageValue(props.languageText, "Country")}
        list={props.countries}
        value={countryValue}
        handleDropdownSelect={handleCountrySelect}
        searchOption
      />
    );
  };

  const renderFacilitatorElement = (): JSX.Element => {
    const handleFacilitatorSelect = (
      selectedItem: IDropdownSelectedItem,
    ): void => {
      setSelectedFacilitatorId(selectedItem.id);
    };
    const facilitatorsList: IDropdownList[] = props.facilitators.map(
      (item) => ({
        id: item.id,
        displayName: item.name,
        value: item.id.toString(),
      }),
    );

    return (
      <DropdownSelect
        name={EditModalEnum.facilitator}
        label={getLanguageValue(props.languageText, "Facilitator")}
        list={facilitatorsList}
        value={selectedFacilitatorId.toString()}
        handleDropdownSelect={handleFacilitatorSelect}
      />
    );
  };

  const renderSourceTypeElement = (): JSX.Element => {
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSourceTypeValue(e.target.value);
    };
    return (
      <Input
        name={props.editModalName}
        label={getLanguageValue(props.languageText, "Source type")}
        placeholder={getLanguageValue(props.languageText, "Source type")}
        value={sourceTypeValue}
        handleInputChange={handleInputChange}
      />
    );
  };

  function renderBooleanElement(
    key: keyof IEditActivity,
    label: string,
  ): JSX.Element {
    return (
      <Checkbox
        name={key}
        label={label}
        value={isProBono}
        handleCheckboxChange={(event) => {
          setIsProBono(event.target.checked);
        }}
      />
    );
  }

  const renderModalBody = (): JSX.Element => {
    let element: JSX.Element = <></>;

    switch (props.editModalName) {
      case EditModalEnum.status:
        element = renderStatusElement();
        break;
      case EditModalEnum.completionDate:
        element = renderDateElement();
        break;
      case EditModalEnum.name:
        element = renderDescriptionElement();
        break;
      case EditModalEnum.notes:
        element = renderNotesElement();
        break;
      case EditModalEnum.language:
        element = renderLanguageElement();
        break;
      case EditModalEnum.country:
        element = renderCountryElement();
        break;
      case EditModalEnum.facilitator:
        element = renderFacilitatorElement();
        break;
      case EditModalEnum.sourceType:
        element = renderSourceTypeElement();
        break;
      case EditModalEnum.isProBono:
        // the 'ProBono' label is intentionally in one word because that's
        // what the current translation is defined as.
        element = renderBooleanElement("isProBono", "ProBono");
        break;
      default:
        break;
    }

    return element;
  };

  const openShowConfirmationModal = (): void => {
    setShowConfirmationModal(true);
  };

  const closeShowConfirmationModal = (): void => {
    setShowConfirmationModal(false);
  };

  const updateCompletionDateByAdmin = (): void => {
    const formattedDate = getFormattedDateOnly(completionDate!);
    updateCompletionDate(props.id, formattedDate, dispatch).then((response) => {
      if (response) {
        dispatch(addToast("Changes saved successfully") as AnyAction);
        props.setCompletionDateValue(formattedDate);
        props.closeModal();
      }
    });
  };

  const handleSaveClick = async (): Promise<void> => {
    if (
      isLoggedInRoleAdmin(loggedInUserRole) &&
      props.editModalName === EditModalEnum.completionDate
    ) {
      updateCompletionDateByAdmin();
    } else {
      const body: ISaveProfileEditFieldValueBody = {
        id: props.id,
        fieldName: "",
        fieldValue: "",
      };

      //This completionDate is only defined in the case of EditModalEnum. - Joakim, 241204
      //Let's just take today's date so that we at least have a decent value - "should never happen..."
      const formattedDate = completionDate
        ? getFormattedDateOnly(completionDate)
        : getFormattedDateOnly(new Date());
      const languageId = String(
        props.languages.find((language) => language.name === languageValue)
          ?.id ?? "",
      );
      const countryId = String(
        props.countries.find((country) => country.value === countryValue)?.id ??
          "",
      );
      const userId = String(
        props.facilitators.find(
          (facilitator) => facilitator.id === selectedFacilitatorId,
        )?.id ?? "",
      );

      switch (props.editModalName) {
        case EditModalEnum.status:
          body.fieldName = "Status";
          body.fieldValue = status;
          break;
        case EditModalEnum.completionDate:
          body.fieldName = "CompletionDate";
          body.fieldValue = formattedDate;
          break;
        case EditModalEnum.name:
          body.fieldName = "Name";
          body.fieldValue = name;
          break;
        case EditModalEnum.notes:
          body.fieldName = "Notes";
          body.fieldValue = notes;
          break;
        case EditModalEnum.language:
          body.fieldName = "IDILanguageId";
          body.fieldValue = languageId;
          break;
        case EditModalEnum.country:
          body.fieldName = "CountryId";
          body.fieldValue = countryId;
          break;
        case EditModalEnum.facilitator:
          body.fieldName = "FacilitatorId";
          body.fieldValue = userId;
          break;
        case EditModalEnum.sourceType:
          body.fieldName = "SourceType";
          body.fieldValue = sourceTypeValue;
          break;
        case EditModalEnum.isProBono:
          body.fieldName = "IsProBono";
          body.fieldValue = String(isProBono);
          break;
        default:
          break;
      }

      dispatch(setSpinner(true));
      await saveActivityEditFieldValue(body, dispatch)
        .then(async (response) => {
          if (response) {
            //See comment above
            const formattedDate = completionDate
              ? getFormattedDateOnly(completionDate)
              : getFormattedDateOnly(new Date());
            const languageName =
              props.languages.find(
                (language) => language.name === languageValue,
              )?.displayName ?? "";
            const languageId =
              props.languages.find(
                (language) => language.name === languageValue,
              )?.id ?? 0;
            const countryName =
              props.countries.find((country) => country.value === countryValue)
                ?.displayName ?? "";

            switch (props.editModalName) {
              case EditModalEnum.status:
                props.setStatusValue(Number(status));
                if (status === String(ActivityStatus.Delivered))
                  props.refetchFullProfile();
                break;
              case EditModalEnum.completionDate:
                props.setCompletionDateValue(formattedDate);
                break;
              case EditModalEnum.name:
                props.setNameValue(name);
                break;
              case EditModalEnum.notes:
                props.setNotesValue(notes);
                break;
              case EditModalEnum.language:
                props.setLanguageName(languageName);
                props.handleLanguageSelect(languageId);
                props.closeModal();
                await recalculateProfiles(props.profileData.id, dispatch);
                break;
              case EditModalEnum.country:
                props.setCountryName(countryName);
                break;
              case EditModalEnum.facilitator:
                props.handleFacilitatorSelect(selectedFacilitatorId);
                break;
              case EditModalEnum.sourceType:
                props.setSourceType(sourceTypeValue);
                break;
              case EditModalEnum.isProBono:
                props.refetchFullProfile();
                break;
              default:
                break;
            }
            dispatch(addToast("Changes saved successfully") as AnyAction);
            props.closeModal();
          }
        })
        .finally(() => dispatch(setSpinner(false)));
    }
  };

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(props.languageText, "Edit Profile")}
        submitButtonText={getLanguageValue(props.languageText, "Save")}
        cancelButtonText={getLanguageValue(props.languageText, "Cancel")}
        handleSubmitClick={
          props.editModalName === EditModalEnum.language
            ? openShowConfirmationModal
            : handleSaveClick
        }
        handleCancelClick={props.closeModal}
      >
        {renderModalBody()}
      </ModalComponent>

      {showConfirmationModal && (
        <EditProfileConfirmationModal
          languageText={props.languageText}
          completedAndDeliveredProfileCount={completedAndDeliveredProfileCount}
          closeShowConfirmationModal={closeShowConfirmationModal}
          handleSaveClick={handleSaveClick}
        />
      )}
    </>
  );
};
