import React from "react";
import { ITranslationObject } from "../../commonUtils/languageFunctionsHelper";

interface IBooleanCellProps {
  boolValue: boolean;
  trueValueText?: string;
  falseValueText?: string;
  languageText: ITranslationObject;
}

export const BooleanCell = (props: IBooleanCellProps) => {
  return (
    <>
      {props.boolValue ? (
        <i className="bi bi-check-square-fill text-success fs-5"></i>
      ) : (
        <i className="bi bi-x-square-fill text-danger fs-5"></i>
      )}
    </>
  );
};
