import React from "react";
import clsx from "clsx";

import { AutocompleteInput } from "../../../../components/formComponents/autocompleteInput";
import { AddExistingIcon } from "./addExistingIcon";
import { FileUpload } from "../../../../components/formComponents/fileUpload";
import { Input } from "../../../../components/formComponents/input";
import { TextArea } from "../../../../components/formComponents/textArea";
import { TextEditor } from "../../../../components/textEditor/textEditor";
import { ToggleCheckbox } from "@app/components/formComponents/toggleCheckbox";
import {
  DropdownSelect,
  IDropdownList,
  IDropdownSelectedItem,
} from "@app/components/formComponents/dropdownSelect";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../commonUtils/languageFunctionsHelper";
import { CourseInputs, ICourseFocusInput, ICourses, ILogo } from "../hooks";
import { ILanguage } from "@app/containers/commonInterfaces";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";

interface IAddEditCourseProps {
  languageText: ITranslationObject;
  courseInfo: ICourses;
  focusInput: ICourseFocusInput;
  languages: ReadonlyArray<ILanguage>;
  categories: IDropdownList[];
  tagNames: string[];
  isSubmitting: boolean;
  imgErrorMessage: string;
  isCourseSaved: boolean;
  existingIconModal: boolean;
  baseUrl: string;
  uniqueIcons: ILogo[];
  isEditMode: boolean;
  editCourseId: number;
  onCloseExistingIconModal: () => void;
  onOpenExistingIconModal: () => void;
  handleFileUpload: (file: File) => void;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleTextAreaChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleTextEditorChange: (content: string, name: string) => void;
  handleBlurEvent: (e: React.FocusEvent<HTMLInputElement>) => void;
  handleTextAreaBlurEvent: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  handleDropdownSelect: (selectedItem: IDropdownSelectedItem) => void;
  handleAutoInputChange: (name: string, value: string) => void;
  handleCourseSubmit: () => void;
  onSelectIcon: (selectedIcon: string, selectedIconPath: string) => void;
  removeIcon: () => void;
  handleCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const AddEditCourse = (props: IAddEditCourseProps): JSX.Element => {
  const languageDropdownItems: Array<IDropdownList> = props.languages.map(
    (l) => {
      return {
        id: l.id,
        displayName: l.displayName,
        value: String(l.id), // matched against 'idiLanguageId'
      };
    },
  );

  return (
    <>
      <form>
        <div className="row">
          {/* Title */}
          <div
            className={clsx(
              props.editCourseId ? "col-md-5 col-12" : "col-md-7 col-12",
            )}
          >
            <Input
              name={CourseInputs.title}
              label={getLanguageValue(props.languageText, "Title")}
              placeholder={getLanguageValue(props.languageText, "Title")}
              value={props.courseInfo.title}
              errorMessage={props.focusInput.title.errorMessage}
              handleInputChange={props.handleInputChange}
              handleBlurEvent={props.handleBlurEvent}
              required
            />
          </div>

          {props.editCourseId != 0 && (
            <div className="col-md-2 col-6">
              <Input
                label={getLanguageValue(props.languageText, "Duration in sec")}
                disable={true}
                value={String(props.courseInfo.duration)}
              />
            </div>
          )}

          {/* Key Ideas */}
          <div className="col-md-2 col-6">
            <Input
              name={CourseInputs.keyIdeas}
              label={getLanguageValue(props.languageText, "Key Ideas")}
              placeholder={getLanguageValue(props.languageText, "Key Ideas")}
              value={props.courseInfo.keyIdeas}
              errorMessage={props.focusInput.keyIdeas.errorMessage}
              handleInputChange={props.handleInputChange}
              handleBlurEvent={props.handleBlurEvent}
              tooltipText={getLanguageValue(
                props.languageText,
                "Choose a number between 1-999",
              )}
              required
            />
          </div>

          {/* Language */}
          <div className="col-md-3 col-12">
            <DropdownSelect
              name={CourseInputs.idiLanguageId}
              label={getLanguageValue(props.languageText, "Language")}
              defaultLabel={getLanguageValue(
                props.languageText,
                "Select language",
              )}
              list={languageDropdownItems}
              value={String(props.courseInfo.idiLanguageId)}
              handleDropdownSelect={props.handleDropdownSelect}
              focusInput={props.focusInput.idiLanguageId}
              required
            />
          </div>
        </div>

        <div className="row">
          {/* Category */}
          <div className="col-md-4 col-12">
            <DropdownSelect
              name={CourseInputs.categoryId}
              label={getLanguageValue(props.languageText, "Category")}
              defaultLabel={getLanguageValue(
                props.languageText,
                "Select category",
              )}
              list={props.categories}
              value={props.courseInfo.categoryId}
              handleDropdownSelect={props.handleDropdownSelect}
              focusInput={props.focusInput.categoryId}
              required
            />
          </div>

          {/* Tags */}
          <div className="col-md-4 col-12">
            <AutocompleteInput
              name={CourseInputs.tags}
              label={getLanguageValue(props.languageText, "Tags")}
              placeholder={getLanguageValue(props.languageText, "Add tags")}
              value={
                props.courseInfo.tags ? props.courseInfo.tags.join(",") : ""
              }
              list={props.tagNames}
              handleAutoInputChange={props.handleAutoInputChange}
            />
          </div>
          <div className="col-md-4 col-12">
            <ToggleCheckbox
              label={getLanguageValue(
                props.languageText,
                "OnlyForDeliveredProfile",
              )}
              name={CourseInputs.onlyForDeliveredProfile}
              value={props.courseInfo.onlyForDeliveredProfile}
              handleCheckboxChange={props.handleCheckboxChange}
            />
          </div>
        </div>

        <FileUpload
          label={getLanguageValue(props.languageText, "Icon")}
          errorMessage={
            props.imgErrorMessage.length > 0 ? props.imgErrorMessage : ""
          }
          name={CourseInputs.logoFile}
          handleFileUpload={props.handleFileUpload}
          imagePath={
            (props.courseInfo.logo &&
              !props.courseInfo.logo.match(/\.(png|jpe?g|svg)$/i) &&
              props.courseInfo.logo) ||
            props.courseInfo.logoFullUrl
          }
          isEditMode={props.isEditMode}
          removeIconBtnText={getLanguageValue(
            props.languageText,
            "Remove icon",
          )}
          selectIconBtnText={getLanguageValue(
            props.languageText,
            "Select existing icon",
          )}
          removeIcon={props.removeIcon}
          onOpenExistingIconModal={props.onOpenExistingIconModal}
          required
        />

        {/* Description */}
        <TextArea
          name={CourseInputs.description}
          label={getLanguageValue(props.languageText, "Description")}
          placeholder={getLanguageValue(props.languageText, "Description")}
          value={props.courseInfo.description || ""}
          focusInput={props.focusInput.description}
          onChange={props.handleTextAreaChange}
          handleBlurEvent={props.handleTextAreaBlurEvent}
          checkMarkNotRequired={true}
          required
        />

        <div className="grey-bg-darker mx-n7 h-1px" />

        <div className="fs-2 fw-bold mt-7 mb-5">
          {getLanguageValue(props.languageText, "Summary")}
        </div>

        {/* Key Message */}
        <TextEditor
          name={CourseInputs.keyMessage}
          label={`${getLanguageValue(props.languageText, "The key message in this course")}:`}
          placeholder={getLanguageValue(props.languageText, "Key Message")}
          handleTextEditor={props.handleTextEditorChange}
          focusInput={props.focusInput.keyMessage}
          value={props.courseInfo.keyMessage || ""}
          required
        />

        {/* Actionable Advice */}
        <TextEditor
          name={CourseInputs.actionableAdvice}
          label={`${getLanguageValue(props.languageText, "Actionable Advice")}:`}
          placeholder={getLanguageValue(
            props.languageText,
            "Actionable Advice",
          )}
          handleTextEditor={props.handleTextEditorChange}
          focusInput={props.focusInput.actionableAdvice}
          value={props.courseInfo.actionableAdvice || ""}
          required
        />

        {/* Suggested Further Reading */}
        <TextEditor
          name={CourseInputs.suggestedFurtherReading}
          label={`${getLanguageValue(props.languageText, "Suggested Further Reading")}:`}
          placeholder={getLanguageValue(
            props.languageText,
            "Suggested Further Reading",
          )}
          handleTextEditor={props.handleTextEditorChange}
          focusInput={props.focusInput.suggestedFurtherReading}
          value={props.courseInfo.suggestedFurtherReading || ""}
          required
        />

        {/* References */}
        <TextEditor
          name={CourseInputs.references}
          label={`${getLanguageValue(props.languageText, "References")}:`}
          placeholder={getLanguageValue(props.languageText, "References")}
          handleTextEditor={props.handleTextEditorChange}
          focusInput={props.focusInput.references}
          value={props.courseInfo.references || ""}
          required
        />
      </form>

      <button
        type="submit"
        className="btn btn-success min-w-125px"
        data-kt-users-modal-action="submit"
        onClick={props.handleCourseSubmit}
        disabled={props.isCourseSaved || props.isSubmitting}
      >
        <span className="indicator-label">
          {getLanguageValue(props.languageText, "Save")}
        </span>
      </button>

      {props.existingIconModal && (
        <AddExistingIcon
          languageText={props.languageText}
          uniqueIcons={props.uniqueIcons}
          onCloseExistingIconModal={props.onCloseExistingIconModal}
          onSelectIcon={props.onSelectIcon}
        />
      )}
    </>
  );
};
