import { Dispatch } from "redux";

import { rawAxiosApi, apiEndPoints } from "../../../../api";
import { setNotification } from "../../../actions";
import { IProfileInvoiceDetails } from "../../../../components/invoice/invoiceInterface";
import { ActivityId } from "@app/containers/reducer";
import { ApiResponse, ApiResult } from "@app/types";

export const getActivityInvoiceAddresses = async (
  activityId: ActivityId,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.get(
      apiEndPoints.getActivityInvoiceAddresses,
      {
        params: { activityId },
      },
    );
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getProfileInvoiceDetails = async (
  activityId: ActivityId,
  dispatch: Dispatch,
): Promise<IProfileInvoiceDetails> => {
  try {
    const response = await rawAxiosApi.get(
      apiEndPoints.getProfileInvoiceDetails,
      {
        params: { activityId },
      },
    );
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getInvoiceArticlesByProfileOrClientSubscriptionId = async (
  id: number, // ProfileId or ClientSubscriptionId (wtf? why can it be either?)
  isActivity: boolean,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.get(
      apiEndPoints.getInvoiceArticlesByProfileOrClientSubscriptionId,
      {
        params: { id, isActivity },
      },
    );
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

type FortnoxExistingInvoice = {
  customerName: string;
  customerNumber: string;
  documentNumber: number | null;
  invoiceDate: string | null;
};

export const getFortnoxInvoicesByCustomerNumber = async (
  customerNumber: string,
  dispatch: Dispatch,
): Promise<Array<FortnoxExistingInvoice>> => {
  try {
    const response = await rawAxiosApi.get<
      ApiResponse<Array<FortnoxExistingInvoice>>
    >(apiEndPoints.getFortnoxInvoicesByCustomerNumber, {
      params: { customerNumber },
    });
    if (!response.data.success) {
      return Promise.reject(response.data);
    }
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const createOrUpdateProfileInvoice = async (
  body: IProfileInvoiceDetails,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.post(
      apiEndPoints.createOrUpdateProfileInvoice,
      JSON.stringify(body),
    );
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const sendActivityInvoiceToFortnox = async (
  activityId: ActivityId,
  idiClientId: number,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.post(
      apiEndPoints.sendProfileInvoiceToFortnox,
      {},
      {
        params: { activityId, idiClientId },
      },
    );
    return response.data.success;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const updateFortnoxInvoiceStatus = async (
  idiInvoiceId: number,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.put(
      apiEndPoints.updateFortnoxInvoiceStatus,
      {},
      {
        params: { idiInvoiceId },
      },
    );
    return response.data.success;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
