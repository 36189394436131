import React from "react";
import { ModalComponent } from "./modalComponent";

interface ICustomModalProps {
  headerText?: string;
  bodyText: string | JSX.Element;
  cancelButtonText?: string;
  submitButtonText?: string;
  isSubmitDangerButton?: boolean;
  cancelButtonDisabled?: boolean;
  submitButtonDisabled?: boolean;
  handleSubmitClick?: () => void;
  handleCancelClick: () => void;
  handleCloseModal?: () => void;
}

export const CustomModal = (props: ICustomModalProps) => {
  const closeIconClick = (): void => {
    if (props.handleCloseModal) {
      props.handleCloseModal();
    } else {
      props.handleCancelClick();
    }
  };

  return (
    <>
      <ModalComponent
        headerText={props.headerText || ""}
        submitButtonText={props.submitButtonText}
        submitButtonDisabled={props.submitButtonDisabled}
        cancelButtonText={props.cancelButtonText}
        cancelButtonDisabled={props.cancelButtonDisabled}
        isSubmitDangerButton={props.isSubmitDangerButton}
        handleSubmitClick={props.handleSubmitClick}
        handleCancelClick={closeIconClick}
      >
        {props.bodyText}
      </ModalComponent>
    </>
  );
};
