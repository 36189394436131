import React from "react";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../../../../commonUtils/languageFunctionsHelper";
import { GroupDownload } from "./groupDownload";
import { IEditActivity } from "@app/containers/activityList/editActivity/interface";
import { ModalComponent } from "@app/components/modals/modalComponent";
import { ActivityId, ProfileId } from "@app/containers/reducer";

interface IGroupDownloadModalProps {
  id: ActivityId;
  languageText: ITranslationObject;
  name: string;
  profileIds: Array<ProfileId>;
  handleCloseClick: () => void;
  profileData: IEditActivity;
}

export const GroupDownloadModal = (
  props: IGroupDownloadModalProps,
): JSX.Element => {
  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(props.languageText, "Group Profile")}
        cancelButtonText={getLanguageValue(props.languageText, "Close")}
        handleCancelClick={props.handleCloseClick}
        width="xl"
      >
        <GroupDownload
          id={props.id}
          languageText={props.languageText}
          name={props.name}
          profileIds={props.profileIds}
          profileData={props.profileData}
        />
      </ModalComponent>
    </>
  );
};
