import React from "react";

import { IAddEditDocsModalProps, useAddEditDocs } from "./hooks";
import { DocumentInputs } from "../types";

import { Input } from "../../../../components/formComponents/input";
import { Switchbox } from "../../../../components/formComponents/switchbox";
import { TextArea } from "../../../../components/formComponents/textArea";
import { ReactDatePicker } from "../../../../components/datePicker/ReactDatePicker";
import { FileUpload } from "../../../../components/formComponents/fileUpload";
import {
  DropdownSelect,
  IDropdownList,
} from "@app/components/formComponents/dropdownSelect";
import { getLanguageValue } from "../../../../commonUtils/languageFunctionsHelper";
import { ModalComponent } from "../../../../components/modals/modalComponent";

export const AddEditDocumentModal = (props: IAddEditDocsModalProps) => {
  const {
    languageText,
    focusInput,
    isEditClicked,
    languages,
    docData,
    documentCategory,
    handleDocSubmit,
    handleDateSelect,
    handleDropdownSelect,
    handleBlurEvent,
    handleInputChange,
    handleTextAreaChange,
    handleCheckboxChange,
    handleFileUpload,
    closeModal,
    removeIcon,
  } = useAddEditDocs(props);

  const languageDropdownItems: Array<IDropdownList> = languages.map((l) => {
    return {
      id: l.id,
      displayName: l.displayName,
      value: String(l.id), // matched against 'iDILanguageId'
    };
  });

  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(
          languageText,
          isEditClicked ? "Edit Documents" : "Add Documents",
        )}
        cancelButtonText={getLanguageValue(languageText, "Cancel")}
        submitButtonText={getLanguageValue(languageText, "Save")}
        handleSubmitClick={handleDocSubmit}
        handleCancelClick={closeModal}
        width="lg"
      >
        <form className="row">
          <div className="col-6">
            <Input
              name={DocumentInputs.title}
              label={getLanguageValue(languageText, "Name")}
              errorMessage={focusInput.title.errorMessage}
              handleBlurEvent={handleBlurEvent}
              handleInputChange={handleInputChange}
              value={docData.title}
              placeholder={getLanguageValue(
                languageText,
                "Latest Docs from IDI",
              )}
              required
            />
          </div>
          <div className="col-3">
            <ReactDatePicker
              name={DocumentInputs.publishedDate}
              label={getLanguageValue(languageText, "Date")}
              placeholder={getLanguageValue(languageText, "Select a date")}
              date={docData.publishedDate ?? new Date()}
              focusInput={focusInput.publishedDate}
              minDate={new Date()}
              handleDateSelect={handleDateSelect}
            />
          </div>
          <div className="col-3 mt-8 float-right">
            <Switchbox
              name={DocumentInputs.published}
              labelFirstText={getLanguageValue(languageText, "Published")}
              value={docData.published}
              handleSwitchboxChange={handleCheckboxChange}
            />
          </div>
          <div className="row">
            <div className="col-4">
              <FileUpload
                label={getLanguageValue(languageText, "Choose a file")}
                name={DocumentInputs.file}
                handleFileUpload={handleFileUpload}
                fileName={docData.originalFileName}
                imagePath={docData.file}
                isDocType={true}
                errorMessage={focusInput.file.errorMessage}
                isEditMode={isEditClicked}
                removeIconBtnText={getLanguageValue(
                  languageText,
                  "Remove File",
                )}
                removeIcon={removeIcon}
                required
              />
            </div>
            <div className="col-4">
              <DropdownSelect
                name={DocumentInputs.documentCategoryId}
                label={getLanguageValue(languageText, "Document Category")}
                defaultLabel={getLanguageValue(
                  languageText,
                  "Search Document Category",
                )}
                list={documentCategory}
                value={docData.documentCategoryId.toString()}
                focusInput={focusInput.documentCategoryId}
                handleDropdownSelect={handleDropdownSelect}
                searchOption
                required
              />
            </div>
            <div className="col-4">
              <DropdownSelect
                dropdownHeight="mh-450px"
                name={DocumentInputs.iDILanguageId}
                label={getLanguageValue(languageText, "Language")}
                defaultLabel={getLanguageValue(languageText, "Select Language")}
                list={languageDropdownItems}
                value={docData.iDILanguageId.toString()}
                focusInput={focusInput.iDILanguageId}
                handleDropdownSelect={handleDropdownSelect}
                searchOption
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <TextArea
                name={DocumentInputs.excerpt}
                focusInput={focusInput.excerpt}
                label={getLanguageValue(languageText, "Description")}
                placeholder={getLanguageValue(languageText, "Description")}
                onChange={handleTextAreaChange}
                value={docData.excerpt}
                areaClass="form-control form-control-solid min-h-100px"
                checkMarkNotRequired={true}
                required
              />
            </div>
          </div>
        </form>
      </ModalComponent>
    </>
  );
};
