import React, { SyntheticEvent, useEffect, useState } from "react";
import { IClient, ICountry } from "../commonInterfaces";
import { useDispatch, useSelector } from "react-redux";
import { useApiEndpoints } from "@app/api/end-points";
import { RootState } from "@app/store/configureStore";
import { getLanguageValue } from "@app/commonUtils/languageFunctionsHelper";
import { Checkbox } from "@app/components/formComponents/checkbox";
import {
  DropdownSelect,
  IDropdownList,
  IDropdownSelectedItem,
} from "@app/components/formComponents/dropdownSelect";
import { IFocusError, Input } from "@app/components/formComponents/input";
import { TextArea } from "@app/components/formComponents/textArea";
import { ToggleCardCheckbox } from "@app/components/formComponents/toggleCardCheckbox";
import { AccountInputs } from "../clientList/addEditModal/clientEnums";
import { PhoneNumberInput } from "../employeeList/addEmployeeModal/phoneNumberInput";
import { emptyClient } from "@app/types-empty-states";
import {
  ICreateUpdateClient,
  IFocusInputClientDetails,
} from "../clientList/addEditModal/addEditAccountModalHooks";
import { E164Number } from "libphonenumber-js/types";
import { ValidationHelper } from "../validationHelper";
import {
  addToast,
  setNotification,
  setSpinner,
  setOpenAccountList,
  setOpenRightMenu,
} from "../actions";
import { getAllCountries } from "../clientList/addEditModal/action";
import { AnyAction } from "redux";
import {
  isLoggedInRoleAdmin,
  isLoggedInRoleFacilitator,
} from "@app/commonUtils/roleHelper";
import { setCurrentClient } from "../auth/signUp/actions";
import { IGetAllClientsByUserBody } from "./account/hooks";

type ClientDetailsProps = {
  clientId: number | null;
  afterSave?: () => void;
};

export const ClientDetails: React.FC<ClientDetailsProps> = (
  props: ClientDetailsProps,
) => {
  const dispatch = useDispatch();
  const api = useApiEndpoints(dispatch);
  const [client, setClient] = useState<IClient>(emptyClient);
  const [accounts, setAccounts] = React.useState<ReadonlyArray<IClient>>([]);
  const [countries, setCountries] = useState<IDropdownList[]>([]);
  const loggedInUserRole = useSelector(
    (state: RootState) => state.loginReducer.loggedInUserRole,
  );
  const userId = useSelector((state: RootState) => state.loginReducer.userId);

  const initialFocusInputState: IFocusError = {
    touched: false,
    errorMessage: "",
  };
  const [focusInput, setFocusInput] = useState<IFocusInputClientDetails>({
    name: initialFocusInputState,
    email: initialFocusInputState,
    telephone: initialFocusInputState,
    address: initialFocusInputState,
    city: initialFocusInputState,
    zip: initialFocusInputState,
    countryId: initialFocusInputState,
    invoiceCompanyName: initialFocusInputState,
    invoiceAddress: initialFocusInputState,
    invoiceCity: initialFocusInputState,
    invoiceZip: initialFocusInputState,
    invoiceCountryId: initialFocusInputState,
  });

  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  /** useEffects */
  useEffect(() => {
    getAllCountries(dispatch).then((response: ICountry[]) => {
      if (response?.length > 0) {
        const countries: IDropdownList[] = response.map((item) => ({
          id: item.id,
          displayName: item.name,
          value: String(item.id),
        }));
        setCountries(countries);
      }
    });

    //This is only for showing the switch account button
    const body: IGetAllClientsByUserBody = {
      userId: userId,
      roleName: loggedInUserRole || "",
      filter: "",
      sorting: "",
      maxResultCount: 1_000,
      skipCount: 0,
    };
    api
      .getAllClientsByUser(body)
      .then((response) => setAccounts(response.items));
  }, []);

  useEffect(() => {
    if (!props.clientId) {
      return;
    }
    api
      .getClient(props.clientId)
      .then((response) => {
        setClient(response);
      })
      .catch((error) => dispatch(setNotification(error)));
  }, [props.clientId]);

  /** /useEffects */

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const name = e.target.name as keyof IFocusInputClientDetails;
    const value = e.target.value;

    setClient({
      ...client,
      [name]: value,
    });

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { name, checked } = e.target;

    setClient({
      ...client,
      [name]: checked,
    });
  };

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    const name = e.target.name as keyof IFocusInputClientDetails;
    const value = e.target.value;
    handleFormErrors(name, value);
  };

  const handleCountrySelect = (selectedItem: IDropdownSelectedItem): void => {
    const name = selectedItem.name as keyof IFocusInputClientDetails;
    const value = selectedItem.value;

    setClient({
      ...client,
      [name]: value,
    });

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const name = e.target.name as keyof IFocusInputClientDetails;
    const value = e.target.value;

    setClient({
      ...client,
      [name]: value,
    });

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  };

  const handleTextAreaBlurEvent = (
    e: React.FocusEvent<HTMLTextAreaElement>,
  ): void => {
    const name = e.target.name as keyof IFocusInputClientDetails;
    const value = e.target.value;
    handleFormErrors(name, value);
  };

  const handlePhoneInputChange = (value: E164Number): void => {
    setClient({
      ...client,
      telephone: value,
    });

    if (focusInput.telephone?.touched) {
      handleFormErrors(AccountInputs.telephone, value);
    }
  };

  const handleDifferentInvoiceAddress = (): void => {
    if (client.differentInvoiceAddress) {
      setClient({
        ...client,
        differentInvoiceAddress: false,
      });
    } else {
      setClient({
        ...client,
        differentInvoiceAddress: true,
        invoiceAddress: "",
        invoiceZip: "",
        invoiceCity: "",
        invoiceCountryId: 0,
      });
    }
  };

  const handleFormErrors = (name: string, value: unknown): void => {
    let errorMessage: string = "";
    if (!value) {
      const errorMessages: { [key: string]: string } = {
        [AccountInputs.name]: "Company name is required",
        [AccountInputs.countryId]: "Country is required",
        [AccountInputs.invoiceCompanyName]: "Company name is required",
        [AccountInputs.invoiceCountryId]: "Country is required",
      };
      errorMessage =
        errorMessages[name] &&
        getLanguageValue(languageText, errorMessages[name]);
    }
    if (name === AccountInputs.email && typeof value === "string" && value) {
      const isValid = ValidationHelper.isEmailValid(value);
      if (!isValid)
        errorMessage = getLanguageValue(languageText, "Invalid email");
    }
    if (
      name === AccountInputs.telephone &&
      typeof value === "string" &&
      value
    ) {
      if (value.length >= 1 && value.length <= 4) {
        errorMessage = getLanguageValue(languageText, "Invalid phonenumber");
      }
    }

    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }));
  };

  const handleClientValidationOnSubmit = (): boolean => {
    if (client.differentInvoiceAddress) {
      if (
        !client.name ||
        focusInput.name.errorMessage ||
        !client.countryId ||
        focusInput.countryId.errorMessage ||
        !client.invoiceCompanyName ||
        focusInput.invoiceCompanyName.errorMessage ||
        !client.invoiceCountryId ||
        focusInput.invoiceCountryId.errorMessage
      ) {
        for (const item of Object.values(AccountInputs).slice(0, 4)) {
          handleFormErrors(item, client[item]);
        }
        return false;
      }
    } else {
      if (
        !client.name ||
        focusInput.name.errorMessage ||
        !client.countryId ||
        focusInput.countryId.errorMessage
      ) {
        for (const item of Object.values(AccountInputs).slice(0, 2)) {
          handleFormErrors(item, client[item]);
        }
        return false;
      }
    }

    return true;
  };

  function handleSave(event: SyntheticEvent): void {
    if (!handleClientValidationOnSubmit()) return;

    dispatch(setSpinner(true));
    const body: ICreateUpdateClient = {
      id: props.clientId ?? 0,
      name: client.name,
      businessIdentification: client.businessIdentification,
      invoiceReference: client.invoiceReference,
      email: client.email,
      telephone: client.telephone,
      address: client.address,
      city: client.city,
      zip: client.zip,
      countryId: client.countryId,
      euvatid: client.euvatid,
      differentInvoiceAddress: client.differentInvoiceAddress,
      invoiceCompanyName: client.invoiceCompanyName,
      invoiceAddress: client.invoiceAddress,
      invoiceCity: client.invoiceCity,
      invoiceZip: client.invoiceZip,
      invoiceCountryId: client.invoiceCountryId,
      invoiceEUVATID: client.invoiceEUVATID,
      enableParticipantCreateProfile: client.enableParticipantCreateProfile,
      sourceType: client.sourceType,
      sourceAddress: client.sourceAddress,
    };
    api
      .updateClient(body)
      .then((response) => {
        if (response?.success) {
          //Update so that any changes to name is shown in nav menu
          //FIXME: this should probably be a whole updated client record
          dispatch(
            setCurrentClient({
              currentClientId: body.id ?? 0,
              currentClientName: body.name,
            }),
          );
          dispatch(addToast("Changes saved successfully") as AnyAction);
        }
        props.afterSave?.();
      })
      .catch((error) => {
        dispatch(setNotification(error));
        throw error;
      })
      .finally(() => {
        dispatch(setSpinner(false));
      });
  }

  return (
    <>
      <div>
        {accounts.length > 1 && (
          <div className="row">
            <div className="col-12">
              <button
                className="btn btn-light"
                onClick={() => {
                  dispatch(setOpenAccountList(true));
                }}
              >
                <i className="bi bi-shuffle me-2" />
                {getLanguageValue(languageText, "Switch account")}
              </button>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12 col-md-3">
            <Input
              name={AccountInputs.name}
              label={getLanguageValue(languageText, "Company Name")}
              placeholder={getLanguageValue(languageText, "Company Name")}
              value={client.name ?? ""}
              errorMessage={focusInput.name.errorMessage}
              handleBlurEvent={handleBlurEvent}
              handleInputChange={handleInputChange}
              required
            />
          </div>
          <div className="col-12 col-md-3">
            <Input
              name={AccountInputs.businessIdentification}
              label={`${getLanguageValue(languageText, "Business identification")} #`}
              placeholder={getLanguageValue(
                languageText,
                "Business identification",
              )}
              value={client.businessIdentification ?? ""}
              handleInputChange={handleInputChange}
            />
          </div>
          <div className="col-12 col-sm-6">
            <Input
              name={AccountInputs.invoiceReference}
              label={getLanguageValue(languageText, "Invoice Reference")}
              placeholder={getLanguageValue(languageText, "Invoice Reference")}
              value={client.invoiceReference ?? ""}
              handleInputChange={handleInputChange}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-9">
            <Input
              name={AccountInputs.email}
              label={getLanguageValue(languageText, "Email")}
              placeholder={getLanguageValue(languageText, "Email")}
              value={client.email ?? ""}
              errorMessage={focusInput.email.errorMessage}
              handleBlurEvent={handleBlurEvent}
              handleInputChange={handleInputChange}
            />
          </div>
          <div className="col-12 col-md-3">
            <PhoneNumberInput
              languageText={languageText}
              name={AccountInputs.telephone}
              label={getLanguageValue(languageText, "Phone")}
              placeholder="+123 45 67 89"
              value={(client.telephone as E164Number) || ""}
              phoneNumberErrorMsg={focusInput.telephone.errorMessage}
              onChange={handlePhoneInputChange}
              onBlur={handleBlurEvent}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <TextArea
              name={AccountInputs.address}
              label={getLanguageValue(languageText, "Address")}
              placeholder={getLanguageValue(languageText, "Address")}
              value={client.address ?? ""}
              focusInput={focusInput.address}
              onChange={handleTextAreaChange}
              handleBlurEvent={handleTextAreaBlurEvent}
              checkMarkNotRequired={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-3">
            <Input
              name={AccountInputs.city}
              label={getLanguageValue(languageText, "City")}
              placeholder={getLanguageValue(languageText, "City")}
              value={client.city ?? ""}
              errorMessage={focusInput.city.errorMessage}
              handleBlurEvent={handleBlurEvent}
              handleInputChange={handleInputChange}
            />
          </div>
          <div className="col-12 col-md-3">
            <Input
              name={AccountInputs.zip}
              label={getLanguageValue(languageText, "Zip Code")}
              placeholder={getLanguageValue(languageText, "Zip Code")}
              type="number"
              value={client.zip ?? ""}
              errorMessage={focusInput.zip.errorMessage}
              handleBlurEvent={handleBlurEvent}
              handleInputChange={handleInputChange}
            />
          </div>
          <div className="col-12 col-md-3">
            <DropdownSelect
              name={AccountInputs.countryId}
              label={getLanguageValue(languageText, "Country")}
              defaultLabel={getLanguageValue(languageText, "Select Country")}
              list={countries}
              value={String(client.countryId)}
              focusInput={focusInput.countryId}
              handleDropdownSelect={handleCountrySelect}
              searchOption
              required
            />
          </div>
          <div className="col-12 col-md-3">
            <Input
              name={AccountInputs.euvatid}
              label={getLanguageValue(languageText, "EU VAT ID (optional)")}
              placeholder={getLanguageValue(languageText, "EU VAT ID")}
              value={client.euvatid ?? ""}
              handleInputChange={handleInputChange}
            />
          </div>
        </div>

        <ToggleCardCheckbox
          header={getLanguageValue(languageText, "Different invoice address")}
          description={getLanguageValue(
            languageText,
            "Add a separate invoice address",
          )}
          containerClass="col-12 col-md-5 mb-3"
          checked={client.differentInvoiceAddress}
          handleClick={handleDifferentInvoiceAddress}
        />

        {client.differentInvoiceAddress && (
          <>
            <div className="row">
              <div className="col-12 col-md-12">
                <Input
                  name={AccountInputs.invoiceCompanyName}
                  label={getLanguageValue(languageText, "Company Name")}
                  placeholder={getLanguageValue(languageText, "Company Name")}
                  value={client.invoiceCompanyName ?? ""}
                  errorMessage={focusInput.invoiceCompanyName.errorMessage}
                  handleBlurEvent={handleBlurEvent}
                  handleInputChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12">
                <TextArea
                  name={AccountInputs.invoiceAddress}
                  label={getLanguageValue(languageText, "Address")}
                  placeholder={getLanguageValue(languageText, "Address")}
                  value={client.invoiceAddress ?? ""}
                  focusInput={focusInput.invoiceAddress}
                  handleBlurEvent={handleTextAreaBlurEvent}
                  onChange={handleTextAreaChange}
                  checkMarkNotRequired={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-3">
                <Input
                  name={AccountInputs.invoiceCity}
                  label={getLanguageValue(languageText, "City")}
                  placeholder={getLanguageValue(languageText, "City")}
                  value={client.invoiceCity ?? ""}
                  errorMessage={focusInput.invoiceCity.errorMessage}
                  handleBlurEvent={handleBlurEvent}
                  handleInputChange={handleInputChange}
                />
              </div>
              <div className="col-12 col-md-3">
                <Input
                  name={AccountInputs.invoiceZip}
                  label={getLanguageValue(languageText, "Zip Code")}
                  placeholder={getLanguageValue(languageText, "Zip Code")}
                  type="number"
                  value={client.invoiceZip ?? ""}
                  errorMessage={focusInput.invoiceZip.errorMessage}
                  handleBlurEvent={handleBlurEvent}
                  handleInputChange={handleInputChange}
                />
              </div>
              <div className="col-12 col-md-3">
                <DropdownSelect
                  name={AccountInputs.invoiceCountryId}
                  label={getLanguageValue(languageText, "Country")}
                  defaultLabel={getLanguageValue(
                    languageText,
                    "Select Country",
                  )}
                  list={countries}
                  value={String(client.invoiceCountryId)}
                  focusInput={focusInput.invoiceCountryId}
                  handleDropdownSelect={handleCountrySelect}
                  searchOption
                  required
                />
              </div>
              <div className="col-12 col-md-3">
                <Input
                  name={AccountInputs.invoiceEUVATID}
                  label={getLanguageValue(languageText, "EU VAT ID (optional)")}
                  placeholder={getLanguageValue(languageText, "EU VAT ID")}
                  value={client.invoiceEUVATID ?? ""}
                  handleInputChange={handleInputChange}
                />
              </div>
            </div>
          </>
        )}

        {isLoggedInRoleAdmin(loggedInUserRole) ||
          (isLoggedInRoleFacilitator(loggedInUserRole) && (
            <div className="mb-3">
              <Checkbox
                name={AccountInputs.enableParticipantCreateProfile}
                label={getLanguageValue(
                  languageText,
                  "Can participants create profiles",
                )}
                value={client.enableParticipantCreateProfile ?? ""}
                handleCheckboxChange={handleCheckboxChange}
              />
            </div>
          ))}
        {isLoggedInRoleAdmin(loggedInUserRole) && (
          <>
            <Input
              name={AccountInputs.sourceType}
              label={getLanguageValue(languageText, "Source type")}
              placeholder={getLanguageValue(languageText, "Source type")}
              value={client.sourceType ?? ""}
              handleInputChange={handleInputChange}
            />

            <Input
              name={AccountInputs.sourceAddress}
              label={getLanguageValue(languageText, "Source address")}
              placeholder={getLanguageValue(languageText, "Source address")}
              value={client.sourceAddress ?? ""}
              handleInputChange={handleInputChange}
            />
          </>
        )}
        <div className="d-flex justify-content-end">
          <button className="btn btn-primary" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </>
  );
};
