import { getLanguageValue } from "@app/commonUtils/languageFunctionsHelper";
import { RootState } from "@app/store/configureStore";
import React from "react";
import { useSelector } from "react-redux";

interface IBarChartProps {
  label: string;
  bar1Height: string; // height as a string with percentage sign
  bar1NarrowHeight: string;
  bar1Label: string;
  bar2Height: string;
  bar2NarrowHeight: string;
  bar2Label: string;
}

export const BarChart = (props: IBarChartProps): JSX.Element => {
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );
  return (
    <div className="d-flex flex-column justify-content-end idi-profile-dimensions-height rounded border p-2 text-white">
      <div className="d-flex align-items-end position-relative w-100 h-100 mb-2 gap-2">
        <div
          className="bg-danger p-2 position-absolute bottom-0 start-0"
          style={{
            height: `${props.bar1NarrowHeight}`,
          }}
        ></div>
        <div
          className="w-50 bg-success d-flex justify-content-center align-items-end pb-1"
          style={{
            height: `${props.bar1Height}`,
          }}
        >
          <span className="z-1">
            {getLanguageValue(languageText, props.bar1Label)}
          </span>
        </div>

        <div
          className="bg-danger p-2 position-absolute bottom-0 end-0"
          style={{
            height: `${props.bar2NarrowHeight}`,
          }}
        ></div>
        <div
          className="w-50 bg-success d-flex justify-content-center align-items-end pb-1"
          style={{
            height: `${props.bar2Height}`,
          }}
        >
          <span className="z-1">
            {getLanguageValue(languageText, props.bar2Label)}
          </span>
        </div>
      </div>
      <div className="w-100 text-dark fw-bold">
        {getLanguageValue(languageText, props.label)}
      </div>
    </div>
  );
};
