import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AnyAction } from "redux";

import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import { IFocusError } from "../../../components/formComponents/input";
import { addToast, clearNotifications, setSpinner } from "../../actions";
import { RootState } from "@app/store/configureStore";
import {
  getEmailCode,
  sendParticipantPasswordEmail,
  setParticipantProfileDetails,
} from "../actions";
import { getUserDetails, loginUserAction } from "../../auth/signUp/actions";
import { sliderDataSteps } from "../../../components/slider/sliderData";
import { getParticipantProfileInfo } from "@app/commonUtils/participantProfileHelper";
import { useParticipantProfileAuth } from "../useParticipantProfileAuth";
import { useAuthFlow } from "@app/auth";
import { UserId } from "@app/containers/reducer";
import { useApiEndpoints } from "@app/api/end-points";
import { IProfileDetails } from "@app/types";

export interface ISliderDataProps {
  id: number;
  header: string;
  text: string;
  img: string;
}

interface IFocusInput {
  password: IFocusError;
}

interface IPayload {
  email: string;
  password: string;
}

export enum PasswordInput {
  password = "password",
}

export const useSignInPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const api = useApiEndpoints(dispatch);
  const { uniqueCode } = useParticipantProfileAuth();

  const participantProfileDetails = useSelector(
    (state: RootState) => state.participantReducer.participantProfileDetails,
  );
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );
  const userLanguage = useSelector(
    (state: RootState) => state.loginReducer.userLanguage,
  );
  const sliderData = useMemo(
    () => sliderDataSteps(languageText),
    [languageText],
  );

  /** FIXME: rename this incomprehensible garbage. */
  const [isVerificationCode, setIsVerificationCode] = useState<boolean>(false);

  const [provider, setProvider] = useState<string>("");
  const [profile, setProfile] = useState<any>();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [password, setPassword] = useState<string>("");
  const [resetPasswordModal, setResetPasswordModal] = useState<boolean>(false);

  /** FIXME: rename this incomprehensible garbage. */
  const [isActivationCode, setIsActivationCode] = useState<boolean>(false);

  const [userId, setUserId] = useState<UserId>(0 as UserId);
  const [formError, setFormError] = useState<IFocusInput>({
    password: {
      touched: false,
      errorMessage: "",
    },
  });
  const authFlow = useAuthFlow(dispatch, navigate);

  useEffect(() => {
    if (uniqueCode && !participantProfileDetails.emailAddress) {
      dispatch(setSpinner(true));
      api
        .checkValidParticipantOrRespondentLink(uniqueCode)
        .then((response) => {
          if (response) {
            const { isPasswordSet, emailAddress } = response;

            const updatedParticipantProfileDetails: IProfileDetails = {
              ...participantProfileDetails,
              isPasswordSet,
              emailAddress,
            };
            dispatch(
              setParticipantProfileDetails(updatedParticipantProfileDetails),
            );
          }
        })
        .finally(() => dispatch(setSpinner(false)));
    }
  }, [uniqueCode]);

  const handleFormErrors = (name: string, value: string): void => {
    let errorMessage: string = "";

    switch (name) {
      case PasswordInput.password:
        if (!value) {
          errorMessage = getLanguageValue(languageText, "Password is required");
        }
        break;
      default:
        break;
    }

    setFormError((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }));
  };

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    const name = e.target.name;
    const value = e.target.value;
    handleFormErrors(name, value);
  };

  const handleValidationOnSubmit = (): boolean => {
    if (!password || formError.password.errorMessage) {
      handleFormErrors(PasswordInput.password, password);
      return false;
    }
    return true;
  };

  const handleNavigation = async (): Promise<void> => {
    dispatch(setSpinner(true));

    // this call to 'getUserDetails' looks like a no-op but it has severe side
    // effects throughout the entire fucking app, so we kind of need to call
    // it here. should probably be moved to the root <App /> or something.
    //   -johan, 2024-09-28
    // Not sure this is valid anymore since there is a global call to getUserDetails
    // but this needs to be investigated further. Joakim, 2025-01-07
    await getUserDetails(dispatch, userLanguage.userLanguageCode);
    const profileDetails = await getParticipantProfileInfo(
      uniqueCode,
      dispatch,
      false,
    );
    const url = authFlow.guessURLToCurrentParticipantStep(
      uniqueCode,
      profileDetails,
    );
    navigate(url);
  };

  const handleLogin = async (e: {
    preventDefault: () => void;
  }): Promise<void> => {
    e.preventDefault();
    dispatch(clearNotifications(""));
    if (!handleValidationOnSubmit()) return;
    if (!participantProfileDetails.emailAddress) return;

    dispatch(setSpinner(true));
    const payload: IPayload = {
      email: participantProfileDetails.emailAddress,
      password: password,
    };
    await loginUserAction(dispatch, payload, true)
      .then((response) => {
        dispatch(getEmailCode(password));

        const accessToken = response && response.accessToken?.token;
        if (!accessToken) {
          if (!response.emailConfirmed) {
            setIsActivationCode(true);
            setUserId(response.userId);
            setPassword(password);
          }
        } else {
          handleNavigation();
        }
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  const handlePasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const name = e.target.name;
    const value = e.target.value;
    setPassword(value);
    handleFormErrors(name, value);
  };

  // Reset Password fn's
  const handleResetPassword = (): void => setResetPasswordModal(true);

  const cancelResetPassword = (): void => setResetPasswordModal(false);

  const handlePasswordSend = (): void => {
    dispatch(setSpinner(true));

    sendParticipantPasswordEmail(
      uniqueCode,
      userLanguage.userLanguageCode,
      dispatch,
    )
      .then((response) => {
        if (response?.success) {
          dispatch(addToast("New password sent to your email") as AnyAction);
        }
      })
      .finally(() => {
        dispatch(setSpinner(false));
        setResetPasswordModal(false);
      });
  };

  return {
    languageText,
    password,
    formError,
    currentIndex,
    sliderData,
    profile,
    provider,
    participantProfileDetails,
    isVerificationCode,
    resetPasswordModal,
    isActivationCode,
    uniqueCode,
    dispatch,
    setProfile,
    setProvider,
    setIsVerificationCode,
    setIsActivationCode,
    setCurrentIndex,
    handleBlurEvent,
    handlePasswordChange,
    handleLogin,
    handleResetPassword,
    cancelResetPassword,
    handlePasswordSend,
    userId,
    handleNavigation,
  };
};
