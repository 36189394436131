import { Dispatch } from "redux";
import { rawAxiosApi, apiEndPoints } from "../../api";
import { setNotification } from "../actions";
import { SET_PRESENTATION_SLIDES, SET_PARTICIPANTS_LIST } from "./constants";
import {
  IDeliverProfileParams,
  IParticipantCurrentSlideParams,
  IPresentationSlide,
} from "./hooks";
import { IPresentationDataResponse } from "../participantDelivery/hooks";
import { ApiResponse, ApiResult } from "@app/types";
import { ProfileId } from "../reducer";

export const setPresentationSlides = (payload: any) => ({
  type: SET_PRESENTATION_SLIDES,
  payload,
});

export const setParticipantsList = (payload: any) => ({
  type: SET_PARTICIPANTS_LIST,
  payload,
});

export const getFacilitatorPresentation = async (
  presentationPublicId: string,
  dispatch: Dispatch,
): Promise<IPresentationDataResponse> => {
  try {
    const response = await rawAxiosApi.get(
      apiEndPoints.facilitatorPresentation,
      {
        params: { publicId: presentationPublicId },
      },
    );
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const updateCoursePresentationStatus = async (
  coursePresentationId: number,
  languageCode: string,
  status: number,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.put(
      apiEndPoints.updateCoursePresentationStatus,
      // we are posting a raw number in the body, which isn't totally obvious.
      status,
      {
        params: { coursePresentationId, languageCode },
      },
    );
    return response.data.success;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getAllPresentationSlides = async (
  templateId: number,
  dispatch: Dispatch,
): Promise<ApiResult<IPresentationSlide>> => {
  try {
    const response = await rawAxiosApi.get<
      ApiResponse<ApiResult<IPresentationSlide>>
    >(apiEndPoints.getAllPresentationSlides, {
      params: { templateId },
    });
    if (!response.data.success) {
      return await Promise.reject(response.data);
    }
    dispatch(setPresentationSlides(response.data.result.items));
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getPresentationStatus = async (
  cpId: number,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.get(
      apiEndPoints.coursePresentationGetById,
      {
        params: { id: cpId },
      },
    );
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getAllParticipants = async (
  courseId: number,
  presentationId: number,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.get(
      apiEndPoints.getAllPresentationParticipants,
      {
        params: { courseId, presentationId },
      },
    );
    dispatch(setParticipantsList(response.data.result.items));
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const UpdateParticipantScreen = async (
  cpId: number,
  screen: number,
  index: number | null,
  dispatch: Dispatch,
) => {
  const queryParmas = {
    coursePresentationId: cpId,
    slide: String(screen),
    stepIndex: String(index),
  };
  try {
    await rawAxiosApi.put(
      apiEndPoints.updateParticipantsSlide,
      {},
      { params: queryParmas },
    );
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

// i don't understand how this endpoint is useful. despite having the cute
// prefix 'participant' it's only every called from a facilitator context.
//
// it's purpose seems to be to determine which slide the presentation
// participants is currently seeing... but surely we can just read that from
// the presentation itself? why do we need a whole endpoint for it?
//
//   -johan, 2024-11-04
export const getParticipantCurrentSlide = async (
  queryParmas: IParticipantCurrentSlideParams,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.get(apiEndPoints.getTemplateSlideById, {
      params: queryParmas,
    });
    return response;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const deliverProfileParticipants = async (
  profileIds: Array<ProfileId>,
  queryParms: IDeliverProfileParams,
  dispatch: Dispatch,
) => {
  try {
    const response = await rawAxiosApi.post(
      apiEndPoints.participantsProfileDeliver,
      profileIds,
      {
        params: queryParms,
      },
    );
    if (response.data.success) {
      return response.data.success;
    }
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
