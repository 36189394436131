import { Dispatch } from "redux";

import { rawAxiosApi, apiEndPoints } from "../../api";
import { setNotification } from "../actions";
import { IRequestCourseBody } from "./hooks";
import {
  ICreateUpdateCourseBody,
  ICreateUpdateCoursePartsBody,
  IQuizParts,
  IReorderingQuizOrCourseParts,
} from "./addEditCourseModal/hooks";
import { ICoursePermissionBody } from "./addEditCourseModal/editCoursePermission/hooks";
import { IUpdateCourseOrderBody } from "./reorderCoursesModal/reorderCoursesModal";
import { IAcademyCategories, ICourse } from "../academy/types";
import { ApiResponse, ApiResult } from "@app/types";

export const getAllCourses = async (
  body: IRequestCourseBody,
  dispatch: Dispatch,
): Promise<ApiResult<ICourse>> => {
  try {
    const response = await rawAxiosApi.get<ApiResponse<ApiResult<ICourse>>>(
      apiEndPoints.getAllCourses,
      {
        params: body,
      },
    );
    if (!response.data.success) {
      return Promise.reject(response.data);
    }
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getOrderedCourses = async (dispatch: Dispatch) => {
  try {
    const response = await rawAxiosApi.get(apiEndPoints.getOrderedCourses);
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const updateCourseOrder = async (
  body: IUpdateCourseOrderBody[],
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.put(
      apiEndPoints.updateCourseOrder,
      JSON.stringify(body),
    );
    return response.data.success;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getAcademyCategory = async (
  dispatch: Dispatch,
): Promise<Array<IAcademyCategories>> => {
  try {
    const response = await rawAxiosApi.get<
      ApiResponse<Array<IAcademyCategories>>
    >(apiEndPoints.getAcademyCategory);
    if (!response.data.success) {
      return Promise.reject(response.data);
    }
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getAllTagNames = async (dispatch: Dispatch) => {
  try {
    const response = await rawAxiosApi.get(apiEndPoints.getAllTagNames);
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getCoursebyId = async (id: number, dispatch: Dispatch) => {
  try {
    const response = await rawAxiosApi.get(apiEndPoints.getCoursebyId, {
      params: { id },
    });
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const createCourse = async (
  formData: ICreateUpdateCourseBody,
  dispatch: Dispatch,
): Promise<any> => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  try {
    const response = await rawAxiosApi.post(
      apiEndPoints.createCourse,
      formData,
      {
        headers: headers,
      },
    );
    return response.data;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const updateCourse = async (
  formData: ICreateUpdateCourseBody,
  dispatch: Dispatch,
): Promise<any> => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  try {
    const response = await rawAxiosApi.put(
      apiEndPoints.updateCourse,
      formData,
      {
        headers: headers,
      },
    );
    return response.data;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const toggleActiveStatus = async (
  id: number,
  isEnabled: boolean,
  dispatch: Dispatch,
) => {
  try {
    const response = await rawAxiosApi.post(apiEndPoints.toggleActiveStatus, {
      id,
      isEnabled,
    });
    return response.data;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const updateCoursePartsOrder = async (
  courseId: number,
  body: IReorderingQuizOrCourseParts[],
  dispatch: Dispatch,
): Promise<boolean> => {
  try {
    const response = await rawAxiosApi.put(
      apiEndPoints.updateCoursePartsOrder,
      body,
      {
        params: { courseId },
      },
    );
    return response.data.success;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
  return false;
};

export const deleteCourse = async (
  courseId: number,
  dispatch: Dispatch,
): Promise<any> => {
  const paramQuery = {
    id: courseId,
  };
  try {
    await rawAxiosApi.delete(apiEndPoints.deleteCourse, {
      params: paramQuery,
    });
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const createCourseParts = async (
  body: ICreateUpdateCoursePartsBody,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.post(
      apiEndPoints.createCourseParts,
      JSON.stringify(body),
    );
    return response.data;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const updateCourseParts = async (
  body: ICreateUpdateCoursePartsBody,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.put(
      apiEndPoints.updateCourseParts,
      JSON.stringify(body),
    );
    return response.data;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getCoursePartsByCourseId = async (
  courseId: number,
  dispatch: Dispatch,
) => {
  try {
    const response = await rawAxiosApi.get(
      apiEndPoints.getCoursePartsByCourseId,
      {
        params: { courseId },
      },
    );
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

//  create quiz
export const createQuiz = async (
  body: IQuizParts,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.post(
      apiEndPoints.createQuiz,
      JSON.stringify(body),
    );
    return response.data;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getQuizById = async (courseId: number, dispatch: Dispatch) => {
  try {
    const response = await rawAxiosApi.get(apiEndPoints.getQuizById, {
      params: { courseId },
    });
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const updateQuiz = async (body: IQuizParts, dispatch: Dispatch) => {
  try {
    const response = await rawAxiosApi.put(apiEndPoints.updateQuiz, body);
    return response.data;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const updateQuizOrder = async (
  courseId: number,
  body: IReorderingQuizOrCourseParts[],
  dispatch: Dispatch,
): Promise<boolean> => {
  try {
    const response = await rawAxiosApi.put(apiEndPoints.updateQuizOrder, body, {
      params: { courseId },
    });
    return response.data.success;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const deleteQuiz = async (
  id: number,
  dispatch: Dispatch,
): Promise<any> => {
  const paramQuery = {
    id: id,
  };
  try {
    const response = await rawAxiosApi.delete(apiEndPoints.deleteQuiz, {
      params: paramQuery,
    });
    return response.data.success;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const deleteCoursePart = async (
  id: number,
  dispatch: Dispatch,
): Promise<any> => {
  const paramQuery = {
    id: id,
  };
  try {
    const response = await rawAxiosApi.delete(apiEndPoints.deleteCoursePart, {
      params: paramQuery,
    });
    return response.data.success;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const createAcademyCoursePermission = async (
  formData: ICoursePermissionBody,
  dispatch: Dispatch,
): Promise<any> => {
  try {
    const response = await rawAxiosApi.post(
      apiEndPoints.createAcademyCoursePermission,
      formData,
      {},
    );
    return response.data;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export type ITinyUser = {
  id: number;
  name: string;
};

export const getParticipantUserNames = async (
  searchText: string,
  dispatch: Dispatch,
): Promise<Array<ITinyUser>> => {
  try {
    const response = await rawAxiosApi.get(
      apiEndPoints.getParticipantUserNames,
      {
        params: { searchText },
      },
    );
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export type ITinyClient = {
  id: number;
  name: string;
};

export const getCoursePermissionByCourseId = async (
  courseId: number,
  dispatch: Dispatch,
) => {
  try {
    const response = await rawAxiosApi.get(
      apiEndPoints.getCoursePermissionByCourseId,
      {
        params: { courseId },
      },
    );
    return response.data.result;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const deleteAcademyCoursePermission = async (
  id: number,
  dispatch: Dispatch,
): Promise<any> => {
  const paramQuery = {
    id: id,
  };
  try {
    const response = await rawAxiosApi.delete(
      apiEndPoints.deleteAcademyCoursePermission,
      {
        params: paramQuery,
      },
    );
    return response.data;
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};
