import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";

import { IFocusError } from "../../../components/formComponents/input";
import { RootState } from "@app/store/configureStore";
import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import {
  createCourse,
  createCourseParts,
  getAcademyCategory,
  getAllCourses,
  getAllTagNames,
  getCoursePartsByCourseId,
  getCoursebyId,
  updateCourse,
  updateCourseParts,
  createQuiz,
  deleteQuiz,
  getQuizById,
  updateCoursePartsOrder,
  updateQuiz,
  updateQuizOrder,
  deleteCoursePart,
} from "../actions";
import {
  IDropdownList,
  IDropdownSelectedItem,
} from "@app/components/formComponents/dropdownSelect";
import { getFacilitatorLanguages } from "../../languageList/languageTexts/actions";
import { RenderFlagComponent } from "../../languageList/renderFlagComponent";
import { API_BASE_URL } from "../../../constants";
import { IRequestCourseBody } from "../hooks";
import {
  addToast,
  getMediaURL,
  setNotification,
  setSpinner,
} from "../../actions";
import { UserGeneratedMediaType } from "../../commonEnums";
import { IReorderData } from "../../../components/reorderingModel/reorderModal";
import { ILanguage } from "@app/containers/commonInterfaces";

export interface ICreateUpdateCourseBody {
  id: number;
  logoFile?: File | null;
  logo?: string;
  title: string;
  keyIdeas: number;
  idiLanguageId: number;
  categoryId: number;
  tags: string;
  description: string;
  keyMessage: string;
  actionableAdvice: string;
  suggestedFurtherReading: string;
  references: string;
  isEnabled?: boolean;
  onlyForDeliveredProfile: boolean;
}

export interface ICreateUpdateCoursePartsBody {
  id: number;
  courseId: number;
  partNumber: number;
  title: string;
  duration: number;
  dimension: number;
  grade: number;
  content: string;
}

export const CourseInputs = {
  id: "id",
  title: "title",
  keyIdeas: "keyIdeas",
  idiLanguageId: "idiLanguageId",
  categoryId: "categoryId",
  tags: "tags",
  logoFile: "logoFile",
  logo: "logo",
  description: "description",
  keyMessage: "keyMessage",
  actionableAdvice: "actionableAdvice",
  suggestedFurtherReading: "suggestedFurtherReading",
  references: "references",
  onlyForDeliveredProfile: "onlyForDeliveredProfile",
} as const;

export enum CourseParts {
  title = "title",
  duration = "duration",
  content = "content",
  dimension = "dimension",
  grade = "grade",
}

export enum ReorderOption {
  courseParts = "courseParts",
  quiz = "quiz",
}

export enum QuizParts {
  question = "question",
  correctAnswerReason = "correctAnswerReason",
  answerOption = "answerOption",
  isCorrect = "isCorrect",
}

export interface ICourseFocusInput {
  title: IFocusError;
  description: IFocusError;
  keyIdeas: IFocusError;
  actionableAdvice: IFocusError;
  references: IFocusError;
  suggestedFurtherReading: IFocusError;
  keyMessage: IFocusError;
  idiLanguageId: IFocusError;
  categoryId: IFocusError;
}

export interface ICourses {
  id: number;
  logoFile: File | null;
  logo: string;
  title: string;
  keyIdeas: string;
  idiLanguageId: number;
  categoryId: string;
  tags: string[];
  description: string;
  keyMessage: string;
  actionableAdvice: string;
  suggestedFurtherReading: string;
  references: string;
  onlyForDeliveredProfile: boolean;
  duration: number;
  logoFullUrl?: string;
}

export interface ICourseParts {
  id: number;
  partNumber: number;
  title: string;
  duration: string;
  dimension: string;
  grade: string;
  content: string;
  isSaved?: boolean;
  isError: boolean;
}

export interface ILogo {
  logo: string;
  logoFullUrl: string;
}

export interface IQuizChoicePart {
  quizId: number;
  answerOption: string;
  isCorrect: boolean;
  id?: number;
}

export interface IQuizParts {
  courseId: number;
  question: string;
  correctAnswerReason: string;
  orderNumber: number;
  quizOptions: IQuizChoicePart[];
  isError?: boolean;
  id: number;
}

export interface IReorderingQuizOrCourseParts {
  id: number;
  partNumber?: number;
  orderNumbber?: number;
}

export interface IAddEditCourseModalProps {
  editCourseId: number;
  closeCourseModal: (refreshCourseList: boolean) => void;
  refetchCourseList: () => void;
}

export const useAddEditCourseModal = (props: IAddEditCourseModalProps) => {
  const dispatch = useDispatch();
  const bottomRef = useRef<HTMLDivElement>(null);
  const baseUrl = API_BASE_URL || "";
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [existingIconModal, setExistingIconModal] = useState<boolean>(false);
  const [uniqueIcons, setUniqueIcons] = useState<ILogo[]>([]);
  // Course states
  const [courseInfo, setCourseInfo] = useState<ICourses>({
    id: 0,
    logoFile: null,
    logo: "",
    title: "",
    keyIdeas: "",
    idiLanguageId: 0,
    categoryId: "",
    tags: [],
    onlyForDeliveredProfile: false,
    description: "",
    keyMessage: "",
    actionableAdvice: "",
    suggestedFurtherReading: "",
    references: "",
    duration: 0,
  });
  const [focusInput, setFocusInput] = useState<ICourseFocusInput>({
    title: {
      touched: false,
      errorMessage: "",
    },
    description: {
      touched: false,
      errorMessage: "",
    },
    keyIdeas: {
      touched: false,
      errorMessage: "",
    },
    actionableAdvice: {
      touched: false,
      errorMessage: "",
    },
    references: {
      touched: false,
      errorMessage: "",
    },
    suggestedFurtherReading: {
      touched: false,
      errorMessage: "",
    },
    keyMessage: {
      touched: false,
      errorMessage: "",
    },
    idiLanguageId: {
      touched: false,
      errorMessage: "",
    },
    categoryId: {
      touched: false,
      errorMessage: "",
    },
  });
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [imgErrorMessage, setImgErrorMessage] = useState<string>("");
  const [reorderOption, setReorderOption] = useState<string>("");
  const [languages, setLanguages] = useState<ReadonlyArray<ILanguage>>([]);
  const [categories, setCategories] = useState<IDropdownList[]>([]);
  const [tagNames, setTagNames] = useState<string[]>([]);
  const [courseId, setCourseId] = useState<number>(0);
  const [openReorderModel, setOpenReorderModel] = useState<boolean>(false); //reordering model
  const [dataToReorder, setDataToReorder] = useState<IReorderData[]>([]);
  const [isCourseSaved, setIsCourseSaved] = useState<boolean>(false); // To disable save btn once saved
  const [courseCreated, setCourseCreated] = useState<boolean>(false); // Once course is created successfully, then user is able to add courseparts
  const [courseUpdated, setCourseUpdated] = useState<boolean>(false); // This flag is used to re-render the course data on requirement
  const [logoEdited, setLogoEdited] = useState(false);
  // Course parts states
  const initialCoursePartState: ICourseParts = {
    title: "",
    duration: "",
    partNumber: 1,
    dimension: "",
    grade: "",
    content: "",
    isSaved: false,
    isError: false,
    id: 0,
  };
  const [courseParts, setCourseParts] = useState<ICourseParts[]>([
    initialCoursePartState,
  ]);
  const [canAddNewCoursePart, setCanAddNewCoursePart] =
    useState<boolean>(false); // Prevent user from adding parts before the previous part is created
  //  Course create Quiz
  const [canAddNewQuizPart, setCanAddNewQuizPart] = useState<boolean>(false);
  const [isQuizEdited, setIsQuizEdited] = useState<boolean>(false); // To disable save btn once saved
  const [quizCreated, setQuizCreated] = useState<boolean>(false);
  const [editedQuizIndex, setEditedQuizIndex] = useState<number | null>(null);
  const initialOptions = [
    {
      quizId: 1,
      answerOption: "",
      isCorrect: false,
    },
    {
      quizId: 2,
      answerOption: "",
      isCorrect: false,
    },
    {
      quizId: 3,
      answerOption: "",
      isCorrect: false,
    },
  ];
  const initialQuizPartState: IQuizParts = {
    courseId: 0,
    question: "",
    correctAnswerReason: "",
    orderNumber: 1,
    quizOptions: initialOptions,
    isError: false,
    id: 0,
  };

  const [quizPart, setQuizPart] = useState<IQuizParts[]>([
    initialQuizPartState,
  ]);

  const body: IRequestCourseBody = {
    filter: "",
    sorting: "",
    maxResultCount: 1000,
    skipCount: 0,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setSpinner(true));

        // FIXME: this looks severely incorrect. why are we fetching every single course
        //   when editing some other course? is it just so we get access to all images?
        //   it looks like we really need an index over our uploaded files.
        //   - johan, 2024-11-21
        const response = await getAllCourses(body, dispatch);
        // Fetch media image files for each course and update the response
        const updatedResponse = await Promise.all(
          response.items.map((courseList) => {
            const logoURL = getMediaURL(
              courseList.id,
              UserGeneratedMediaType.AcademyCourse,
            );
            return { ...courseList, logoFullUrl: logoURL };
          }),
        );

        //Getting unique Icon
        const uniqueLogos: string[] = [
          ...new Set<string>(
            updatedResponse
              .filter((item) => item.logo !== null)
              .map((item) => item.logo!),
          ),
        ];

        // Create an array of objects with logo and logoFullUrl properties
        const uniqueLogoObjects: ILogo[] = uniqueLogos.map((logo) => {
          const item = updatedResponse.find((obj) => obj.logo === logo);
          return {
            logo: item?.logo || "",
            logoFullUrl: item?.logoFullUrl || "",
          };
        });
        setUniqueIcons(uniqueLogoObjects);
      } catch (error) {
        dispatch(setNotification(error));
        throw error;
      } finally {
        dispatch(setSpinner(false));
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (courseCreated && !props.editCourseId) {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [courseCreated]);

  // Course
  const getCourse = async (
    courseId: number,
    languages: ReadonlyArray<ILanguage>,
    categories: IDropdownList[],
  ): Promise<void> => {
    await getCoursebyId(courseId, dispatch).then(async (response) => {
      if (response) {
        const idiLanguageId =
          languages.find((language) => language.id === response.idiLanguageId)
            ?.id ?? "";
        const categoryId =
          categories.find(
            (category) => category.value === String(response.categoryId),
          )?.value ?? "";

        const logoURL = getMediaURL(
          response.id,
          UserGeneratedMediaType.AcademyCourse,
        );

        const updatedCourseInfo: ICourses = {
          ...response,
          id: response.id,
          logo: response.logo, // path of logo file getting from api
          logoFullUrl: logoURL,
          title: response.title,
          onlyForDeliveredProfile: response.onlyForDeliveredProfile,
          keyIdeas: String(response.keyIdeas),
          idiLanguageId: idiLanguageId,
          categoryId: categoryId,
          tags: response.existingNames,
          description: response.description,
          references: response.references,
          suggestedFurtherReading: response.suggestedFurtherReading,
          actionableAdvice: response.actionableAdvice,
          keyMessage: response.keyMessage,
          duration: response.duration,
        };
        setCourseInfo(updatedCourseInfo);
      }

      setIsCourseSaved(true);
      setCourseUpdated(false);
    });
  };

  useEffect(() => {
    if (courseId && courseUpdated) {
      getCourse(courseId, languages, categories);
    }
  }, [courseUpdated]);

  const fetchDetails = async () => {
    // Getting Languages
    const facilitatorLanguages: IDropdownList[] = [];
    const facilitatorResponse = await getFacilitatorLanguages(dispatch);
    setLanguages(facilitatorResponse);

    // Getting Categories
    let categories: IDropdownList[] = [];
    const categoryResponse = await getAcademyCategory(dispatch);
    if (categoryResponse?.length > 0) {
      categories = categoryResponse.map((item) => ({
        id: item.id,
        displayName: item.name,
        value: String(item.id),
      }));
    }
    setCategories(categories);

    if (props.editCourseId) {
      setCourseId(props.editCourseId);
      getCourse(props.editCourseId, facilitatorResponse, categories);
    }
  };

  // Getting Tag Names
  const getTagNames = async (): Promise<void> => {
    const tagNames: Array<string> = await getAllTagNames(dispatch);
    setTagNames(tagNames);
  };

  useEffect(() => {
    fetchDetails();
    getTagNames();
  }, []);

  // Course Part
  const updateCoursePartInfo = (courseParts: ICourseParts[]) => {
    const updatedCourseParts: ICourseParts[] = [];

    courseParts.map((part) => {
      updatedCourseParts.push({
        id: part.id,

        partNumber: part.partNumber,
        title: part.title,
        duration: part.duration,
        dimension: part.dimension ? String(part.dimension) : "",
        grade: part.grade ? String(part.grade) : "",
        content: part.content,
        isSaved: true,
        isError: false,
      });
    });
    setCourseParts(updatedCourseParts);
  };

  const getCourseParts = async (courseId: number): Promise<void> => {
    await getCoursePartsByCourseId(courseId, dispatch).then((response) => {
      if (response.items.length > 0) {
        updateCoursePartInfo(response.items);
      } else {
        setCourseParts([initialCoursePartState]);
      }
    });
  };

  // quiz Part
  const updatedQuizParts = (quizPart: IQuizParts[]) => {
    const updatedQuizParts: IQuizParts[] = [];

    quizPart.map((part) => {
      updatedQuizParts.push({
        courseId: part.courseId,
        orderNumber: part.orderNumber,
        question: part.question,
        correctAnswerReason: part.correctAnswerReason,
        quizOptions: part.quizOptions,
        id: part.id,
        isError: false,
      });
    });
    setQuizPart(updatedQuizParts);
  };

  const getQuizParts = async (courseId: number): Promise<void> => {
    await getQuizById(courseId, dispatch).then((response) => {
      if (response.quizes.length > 0) {
        updatedQuizParts(response.quizes);
        setCanAddNewQuizPart(true);
        setIsQuizEdited(true);
      } else {
        setQuizPart([initialQuizPartState]);
        setIsQuizEdited(false);
        setCanAddNewQuizPart(false);
      }
    });
  };

  useEffect(() => {
    if (props.editCourseId) {
      setCourseCreated(true);
      setCanAddNewCoursePart(true);
      getCourseParts(props.editCourseId);
      setIsEditMode(true);
      getQuizParts(props.editCourseId);
      setQuizCreated(true);
    }
  }, []);

  const removeIcon = (): void => {
    setIsEditMode(false);

    setCourseInfo({
      ...courseInfo,
      logo: "",
      logoFile: null,
      logoFullUrl: "",
    });
  };

  const onSelectIcon = (
    selectedIcon: string,
    selectedIconPath: string,
  ): void => {
    setCourseInfo({
      ...courseInfo,
      logo: selectedIcon,
      logoFullUrl: selectedIconPath,
    });

    setExistingIconModal(false);
    setIsCourseSaved(false);
  };

  const onCloseExistingIconModal = (): void => {
    setExistingIconModal(false);
  };

  const onOpenExistingIconModal = (): void => {
    setExistingIconModal(true);
  };

  // Course fn's
  const handleFileUpload = (file: File) => {
    if (file) {
      setImgErrorMessage("");
      const reader = new FileReader();
      reader.onload = (event) => {
        setCourseInfo({
          ...courseInfo,
          logoFile: file,
          logo: reader.result as string,
        });
      };
      reader.readAsDataURL(file);
    } else {
      setCourseInfo({
        ...courseInfo,
        logoFile: null,
        logo: "",
      });
    }

    if (isEditMode) {
      setLogoEdited(true);
    }
    setIsCourseSaved(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name as keyof ICourseFocusInput;
    const value = e.target.value;

    if (name === CourseInputs.keyIdeas) {
      const parsedValue = parseFloat(value);

      if (
        value !== "" &&
        (!/^\d+(\.\d+)?$/.test(value) ||
          isNaN(parsedValue) ||
          parsedValue < 1 ||
          parsedValue > 999)
      ) {
        return;
      }
    }

    setCourseInfo({
      ...courseInfo,
      [name]: value,
    });
    setIsCourseSaved(false);

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  };

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const name = e.target.name as keyof ICourseFocusInput;
    const value = e.target.value;

    setCourseInfo({
      ...courseInfo,
      [name]: value,
    });
    setIsCourseSaved(false);

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  };

  const handleTextEditorChange = (content: string, name: string): void => {
    setCourseInfo({
      ...courseInfo,
      [name]: content,
    });
    setIsCourseSaved(false);
    handleFormErrors(name, content);
  };

  const handleFormErrors = (name: string, value: unknown): void => {
    let errorMessage: string = "";

    switch (name) {
      case CourseInputs.title:
        if (!value) {
          errorMessage = getLanguageValue(languageText, "Title is required");
        }
        break;
      case CourseInputs.description:
        if (!value) {
          errorMessage = getLanguageValue(
            languageText,
            "Description is required",
          );
        }
        break;
      case CourseInputs.keyIdeas:
        if (!value) {
          errorMessage = getLanguageValue(
            languageText,
            "Key ideas is required",
          );
        }
        break;
      case CourseInputs.actionableAdvice:
        if (!value) {
          errorMessage = getLanguageValue(
            languageText,
            "Actionable advice is required",
          );
        }
        break;
      case CourseInputs.references:
        if (!value) {
          errorMessage = getLanguageValue(
            languageText,
            "References is required",
          );
        }
        break;
      case CourseInputs.suggestedFurtherReading:
        if (!value) {
          errorMessage = getLanguageValue(
            languageText,
            "Suggested further reading is required",
          );
        }
        break;
      case CourseInputs.keyMessage:
        if (!value) {
          errorMessage = getLanguageValue(
            languageText,
            "Key message is required",
          );
        }
        break;
      case CourseInputs.idiLanguageId:
        if (!value) {
          errorMessage = getLanguageValue(languageText, "Language is required");
        }
        break;
      case CourseInputs.categoryId:
        if (!value) {
          errorMessage = getLanguageValue(languageText, "Category is required");
        }
        break;
      default:
        break;
    }

    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }));
  };

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    const name = e.target.name;
    const value = e.target.value;
    handleFormErrors(name, value);
  };

  const handleTextAreaBlurEvent = (
    e: React.FocusEvent<HTMLTextAreaElement>,
  ): void => {
    const name = e.target.name;
    const value = e.target.value;
    handleFormErrors(name, value);
  };

  const handleDropdownSelect = (selectedItem: IDropdownSelectedItem): void => {
    const name = selectedItem.name as keyof ICourseFocusInput;
    const value = selectedItem.value;

    setCourseInfo({
      ...courseInfo,
      [name]: value,
    });
    setIsCourseSaved(false);

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value);
    }
  };

  const handleAutoInputChange = (name: string, value: string): void => {
    setCourseInfo({
      ...courseInfo,
      [name]: value.split(","),
    });
    setIsCourseSaved(false);
  };

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const name = e.target.name;

    setCourseInfo({
      ...courseInfo,
      [name]: e.target.checked,
    });
    setIsCourseSaved(false);
  };

  const handleValidationOnSubmit = (): boolean => {
    let hasErrors = false;
    const noFileProvidedError = getLanguageValue(
      languageText,
      "Icon is required",
    );
    const invalidFileError = getLanguageValue(
      languageText,
      "The selected file is not a valid image file",
    );

    if (
      !courseInfo.title ||
      focusInput.title.errorMessage ||
      !courseInfo.description ||
      focusInput.description.errorMessage ||
      !courseInfo.keyIdeas ||
      focusInput.keyIdeas.errorMessage ||
      !courseInfo.actionableAdvice ||
      focusInput.actionableAdvice.errorMessage ||
      !courseInfo.references ||
      focusInput.references.errorMessage ||
      !courseInfo.suggestedFurtherReading ||
      focusInput.suggestedFurtherReading.errorMessage ||
      !courseInfo.keyMessage ||
      focusInput.keyMessage.errorMessage ||
      !courseInfo.idiLanguageId ||
      !courseInfo.categoryId
    ) {
      hasErrors = true;
      for (const item of Object.values(CourseInputs)) {
        handleFormErrors(item, courseInfo[item]);
      }
    }

    if (!courseInfo.logo.match(/\.(png|jpe?g|svg)$/i)) {
      if (courseId && courseInfo.logoFile) {
        if (
          !courseInfo.logoFile ||
          !courseInfo.logoFile.name.match(/\.(png|jpe?g|svg)$/i)
        ) {
          hasErrors = true;
          setImgErrorMessage(invalidFileError);
        }
      } else if (
        !courseId &&
        !courseInfo.logoFile?.name.match(/\.(png|jpe?g|svg)$/i)
      ) {
        hasErrors = true;
        setImgErrorMessage(invalidFileError);
      }

      if (!courseId && !courseInfo.logoFile) {
        hasErrors = true;
        setImgErrorMessage(noFileProvidedError);
      } else if (courseId && !courseInfo.logo) {
        hasErrors = true;
        setImgErrorMessage(noFileProvidedError);
      }
    }

    return !hasErrors;
  };

  const handleSubmit = (): void => {
    setIsSubmitting(true);
    dispatch(setSpinner(true));

    // remove duplicate tags
    const splittedTags =
      courseInfo.tags?.length > 0
        ? courseInfo.tags.map((tag) => tag.trim())
        : [];
    const updatedTags =
      splittedTags.length > 0 ? [...new Set(splittedTags)].join(",") : "";

    const body: ICreateUpdateCourseBody = {
      id: courseId ?? 0,
      logoFile: courseInfo.logoFile!,
      logo: !isEditMode || logoEdited ? courseInfo.logo : "",
      title: courseInfo.title,
      onlyForDeliveredProfile: courseInfo.onlyForDeliveredProfile,
      keyIdeas: Number(courseInfo.keyIdeas),
      idiLanguageId: Number(courseInfo.idiLanguageId),
      categoryId: Number(courseInfo.categoryId),
      tags: updatedTags,
      description: courseInfo.description,
      keyMessage: courseInfo.keyMessage,
      actionableAdvice: courseInfo.actionableAdvice,
      suggestedFurtherReading: courseInfo.suggestedFurtherReading,
      references: courseInfo.references,
    };

    if (!courseId) {
      body.isEnabled = true;
    }

    if (!courseId) {
      createCourse(body, dispatch)
        .then((response) => {
          if (response?.success) {
            const courseId: number = response.result; // Get and Set the created courseId for courseparts
            setCourseId(courseId);
            setCourseCreated(true); // Once course is created, show option to add courseparts
            setIsCourseSaved(true);
            props.refetchCourseList();
            setCourseUpdated(true);
            dispatch(addToast("Course created successfully") as AnyAction);
          }
        })
        .finally(() => {
          setIsEditMode(true);
          setIsSubmitting(false);
          dispatch(setSpinner(false));
        });
    } else {
      updateCourse(body, dispatch)
        .then((response) => {
          if (response?.success) {
            setIsCourseSaved(true);
            setCourseUpdated(true);
            props.refetchCourseList();
            setLogoEdited(false);
            dispatch(addToast("Changes saved successfully") as AnyAction);
          }
        })
        .finally(() => {
          setIsEditMode(true);
          setIsSubmitting(false);
          dispatch(setSpinner(false));
        });
    }
  };

  const handleCourseSubmit = (): void => {
    if (!handleValidationOnSubmit()) return;
    else handleSubmit();
  };

  // Course part fn's
  const handleAddPart = (): void => {
    setCourseParts((prevParts) => [
      ...prevParts,
      {
        title: "",
        duration: "",
        dimension: "",
        grade: "",
        content: "",
        partNumber: prevParts.length + 1,
        isSaved: false,
        isError: false,
        id: 0,
      },
    ]);
  };

  const handleDeleteQuizOption = (index: number, quizIndex: number): void => {
    const currentQuizPart = quizPart[quizIndex];
    const optionToDelete = currentQuizPart.quizOptions[index];

    if (optionToDelete.isCorrect) {
      dispatch(
        setNotification(
          getLanguageValue(
            languageText,
            "Cannot delete the correct answer option",
          ),
        ),
      );
      return;
    }

    const newQuizParts = [...quizPart];
    newQuizParts[quizIndex].quizOptions.splice(index, 1);
    setQuizPart(newQuizParts);
    setEditedQuizIndex(quizIndex);
  };

  const handleDeletePart = (id: number, index: number) => {
    const coursesId = props.editCourseId ? props.editCourseId : courseId;
    if (id) {
      deleteCoursePart(id, dispatch).then((response) => {
        if (response) {
          getCourseParts(coursesId);
          dispatch(addToast("Part Deleted") as AnyAction);
        }
      });
    } else {
      const updatedParts = [
        ...courseParts.slice(0, index),
        ...courseParts.slice(index + 1),
      ];
      setCourseParts(updatedParts);
    }
  };

  const onCoursePartInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ): void => {
    const { name, value } = e.target;

    if (name === CourseParts.duration) {
      const parsedValue = parseFloat(value);

      if (
        value !== "" &&
        (!/^\d+(\.\d+)?$/.test(value) ||
          isNaN(parsedValue) ||
          parsedValue < 1 ||
          parsedValue > 999)
      ) {
        return;
      }
    }

    setCourseParts((prevParts) =>
      prevParts.map((part, i) =>
        i === index
          ? { ...part, [name]: value, isSaved: false, isError: false }
          : part,
      ),
    );
  };

  const onCoursePartTextEditorChange = (
    value: string,
    name: string,
    index: number,
  ): void => {
    const words = value.trim().split(/\s+/).length;
    const duration = String(Math.ceil(words / 5));

    setCourseParts((prevParts) =>
      prevParts.map((part, i) =>
        i === index && part.content !== value
          ? {
              ...part,
              [name]: value,
              duration: duration,
              isSaved: false,
              isError: false,
            }
          : part,
      ),
    );
  };

  const handleCoursePartDropdownSelect = (
    selectedItem: IDropdownSelectedItem,
    index: number,
  ): void => {
    const { name, value } = selectedItem;

    setCourseParts((prevParts) =>
      prevParts.map((part, i) =>
        i === index
          ? { ...part, [name]: value, isSaved: false, isError: false }
          : part,
      ),
    );
  };

  const handleCoursePartValidation = (
    coursePart: ICourseParts,
    index: number,
  ): boolean => {
    let hasError: boolean = false;

    const { title, duration, content } = coursePart;
    if (!title || !duration || !content) {
      hasError = true;
      setCourseParts((prevParts) =>
        prevParts.map((part, i) =>
          i === index ? { ...part, isError: true } : part,
        ),
      );
    }

    return hasError;
  };

  const handleCoursePartSubmit = (
    e: React.FormEvent<HTMLFormElement>,
    index: number,
  ): void => {
    e.preventDefault();
    const updatedCourseParts = [...courseParts]; // Create a copy of the courseParts array
    const part = courseParts[index]; // get the specific course part
    const { title, duration, dimension, grade, content, partNumber, id } = part; // extract the properties

    if (handleCoursePartValidation(part, index)) return;

    setIsSubmitting(true);
    dispatch(setSpinner(true));

    const body: ICreateUpdateCoursePartsBody = {
      id: id ?? 0,
      courseId: courseId,
      partNumber,
      title,
      duration: Number(duration),
      dimension: Number(dimension),
      grade: Number(grade),
      content,
    };

    if (!id) {
      createCourseParts(body, dispatch)
        .then((response) => {
          if (response?.success) {
            part.isSaved = true;
            setCourseParts(updatedCourseParts);
            setCanAddNewCoursePart(true);
            getCourseParts(courseId);
            setQuizCreated(true);
            dispatch(addToast("Coursepart created successfully") as AnyAction);
          }
        })
        .finally(() => {
          setIsSubmitting(false);
          dispatch(setSpinner(false));
        });
    } else {
      updateCourseParts(body, dispatch)
        .then((response) => {
          if (response?.success) {
            part.isSaved = true;
            setCourseParts(updatedCourseParts);
            setCanAddNewCoursePart(true);
            getCourseParts(courseId);
            dispatch(addToast("Coursepart updated successfully") as AnyAction);
          }
        })
        .finally(() => {
          setIsSubmitting(false);
          dispatch(setSpinner(false));
        });
    }
  };

  const closeAddEditCourseModal = (value: boolean) => {
    props.closeCourseModal(value);
  };

  const closeModal = () => closeAddEditCourseModal(false);

  //  Add Quiz Part
  const handleAddQuestions = (): void => {
    setQuizPart((prevParts) => [
      ...prevParts,
      {
        courseId: 0,
        question: "",
        correctAnswerReason: "",
        orderNumber: prevParts.length + 1,
        quizOptions: initialOptions,
        isError: false,
        id: 0,
      },
    ]);
    setCanAddNewQuizPart(false);
  };

  const onQuestionPartInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    quizIndex: number,
  ): void => {
    const name = e.target.name as keyof IQuizChoicePart;
    const value = e.target.value;

    const updatedOptions = [...quizPart[quizIndex].quizOptions];
    updatedOptions[index] = {
      ...updatedOptions[index],
      [name]: value,
    };

    setQuizPart((prevParts) =>
      prevParts.map((part, i) =>
        i === quizIndex
          ? { ...part, quizOptions: updatedOptions, isError: false }
          : part,
      ),
    );
    setEditedQuizIndex(quizIndex);
  };

  const onQuestionInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ): void => {
    const { name, value } = e.target;

    setQuizPart((prevParts) =>
      prevParts.map((part, i) =>
        i === index ? { ...part, [name]: value, isError: false } : part,
      ),
    );
    setEditedQuizIndex(index);
  };

  const onQuestionTextEditorChange = (
    value: string,
    name: string,
    index: number,
  ): void => {
    setQuizPart((prevParts) =>
      prevParts.map((part, i) =>
        i === index ? { ...part, [name]: value, isError: false } : part,
      ),
    );
    setEditedQuizIndex(index);
  };

  const handleOptionChange = (index: number, quizIndex: number) => {
    const updatedOptions = quizPart[quizIndex].quizOptions.map((option, i) => ({
      ...option,
      isCorrect: i === index,
    }));
    setQuizPart((prevParts) =>
      prevParts.map((part, i) =>
        i === quizIndex
          ? { ...part, quizOptions: updatedOptions, isError: false }
          : part,
      ),
    );
    setEditedQuizIndex(quizIndex);
  };

  const handleAddQuizOption = (quizIndex: number) => {
    const newQuizParts = [...quizPart];
    newQuizParts[quizIndex].quizOptions.push({
      quizId: quizPart[quizIndex].quizOptions[0].quizId,
      answerOption: "",
      isCorrect: false,
    });
    setQuizPart(newQuizParts);
  };

  const handleQuizPartValidation = (
    quizPart: IQuizParts,
    index: number,
  ): boolean => {
    let hasError: boolean = false;
    let isCorrectOptionSelected = false;

    for (const option of quizPart.quizOptions) {
      if (option.answerOption.trim() === "") {
        hasError = true;
        setQuizPart((prevParts) =>
          prevParts.map((part, i) =>
            i === index ? { ...part, isError: true } : part,
          ),
        );
      }
      if (option.isCorrect) {
        isCorrectOptionSelected = true;
        break;
      }
    }
    if (!isCorrectOptionSelected) {
      hasError = true;
      setQuizPart((prevParts) =>
        prevParts.map((part, i) =>
          i === index ? { ...part, isError: true } : part,
        ),
      );
    }

    const { question, correctAnswerReason } = quizPart;
    if (!question || !correctAnswerReason) {
      hasError = true;
      setQuizPart((prevParts) =>
        prevParts.map((part, i) =>
          i === index ? { ...part, isError: true } : part,
        ),
      );
    }

    return hasError;
  };

  const handleQuestionPartSubmit = (
    e: React.FormEvent<HTMLFormElement>,
    index: number,
  ): void => {
    e.preventDefault();
    const updatedQuizPart = [...quizPart];
    const part = quizPart[index];
    const { question, correctAnswerReason, orderNumber, quizOptions, id } =
      part;
    const updatedQuizOptions = quizOptions.map((option) => ({
      ...option,
      id: 0,
    }));

    if (handleQuizPartValidation(part, index)) return;

    const body: IQuizParts = {
      courseId: courseId,
      question,
      correctAnswerReason,
      orderNumber,
      quizOptions: isEditMode ? updatedQuizOptions : quizOptions,
      id: id ?? 0,
    };
    if (id || (props.editCourseId && canAddNewQuizPart)) {
      updateQuiz(body, dispatch).then((response) => {
        if (response?.success) {
          setQuizPart(updatedQuizPart);
          setEditedQuizIndex(null);
          dispatch(addToast("Quiz Updated successfully") as AnyAction);
        }
      });
    } else {
      createQuiz(body, dispatch)
        .then((response) => {
          if (response?.success) {
            setQuizPart(updatedQuizPart);
            setCanAddNewQuizPart(true);
            setEditedQuizIndex(null);
            getQuizParts(courseId);
            dispatch(addToast("Quiz created successfully") as AnyAction);
          }
        })
        .finally(() => {
          if (props.editCourseId)
            getQuizById(props.editCourseId, dispatch).then((response) => {
              if (response.quizes.length > 0) {
                updatedQuizParts(response.quizes);
                setCanAddNewQuizPart(true);
              }
            });
        });
    }
  };

  const handleDeleteQuiz = (id: number, index: number) => {
    const coursesId = props.editCourseId ? props.editCourseId : courseId;
    if (id) {
      deleteQuiz(id, dispatch).then((response) => {
        if (response) {
          getQuizParts(coursesId);
          dispatch(addToast("Quiz Deleted") as AnyAction);
        }
      });
    } else {
      const removeQuiz = [
        ...quizPart.slice(0, index),
        ...quizPart.slice(index + 1),
      ];
      setQuizPart(removeQuiz);
      setCanAddNewQuizPart(true);
    }
  };

  const closeReorderingModel = () => {
    setDataToReorder([]);
    setOpenReorderModel(false);
  };

  const openReorderingModel = (option: ReorderOption) => {
    setReorderOption(option);
    let updatedDataToReorder: IReorderData[] = [];
    if (option === ReorderOption.courseParts) {
      updatedDataToReorder = courseParts.map((part) => ({
        id: part.id,
        orderNumber: part.partNumber,
        title: part.title,
      }));
    } else {
      updatedDataToReorder = quizPart.map((quiz) => ({
        id: quiz.id,
        orderNumber: quiz.orderNumber,
        title: quiz.question,
      }));
    }
    setDataToReorder(updatedDataToReorder);
    setOpenReorderModel(true);
  };

  const handleSaveReorder = (updatedList: IReorderData[]): void => {
    const coursesId = props.editCourseId ? props.editCourseId : courseId;

    if (reorderOption === ReorderOption.courseParts) {
      const updatedReorderedList: IReorderingQuizOrCourseParts[] =
        updatedList.map((part) => ({
          id: part.id,
          partNumber: part.orderNumber,
        }));
      updateCoursePartsOrder(coursesId, updatedReorderedList, dispatch).then(
        (res) => {
          if (res) {
            setOpenReorderModel(false);
            setDataToReorder([]);
            getCoursePartsByCourseId(coursesId, dispatch).then((response) => {
              if (response.items.length > 0) {
                updateCoursePartInfo(response.items);
              }
            });
            dispatch(
              addToast("Courseparts re-ordered successfully") as AnyAction,
            );
          }
        },
      );
    } else {
      const updatedReorderedList: IReorderingQuizOrCourseParts[] =
        updatedList.map((part) => ({
          id: part.id,
          orderNumbber: part.orderNumber,
        }));
      updateQuizOrder(coursesId, updatedReorderedList, dispatch).then((res) => {
        if (res) {
          setOpenReorderModel(false);
          setDataToReorder([]);
          getQuizById(coursesId, dispatch).then((response) => {
            if (response.quizes.length > 0) {
              updatedQuizParts(response.quizes);
            }
          });
          dispatch(addToast("Questions re-ordered successfully") as AnyAction);
        }
      });
    }
  };

  return {
    languageText,
    courseInfo,
    focusInput,
    isSubmitting,
    imgErrorMessage,
    languages,
    categories,
    tagNames,
    courseCreated,
    isCourseSaved,
    courseParts,
    bottomRef,
    canAddNewCoursePart,
    existingIconModal,
    baseUrl,
    uniqueIcons,
    isEditMode,
    quizPart,
    quizCreated,
    isQuizEdited,
    editedQuizIndex,
    canAddNewQuizPart,
    openReorderModel,
    dataToReorder,
    reorderOption,
    handleAddPart,
    handleDeletePart,
    handleFileUpload,
    handleInputChange,
    handleTextAreaChange,
    handleBlurEvent,
    handleTextAreaBlurEvent,
    handleDropdownSelect,
    handleAutoInputChange,
    handleCourseSubmit,
    onCoursePartInputChange,
    handleCoursePartDropdownSelect,
    handleCoursePartSubmit,
    closeModal,
    onSelectIcon,
    onCloseExistingIconModal,
    onOpenExistingIconModal,
    removeIcon,
    openReorderingModel,
    closeReorderingModel,
    handleSaveReorder,
    handleOptionChange,
    onQuestionPartInputChange,
    handleQuestionPartSubmit,
    onQuestionInputChange,
    handleAddQuestions,
    handleAddQuizOption,
    handleDeleteQuiz,
    handleDeleteQuizOption,
    handleTextEditorChange,
    onCoursePartTextEditorChange,
    onQuestionTextEditorChange,
    handleCheckboxChange,
  };
};
