import React from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../commonUtils/languageFunctionsHelper";
import { IFacilitatorDashboardLatestProfile } from "../interface";
import { routePath } from "../../routePaths";

interface IFacilitatorDashboardProfileSectionProps {
  languageText: ITranslationObject;
  latestProfile: IFacilitatorDashboardLatestProfile;
}

export const FacilitatorDashboardProfileSection = (
  props: IFacilitatorDashboardProfileSectionProps,
): JSX.Element => {
  const navigate = useNavigate();

  let progressBarWidth: number = 0;
  if (props.latestProfile.addedProfiles) progressBarWidth = 30;
  if (props.latestProfile.invitedProfiles) progressBarWidth += 30;
  if (props.latestProfile.deliveredProfiles) progressBarWidth += 30;

  const handleInvitedParticipantsClick = (): void => {
    if (
      props.latestProfile.id &&
      props.latestProfile.addedProfiles &&
      !props.latestProfile.invitedProfiles
    )
      navigate(
        routePath.editActivity.replace(
          ":id",
          props.latestProfile.id.toString(),
        ),
      );
  };

  return (
    <div className="col-12 col-md-4 mb-7 mb-md-0">
      <div className="card h-100 shadow-sm bg-white rounded border-0">
        <div className="card-header border-0 p-3 fs-5 text-body-tertiary">
          {getLanguageValue(props.languageText, "Ongoing activity")}
        </div>
        <div className="card-body p-4">
          <div className="progress">
            <div
              className="progress-bar bg-success"
              style={{ width: `${progressBarWidth}%` }}
            ></div>
          </div>

          {/* Added Participants & Completion date */}
          <div
            className={clsx(
              "d-flex mt-3 justify-content-between align-items-center p-2 flex-wrap rounded shadow-sm",
              props.latestProfile.addedProfiles ? "grey-bg" : "grey-bg",
            )}
          >
            <div className="small ms-1">
              {getLanguageValue(props.languageText, "Completion")}{" "}
              {props.latestProfile.id
                ? props.latestProfile.completionDate.substring(0, 10)
                : ""}
            </div>
            <span className="border px-2 rounded">
              {props.latestProfile.addedProfiles}
            </span>
          </div>

          {/* Invited Participants */}
          <div
            className={clsx(
              "d-flex mt-1 justify-content-between align-items-center p-2 flex-wrap rounded shadow-sm",
              !props.latestProfile.addedProfiles
                ? "bg-white"
                : !props.latestProfile.invitedProfiles
                  ? "grey-bg"
                  : "grey-bg",
              props.latestProfile.id &&
                props.latestProfile.addedProfiles &&
                !props.latestProfile.invitedProfiles &&
                "cursor-pointer",
            )}
            onClick={handleInvitedParticipantsClick}
          >
            <div className="small ms-1">
              {getLanguageValue(props.languageText, "Invite participants")}
            </div>
            {props.latestProfile.id && !props.latestProfile.invitedProfiles ? (
              <i className="bi bi-arrow-right fs-4 me-2 text-success" />
            ) : (
              <span className="border px-2 rounded">
                {props.latestProfile.invitedProfiles}
              </span>
            )}
          </div>

          {/* Delivered Participants / Profiles */}
          <div
            className={clsx(
              "d-flex mt-1 justify-content-between align-items-center p-2 flex-wrap rounded shadow-sm",
              !props.latestProfile.invitedProfiles
                ? "bg-white"
                : !props.latestProfile.deliveredProfiles
                  ? "grey-bg"
                  : "grey-bg",
            )}
          >
            <div className="small ms-1">
              {getLanguageValue(props.languageText, "Completed profiles")}
            </div>
            <span
              className={clsx(
                "border px-2 rounded",
                props.latestProfile.deliveredProfiles
                  ? "bg-success text-white"
                  : "bg-white",
              )}
            >
              {props.latestProfile.deliveredProfiles}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
