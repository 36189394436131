import React from "react";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../commonUtils/languageFunctionsHelper";
import { ModalComponent } from "@app/components/modals/modalComponent";

interface ICancelPresentationModalProps {
  languageText: ITranslationObject;
  isParticipantDelivery?: boolean;
  handleCloseModal: () => void;
  handleConfirmCancelPresentation: () => void;
}

export const CancelPresentationModal: React.FC<
  ICancelPresentationModalProps
> = (props) => {
  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(
          props.languageText,
          !props.isParticipantDelivery
            ? "Cancel Presentation"
            : "Close Presentation",
        )}
        submitButtonText={getLanguageValue(props.languageText, "Yes")}
        cancelButtonText={getLanguageValue(props.languageText, "No")}
        handleSubmitClick={props.handleConfirmCancelPresentation}
        handleCancelClick={props.handleCloseModal}
        isSubmitDangerButton={true}
      >
        {getLanguageValue(
          props.languageText,
          !props.isParticipantDelivery
            ? "Do you want to leave the presentation"
            : "Do you want to close the presentation",
        )}
        ?
      </ModalComponent>
    </>
  );
};
