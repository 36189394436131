import clsx from "clsx";
import React from "react";

export interface ITabNavItem<T> {
  id: T;
  title: string;
}

interface ITabNavItemProps<T> {
  navTabs: Array<ITabNavItem<T>>;
  className?: string;
  activeTab: T;
  setActiveTab: React.Dispatch<React.SetStateAction<T>>;
}

export function TabNavItem<T>(props: ITabNavItemProps<T>): JSX.Element {
  return (
    <ul className="nav nav-tabs mb-3">
      {props.navTabs.map((tab) => (
        <li
          key={String(tab.id)}
          className={clsx("nav-item", props.className && props.className)}
          onClick={() => props.setActiveTab(tab.id)}
        >
          <button
            className={clsx("nav-link", {
              active: props.activeTab === tab.id,
            })}
            data-bs-toggle="tab"
          >
            {tab.title}
          </button>
        </li>
      ))}
    </ul>
  );
}
