import React from "react";

import { DropdownInputs, useDemographicPage } from "./hooks";
import { DropdownSelect } from "@app/components/formComponents/dropdownSelect";
import { Input } from "../../../../components/formComponents/input";
import { StepComponent } from "../../createProfilePage";
import { CurrentStepPanel } from "../../panels";

export const DemographicPage: StepComponent = (props) => {
  const {
    ageDropdownList,
    genderList,
    sectorList,
    seniorityList,
    educationList,
    demographicInfo,
    handleDropdownSelect,
    handleInputChange,
    goToPrevious,
    onSubmitClick,
  } = useDemographicPage(props);

  const __t = props.__t;
  const title = __t("Voluntary demographic questions");

  switch (props.status) {
    case "upcoming":
      return null;
    case "current":
      return (
        <CurrentStepPanel
          __t={__t}
          title={title}
          onPreviousClick={goToPrevious}
          onNextClick={onSubmitClick}
        >
          <form className="">
            <div className="row p-4 pb-3">
              <div className="col-12 mb-4 pb-1">
                {__t(
                  "Please answer the voluntary demographic questions below and contribute to our statistics",
                )}
              </div>
              <div className="col-lg-4 mb-3">
                <DropdownSelect
                  label={__t("Age")}
                  defaultLabel={__t("Select age")}
                  name={DropdownInputs.age}
                  list={ageDropdownList}
                  handleDropdownSelect={handleDropdownSelect}
                  value={demographicInfo.age}
                />
              </div>
              <div className="col-lg-4 mb-3">
                <DropdownSelect
                  label={__t("Legal Gender")}
                  defaultLabel={__t("Select gender")}
                  name={DropdownInputs.gender}
                  list={genderList}
                  handleDropdownSelect={handleDropdownSelect}
                  value={demographicInfo.gender}
                />
              </div>
              <div className="col-lg-4 mb-3">
                <DropdownSelect
                  name={DropdownInputs.sector}
                  label={__t("Sector")}
                  defaultLabel={__t("Select sector")}
                  value={demographicInfo.sector}
                  list={sectorList}
                  handleDropdownSelect={handleDropdownSelect}
                />
              </div>
              <div className="col-lg-4 mb-3">
                <DropdownSelect
                  name={DropdownInputs.seniority}
                  label={__t("Seniority")}
                  defaultLabel={__t("Select Seniority")}
                  value={demographicInfo.seniority}
                  list={seniorityList}
                  handleDropdownSelect={handleDropdownSelect}
                />
              </div>
              <div className="col-lg-4 mb-3">
                <DropdownSelect
                  name={DropdownInputs.education}
                  label={__t("Education")}
                  defaultLabel={__t("Select Education")}
                  value={demographicInfo.education}
                  list={educationList}
                  handleDropdownSelect={handleDropdownSelect}
                />
              </div>
              <div className="col-lg-4 mb-3">
                <Input
                  name={DropdownInputs.occupation}
                  label={__t("Occupation")}
                  value={demographicInfo.occupation}
                  handleInputChange={handleInputChange}
                />
              </div>
            </div>
          </form>
        </CurrentStepPanel>
      );
    case "completed":
      return null;
  }
};
