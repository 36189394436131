import React from "react";
import { DecimalSeparatorCountries } from "../../containers/placementsList/hooks";

interface IReplaceDecimalCellProps {
  numberValue: number;
  userLanguage: string;
}

export const ReplaceDecimalCell = (props: IReplaceDecimalCellProps) => {
  const value: string = String(props.numberValue);
  const decimalSeparatorCountries = Object.values(DecimalSeparatorCountries);

  if (
    props.userLanguage &&
    decimalSeparatorCountries.includes(
      props.userLanguage as DecimalSeparatorCountries,
    )
  ) {
    if (typeof value === "string" && value.includes(".")) {
      return <span>{value.replace(".", ",")}</span>;
    }
  }

  return <span>{value}</span>;
};
