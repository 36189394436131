import React from "react";
import { useNavigate } from "react-router-dom";

import { routePath } from "../../routePaths";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../commonUtils/languageFunctionsHelper";
import { ICourse } from "../types";

interface ICourseBreadcumbProps {
  languageText: ITranslationObject;
  course: ICourse;
}

export const CourseBreadcumb = (props: ICourseBreadcumbProps): JSX.Element => {
  const navigate = useNavigate();

  return (
    <h4 className="mb-4 d-flex gap-2 flex-wrap fw-bold">
      <span
        role="button"
        onClick={() => navigate(routePath.participantAcademy)}
      >
        {getLanguageValue(props.languageText, "Academy")}
      </span>
      /
      <span
        role="button"
        onClick={() =>
          navigate(
            routePath.participantCategoryCourses.replace(
              ":parentId",
              props.course.parentCategoryId.toString(),
            ),
          )
        }
      >
        {props.course.parentCategoryName}
      </span>
      /
      <span
        role="button"
        onClick={() =>
          navigate(
            routePath.participantCourseLessons.replace(
              ":categoryId",
              props.course.categoryId.toString(),
            ),
          )
        }
      >
        {props.course.categoryName}
      </span>
      / {props.course.title}
    </h4>
  );
};
