import React, { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";

import { ReactTable } from "../../reactTable/reactTable";
import { IInvoiceExistingAddress } from "../invoiceInterface";
import { ModalComponent } from "../../modals/modalComponent";
import {
  ITranslationObject,
  getLanguageValue,
} from "../../../commonUtils/languageFunctionsHelper";

interface ISelectBillingAddress {
  languageText: ITranslationObject;
  existingAddresses: IInvoiceExistingAddress[];
  onSelectAddressClick: (data: IInvoiceExistingAddress) => void;
  closeSelectAddressModal: () => void;
}

export const SelectExistingInvoiceAddress = (props: ISelectBillingAddress) => {
  const tableHeader = useMemo<ColumnDef<IInvoiceExistingAddress, any>[]>(
    () => [
      {
        header: getLanguageValue(props.languageText, "Select"),
        disableSortBy: true,
        cell: ({ ...cellProps }) => (
          <button
            className="btn btn-success btn-sm"
            onClick={() => props.onSelectAddressClick(cellProps.row.original)}
          >
            {getLanguageValue(props.languageText, "Select")}
          </button>
        ),
      },
      {
        header: getLanguageValue(props.languageText, "Company"),
        accessorKey: "company",
        width: "min-w-100px",
      },
      {
        header: getLanguageValue(props.languageText, "Address"),
        cell: ({ ...cellProps }) => {
          return (
            <>
              {cellProps.row.original?.address},{cellProps.row.original?.city},
              {cellProps.row.original?.country},{cellProps.row.original?.zip}
            </>
          );
        },
      },
    ],
    [props.languageText],
  );

  return (
    <ModalComponent
      width="lg"
      headerText={getLanguageValue(
        props.languageText,
        "Select Invoice Address",
      )}
      cancelButtonText={getLanguageValue(props.languageText, "Close")}
      handleCancelClick={props.closeSelectAddressModal}
    >
      <ReactTable
        tableHeader={tableHeader}
        data={props.existingAddresses}
        minHeight="150px"
      />
    </ModalComponent>
  );
};
