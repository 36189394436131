import React from "react";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../../../commonUtils/languageFunctionsHelper";
import { getDirOrAffDeviation } from "@app/commonUtils/dimensionsHelper";
import { IProfileReport } from "@app/types";

interface IAffiliationDirectivenessGraphProps {
  languageText: ITranslationObject;
  report: IProfileReport;
}

export const AffiliationDirectivenessGraph = (
  props: IAffiliationDirectivenessGraphProps,
): JSX.Element => {
  // Directiveness deviation
  const { leftOrBottomDeviation: leftDevD, rightOrTopDeviation: rightDevD } =
    getDirOrAffDeviation(props.report.oDirPos, props.report.outsideStdDevD);
  // Affiliation deviation
  const { leftOrBottomDeviation: bottomDevA, rightOrTopDeviation: topDevA } =
    getDirOrAffDeviation(props.report.oAffPos, props.report.outsideStdDevA);

  return (
    <>
      {/* pad the container to accomodate for the negative margin of the labels (ms-n4) below */}
      <div className="position-relative ps-4">
        <div className="ratio ratio-1x1 border position-relative">
          <div
            className={`idi-position-parent y${props.report.oAffPos} x${props.report.oDirPos}`}
          >
            <div className="idi-position-child bg-success">
              <div className={`deviDir deviDirL${leftDevD}`}></div>
              <div className={`deviDir deviDirR${rightDevD}`}></div>
              <div className={`deviAff deviAffB${bottomDevA}`}></div>
              <div className={`deviAff deviAffT${topDevA}`}></div>
            </div>
          </div>

          <div
            className={`idi-position-parent y${props.report.sAffPos} x${props.report.sDirPos}`}
          >
            <div className="idi-position-child bg-danger rounded-circle"></div>
          </div>

          <div className="d-flex justify-content-between rotate90 ms-n4">
            <div>{getLanguageValue(props.languageText, "Task focus")}</div>
            <div className="fw-bold">
              {getLanguageValue(props.languageText, "Affiliation")}
            </div>
            <div>{getLanguageValue(props.languageText, "People focus")}</div>
          </div>

          <div className="d-flex flex-wrap">
            <div className="h-25 w-25 border-bottom border-end"></div>
            <div className="h-25 w-25 border-bottom border-end"></div>
            <div className="h-25 w-25 border-bottom border-end"></div>
            <div className="h-25 w-25 border-bottom"></div>
            <div className="h-25 w-25 border-bottom border-end"></div>
            <div className="h-25 w-25 border-bottom border-end"></div>
            <div className="h-25 w-25 border-bottom border-end"></div>
            <div className="h-25 w-25 border-bottom"></div>
            <div className="h-25 w-25 border-bottom border-end"></div>
            <div className="h-25 w-25 border-bottom border-end"></div>
            <div className="h-25 w-25 border-bottom border-end"></div>
            <div className="h-25 w-25 border-bottom"></div>
            <div className="h-25 w-25 border-end"></div>
            <div className="h-25 w-25 border-end"></div>
            <div className="h-25 w-25 border-end"></div>
            <div className="h-25 w-25"></div>
          </div>
        </div>
        <div className="d-flex justify-content-between mt-1">
          <div>{getLanguageValue(props.languageText, "Inquiry")}</div>
          <div className="fw-bold">
            {getLanguageValue(props.languageText, "Directiveness")}
          </div>
          <div>{getLanguageValue(props.languageText, "Advocacy")}</div>
        </div>

        <div className="d-flex gap-4 mt-3">
          <div className="d-flex align-items-center gap-2">
            <div className="self-circle bg-danger" />
            {getLanguageValue(props.languageText, "Self")}
          </div>
          <div className="d-flex align-items-center gap-2">
            <div className="other-square bg-success" />
            {getLanguageValue(props.languageText, "Respondents")}
          </div>
        </div>
      </div>
    </>
  );
};
