import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "@app/store/configureStore";
import {
  getProfileParticipantRole,
  setParticipantProfileDetails,
} from "../../actions";
import { setSpinner } from "../../../actions";
import { IProfileDetails } from "@app/types";
import { useParticipantProfileAuth } from "../../useParticipantProfileAuth";
import { participantRoleSelectionInstructionSteps } from "@app/components/instruction/instructionSteps";
import { StepComponentProps, Step } from "../../createProfilePage";
import { TypeOfRole } from "@app/containers/activityList/activityEnums";

export interface IParticipantStepperData {
  id: number;
  title: string;
  page: JSX.Element;
}

export interface IParticipantRoleData {
  roleId: number;
  roleText: string;
  noOfRespondents: number;
  id: number;
}

export const useRolePage = (props: StepComponentProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uniqueCode } = useParticipantProfileAuth();

  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );
  const profileDetails = useSelector(
    (state: RootState) => state.participantReducer.participantProfileDetails,
  );
  const instructionSteps = useMemo(
    () => participantRoleSelectionInstructionSteps(languageText),
    [languageText],
  );

  const [roleCanBeChangedByParticipant, setRoleCanBeChangedByParticipant] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const fetchProfileParticipantRole = async () => {
    dispatch(setSpinner(true));
    const response = await getProfileParticipantRole(
      profileDetails.profileId,
      dispatch,
    ).finally(() => dispatch(setSpinner(false)));

    if (response) {
      // Set role settings
      const {
        roleId,
        roleText,
        noOfRespondents,
        roleCanBeChangedByParticipant,
      } = response;

      const updatedParticipantProfileDetails: IProfileDetails = {
        ...profileDetails,
        roleId: roleId ? roleId : 0,
        roleText: roleText ? roleText : "",
        noOfRespondents: noOfRespondents ? noOfRespondents : 0,
      };
      dispatch(setParticipantProfileDetails(updatedParticipantProfileDetails));
      setRoleCanBeChangedByParticipant(roleCanBeChangedByParticipant);
    }
  };

  useEffect(() => {
    if (profileDetails.profileId) {
      fetchProfileParticipantRole();
    }
  }, [profileDetails.profileId]);

  const handleTypeOfRoleSave = (typeOfRole: string | number): void => {
    let updatedParticipantProfileDetails: IProfileDetails = {
      ...profileDetails,
    };

    if (typeof typeOfRole === "string") {
      updatedParticipantProfileDetails = {
        ...updatedParticipantProfileDetails,
        roleId: TypeOfRole.Colleagues,
        roleText: typeOfRole,
      };
    } else {
      updatedParticipantProfileDetails = {
        ...updatedParticipantProfileDetails,
        roleId: typeOfRole,
        roleText: "",
      };
    }
    dispatch(setParticipantProfileDetails(updatedParticipantProfileDetails));
    if (errorMessage) setErrorMessage("");
  };

  const handleNoOfRespondentsSave = (noOfRespondents: number): void => {
    const updatedParticipantProfileDetails: IProfileDetails = {
      ...profileDetails,
      noOfRespondents: noOfRespondents,
    };
    dispatch(setParticipantProfileDetails(updatedParticipantProfileDetails));
    if (errorMessage) setErrorMessage("");
  };

  return {
    languageText,
    instructionSteps,
    errorMessage,
    roleCanBeChangedByParticipant,
    participantProfileDetails: profileDetails,
    handleTypeOfRoleSave,
    handleNoOfRespondentsSave,
  };
};
