import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "@app/store/configureStore";
import { ICourse, IQuiz, IUserQuizDetails } from "../types";
import { routePath } from "../../routePaths";
import { setSpinner } from "../../actions";
import { useApiEndpoints } from "@app/api/end-points";

export const useQuiz = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const api = useApiEndpoints(dispatch);
  const params = useParams();
  const courseId = Number(params.courseId);
  const quizId = Number(params.quizId);

  // Handling Language
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );

  const [courseQuiz, setCourseQuiz] = useState<ICourse>();
  const [userQuizDetails, setUserQuizDetails] = useState<
    IUserQuizDetails | undefined
  >(undefined);
  const [startedAt, setStartedAt] = useState<number>(Date.now());
  const quizzes = courseQuiz?.quizes ?? [];
  const activeQuiz = quizzes.findIndex((q) => q.id === quizId);

  const fetchCourse = async (): Promise<void> => {
    dispatch(setSpinner(true));
    api
      .getCourseQuizzes(courseId)
      .then((fetchedCourseQuizes) => {
        setCourseQuiz(fetchedCourseQuizes);
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  useEffect(() => {
    fetchCourse();
  }, [courseId]);

  useEffect(() => {
    // TODO: ideally we would load all of these in an array from the backend.
    //   loading them one-by-one is kinda silly and makes the UI slightly more
    //   sluggish.
    api.getUserQuizDetails(quizId).then((res) => {
      setUserQuizDetails(res || undefined);
    });

    setStartedAt(Date.now());
  }, [quizId]);

  const backClickHandler = (): void => {
    let navUrl: string = "";
    if (activeQuiz === 0) {
      api.getUserCourseParts(courseId).then((course: ICourse) => {
        if (course && course.courseParts) {
          navUrl = routePath.participantCourse
            .replace(":courseId", String(courseId))
            .replace(
              ":partId",
              course.courseParts[course.courseParts.length - 1].id.toString() ||
                "",
            );
          navigate(navUrl);
        }
      });
    } else {
      navUrl = routePath.participantQuiz
        .replace(":courseId", String(courseId))
        .replace(":quizId", quizzes[activeQuiz - 1].id.toString());
      navigate(navUrl);
    }
  };

  const forwardClickHandler = async (): Promise<void> => {
    if (!userQuizDetails?.quizOptionId) {
      return;
    }

    // trigger another save so we're certain that the time spent is also updated.
    handleOptionChange(userQuizDetails.quizOptionId);

    // FIXME: this should go away. i'm just doing this to prevent flicker in the view
    //   when the quiz ID changes.
    setUserQuizDetails(undefined);

    let navUrl: string = "";
    if (activeQuiz === quizzes.length - 1) {
      navUrl = routePath.participantCourseSummary.replace(
        ":courseId",
        String(courseId),
      );
      navigate(navUrl);
    } else {
      navUrl = routePath.participantQuiz
        .replace(":courseId", String(courseId))
        .replace(":quizId", quizzes[activeQuiz + 1].id.toString());
      navigate(navUrl);
    }
  };

  const handleOptionChange = (quizOptionId: number): void => {
    // Date.now returns a millisecond timestamp but we want the time spent in whole seconds.
    const timeSpent = Math.round((Date.now() - startedAt) / 1_000);
    const thing: IUserQuizDetails = {
      quizId: quizId,
      quizOptionId: quizOptionId,
      timeSpent: timeSpent,
    };

    setUserQuizDetails(thing);
    api.updateUserQuizDetails(thing);
  };

  return {
    courseQuiz,
    activeQuiz,
    languageText,
    userQuizDetails,
    handleOptionChange,
    forwardClickHandler,
    backClickHandler,
  };
};
