import React, { useMemo } from "react";
import clsx from "clsx";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../commonUtils/languageFunctionsHelper";
import { Checkbox } from "../../../components/formComponents/checkbox";
import { InviteRespondents } from "../activityEnums";

interface IInviteOptions {
  id: number;
  name: keyof typeof InviteRespondents;
  label: string;
}

export interface IInviteRespondents {
  isInviteExternal: boolean;
  isInviteColleagues: boolean;
  isInviteOtherParticipant: boolean;
}

interface IActivityRespondentsStepProps {
  languageText: ITranslationObject;
  isGroup: boolean;
  inviteRespondents: IInviteRespondents;
  showRespondentsSaveBtn?: boolean;
  respondentFromEditProfile?: boolean;
  handleRespondentsCheckboxChange: (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  onSaveClick?: () => void;
  respondentErrorMessage?: string;
  formName?: string;
}

export const ActivityRespondentsStep = (
  props: IActivityRespondentsStepProps,
) => {
  const inviteRespondentsOptions = useMemo(() => {
    const options: IInviteOptions[] = [
      {
        id: 1,
        name: InviteRespondents.isInviteExternal,
        label: getLanguageValue(
          props.languageText,
          "Participants can invite external respondents",
        ),
      },
      {
        id: 2,
        name: InviteRespondents.isInviteColleagues,
        label: getLanguageValue(
          props.languageText,
          "Participants can choose other colleagues as respondents",
        ),
      },
      {
        id: 3,
        name: InviteRespondents.isInviteOtherParticipant,
        label: getLanguageValue(
          props.languageText,
          "Participants can choose other participants as respondents",
        ),
      },
    ];

    return options;
  }, [props.languageText]);

  return (
    <>
      <div id="activityRespondentStep">
        {!props.respondentFromEditProfile && (
          <div className="fs-5 fw-bold mb-3">
            {getLanguageValue(props.languageText, "Respondents")}
          </div>
        )}

        {inviteRespondentsOptions.map((item) => {
          const checked = props.inviteRespondents[item.name];
          return (
            <Checkbox
              key={item.id}
              className="mb-3"
              inputClass={clsx(!checked && "bg-white border-secondary")}
              labelClass="ms-2"
              name={item.name}
              label={item.label}
              value={checked}
              handleCheckboxChange={props.handleRespondentsCheckboxChange}
            />
          );
        })}

        {props.respondentErrorMessage && (
          <div className="invalid-feedback d-inline-block">
            {props.respondentErrorMessage}
          </div>
        )}

        {props.showRespondentsSaveBtn && !props.respondentFromEditProfile && (
          <div className="mt-4">
            <span
              className="fs-5 fw-bold text-decoration-underline"
              onClick={props.onSaveClick}
              role="button"
            >
              {getLanguageValue(props.languageText, "Save and continue")}
            </span>
          </div>
        )}
      </div>
    </>
  );
};
