import React from "react";

import {
  ILanguageObject,
  ITranslationObject,
  getLanguageValue,
} from "../../commonUtils/languageFunctionsHelper";
import famfamfam_flags from "../../../public/DeliveryImages/famfamfam_flags.png";
import { ModalComponent } from "./modalComponent";

interface ILanguageModalProps {
  languageText: ITranslationObject;
  languages: ILanguageObject[];
  headerText: string;
  selectedProfileLanguageId?: number;
  handleChangeLanguage: (selectedLanguage: ILanguageObject) => void;
  closeLanguageModal: () => void;
}

export const LanguageModal = (props: ILanguageModalProps) => {
  return (
    <>
      <ModalComponent
        headerText={props.headerText}
        cancelButtonText={getLanguageValue(props.languageText, "Close")}
        handleCancelClick={props.closeLanguageModal}
      >
        <div className="row">
          {props.languages.map((lang) => {
            return (
              <button
                key={lang.id}
                className={`btn col-4 d-flex align-items-center gap-3 w-20  ${
                  props.selectedProfileLanguageId === lang.id ? "disabled" : ""
                }`}
                onClick={() => props.handleChangeLanguage(lang)}
              >
                <div
                  className={`${lang.icon}`}
                  style={{ backgroundImage: `url(${famfamfam_flags})` }}
                />
                <span>{lang.displayName}</span>
                <span>
                  {props.selectedProfileLanguageId === lang.id && (
                    <i className="bi bi-check-lg text-success"></i>
                  )}
                </span>
              </button>
            );
          })}
        </div>
      </ModalComponent>
    </>
  );
};
