import React from "react";

import {
  DropdownSelect,
  IDropdownList,
} from "@app/components/formComponents/dropdownSelect";
import { Input } from "../../../components/formComponents/input";
import { ToggleCheckbox } from "@app/components/formComponents/toggleCheckbox";
import {
  IAddEditPlacementProps,
  useAddEditPlacementModal,
  PlacementsInput,
} from "./hooks";
import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import { ModalComponent } from "@app/components/modals/modalComponent";
import { ICulture } from "@app/containers/commonInterfaces";

export const AddEditPlacementModal: React.FC<IAddEditPlacementProps> = (
  props,
): JSX.Element => {
  const {
    isEditClicked,
    languageText,
    placementInfo,
    focusInput,
    isSubmitting,
    bhaveOptions,
    onSubmitClick,
    handleCheckboxChange,
    handleDropdownSelect,
    handleBlurEvent,
    handleInputChange,
    closeModal,
  } = useAddEditPlacementModal(props);

  const cultureDropdownItems: Array<IDropdownList> = props.cultures.map((c) => {
    return {
      id: c.id,
      displayName: c.displayName,
      value: String(c.id), // matched against 'cultureId'
    };
  });

  return (
    <>
      <ModalComponent
        headerText={
          isEditClicked
            ? getLanguageValue(languageText, "Edit Placement")
            : getLanguageValue(languageText, "Add Placement")
        }
        submitButtonText={getLanguageValue(languageText, "Submit")}
        cancelButtonText={getLanguageValue(languageText, "Cancel")}
        handleSubmitClick={onSubmitClick}
        handleCancelClick={closeModal}
        submitButtonDisabled={isSubmitting}
        cancelButtonDisabled={isSubmitting}
      >
        <form>
          <DropdownSelect
            dropdownHeight="mh-500px"
            name={PlacementsInput.countryCode}
            label={getLanguageValue(languageText, "Country")}
            defaultLabel={getLanguageValue(languageText, "Select Country")}
            list={props.countriesList}
            value={placementInfo.countryCode}
            focusInput={focusInput.countryCode}
            handleDropdownSelect={handleDropdownSelect}
            searchOption
            required
          />
          <DropdownSelect
            dropdownHeight="mh-500px"
            name={PlacementsInput.idiLanguageId}
            label={getLanguageValue(languageText, "Culture")}
            defaultLabel={getLanguageValue(languageText, "Select Culture")}
            list={cultureDropdownItems}
            value={String(placementInfo.idiLanguageId)}
            focusInput={focusInput.idiLanguageId}
            handleDropdownSelect={handleDropdownSelect}
            searchOption
            required
          />
          <Input
            name={PlacementsInput.position}
            label={getLanguageValue(languageText, "Position")}
            placeholder={getLanguageValue(languageText, "Position")}
            value={placementInfo.position}
            errorMessage={focusInput.position.errorMessage}
            handleBlurEvent={handleBlurEvent}
            handleInputChange={handleInputChange}
            tooltipText={getLanguageValue(
              languageText,
              "Number must be between 0-100",
            )}
            required
          />
          <Input
            name={PlacementsInput.low}
            label={getLanguageValue(languageText, "Low")}
            placeholder={getLanguageValue(languageText, "Low")}
            value={placementInfo.low}
            errorMessage={focusInput.low.errorMessage}
            handleBlurEvent={handleBlurEvent}
            handleInputChange={handleInputChange}
            tooltipText={getLanguageValue(
              languageText,
              "Number must be between 0-7",
            )}
            required
          />
          <Input
            name={PlacementsInput.high}
            label={getLanguageValue(languageText, "High")}
            placeholder={getLanguageValue(languageText, "High")}
            value={placementInfo.high}
            errorMessage={focusInput.high.errorMessage}
            handleBlurEvent={handleBlurEvent}
            handleInputChange={handleInputChange}
            tooltipText={getLanguageValue(
              languageText,
              "Number must be between 0-7",
            )}
            required
          />
          <Input
            name={PlacementsInput.description}
            label={getLanguageValue(languageText, "Description")}
            placeholder={getLanguageValue(languageText, "Description")}
            value={placementInfo.description}
            handleBlurEvent={handleBlurEvent}
            handleInputChange={handleInputChange}
          />
          <DropdownSelect
            label={getLanguageValue(languageText, "Behave")}
            name={PlacementsInput.behave}
            list={bhaveOptions}
            defaultLabel={getLanguageValue(languageText, "Select Behave")}
            value={placementInfo.behave}
            focusInput={focusInput.behave}
            handleDropdownSelect={handleDropdownSelect}
            required
          />
          <ToggleCheckbox
            label={getLanguageValue(languageText, "Self")}
            name={PlacementsInput.self}
            value={placementInfo.self}
            handleCheckboxChange={handleCheckboxChange}
          />
        </form>
      </ModalComponent>
    </>
  );
};
