import React, { useEffect, useMemo, useState } from "react";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../commonUtils/languageFunctionsHelper";
import {
  ToggleButtonGroup,
  IButtonGroupList,
} from "../../../components/formComponents/toggleButtonGroup";
import { IndividualGroupBtnsEnum } from "../activityEnums";
import { ChangeProfileModal } from "./changeProfile/changeProfileModal";
import { ActivityId } from "@app/containers/reducer";
interface IActivityGroupSelectionStepProps {
  languageText: ITranslationObject;
  selectedBtn: string;
  showEditModeGroupSelect?: boolean;
  createdActivityId: ActivityId;
  stepsCompleted: number[];
  setShowEditModeGroupSelect?: React.Dispatch<React.SetStateAction<boolean>>;
  handleButtonChange: (name: string) => void;
  onSaveClick: () => void;
}

export const ActivityGroupSelectionStep = (
  props: IActivityGroupSelectionStepProps,
): JSX.Element => {
  const individualGroupBtns = useMemo(
    () =>
      [
        {
          id: 1,
          name: IndividualGroupBtnsEnum.group,
          title: getLanguageValue(props.languageText, "Multiple Participants"),
        },
        {
          id: 2,
          name: IndividualGroupBtnsEnum.individual,
          title: getLanguageValue(props.languageText, "One Participant"),
        },
      ] as IButtonGroupList[],
    [props.languageText],
  );

  const [changeProfileTypeModal, setChangeProfileTypeModal] =
    useState<boolean>(false);
  const [groupTitle, setGrouptitle] = useState<string>("");

  useEffect(() => {
    if (props.selectedBtn !== "") {
      const filteredArray = individualGroupBtns.filter((value) =>
        props.selectedBtn.includes(value.name),
      );
      setGrouptitle(filteredArray[0].title);
    } else {
      setGrouptitle("");
    }
  }, [groupTitle, props.selectedBtn, props.languageText]);

  const handleButtonClick = (name: string): void => {
    if (props.createdActivityId) {
      setChangeProfileTypeModal(true);
    } else props.handleButtonChange(name);
    if (props.stepsCompleted.length == 2) {
      if (props.setShowEditModeGroupSelect)
        props.setShowEditModeGroupSelect(false);
    }
  };

  const closeChangeProfileTypeModal = (): void => {
    setChangeProfileTypeModal(false);
    if (props.setShowEditModeGroupSelect)
      props.setShowEditModeGroupSelect(false);
  };

  const onProfileChange = (): void => {
    props.handleButtonChange("");
    closeChangeProfileTypeModal();
  };

  const getChangeProfileMessage = (): string => {
    let message: string = "";

    if (props.selectedBtn === IndividualGroupBtnsEnum.group) {
      message =
        "Do you want to change profile type to individual? It will clear your selected profile details";
    } else {
      message = "Do you want to change profile type to group?";
    }

    return getLanguageValue(props.languageText, message);
  };

  const onSelectGroupBtnClick = () => {
    if (props.setShowEditModeGroupSelect)
      props.setShowEditModeGroupSelect(true);
  };

  return (
    <>
      <div className="mb-4 p-4 rounded shadow-sm bg-white">
        <div className="fs-5 fw-bold mb-3">
          {getLanguageValue(
            props.languageText,
            "Group or Individual IDI profile",
          )}
        </div>
        {props.showEditModeGroupSelect ? (
          <ToggleButtonGroup
            buttonList={individualGroupBtns}
            selectedBtn={props.selectedBtn}
            className="me-2"
            handleButtonClick={handleButtonClick}
          />
        ) : (
          <div className="d-flex align-items-center">
            <div className="btn me-3 border">{groupTitle}</div>
            <button className="btn btn-light" onClick={onSelectGroupBtnClick}>
              <i className="bi bi-pencil ms-1"></i>
            </button>
          </div>
        )}

        {!props.stepsCompleted.includes(1) && (
          <div className="mt-4">
            <span
              className="fs-5 fw-bold text-decoration-underline"
              onClick={props.onSaveClick}
              role="button"
            >
              {getLanguageValue(props.languageText, "Save and continue")}
            </span>
          </div>
        )}
      </div>
      {changeProfileTypeModal && (
        <ChangeProfileModal
          languageText={props.languageText}
          bodyText={getChangeProfileMessage()}
          handleCancelClick={closeChangeProfileTypeModal}
          handleSaveClick={onProfileChange}
        />
      )}
    </>
  );
};
