import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  LoginSocialGoogle,
  IResolveParams,
  LoginSocialMicrosoft,
} from "reactjs-social-login";

import { Input } from "../../../components/formComponents/input";
import { LoginTermsFooter } from "./loginTermsFooter";
import { ExternalUserLogin } from "./externalUserLogin";
import { setNotification } from "../../actions";
import { getLanguageValue } from "../../../commonUtils/languageFunctionsHelper";
import { LoginErrorObject, UserInputs } from "./hooks";
import { routePath } from "@app/containers/routePaths";
import { GoogleIcon, MicrosoftIcon } from "./signUpResources";

interface LoginScreenProps {
  languageText: any;
  email: string;
  password: string;
  formError: LoginErrorObject;
  setForgotPasswordPage: () => void;
  handleSubmit: (e: { preventDefault: () => void }) => void;
  handleEmailChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handlePasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlurEvent: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const LoginScreen: React.FC<LoginScreenProps> = (props) => {
  const dispatch = useDispatch();

  const [provider, setProvider] = useState<string>("");

  /** FIXME: any? what? */
  const [profile, setProfile] = useState<any>();
  const usernameRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    usernameRef.current?.focus();
  }, []);

  return (
    <>
      <div className="d-flex flex-column justify-content-between">
        <div className="p-0">
          <Link
            className="btn border border-secondary-subtle mb-4 w-100 fw-bold"
            to={routePath.register}
          >
            {getLanguageValue(props.languageText, "New here? Sign up!")}
          </Link>
          <div className="mb-4 fs-4 fw-bolder">
            {getLanguageValue(props.languageText, "Sign in")}
          </div>
          <form onSubmit={props.handleSubmit} className="form w-100 mt-2">
            <Input
              type="text"
              handleInputChange={props.handleEmailChange}
              value={props.email || ""}
              errorMessage={props.formError.email.errorMessage}
              handleBlurEvent={props.handleBlurEvent}
              placeholder={getLanguageValue(props.languageText, "Email")}
              name={UserInputs.email}
              required={true}
              checkMarkNotRequired={true}
              inputRef={usernameRef}
            />
            <Input
              handleInputChange={props.handlePasswordChange}
              value={props.password || ""}
              errorMessage={props.formError.password.errorMessage}
              handleBlurEvent={props.handleBlurEvent}
              placeholder={getLanguageValue(props.languageText, "Password")}
              name={UserInputs.password}
              id="password"
              required={true}
              checkMarkNotRequired={true}
              type="password"
            />
            <button type="submit" className="btn btn-success w-100 mb-3">
              {getLanguageValue(props.languageText, "Sign in")}
            </button>
            <a
              className="fw-bold"
              onClick={props.setForgotPasswordPage}
              id="forget-password"
              href="#"
            >
              {getLanguageValue(props.languageText, "Forgot password")}?
            </a>
          </form>
          {provider && profile && (
            <ExternalUserLogin provider={provider} profile={profile} />
          )}
          <div className="row mt-3">
            <div className="col-6">
              <LoginSocialGoogle
                client_id={process.env.REACT_APP_GG_APP_ID || ""}
                redirect_uri={process.env.REDIRECT_URI}
                scope="openid profile email"
                onResolve={({ provider, data }: IResolveParams) => {
                  if (data && (data.access_token || data.code)) {
                    setProvider(provider);
                    setProfile(data);
                  }
                }}
                onReject={(error: any) => {
                  if (process.env.NODE_ENV?.toLowerCase() !== "production") {
                    console.error("Google login error:", error);
                  }
                }}
              >
                <button className="btn border border-secondary-subtle p-2 w-100">
                  <span>
                    {getLanguageValue(props.languageText, "Sign in with")}{" "}
                    <br />
                    <div className="d-flex align-items-end justify-content-center">
                      <span>
                        <GoogleIcon />
                      </span>
                      <span className="ms-2 fw-bold">Google</span>
                    </div>
                  </span>
                </button>
              </LoginSocialGoogle>
            </div>

            <div className="col-6">
              <LoginSocialMicrosoft
                client_id={process.env.REACT_APP_MICROSOFT_APP_ID || ""}
                redirect_uri={process.env.REDIRECT_URI || ""}
                isOnlyGetToken={true}
                scope="profile openid email user.read"
                onResolve={({ provider, data }: IResolveParams) => {
                  setProvider(provider);
                  setProfile(data);
                }}
                onReject={(error: any) => {
                  if (process.env.NODE_ENV?.toLowerCase() !== "production") {
                    console.error("Microsoft login error:", error);
                  }
                }}
              >
                <button className="btn border border-secondary-subtle p-2 w-100">
                  <span>
                    {getLanguageValue(props.languageText, "Sign in with")}{" "}
                    <br />
                    <div className="d-flex align-items-end justify-content-center">
                      <span>
                        <MicrosoftIcon />
                      </span>
                      <span className="ms-2 fw-bold">Microsoft</span>
                    </div>
                  </span>
                </button>
              </LoginSocialMicrosoft>
            </div>
            <LoginTermsFooter languageText={props.languageText} />
          </div>
        </div>
      </div>
    </>
  );
};
