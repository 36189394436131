import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { ReactTooltip } from "../tooltip/reactTooltip";
import { IReorderData } from "./reorderModal";

export const ReorderItem = (props: IReorderData): JSX.Element => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  return (
    <div
      className="d-flex align-items-center rounded mb-2 p-2 bg-secondary-subtle fw-bold gap-2"
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <span className="fs-5">{props.orderNumber}.</span>
      <ReactTooltip id={String(props.orderNumber)} tooltipText={props.title}>
        {props.title}
      </ReactTooltip>
    </div>
  );
};
