import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../../../../commonUtils/languageFunctionsHelper";
import { IProfileReport } from "@app/types";
import { IndividualPreview } from "./individualPreview";
import { setNotification, setSpinner } from "../../../../../../actions";
import { emptyParticipantProfileReport } from "../../../../../../utils";
import { ModalComponent } from "@app/components/modals/modalComponent";
import { ProfileId } from "@app/containers/reducer";
import { useApiEndpoints } from "@app/api/end-points";

interface IPreviewModalProps {
  languageText: ITranslationObject;
  profileId: ProfileId;
  handleCloseClick: () => void;
}

export const IndividualPreviewModal = (
  props: IPreviewModalProps,
): JSX.Element => {
  const dispatch = useDispatch();
  const api = useApiEndpoints(dispatch);

  const initialReportState: IProfileReport = emptyParticipantProfileReport();
  const [participantReport, setParticipantReport] =
    useState<IProfileReport>(initialReportState);

  useEffect(() => {
    dispatch(setSpinner(true));
    api
      .getProfileReport(props.profileId)
      .then(
        (response: IProfileReport) => {
          if (response) {
            setParticipantReport(response);
          }
        },
        (error) => {
          dispatch(setNotification(error));
        },
      )
      .finally(() => dispatch(setSpinner(false)));
  }, []);

  return (
    <>
      <ModalComponent
        headerText={`${getLanguageValue(props.languageText, "Profile")} - ${participantReport.name}`}
        cancelButtonText={getLanguageValue(props.languageText, "Close")}
        handleCancelClick={props.handleCloseClick}
        width="lg"
      >
        <IndividualPreview
          languageText={props.languageText}
          participantReport={participantReport}
        />
      </ModalComponent>
    </>
  );
};
