import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import { IAcademyCoursesLanguages } from "../types";
import { RootState } from "@app/store/configureStore";
import {
  getAcademyCoursesLanguages,
  setAcademyCourseLanguages,
  setCourseLanguageId,
} from "../actions";
import { academyInstructionSteps } from "@app/components/instruction/instructionSteps";
import { Instruction } from "@app/components/instruction/instruction";
import { GuidePages } from "@app/containers/commonEnums";

export const CourseLanguageSelector = (): JSX.Element => {
  const dispatch = useDispatch();

  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );
  const academyCourseLanguages: IAcademyCoursesLanguages[] = useSelector(
    (state: RootState) => state.academyReducer.academyCourseLanguages,
  );
  const selectedCourseLanguageId: number = useSelector(
    (state: RootState) => state.academyReducer.selectedCourseLanguageId,
  );

  useEffect(() => {
    if (!academyCourseLanguages.length) {
      getAcademyCoursesLanguages(dispatch).then((response) => {
        if (response && response.length > 0) {
          dispatch(setAcademyCourseLanguages(response));
        }
      });
    }
  }, []);

  // Instruction steps
  const instructionSteps = useMemo(
    () => academyInstructionSteps(languageText),
    [languageText],
  );

  const handleCourseLanguageClick = (languageId: number): void => {
    if (selectedCourseLanguageId === languageId) return;
    dispatch(setCourseLanguageId(languageId));
  };

  return (
    <>
      {/* Course Language Selection */}
      <div className="d-flex align-items-center float-end">
        {academyCourseLanguages.length > 0 && (
          <div id="academyInstruction2" className="d-flex gap-3 flex-wrap px-3">
            {academyCourseLanguages.map((language) => {
              const selectedLanguage = selectedCourseLanguageId === language.id;
              return (
                <div
                  key={language.id}
                  className={clsx(
                    selectedLanguage && "fw-bold text-decoration-underline",
                  )}
                  onClick={() => handleCourseLanguageClick(language.id)}
                  role="button"
                >
                  {language.name}
                </div>
              );
            })}
          </div>
        )}

        <Instruction
          showHelpButton={true}
          targetElement="academyInstruction8"
          guidePage={GuidePages.Academy}
          instructionSteps={instructionSteps}
        />
      </div>
    </>
  );
};
