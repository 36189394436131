import React from "react";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../commonUtils/languageFunctionsHelper";
import { RoleSettingsWithEditOption } from "./roleSettingsWithEditOption";
import { ModalComponent } from "@app/components/modals/modalComponent";
import { ProfileId } from "@app/containers/reducer";

interface IRoleSettingsModalWithEditOptionProps {
  languageText: ITranslationObject;
  profileId: ProfileId;
  status: number;
  roleId: number;
  roleText: string;
  noOfRespondents: number;
  respondentsAnswered?: number;
  unselectRoleEnabled?: boolean;
  refetchParticipants: () => void;
  refetchOngoingParticipants?: () => void;
  refetchCompletedParticipants?: () => void;
  handleCloseClick: () => void;
}

export const RoleSettingsModalWithEditOption: React.FC<
  IRoleSettingsModalWithEditOptionProps
> = (props) => {
  return (
    <>
      <ModalComponent
        headerText={getLanguageValue(props.languageText, "Role settings")}
        cancelButtonText={getLanguageValue(props.languageText, "Close")}
        handleCancelClick={props.handleCloseClick}
        width="lg"
      >
        <RoleSettingsWithEditOption {...props} canEditNoOfRespondents={true} />
      </ModalComponent>
    </>
  );
};
