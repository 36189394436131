import { Dispatch } from "redux";

import {
  getParticipantFormInfo,
  setParticipantProfileDetails,
} from "@app/containers/participantPages/actions";
import { IProfileDetails } from "@app/types";

export const getParticipantProfileInfo = async (
  uniqueCode: string, // is 'unique code' really our best way of identifying a profile? ok.
  dispatch: Dispatch,
  isSocialLogin: boolean, // social login seems to be synonymous with 'external login'
): Promise<IProfileDetails> => {
  return await getParticipantFormInfo(uniqueCode, dispatch).then((response) => {
    const updatedParticipantProfileDetails: IProfileDetails = {
      languageName: response.languageName,
      participantName: response.participantName,
      isPasswordSet: response.isPasswordSet,
      isDemographicsFilled: response.isDemographicsFilled,
      isFormFilledData: response.isFormFilledData,
      isFormFilled: response.isFormFilled,
      isParticipant: true,
      profileId: response.profileId,
      emailAddress: response.emailAddress,
      noOfRespondents: response.noOfRespondents,
      participantInviteColleagues: response.participantInviteColleagues,
      participantInviteExternal: response.participantInviteExternal,
      participantInviteOtherParticipant:
        response.participantInviteOtherParticipant,

      roleId: response.roleId,
      roleText: response.roleText,
      presentationId: response.presentationId,
      isLocked: response.isLocked,
      isSocialLogin,
      status: response.status,
      respondents: response.respondents,
      roleCanBeChangedByParticipant: response.roleCanBeChangedByParticipant,
      selfDeliveryTemplateId: response.selfDeliveryTemplateId,
      selfDeliverySlideIndex: response.selfDeliverySlideIndex,
    };

    dispatch(setParticipantProfileDetails(updatedParticipantProfileDetails));
    return updatedParticipantProfileDetails;
  });
};
