import React from "react";
import {
  ITranslationObject,
  getLanguageValue,
} from "@app/commonUtils/languageFunctionsHelper";

interface ICultureNormProps {
  languageText: ITranslationObject;
  language: string;
}

export const CultureNorm = (props: ICultureNormProps): JSX.Element => {
  return (
    <div className="border p-2">
      <i className="bi bi-globe2 fs-5 me-2" />
      {getLanguageValue(props.languageText, "Culture Norm")}

      <h5 className="mt-2 fw-bold">{props.language}</h5>
    </div>
  );
};
