import React, { useEffect } from "react";
import clsx from "clsx";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../../commonUtils/languageFunctionsHelper";
import "../../../../css/components/idiMatris.scss";
import {
  canDrawProfileInGraph,
  getProfileGraphLabel,
  handleProfileClick,
} from "../../myIDIProfilesPage/hooks";
import { useNavigate } from "react-router-dom";
import { classNames } from "@app/containers/utils";
import { ProfileId } from "@app/containers/reducer";
import { IMyProfiles } from "@app/types";

interface IParticipantProfileGraphProps {
  languageText: ITranslationObject;
  profiles: ReadonlyArray<IMyProfiles>;
  selectedProfileId?: ProfileId | undefined;
  setSelectedProfileId?: (id: ProfileId | undefined) => unknown;
}

function adjustLine(
  from: HTMLElement,
  to: HTMLElement,
  line: HTMLElement,
): void {
  const fT = from.offsetTop + from.offsetHeight / 2;
  const tT = to.offsetTop + to.offsetHeight / 2;
  const fL = from.offsetLeft + from.offsetWidth / 2;
  const tL = to.offsetLeft + to.offsetWidth / 2;

  const CA = Math.abs(tT - fT);
  const CO = Math.abs(tL - fL);
  const H = Math.sqrt(CA * CA + CO * CO);
  let ANG = (180 / Math.PI) * Math.acos(CA / H);
  let top = 0;
  let left = 0;

  if (tT > fT) {
    top = (tT - fT) / 2 + fT;
  } else {
    top = (fT - tT) / 2 + tT;
  }
  if (tL > fL) {
    left = (tL - fL) / 2 + fL;
  } else {
    left = (fL - tL) / 2 + tL;
  }
  if (
    (fT < tT && fL < tL) ||
    (tT < fT && tL < fL) ||
    (fT > tT && fL > tL) ||
    (tT > fT && tL > fL)
  ) {
    ANG *= -1;
  }
  top -= H / 2;
  line.style.transform = `rotate(${ANG}deg)`;
  line.style.top = `${top}px`;
  line.style.left = `${left}px`;
  line.style.height = `${H}px`;
}

export const ParticipantProfileGraph = (
  props: IParticipantProfileGraphProps,
): JSX.Element => {
  const navigate = useNavigate();

  function graphPositionAsString(profile: IMyProfiles): string {
    return JSON.stringify([profile.oDirPos, profile.oAffPos]);
  }

  /** Saving profileId as well as index since it might be used */
  type ProfileIdAndLabel = [number, string];
  /** the key needs to be a stringified array for the hash comparison to work */
  const graphPositionHash: Map<string, ProfileIdAndLabel[]> = new Map();
  const sortedProfiles = props.profiles
    //Build the hashmap and just return the item so that we don't change the array
    .map((item, index) => {
      /** Let's add 1 to index to start visual profile number from 1 */
      const profileIdAndIndex: ProfileIdAndLabel = [
        item.profileId,
        getProfileGraphLabel(item, props.profiles),
      ];
      const profilePosition = graphPositionAsString(item);
      let positionArray: ProfileIdAndLabel[] = [];
      if (graphPositionHash.has(profilePosition)) {
        positionArray = graphPositionHash.get(profilePosition) ?? [];
      }
      positionArray.push(profileIdAndIndex);
      graphPositionHash.set(profilePosition, positionArray);
      //let's return unchanged
      return item;
    });

  useEffect(() => {
    for (let i = 0; i < sortedProfiles.length - 1; i++) {
      const from = pointAndLineRefs[i];
      const to = pointAndLineRefs[i + 1];

      if (from.line.current && from.point.current && to.point.current) {
        adjustLine(from.point.current, to.point.current, from.line.current);
      }
    }

    // we can depend on the props but not the sorted array.
  }, [props.profiles]);

  // the number of refs needs to be statically allocated, eg. this cannot
  // be a .map() over profiles. in this case we're limiting ourselves to
  // 16 profiles, which should be enough.
  //   -johan, 2024-10-16
  const pointAndLineRefs = new Array(16).fill(0).map(() => {
    return {
      point: React.useRef<HTMLDivElement>(null),
      line: React.useRef<HTMLDivElement>(null),
    };
  });

  return (
    <div className="position-relative">
      <div className="ratio ratio-1x1 border position-relative">
        {sortedProfiles.map((profile, index) => {
          if (!canDrawProfileInGraph(profile)) {
            return null;
          }

          const profilePosition = graphPositionAsString(profile);

          /** Should be at least one, but fallback on empty array to make compiler happy */
          const profilesAtPostition = (
            graphPositionHash.get(profilePosition) || []
          ).map((item) => item[1]);
          const refs = pointAndLineRefs[index];

          return (
            <div key={index}>
              <div
                ref={refs.point}
                className={clsx(
                  `idi-position-parent y${profile.oAffPos} x${profile.oDirPos}`,
                )}
                title={
                  profilesAtPostition.length > 1
                    ? getLanguageValue(
                        props.languageText,
                        "This data point contains two or more profiles",
                      )
                    : profile.title
                }
                // TODO: the hover & click events will obviously not be correct if the point
                //   contains more than one profile, but at least the chart does not feel
                //   totally dead.
                //   -johan, 2024-11-12
                onMouseOver={(event) => {
                  props.setSelectedProfileId?.(profile.profileId);
                }}
                onMouseOut={(event) => {
                  props.setSelectedProfileId?.(undefined);
                }}
                onClick={(event) => {
                  handleProfileClick(profile, navigate);
                }}
                role="button"
              >
                <div
                  className={classNames({
                    "idi-position-child": true,
                    "bg-success": props.selectedProfileId !== profile.profileId,
                    "bg-dark": props.selectedProfileId === profile.profileId,
                  })}
                >
                  {profilesAtPostition.join(",")}
                </div>
              </div>
              {/** Draw the edge between the nodes */}
              <div ref={refs.line} className="lineC" />
            </div>
          );
        })}
        <div className="d-flex justify-content-between rotate90 ms-n4">
          <div>{getLanguageValue(props.languageText, "Task focus")}</div>
          <div className="fw-bold">
            {getLanguageValue(props.languageText, "Affiliation")}
          </div>
          <div>{getLanguageValue(props.languageText, "People focus")}</div>
        </div>
        <div className="d-flex flex-wrap">
          <div className="h-25 w-25 border-bottom border-end"></div>
          <div className="h-25 w-25 border-bottom border-end"></div>
          <div className="h-25 w-25 border-bottom border-end"></div>
          <div className="h-25 w-25 border-bottom"></div>
          <div className="h-25 w-25 border-bottom border-end"></div>
          <div className="h-25 w-25 border-bottom border-end"></div>
          <div className="h-25 w-25 border-bottom border-end"></div>
          <div className="h-25 w-25 border-bottom"></div>
          <div className="h-25 w-25 border-bottom border-end"></div>
          <div className="h-25 w-25 border-bottom border-end"></div>
          <div className="h-25 w-25 border-bottom border-end"></div>
          <div className="h-25 w-25 border-bottom"></div>
          <div className="h-25 w-25 border-end"></div>
          <div className="h-25 w-25 border-end"></div>
          <div className="h-25 w-25 border-end"></div>
          <div className="h-25 w-25"></div>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-1">
        <div>{getLanguageValue(props.languageText, "Inquery")}</div>
        <div className="fw-bold">
          {getLanguageValue(props.languageText, "Directiveness")}
        </div>
        <div>{getLanguageValue(props.languageText, "Advocacy")}</div>
      </div>
    </div>
  );
};
