import { Dispatch } from "redux";
import { rawAxiosApi, apiEndPoints } from "../../api";
import { setNotification, setSpinner } from "../actions";
import { ApiResponse, ApiResult } from "@app/types";
import { ICulture, ILanguage } from "../commonInterfaces";

export const deleteLanguage = async (
  languageId: number,
  dispatch: Dispatch,
): Promise<any> => {
  const paramQuery = {
    id: languageId,
  };
  try {
    await rawAxiosApi.delete(apiEndPoints.deleteLanguage, {
      params: paramQuery,
    });
  } catch (error: any) {
    dispatch(setNotification(error));
    throw error;
  }
};

export const getFacilitatorCultures = async (
  dispatch: Dispatch,
): Promise<ReadonlyArray<ICulture>> => {
  try {
    const response = await rawAxiosApi.get<ApiResponse<ApiResult<ILanguage>>>(
      apiEndPoints.getFacilitatorCultures,
    );

    if (!response.data.success) {
      return Promise.reject();
    }

    const cultures: Array<ICulture> = response.data.result.items.slice();
    cultures.sort((a, b) =>
      a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase()),
    );
    return cultures;
  } catch (error) {
    dispatch(setNotification(error));
    throw error;
  }
};

export type ImportExportTranslations = {
  schemaVersion: number;
  rows: ReadonlyArray<unknown>;
};

export function exportTranslations(
  dispatch: Dispatch,
): Promise<ImportExportTranslations> {
  dispatch(setSpinner(true));
  return rawAxiosApi
    .get<ApiResponse<ImportExportTranslations>>(
      apiEndPoints.exportLanguageTexts,
    )
    .then((res) => {
      if (!res.data.success) {
        return Promise.reject(res.data.error);
      }

      return res.data.result;
    })
    .catch((e) => {
      dispatch(setNotification(e));
      return Promise.reject(e);
    })
    .finally(() => {
      dispatch(setSpinner(false));
    });
}

export function importTranslations(
  data: ImportExportTranslations,
  dispatch: Dispatch,
): Promise<unknown> {
  dispatch(setSpinner(true));
  return rawAxiosApi
    .post(apiEndPoints.importLanguageTexts, data)
    .catch((err) => {
      dispatch(setNotification(err));
      return Promise.reject(err);
    })
    .finally(() => {
      dispatch(setSpinner(false));
    });
}
