import React from "react";

import {
  ITranslationObject,
  getLanguageValue,
} from "../../../commonUtils/languageFunctionsHelper";

interface IDeliveryStatusPendingProps {
  languageText: ITranslationObject;
  coursePresentationDate: string;
  facilitatorName: string;
}

export const DeliveryStatusPending = (
  props: IDeliveryStatusPendingProps,
): JSX.Element => {
  return (
    <div className="load">
      <div className="title-m">
        {getLanguageValue(props.languageText, "IDI Profile Delivery")}
      </div>
      <br />
      <div className="pres-info">
        {getLanguageValue(props.languageText, "Presentation Date")}:
        <br /> <strong> {props.coursePresentationDate}</strong> <br />
        <br />
        {getLanguageValue(props.languageText, "Presenter")}:
        <br /> <strong>{decodeURI(props.facilitatorName)}</strong>
      </div>
      <br />
      {getLanguageValue(
        props.languageText,
        "The presentation has not been started by the presenter yet",
      )}
      ...
    </div>
  );
};
