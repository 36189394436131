export const enum routePath {
  signIn = "/",
  register = "/register",
  registerParticipant = "/register/participant",
  registerFacilitator = "/register/facilitator",
  registerOrganization = "/register/organization",
  facilitatorDelivery = "/f/presentation",
  participantDelivery = "/p/presentation",
  createTemplate = "/createTemplate",
  editTemplate = "/editTemplate",
  createSlide = "/createSlide",
  editSlide = "/editSlide",
  presentationTemplateList = "/templatesList",
  upcomingPresentations = "/upcomingPresentations",
  previewPresentationTemplate = "/templatesList/:id/preview",
  previewPresentationTemplateSelfDelivery = "/templateList/:id/preview-self-delivery",
  slidesList = "/slidesList/:id",
  home = "/home",
  userProfile = "/user-profile",
  plans = "/plans",
  account = "/account",
  languages = "/languages",
  translations = "/translations",
  resetPassword = "/resetPassword",
  verifyEmail = "/verifyemail",
  employeeList = "/employeelist",
  editEmployee = "/employeelist/edit/:id",
  activities = "/activities",
  profiles = "/profiles",
  addActivity = "/activities/add",
  editActivity = "/activities/:id",
  wordPairs = "/wordPairs",
  placement = "/placements",
  switchRoles = "/switchRoles",
  coursesList = "/coursesList",

  // Only Admin routes
  allfacilitators = "/facilitators",
  allparticipants = "/participants",
  allaccounts = "/accounts",
  adminusers = "/adminusers",
  adminNewsList = "/adminnews",
  adminDocumentList = "/admindocuments",
  adminProfileDescriptions = "/adminprofiledescriptions",
  adminEmailTemplates = "/admin/emailtemplates",
  adminLog = "/admin/log",
  adminAlerts = "/admin/alerts",
  adminInvoices = "/admin/invoices",
  adminConsents = "/admin/consents",

  //informations
  information = "/information",
  latestNews = "/latestnews",
  latestBlogs = "/latestblogs",
  latestDocument = "/latestDocuments",
  latestNewsPage = "/latestnews/:id",
  latestBlogPage = "/latestblogs/:id",
  // Participants routes
  dr = "/dr",
  verificationcode = "/verificationcode",
  participantLogIn = "/participant/login",
  participantChangePassword = "/participant/edit", // Change password / Edit participant details page
  participantCreate = "/participant/create", // Demographic page
  participantWelcomePage = "/participant/welcome",
  participantDashboard = "/participant/dashboard",
  participantMyprofiles = "/participant/myprofiles",
  participantProfileReport = "/participant/myprofiles/profileReport/:profileId",
  participantUserProfile = "/participant/user-profile",
  participantAccountPage = "/participant/account",

  // Respondent routes (c -> repsondentCode)
  respondentLanguagePage = "/r/:c",
  respondentLandingPage = "/r/:c/startPage",
  respondentSelfForm = "/r/:c/selfForm",
  respondentThankYou = "/r/:c/thankYou",

  //Academy routes
  participantAcademy = "/participant/academy",
  participantCategoryCourses = "/participant/academy/categoryCourses/:parentId",
  participantCourseLessons = "/participant/academy/categoryCourses/lessons/:categoryId",
  participantCourses = "/participant/lessons",
  participantCourse = "/participant/lessons/:courseId/part/:partId",
  participantQuiz = "/participant/lessons/:courseId/quiz/:quizId",
  participantCourseSummary = "/participant/lessons/:courseId/summary",

  // fortnox install wizard
  fortnoxSetup = "/fortnox-setup",
  profileAdaptabilityDescriptions = "/profile-adaptability-descriptions",
}
