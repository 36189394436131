import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@app/store/configureStore";
import { ColumnDef } from "@tanstack/react-table";
import { CustomActionsCell } from "../../components/reactTable/customActionsCell";
import { BooleanCell } from "../../components/reactTable/booleanCell";
import { getLanguageValue } from "../../commonUtils/languageFunctionsHelper";
import { IDropdownList } from "@app/components/formComponents/dropdownSelect";
import { getAllCountries } from "../clientList/addEditModal/action";
import { ReplaceDecimalCell } from "../../components/reactTable/replaceDecimalCell";
import { getFacilitatorCultures } from "../languageList/actions";
import { getBehaveOptions } from "../allWordsList/interface";
import { setSpinner } from "../actions";
import { ICountry, ICulture } from "../commonInterfaces";
import { Behave } from "../allWordsList/hooks";
import { useApiEndpoints } from "@app/api/end-points";

export interface IPlacements {
  id?: number;
  description: string;
  behave: Behave;
  position: number;
  low: number;
  high: number;
  idiLanguageId: number;
  countryCode: number;
  self: boolean;
}

export interface IListPlacementsParams {
  filter: string;
  filterByCulture: string;
  sorting: string;
  maxResultCount: number;
  skipCount: number;
}

export enum DecimalSeparatorCountries {
  Austria = "de-AT",
  Belgium = "fr-BE",
  Bulgaria = "bg-BG",
  Croatia = "hr-HR",
  CzechRepublic = "cs-CZ",
  Denmark = "da-DK",
  Estonia = "et-EE",
  Finland = "fi-FI",
  France = "fr-FR",
  Germany = "de-DE",
  Greece = "el-GR",
  Hungary = "hu-HU",
  Indonesia = "id-ID",
  Italy = "it-IT",
  Latvia = "lv-LV",
  Lithuania = "lt-LT",
  Luxembourg = "fr-LU",
  Netherlands = "nl-NL",
  Norway = "nb-NO",
  Poland = "pl-PL",
  Portugal = "pt-PT",
  Romania = "ro-RO",
  Slovakia = "sk-SK",
  Slovenia = "sl-SI",
  Spain = "es-ES",
  Sweden = "sv-SE",
  Switzerland = "de-CH",
}

export const usePlacementList = () => {
  const dispatch = useDispatch();
  const api = useApiEndpoints(dispatch);
  const isMounted = useRef(false);
  // Handling Language
  const languageText = useSelector(
    (state: RootState) => state.mainReducer.languageText,
  );
  const userLanguage = useSelector(
    (state: RootState) => state.loginReducer.userLanguage,
  );

  const [data, setData] = useState<IPlacements[]>([]);
  const [filter, setFilter] = useState<string>("");
  const [filterCulture, setFilterCulture] = useState<string>("");
  const [sorting, setSorting] = useState<string>("");
  const [editedRowId, setEditedRowId] = useState<number>(0);
  const [deleteRowId, setDeleteRowId] = useState<number>(0);
  const [selectedActionRowName, setSelectedActionRowName] =
    useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0); // total_data_count
  const [pageLimit, setPageLimit] = useState<number>(10); // items_per_page
  const [pageCount, setPageCount] = useState<number>(0); // total_page_count
  const [pageSelected, setPageSelected] = useState<number>(0); // page_number_selected
  const [openPlacementModal, setOpenPlacementModal] = useState<boolean>(false); // Add/Edit Placement popup
  const [placementUpdated, setPlacementUpdated] = useState<boolean>(false); // This flag is used to re-reder the placement data on requirement
  const [cultures, setCultures] = useState<ReadonlyArray<ICulture>>([]);
  const [countriesList, setCountriesList] = useState<IDropdownList<number>[]>(
    [],
  );

  const bhaveOptions = getBehaveOptions(languageText);
  const tableHeader = useMemo<ColumnDef<IPlacements, any>[]>(
    () => [
      {
        header: "",
        accessorKey: "actions",
        disableSortBy: true,
        cell: ({ ...props }) => (
          <CustomActionsCell
            editOption
            languageText={languageText}
            id={props.row.original.id}
            handleEditClick={handleEditClick}
          />
        ),
      },
      {
        header: getLanguageValue(languageText, "ID"),
        accessorKey: "id",
      },
      {
        header: getLanguageValue(languageText, "Behave"),
        accessorKey: "behave",
        cell: ({ ...props }) => (
          <span>
            {
              bhaveOptions.find(
                (item) => item.value === props.row.original.behave,
              )?.displayName
            }
          </span>
        ),
      },
      {
        header: getLanguageValue(languageText, "Position"),
        accessorKey: "position",
      },
      {
        header: getLanguageValue(languageText, "Low"),
        accessorKey: "low",
        cell: ({ ...props }) => (
          <ReplaceDecimalCell
            numberValue={props.row.original.low}
            userLanguage={userLanguage.userLanguageCode}
          />
        ),
      },
      {
        header: getLanguageValue(languageText, "High"),
        accessorKey: "high",
        cell: ({ ...props }) => (
          <ReplaceDecimalCell
            numberValue={props.row.original.high}
            userLanguage={userLanguage.userLanguageCode}
          />
        ),
      },
      {
        header: getLanguageValue(languageText, "Culture"),
        accessorKey: "cultureId",
        cell: ({ ...props }) => (
          <span>
            {
              cultures.find(
                (item) => item.id === props.row.original.idiLanguageId,
              )?.displayName
            }
          </span>
        ),
      },
      {
        header: getLanguageValue(languageText, "Profile"),
        accessorKey: "self",
        cell: ({ ...props }) => (
          <BooleanCell
            languageText={languageText}
            boolValue={props.row.original.self}
            trueValueText={getLanguageValue(languageText, "Self")}
            falseValueText={getLanguageValue(languageText, "Other")}
          />
        ),
      },
    ],
    [languageText, cultures, countriesList, userLanguage.userLanguageCode],
  );

  useEffect(() => {
    if (isMounted.current) {
      const skipCount = pageLimit * pageSelected;
      fetchPlacement(skipCount);
    }
  }, [pageSelected, sorting]);

  useEffect(() => {
    setPageSelected(0);
    fetchPlacement(0);
    if (!isMounted.current) isMounted.current = true;
  }, [filter, filterCulture, pageLimit]);

  useEffect(() => {
    if (placementUpdated) {
      const skipCount = pageLimit * pageSelected;
      fetchPlacement(skipCount);
    }
  }, [placementUpdated]);

  const fetchPlacement = (skipCount: number): void => {
    dispatch(setSpinner(true));

    const body: IListPlacementsParams = {
      filter: filter,
      filterByCulture: filterCulture,
      sorting: sorting,
      maxResultCount: pageLimit,
      skipCount: skipCount,
    };

    api
      .getAllPlacements(body)
      .then((response) => {
        if (response) {
          const pageSize = Math.ceil(response.totalCount / pageLimit);
          setData(response.items.slice());
          setTotalCount(response.totalCount);
          setPageCount(pageSize);
          if (placementUpdated) setPlacementUpdated(false);
        }
      })
      .finally(() => dispatch(setSpinner(false)));
  };

  useEffect(() => {
    const fetchDetails = async () => {
      const countriesResponse: ICountry[] = await getAllCountries(dispatch);
      const cultureResponse = await getFacilitatorCultures(dispatch);

      let countries: IDropdownList<number>[] = [];

      if (countriesResponse?.length > 0) {
        countries = countriesResponse.map((item) => ({
          id: item.id,
          displayName: item.name,
          value: item.id,
        }));
      }
      setCultures(cultureResponse);
      setCountriesList(countries);
    };

    fetchDetails();
  }, []);

  const addPlacementClick = (): void => {
    setOpenPlacementModal(true);
  };

  const closePlacementModal = (refreshPlacementList: boolean): void => {
    if (refreshPlacementList) {
      setPlacementUpdated(true);
    }
    if (editedRowId) {
      setEditedRowId(0);
    }
    setOpenPlacementModal(false);
  };

  const handleEditClick = (id: number): void => setEditedRowId(id);

  return {
    languageText,
    tableHeader,
    data,
    totalCount,
    pageCount,
    filter,
    pageLimit,
    pageSelected,
    openPlacementModal,
    editedRowId,
    deleteRowId,
    selectedActionRowName,
    cultures,
    countriesList,
    filterCulture,
    setFilter,
    setFilterCulture,
    setSorting,
    setPageLimit,
    setPageSelected,
    addPlacementClick,
    closePlacementModal,
  };
};
